import { FieldFilterType } from "../../base/types";
import { IEntityFilter } from "../../base/models";

export const decodeTableFilters = (filterText: string | null) => {
    if (!filterText) return [];

    const regEx = /(.*)=(Field|Filter|Const)\((.*)\)/;
    const filters: IEntityFilter[] = [];
    const filterItems = JSON.parse(filterText) as string[];

    filterItems.forEach(item => {
        if (!regEx.test(item))
            throw new Error('Invalid filter string');

        const result = regEx.exec(item)
        if (!result)
            throw new Error('Invalid filter string');

        const field = result[1];
        const type: FieldFilterType = result[2] as FieldFilterType;
        const value = result[3];

        filters.push({ field, type, value })
    });

    return filters;
}
