import { ApiTags, rootApi } from '../rootApi';
import { IAuthResponse, IChangePasswordModel as IChangePassword, ILogin, IRegister, IResetPassword } from './models/Auth.models';
import { IAuthTokenModel, IAuthUserModel } from '../../../modules/auth/core/AuthModel';

import { IUserModel } from '../../../modules/auth';

const TAG = ApiTags.Auth;

const authApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    login: builder.mutation<IAuthUserModel, ILogin>({
      query: (model) => ({
        url: '/Authentication/login',
        method: 'POST',
        body: model
      }),
      invalidatesTags: [TAG]
    }),

    register: builder.mutation<IAuthTokenModel, IRegister>({
      query: (model) => ({
        url: `/Authentication/register`,
        method: 'POST',
        body: model
      }),
      invalidatesTags: [TAG]
    }),

    getUserByToken: builder.query<IUserModel, string>({
      query: (token) => ({
        url: `/Authentication/GetUserByToken`,
        method: 'POST',
        body: { token }
      }),
      providesTags: [TAG]
    }),

    registerAdmin: builder.mutation<IAuthTokenModel, IRegister>({
      query: (model) => ({
        url: `/Authentication/register-admin`,
        method: 'POST',
        body: model
      }),
      invalidatesTags: [TAG]
    }),

    requestPassword: builder.mutation<IAuthResponse, string>({
      query: (email) => ({
        url: `/Authentication/RequestPassword`,
        method: 'POST',
        body: JSON.stringify(email),
        headers: { "content-type": "application/json" }
      }),
      invalidatesTags: [TAG]
    }),

    resetPassword: builder.mutation<IAuthTokenModel, IResetPassword>({
      query: (model) => ({
        url: `/Authentication/reset-password`,
        method: 'POST',
        body: model,
      }),
      invalidatesTags: [TAG]
    }),

    changePassword: builder.mutation<IAuthResponse, IChangePassword>({
      query: (model) => ({
        url: `/Authentication/change-password`,
        method: 'POST',
        body: model,
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useRegisterAdminMutation,
  useRequestPasswordMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useGetUserByTokenQuery,
  useLazyGetUserByTokenQuery,
} = authApi;