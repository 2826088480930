import { ApiTags, rootApi } from '../rootApi';

import { ApiResReadMultiple } from '../models';
import { ISite } from './models/Site.model';

const TAG = ApiTags.Site;
const getTags = (entities: ISite[]) => [...entities.map((p) => ({ type: TAG, id: p.portalId })), TAG]

const sitesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getSites: builder.query<ISite[], void>({
      query: () => '/sites',
      transformResponse: (res: ApiResReadMultiple<ISite>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getSite: builder.query<ISite, string>({
      query: (id) => `/sites(${id})`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.portalId }, TAG] : [TAG]
    }),

    createSite: builder.mutation<ISite, ISite>({
      query: (entity) => ({
        url: '/sites',
        method: 'POST',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.portalId }, TAG] : [TAG]
    }),

    updateSite: builder.mutation<ISite, Partial<ISite>>({
      query: (entity) => ({
        url: `/sites(${entity.portalId})`,
        method: 'PATCH',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.portalId }, TAG] : [TAG]
    }),

    deleteSite: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/sites(${id})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetSitesQuery,
  useGetSiteQuery,
  useLazyGetSiteQuery,
  useCreateSiteMutation,
  useUpdateSiteMutation,
  useDeleteSiteMutation,
} = sitesApi;