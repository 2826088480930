import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import clsx from "clsx";
import { useGetProperties } from "../../../base/hooks";

export const TwitterComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const screenName: string | undefined = properties[Property.ScreenName];
    const height: string | undefined = properties[Property.Height];
    const tooltip: string | undefined = properties[Property.Tooltip];

    return (
        <div className={clsx("position-relative overflow-scroll", className)} title={tooltip}>
            {screenName && <TwitterTimelineEmbed
                sourceType="profile"
                screenName={screenName}
                options={{ height: height || 300 }}
            />}
        </div>
    )
}