import { ISPListItem, useDeleteDocument, useDownloadDocument } from "../../../../services/data/sharepoint";
import { MUIIcon, Spinner } from "../../../../widgets";

import { DocumentImage } from "./DocumentImage";
import clsx from "clsx";

export const ViewSPFile = ({ listItem, className, allowDelete, allowSelect, onSelect }: {
    listItem: ISPListItem,
    allowDelete?: boolean,
    downloading?: boolean,
    allowSelect?: boolean,
    onSelect?: (path: string) => void,
    className?: string,
}) => {
    const { downloadDocument, isLoading: downloading } = useDownloadDocument()
    const { deleteDocument, isLoading: isDeleting } = useDeleteDocument()
    const onClickDelete = () => {
        deleteDocument(listItem.serverRelativeUrl)
    }

    return (
        <div className={clsx(className, 'card card-body border p-0')}
            onClick={() => {
                if (!allowSelect || !onSelect) return;
                onSelect(listItem.serverRelativeUrl)
            }}>
            <div className={clsx(
                'd-flex align-items-center',
                'cursor-pointer')
            }>
                <div className='d-flex align-items-center align-self-stretch px-5 bg-light rounded-start'>
                    <span className='symbol symbol-35px'>
                        <DocumentImage fileName={listItem.serverRelativeUrl} />
                    </span>
                </div>
                <div className='d-flex flex-column flex-fill px-3 p-2'>
                    <div className="w-175px text-truncate" title={listItem.name}>
                        <span className='fw-bolder fs-7 text-hover-primary'>{listItem.name}</span>
                    </div>
                    <div className="my-1">
                        <button type="button"
                            disabled={isDeleting || downloading}
                            onClick={() => downloadDocument(listItem.serverRelativeUrl)}
                            className="btn btn-outline text-hover-primary py-1 p-2 fs-9 fw-semibold">
                            <MUIIcon className="fs-6 me-1" iconName="CloudDownloadOutlined" />
                            Download
                        </button>
                    </div>
                </div>
                {(!allowSelect && allowDelete) &&
                    <div className='d-flex align-self-start p-2 '>
                        <button
                            disabled={isDeleting || downloading}
                            className='btn btn-sm p-0 text-hover-primary'
                            title="Delete document"
                            onClick={onClickDelete}>
                            <Spinner show={isDeleting} />
                            {(!isDeleting) && <MUIIcon
                                className="fs-6 fw-bold"
                                iconName="CloseOutlined" />}
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}
