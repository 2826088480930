import { AlertMessage, ProgressIndicator } from "../../../widgets";
import { AttributeDataType, IService, IServiceRequest } from "./models";
import { IControlProps, IPageLink } from "../../../base/models";
import { createContext, useContext, useEffect, useState } from "react";
import { useCreateServiceRequestMutation, useLazyGetServiceQuery, useLazyGetServiceRequestQuery } from "./services";
import { useNavigate, useSearchParams } from "react-router-dom";

import { EditServiceRequest } from "./components/EditServiceRequest";
import { NewServiceRequest } from "./components/NewServiceRequest";
import { Property } from "../../../base/types";
import { getPageLinkUrl } from "../../../base/helpers";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";
import { useGetServiceMetadata } from "./services/useGetServiceMetadata";

interface IServiceRequestContext {
    serviceRequest?: IServiceRequest,
    service?: IService,
    entity?: string,
    setServiceRequest: (request: IServiceRequest) => void,
}

const context = createContext<IServiceRequestContext>({
    setServiceRequest: () => { }
})

export function useServiceRequestContext() {
    return useContext(context)
}

export const ServiceRequestComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const hidden: boolean | undefined = properties[Property.Hidden]
    const tooltip: string | undefined = properties[Property.Tooltip];
    const editMode: boolean | undefined = properties[Property.ModifyAllowed];
    const navigateTo: IPageLink | undefined = properties[Property.PageLink];
    const relativePath: string | undefined = properties[Property.RelativePath];
    const entity: string | undefined = properties[Property.Entity];
    const serviceId2: string | undefined = properties[Property.ServiceId];
    const serviceRequestId2: string | undefined = properties[Property.ServiceRequestId];
    const hideHeader: boolean | undefined = properties[Property.HideHeader];
    const changeStatus: boolean | undefined = properties[Property.ChangeStatus];
    const editOnly: boolean | undefined = properties[Property.EditOnly];
    const documentsOnly: boolean | undefined = properties[Property.DocumentsOnly];

    const [serviceId, setServiceId] = useState<string>()
    const [serviceRequest, setServiceRequest] = useState<IServiceRequest>()
    const { getServiceMetadata, metadata: { data: metadata, isLoading, isError } } = useGetServiceMetadata()

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const { siteId, user } = useAuth()

    const [getService, { data: service }] = useLazyGetServiceQuery();
    const [getServiceRequest] = useLazyGetServiceRequestQuery();
    const [createServiceRequest] = useCreateServiceRequestMutation()

    const queryServiceId = searchParams.get("serviceId") || undefined
    const queryRequestId = searchParams.get("id") || undefined

    useEffect(() => {
        const serviceId = serviceId2 || queryServiceId;
        const requestId = serviceRequestId2 || queryRequestId;

        if (serviceId) {
            setServiceId(serviceId)
            getService(serviceId)
        }

        if (requestId) {
            getServiceRequest(requestId, false)
                .unwrap()
                .then(serviceRequest => {
                    setServiceId(serviceRequest.serviceId)
                    setServiceRequest(serviceRequest)
                })
        }

        // eslint-disable-next-line
    }, [properties])

    useEffect(() => {
        if (!serviceRequest) return;

        getService(serviceRequest.serviceId)
        // eslint-disable-next-line
    }, [serviceRequest])

    useEffect(() => {
        if (!serviceId) return;
        getServiceMetadata(serviceId)
        // eslint-disable-next-line
    }, [serviceId])

    useEffect(() => {
        if (!service || !metadata || serviceRequest) return;

        const attr = metadata.attributes.find(p =>
            (p.datatype === AttributeDataType.Money && service.paidService === true) ||
            p.datatype === AttributeDataType.DocumentChecklist)

        if (!attr) return;

        const currentStageId = metadata.stages[0].id;
        const requestorId = user?.contactId;

        let request: Partial<IServiceRequest> = {
            serviceId: service.id,
            stageId: currentStageId,
            name: service.name,
            requestorId
        }

        createServiceRequest(request)
            .unwrap()
            .then(data => {
                setSearchParams({ "id": data.id })
            })
        // eslint-disable-next-line
    }, [service, metadata, serviceRequest])

    if (mode === "Preview" && hidden === true) {
        return <></>
    }

    if (mode === "Design") {
        return <div>
            <div className="alert alert-info fs-8 mb-0">
                <div className="badge badge-info mb-2">Service Request Component</div>
                <p className="mb-0">Click the preview button to check the layout</p>
            </div>
        </div>
    }

    const handleOnComplete = () => {
        if (siteId && navigateTo)
            navigate(getPageLinkUrl(siteId, navigateTo))
    }

    return (
        <context.Provider value={{ entity, serviceRequest, service, setServiceRequest }}>
            <div
                className={className}
                title={tooltip}>
                <ProgressIndicator show={isLoading} message="Loading..." />
                <AlertMessage type="Error" show={isError} message="Failed to load service request details." />
                {(!serviceRequest && serviceId && !editMode && !editOnly) && <>
                    <NewServiceRequest
                        serviceId={serviceId}
                        entity={entity}
                        relativePath={relativePath}
                        hideHeader={hideHeader === true}
                        metadata={metadata}
                        changeStatus={changeStatus}
                        onComplete={handleOnComplete} />
                </>}
                {(serviceRequest) && <>
                    <EditServiceRequest
                        entity={entity}
                        request={serviceRequest}
                        relativePath={relativePath}
                        documentsOnly={documentsOnly === true}
                        hideHeader={hideHeader === true}
                        metadata={metadata}
                        changeStatus={changeStatus}
                        onComplete={handleOnComplete} />
                </>}
            </div>
        </context.Provider>
    )
}