import { ButtonStyle } from "../../../../../base/types";

export const ButtonStyleValue = ({ label, value, className, onChange }: {
    label: string,
    value: any
    className?: string,
    onChange: (value?: ButtonStyle) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as ButtonStyle;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return <select
        onChange={handleOnSelectionChange}
        value={value || ""}
        className="form-select form-select-sm form-control-xs">
        <option value={ButtonStyle.None}></option>
        <option value={ButtonStyle.Primary}>Primary</option>
        <option value={ButtonStyle.Secondary}>Secondary</option>
        <option value={ButtonStyle.Success}>Success</option>
        <option value={ButtonStyle.Danger}>Danger</option>
        <option value={ButtonStyle.Warning}>Warning</option>
        <option value={ButtonStyle.Info}>Info</option>
        <option value={ButtonStyle.Light}>Light</option>
        <option value={ButtonStyle.Dark}>Dark</option>
        <option value={ButtonStyle.Link}>Link</option>
        <option value={ButtonStyle.OutlinePrimary}>Outline Primary</option>
        <option value={ButtonStyle.OutlineSecondary}>Outline Secondary</option>
        <option value={ButtonStyle.OutlineSuccess}>Outline Success</option>
        <option value={ButtonStyle.OutlineDanger}>Outline Danger</option>
        <option value={ButtonStyle.OutlineWarning}>Outline Warning</option>
        <option value={ButtonStyle.OutlineInfo}>Outline Info</option>
        <option value={ButtonStyle.OutlineLight}>Outline Light</option>
        <option value={ButtonStyle.OutlineDark}>Outline Dark</option>
    </select>
}
