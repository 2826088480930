import { ActionType, IPageActionCollectionProps } from "../models";
import { createAction, readClipboardActionAsync, resetActionIds } from "../../helpers";

import { ActionTypesDialog } from "../components";
import { DesignAction } from "../../../base/models";
import { DndItem } from "../../dnd/models";
import { Droppable } from "../../dnd";
import { PageActionDesigner } from "../PageActionDesigner";
import React from "react";
import { useState } from "react"
import { useToaster } from "../../../layout/toast";

export const LayoutActionCollection = ({ actions, onDesignChange }: IPageActionCollectionProps) => {
    const { showToaster } = useToaster()
    const [dialog, showDialog] = useState<boolean>(false);
    const [insertAt, setInsertAt] = useState<"First" | "Last">("First")

    const onSelectAction = (actionType: ActionType) => {
        const newAction = createAction(actionType)
        switch (insertAt) {
            case "First":
                onDesignChange({
                    type: DesignAction.InsertFirstLayoutAction,
                    action: newAction
                });
                break;
            case "Last":
                onDesignChange({
                    type: DesignAction.InsertLastLayoutAction,
                    action: newAction
                });
                break;
        }
    }
    
    const onDropItem = (item: DndItem, insertAt: "First" | "Last") => {
        if (item.type !== "Action") return;
        switch (insertAt) {
            case "First":
                onDesignChange({
                    type: DesignAction.DropFirstLayoutAction,
                    action: item.action
                });
                break;
            case "Last":
                onDesignChange({
                    type: DesignAction.DropLastLayoutAction,
                    action: item.action
                });
                break;
        }
    }

    const pasteActionAsync = async () => {
        const actionToPaste = await readClipboardActionAsync()
        if (!actionToPaste) {
            showToaster("Not a valid page action")
            return
        }

        onDesignChange({
            type: DesignAction.InsertFirstLayoutAction,
            action: resetActionIds(actionToPaste)
        })
    }

    return (
        <div title={`Page Layout`} className={"pfx-control"}>
            {(actions && actions.length > 0) && <>
                <Droppable layout={"Auto"}
                    title={`Click to insert a new action`}
                    onDropItem={(item) => onDropItem(item, "First")}
                    onClick={(e) => {
                        e.stopPropagation();
                        setInsertAt("First")
                        showDialog(true)
                    }} />

                {actions.slice()
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((action) =>
                        <React.Fragment key={action.actionId}>
                            <PageActionDesigner
                                action={action}
                                onDesignChange={onDesignChange} />
                        </React.Fragment>)}

                <Droppable layout={"Auto"}
                    title={`Click to insert a new action`}
                    onDropItem={(item) => onDropItem(item, "Last")}
                    onClick={(e) => {
                        e.stopPropagation();
                        setInsertAt("Last")
                        showDialog(true)
                    }} />
            </>}

            {(!actions || actions.length === 0) && <>
                <div className="d-flex align-items-center justify-content-center p-3">
                    <button type="button" title="Add a new action" className="btn btn-secondary btn-sm px-5 py-2 me-3"
                        onClick={(e) => {
                            e.stopPropagation()
                            setInsertAt("First")
                            showDialog(true)
                        }}>
                        <i className="las la-plus fs-3"></i> ADD A NEW ACTION
                    </button>
                    <button type="button" title="Paste" className="btn btn-secondary btn-sm px-5 py-2"
                        onClick={(e) => {
                            e.stopPropagation()
                            pasteActionAsync()
                        }}>
                        <i className="las la-clipboard  fs-3"></i> PASTE ACTIONS
                    </button>
                </div>
            </>}

            <ActionTypesDialog
                show={dialog}
                onSelect={onSelectAction}
                onHideModal={() => showDialog(false)}
            />
        </div>)
}