import React from "react";
import clsx from "clsx";

export const Pagination = ({ records, pageSize, curPageNo, onChange }: {
    records: number,
    curPageNo: number,
    pageSize?: number,
    onChange: (pageNo: number) => void
}) => {
    if (!records || records === 0 || !pageSize || pageSize === 0 || records <= pageSize) return <></>

    const pages: number[] = [];
    let totalPages = Math.floor(records / pageSize);
    if (records > totalPages * pageSize) totalPages++;

    let startPage = (curPageNo > 3) ? curPageNo - 3 : 0;
    let endPage = startPage + 7;

    if (endPage >= totalPages) {
        const diff = endPage - totalPages;
        startPage - diff >= 0 ? startPage -= diff : startPage = 0;
        endPage = totalPages - 1;
    }

    for (let i = startPage; i <= endPage; i++) pages.push(i);

    return <>
        <ul className="pagination">
            <li className={clsx("page-item previous", { "disabled": curPageNo === 0 })}>
                <button
                    type="button"
                    className="page-link"
                    onClick={() => onChange(curPageNo - 1)}>
                    <i className="previous"></i>
                </button>
            </li>
            {pages.map(index => <React.Fragment key={index}>
                <li className={clsx("page-item", { "active": curPageNo === index })}>
                    <button
                        type="button"
                        className="page-link"
                        onClick={() => onChange(index)}>
                        {index + 1}
                    </button>
                </li>
            </React.Fragment>)}
            <li className={clsx("page-item next", { "disabled": curPageNo === endPage })}>
                <button
                    type="button"
                    className="page-link"
                    onClick={() => onChange(curPageNo + 1)}>
                    <i className="next"></i>
                </button>
            </li>
        </ul>
    </>
}