import { useEffect, useState } from "react";

import { IPageControl } from "../../../../base/models";

export const EditControlGeneralProps = ({ control, onChange }: {
    control: IPageControl,
    onChange: (control: IPageControl) => void,
}) => {
    const [controlClass, setControlClass] = useState<string>("");

    useEffect(() => {
        setControlClass(control?.className || "");
    }, [control])

    const handleControlClassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setControlClass(e.target.value);
    }

    const handleControlClassBlur = () => {
        onChange({ ...control, className: controlClass })
    }

    return (
        <div className="field-group mb-2">
            <label className="fs-8 fw-bold text-gray-600 mb-1">Class Name</label>
            <input
                type="text"
                onBlur={handleControlClassBlur}
                onChange={handleControlClassChange}
                value={controlClass}
                className="form-control form-control-sm form-control-xs" />
        </div>
    )
} 