import { AlertMessage, MUIIcon, ProgressIndicator } from "../../../widgets";
import { NodeApi, NodeRendererProps, Tree } from "react-arborist";
import { useEffect, useState } from "react";

import { IControlProps } from "../../../base/models";
import { IService } from "./models";
import { Property } from "../../../base/types";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";
import { useLazyGetRootServicesQuery } from "./services/servicesApi";
import { useNavigate } from "react-router-dom";

export const ServicesComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const hidden: boolean | undefined = properties[Property.Hidden]
    const tooltip: string | undefined = properties[Property.Tooltip];
    const pageId: string | undefined = properties[Property.PageId];
    const programType: any | undefined = properties[Property.ProgramType];
    const genderType: any | undefined = properties[Property.GenderType];

    if (mode === "Preview" && hidden === true) {
        return <></>
    }

    return (
        <div
            className={className}
            title={tooltip}>
            <Services pageId={pageId} programType={programType} genderType={genderType} />
        </div>)
}

const Services = ({ pageId, programType, genderType }: { pageId?: string, programType?: any, genderType?: any }) => {
    const { mode } = useDesignContext()
    const [getServices, { data, isLoading, isSuccess, isError }] = useLazyGetRootServicesQuery()
    const [term, setTerm] = useState("");
    const navigate = useNavigate()
    const { siteId } = useAuth()

    useEffect(() => {
        if (!programType || !genderType) return

        getServices({ programType, genderType })
        // eslint-disable-next-line
    }, [programType, genderType])

    const handleOnSelect = (nodes: NodeApi<IService>[]) => {
        if (mode === "Design" || !siteId || !pageId) return;

        const selected = nodes.filter(p => p.isLeaf && p.isSelected).map(p => p.data);
        if (!selected || selected.length === 0)
            return;

        const service = selected[0];
        navigate(`/site(${siteId})/page(${pageId})?serviceId=${service.id}`)
    }

    return <div>
        <ProgressIndicator show={isLoading} message="Please wait.." />
        <AlertMessage type="Error" show={isError} message={"Failed to load services"} />
        {isSuccess && <>
            <div className="input-group input-group-sm mb-2">
                <input type="search"
                    disabled={mode === "Design"}
                    placeholder="Search"
                    className="form-control form-control-sm py-2"
                    value={term} onChange={(e) => setTerm(e.target.value)} />
                <span className="input-group-text py-2">
                    <MUIIcon iconName="SearchOutlined" />
                </span>
            </div>

            <Tree initialData={data}
                rowHeight={24}
                paddingBottom={24}
                openByDefault={false}
                width={`100%`}
                searchTerm={term}
                searchMatch={
                    (node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())
                }
                idAccessor={"id"}
                onSelect={handleOnSelect}
                childrenAccessor={"children"}
            >
                {ServiceNode}
            </Tree>
        </>}
    </div>
}

const FolderArrow = ({ node }: { node: NodeApi<IService> }) => {
    if (node.isLeaf) return <span></span>;
    return (
        <span>
            {node.isOpen ?
                <MUIIcon iconName="ArrowDropDownOutlined" /> :
                <MUIIcon iconName="ArrowRightOutlined" />}
        </span>
    );
}

const ServiceNode = ({ node, style }: NodeRendererProps<IService>) => {
    const formatDate = (dateText: Date | string) => {
        return new Date(dateText).toLocaleString(navigator.language, {
            year: "numeric",
            month: "short",
            day: "2-digit"
        });
    }

    return (
        <div
            style={style}
            role='button'

            onClick={() => node.isInternal && node.toggle()}
        >
            {(node.isLeaf) ? <>
                <button
                    type="button"
                    className="btn btn-link btn-sm text-primary fw-semibold py-0">
                    <MUIIcon iconName="LiveHelpOutlined" className="me-1" />
                    {node.data.name}
                </button>
                {node.data.endDate && <>
                    <span className="fs-8 fst-italic text-gray-500 px-2">
                        <span className="me-1">( service will not be available after </span>
                        <span className="fw-bold">{formatDate(node.data.endDate)}</span>
                        <span className="me-1">{' )'}</span>
                    </span>
                </>}
            </> : <>
                <FolderArrow node={node} />
                <span className="fw-semibold">{node.data.name}</span>
            </>}
        </div>
    )
}