import { ApiTags, rootApi } from "../../rootApi";
import { IFolderItemsQuery, ISPFileInformation, ISPFileInformation2, ISPFolder, ISPListItem } from './models/sharepoint';

import { ISPFile } from "./models";

const TAG = ApiTags.SharePointFile;

const getFolderPath = (url: string) => {
  if (!url) return 'ROOT'

  url = url.toLowerCase()
  const path = (url.startsWith('http')) ? new URL(url).pathname : url.split('/').join("/");
  return path;
}

const getParentFolderPath = (url: string) => {
  if (!url) return 'ROOT'

  url = url.toLowerCase()
  if (url.startsWith('http')) {
    const path = new URL(url).pathname
    return path;
  } else {
    const folders = url.split('/')
    const path = folders.splice(0, folders.length - 1).join("/");
    return path;
  }
}

const sharePointFilesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getSPFiles: builder.query<ISPFile[], void>({
      query: () => `/SPFiles`,
      providesTags: [{ type: TAG, id: getFolderPath('') }]
    }),

    getSPFilesByRelativePath: builder.query<ISPFile[], string>({
      query: (relativePath) => {
        return {
          url: '/SPFiles/GetFiles',
          method: 'POST',
          body: JSON.stringify(relativePath),
          headers: { "content-type": "application/json" }
        }
      },
      providesTags: (result, error, path) => {
        if (error) return []
        return [{ type: TAG, id: getFolderPath(path) }]
      }
    }),

    getSPFileWithContent: builder.query<ISPFile, string>({
      query: (relativePath) => {
        return {
          url: '/SPFiles/Content',
          method: 'POST',
          body: JSON.stringify(relativePath),
          headers: { "content-type": "application/json" }
        }
      },
      providesTags: (result, error, path) => {
        if (error) return []
        return [{ type: TAG, id: getParentFolderPath(path) }]
      }
    }),
    
    uploadFile: builder.mutation<ISPFile, ISPFileInformation>({
      query: (fileInfo) => {
        return {
          url: '/SPFiles/Upload',
          method: 'POST',
          body: fileInfo
        }
      },
      invalidatesTags: (result, error, fileInfo) => {
        if (error) return []
        return [{ type: TAG, id: getFolderPath(fileInfo.folderRelativeUrl) }]
      }
    }),

    uploadFileLarge: builder.mutation<ISPFile, ISPFileInformation2>({
      query: ({data, ...rest}) => {
        return {
          url: '/SPFiles/UploadLarge',
          method: 'POST',
          headers: { 
            "sp-request-info": JSON.stringify(rest),
         },
          body: data
        }
      },
      invalidatesTags: (result, error, fileInfo) => {
        if (error) return []
        return [{ type: TAG, id: getFolderPath(fileInfo.folderRelativeUrl) }]
      }
    }),

    deleteSPFile: builder.mutation<void, string>({
      query: (relativePath) => {
        return {
          url: '/SPFiles/DeleteFile',
          method: 'POST',
          body: JSON.stringify(relativePath),
          headers: { "content-type": "application/json" }
        }
      },
      invalidatesTags: (result, error, path) => {
        if (error) return []
        return [{ type: TAG, id: getParentFolderPath(path) }]
      }
    }),

    getSPFolders: builder.query<ISPFolder[], void>({
      query: () => `/SPFolders`,
      providesTags: (result, error) => {
        if (error) return []
        return [{ type: TAG, id: getFolderPath('') }]
      }
    }),

    getSPFoldersByRelativePath: builder.query<ISPFolder[], string>({
      query: (relativePath) => {
        return {
          url: '/SPFolders/GetFolders',
          method: 'POST',
          body: JSON.stringify(relativePath),
          headers: { "content-type": "application/json" }
        }
      },
      providesTags: (result, error, path) => {
        if (error) return []
        return [{ type: TAG, id: getFolderPath(path) }]
      }
    }),

    deleteSPFolder: builder.mutation<void, string>({
      query: (relativePath) => {
        return {
          url: '/SPFolders/DeleteFolder',
          method: 'POST',
          body: JSON.stringify(relativePath),
          headers: { "content-type": "application/json" }
        }
      },
      invalidatesTags: (result, error, path) => {
        if (error) return []
        return [{ type: TAG, id: getParentFolderPath(path) }]
      }
    }),

    getFolderItems: builder.query<ISPListItem[], IFolderItemsQuery>({
      query: (query) => {
        return {
          url: '/SPFolders/FolderItems',
          method: 'POST',
          body: query
        }
      },
      providesTags: (result, error, args) => {
        if (error) return []
        return [{ type: TAG, id: getFolderPath(args.folderRelativeUrl) }]
      }
    }),
  })
})

export const {
  useLazyGetSPFilesQuery,
  useGetSPFilesQuery,
  useLazyGetSPFilesByRelativePathQuery,
  useGetSPFilesByRelativePathQuery,
  useGetSPFileWithContentQuery,
  useLazyGetSPFileWithContentQuery,
  useUploadFileMutation,
  useDeleteSPFileMutation,
  useUploadFileLargeMutation,

  useLazyGetSPFoldersQuery,
  useGetSPFoldersQuery,
  useLazyGetSPFoldersByRelativePathQuery,
  useGetSPFoldersByRelativePathQuery,
  useDeleteSPFolderMutation,
  useGetFolderItemsQuery,
  useLazyGetFolderItemsQuery
} = sharePointFilesApi;