export interface IEntityDocumentChecklist {
    id: string,
    tableId: string,
    recordId: string,
    recSystemId: string,
    documentChecklistId: string,
    status: string
}

export const EntityDocumentChecklistMetadata = {
    tableId: "33101676",
    fields: {
        id: "Id",
        tableId: "Table Id",
        recordId: "Record Id",
        recSystemId: "Rec System Id",
        documentChecklistId: "Document Checklist Id",
        status: "Status"
    }
}
