import { AlertMessage, ProgressIndicator } from '../framework/widgets'
import { FC, useEffect } from 'react'

import { PageTitle } from '../layout/core'
import { SiteSettings } from '../framework/sites/site-settings'
import { useLazyGetSiteQuery } from '../framework/services/auth'
import { useParams } from 'react-router-dom'

export const SiteSettingsWrapper: FC = () => {
  const { siteId } = useParams()
  const [getSite, { data: site, isLoading, isFetching, isSuccess, isError }] = useLazyGetSiteQuery()

  useEffect(() => {
    if (!siteId) return;

    getSite(siteId);
    // eslint-disable-next-line
  }, [siteId])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{`${`${site?.title} Settings` || 'Loading...'}`}</PageTitle>
      <div>
        <ProgressIndicator show={isLoading || isFetching} message='Loading...' />
        <AlertMessage show={isError} type="Error" message='Failed to load site settings' />
        {(isSuccess && site) && <SiteSettings site={site} />}
      </div>
    </>)
}
