import { useEffect, useState } from "react";

import { useLazyGetLookupAttributeDataQuery } from "../../../services";

export const ReadOnlyLookupAttribute = ({
    attributeId,
    value,
}: {
    attributeId: string,
    value?: string,
    className?: string,
}) => {
    const [getLookupData, { data, isSuccess, isError }] = useLazyGetLookupAttributeDataQuery()
    const [lookupValue, setLookupValue] = useState<string>();

    useEffect(() => {
        if (!value) return;
        getLookupData(attributeId)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isSuccess && data) {
            const entity = data.find(p => `${p.id}` === value)
            setLookupValue(entity?.name)
        }
        // eslint-disable-next-line
    }, [isSuccess, data])

    if (isError) {
        <span className='alert-info' style={{ display: 'block' }}>
            Failed to load lookup data
        </span>
    }

    return (<>{lookupValue}</>)
}
