import { PageMode } from "../../base/types";
import clsx from "clsx";

export const PageModes: { value: PageMode, name: string }[] = [
    { value: PageMode.View, name: "View" },
    { value: PageMode.New, name: "New" },
    { value: PageMode.Edit, name: "Edit" },
];

export const SelectPageMode = ({ value, className, onChange }: {
    onChange: (value: PageMode) => void,
    value: PageMode,
    className?: string
}) => {
    return <select
        onChange={(e) => onChange(e.target.value as PageMode)}
        value={value}
        className={clsx(className, "form-select form-select-sm form-control-xs fs-8")}>
        <option></option>
        {PageModes.map(option => <option key={option.value} value={option.value}>
            {option.name}
        </option>)}
    </select>;
}
