export interface IEntityChecklistItem_BC {
    id: string,
    entityDocumentChecklistId: string,
    documentChecklistItemId: string,
    documentCategoryId?: string,
    documentTypeId: string,
    fileType: string,
    url: string,
    systemId: string
}

export interface IEntityChecklistItem {
    id: string,
    description: string,
    documentCategoryId: string,
    documentChecklistId: string,
    documentUrl: string,
    entityType: string,
    entityId: string
    rejected?: boolean
    rejectedReason?: string
}

export const EntityDocumentChecklistItemMetadata = {
    tableId: "33101677",
    fields: {
        id: "Id",
        entityDocumentChecklistId: "Entity Doc. Checklist Id",
        documentChecklistItemId: "Document Checklist Item Id",
        documentTypeId: "Document Type Id",
        fileType: "File Type",
        url: "Url",
        systemId: "$systemId"
    }
}
