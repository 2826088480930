import { Property, PropertyType } from "../../../../../base/types";

import { IProperty } from "../../../../../base/models";

export const StackedBarsProperties: IProperty[] = [
    { id: Property.LabelsField, name: 'Labels Field', type: PropertyType.String },
    { id: Property.ValuesField, name: 'Values Field', type: PropertyType.String },
    { id: Property.Height, name: 'Height', type: PropertyType.Number },
    { id: Property.CategoryField, name: 'Category Field', type: PropertyType.String },
    { id: Property.AggregateMethod, name: 'Method', type: PropertyType.AggregateMethod },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },    
    { id: Property.ColorMap, name: 'Color Map', type: PropertyType.String },
]
