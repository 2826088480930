import { IAttributeMetadata, IAttributeOption, IService, IServiceAttribute, IServiceStage } from "../models";

import { useLazyGetAttributeOptionsQuery } from "./serviceAttributeOptionsApi";
import { useLazyGetAttributesQuery } from "./attributesMetadataApi";
import { useLazyGetServiceAttributesQuery } from "./serviceAttributesApi";
import { useLazyGetServiceQuery } from "./servicesApi";
import { useLazyGetStagesQuery } from "./serviceStagesApi";
import { useState } from "react";

export interface IServiceMetadata {
    service: IService,
    stages: IServiceStage[],
    attributes: IAttributeMetadata[],
    serviceAttributes: IServiceAttribute[],
    attributeOptions: IAttributeOption[]
}

interface IServiceMetadataResult {
    data?: IServiceMetadata,
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    error?: string
}

export const useGetServiceMetadata = () => {
    const [metadata, setMetadata] = useState<IServiceMetadataResult>({ isLoading: false, isSuccess: false, isError: false });
    const [getService] = useLazyGetServiceQuery()
    const [getStages] = useLazyGetStagesQuery()
    const [getAttributes] = useLazyGetAttributesQuery()
    const [getServiceAttributes] = useLazyGetServiceAttributesQuery()
    const [getAttributeOptions] = useLazyGetAttributeOptionsQuery()

    const getServiceAttributesInner = (
        service: IService,
        allStages: IServiceStage[],
        allAttributes: IAttributeMetadata[]
    ): Promise<IServiceMetadata> => {
        return getServiceAttributes(service.id)
            .unwrap()
            .then(serviceAttributes => {
                const stages = allStages.filter(p => serviceAttributes.find(a => a.serviceStageId === p.id));
                const attributes = allAttributes.filter(p => serviceAttributes.find(a => a.attributeId === p.id));
                return getAttributeOptions()
                    .unwrap()
                    .then(attributeOptions => {
                        const data: IServiceMetadata = { service, stages, serviceAttributes, attributes, attributeOptions };
                        setMetadata({ isLoading: false, isSuccess: true, isError: false, data });
                        return Promise.resolve(data);
                    })
                    .catch(() => {
                        setMetadata({
                            isLoading: false,
                            isSuccess: false,
                            isError: true,
                            error: 'Failed to load service service'
                        });

                        return Promise.reject('Failed to load service service');
                    });
            })
            .catch(() => {
                setMetadata({
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: 'Failed to load service service'
                });

                return Promise.reject('Failed to load service service');
            });
    }

    const getServiceInner = (
        serviceId: string,
        allStages: IServiceStage[],
        allAttributes: IAttributeMetadata[]

    ): Promise<IServiceMetadata> => {
        return getService(serviceId)
            .unwrap()
            .then(service => {
                return getServiceAttributesInner(service, allStages, allAttributes);
            })
            .catch(() => {
                setMetadata({
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: 'Failed to load service'
                });

                return Promise.reject('Failed to load service');
            });
    }

    const getServiceMetadata = (serviceId: string): Promise<IServiceMetadata> => {
        setMetadata({ isLoading: true, isSuccess: false, isError: false })

        return getStages()
            .unwrap()
            .then(allStages => {
                return getAttributes()
                    .unwrap()
                    .then(allAttributes => {
                        return getServiceInner(serviceId, allStages, allAttributes)
                    })
                    .catch(() => {
                        setMetadata({
                            isLoading: false,
                            isSuccess: false,
                            isError: true,
                            error: 'Failed to load attributes'
                        })
                        return Promise.reject('Failed to load attributes')
                    })
            })
            .catch(() => {
                setMetadata({
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: 'Failed to load stages'
                })

                return Promise.reject('Failed to load stages')
            })
    }

    return { getServiceMetadata, metadata }
}
