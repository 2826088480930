import { useEffect, useRef } from "react"

import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { Wrapper } from "@googlemaps/react-wrapper";
import clsx from "clsx";
import { useGetProperties } from "../../../base/hooks";

export const GoogleMapsComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const tooltip: string | undefined = properties[Property.Tooltip];
    const lng: string = properties[Property.GeoLang] || "0";
    const lat: string = properties[Property.GeoLat] || "0";
    const key = process.env.REACT_APP_GOOGLE_MAPS_KEY;

    return (
        <div className={clsx(className, "position-relative")} title={tooltip}>
            {key &&
                <Wrapper apiKey={key}>
                    <MapComponent
                        center={{ lng: parseFloat(lng), lat: parseFloat(lat) }}
                        zoom={10} />
                </Wrapper>}
        </div>)
}

function MapComponent({
    center,
    zoom
}: {
    center: google.maps.LatLngLiteral;
    zoom: number;
}) {
    const ref = useRef<any>();

    useEffect(() => {
        new window.google.maps.Map(ref.current, {
            center,
            zoom,
        });
    });

    return <div style={{ width: "100%", height: "100%" }} ref={ref} id="map" />;
}