import { IServiceRequest, ServiceRequestStatus } from "../models"

import { IServiceMetadata } from "../services/useGetServiceMetadata"
import { ServiceReqHelper } from "../helper"
import { ServiceRequestWizard } from "./ServiceRequestWizard"
import { ViewServiceRequest } from "./ViewServiceRequest"
import { useServiceRequestContext } from "../ServiceRequestComponent"
import { useUpdateServiceRequestMutation } from "../services"

export const EditServiceRequest = ({ request, entity, relativePath, documentsOnly, hideHeader, changeStatus, metadata, onComplete }: {
    request: IServiceRequest,
    entity?: string,
    relativePath?: string,
    documentsOnly: boolean,
    metadata?: IServiceMetadata,
    hideHeader?: boolean,
    changeStatus?: boolean,
    onComplete: () => void,
}) => {
    const [updateServiceRequest] = useUpdateServiceRequestMutation()
    const { serviceRequest, setServiceRequest } = useServiceRequestContext()

    const handleOnChange = (request: Partial<IServiceRequest>) => {
        return updateServiceRequest(request)
            .unwrap()
            .then(data => {
                setServiceRequest(data)
                return Promise.resolve(data)
            })
            .catch(err => Promise.reject('Failed to update service request'))
    }

    const renderServiceRequest = (metadata: IServiceMetadata, serviceRequest: IServiceRequest) => {
        if (documentsOnly && serviceRequest.statusCode && serviceRequest.statusCode === ServiceRequestStatus.Draft) {
            return <></>
        }

        if (serviceRequest.statusCode && serviceRequest.statusCode !== ServiceRequestStatus.Draft) {
            return <ViewServiceRequest
                request={serviceRequest}
                metadata={metadata!}
                documentsOnly={documentsOnly}
                entity={entity}
                relativePath={relativePath}
                hideHeader={hideHeader}
                values={ServiceReqHelper.values(serviceRequest, metadata!)} />
        }

        return <ServiceRequestWizard
            request={serviceRequest}
            metadata={metadata}
            stageId={serviceRequest.stageId}
            entity={entity}
            relativePath={relativePath}
            hideHeader={hideHeader}
            changeStatus={changeStatus}
            onChange={handleOnChange}
            onComplete={onComplete}
            initValues={ServiceReqHelper.values(serviceRequest, metadata)}
            schemas={ServiceReqHelper.schema(metadata)} />
    }

    return <>
        {(serviceRequest && metadata) && <>
            {renderServiceRequest(metadata, serviceRequest)}
        </>}
    </>
}