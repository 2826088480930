import { Checkbox } from "../Checkbox"
import { IBackground } from "../../../../base/styles"
import { SelectBackgroundColor } from "./SelectBackgroundColor"
import { SelectBackgroundOpacity } from "./SelectBackgroundOpacity"
import { SelectHoverColor } from "./SelectHoverColor"
import clsx from "clsx"

export const BackgroundStyles = ({ value, className, onChange }:{
    value: IBackground,
    className?: string,
    onChange: (value?: IBackground) => void
}) => {
    return (
        <div className={clsx(className)}>
            <div className="d-flex flex-row flex-wrap align-items-center">
                <SelectBackgroundColor
                    title="Color"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, color: newValue }) }}
                    value={value?.color} />
                <SelectBackgroundOpacity
                    title="Opacity"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, opacity: newValue }) }}
                    value={value?.opacity} />
                <Checkbox
                    title="Gradient"
                    className="w-50 pe-2 fs-7 fw-semibold mt-2"
                    onChange={(newValue) => { onChange({ ...value, gradient: newValue }) }}
                    value={value?.gradient === true} />
            </div>
            <h6 className="fs-7 fs-bold text-gray-700 mb-2 mt-2">Hover</h6>
            <div className="d-flex flex-row flex-wrap align-items-center">
                <SelectHoverColor
                    title="Hover Color"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, hoverColor: newValue }) }}
                    value={value?.hoverColor} />
                <SelectBackgroundOpacity
                    title="Hover Opacity"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, hoverOpacity: newValue }) }}
                    value={value?.hoverOpacity} />
            </div>
        </div>)
}