import * as authHelper from './../../modules/auth/core/AuthHelpers'

import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const prepareDataTableTags = () => {
    const tags: { tag: string, linkedWith?: string }[] = [];
    for (let i = 0; i < 10000; i++) {
        tags.push({ tag: `DataTable-${i}` })
    }

    return tags;
}

export const DataTableTags = prepareDataTableTags();

const BASE_URL = process.env.REACT_APP_API_URL;
export const ApiTags = {
    Auth: "Auth",
    AccessRole: "AccessRole",
    MemberAccess: "MemberAccess",
    Member: "Member",
    Site: "Site",
    Role: "Role",
    UserRole: "UserRole",
    User: "User",
    ChatMessage: "ChatMessage",
    Component: "Component",
    ComponentMetadata: "ComponentMetadata",
    DataAttribute: "DataAttribute",
    TableField: "TableField",
    PortalAction: "PortalAction",
    Media: "Media",
    DataEntity: "DataEntity",
    Page: "Page",
    PageMetadata: "PageMetadata",
    TableData: "TableData",
    RecordLink: "RecordLink",
    SharePointFolder: "SharePointFolder",
    SharePointFile: "SharePointFile",
    EntityRelations: "EntityRelations",
    Service: "Service",
    ServiceStage: "ServiceStage",
    ServiceAttribute: "ServiceServiceAttribute",
    AttributeMetadata: "AttributeMetadata",
    ServiceRequest: "ServiceRequest",
    AttributeOption: "AttributeOption",
    Payment: "Payment",
    DocumentChecklist: "DocumentChecklist",
    EntityChecklistItem: "EntityChecklistItem",
    LookupData: "LookupData",
    Contact: "Contact",
    Feedback: "Feedback",
}

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        var auth = authHelper.getAuth()
        if (auth?.token) {
            headers.set('authorization', `Bearer ${auth?.token}`)
        }
        return headers
    },
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        window.location.reload()
    }

    return result
}

export const rootApi = createApi({
    baseQuery: baseQueryWithReAuth,
    keepUnusedDataFor: 300,
    tagTypes: [
        ApiTags.Auth,
        ApiTags.AccessRole,
        ApiTags.MemberAccess,
        ApiTags.Member,
        ApiTags.Site,
        ApiTags.Role,
        ApiTags.UserRole,
        ApiTags.User,
        ApiTags.ChatMessage,
        ApiTags.Component,
        ApiTags.ComponentMetadata,
        ApiTags.DataAttribute,
        ApiTags.PortalAction,
        ApiTags.Media,
        ApiTags.DataEntity,
        ApiTags.Page,
        ApiTags.PageMetadata,
        ApiTags.TableData,
        ApiTags.TableField,
        ApiTags.RecordLink,
        ApiTags.SharePointFolder,
        ApiTags.SharePointFile,
        ApiTags.EntityRelations,
        ApiTags.Service,
        ApiTags.ServiceStage,
        ApiTags.ServiceAttribute,
        ApiTags.AttributeMetadata,
        ApiTags.ServiceRequest,
        ApiTags.AttributeOption,
        ApiTags.Payment,
        ApiTags.DocumentChecklist,
        ApiTags.EntityChecklistItem,
        ApiTags.LookupData,
        ApiTags.Contact,
        ApiTags.Feedback,
        ...DataTableTags.map(p => p.tag)
    ],
    endpoints: () => ({}),
})