import { IPage, PageSourceType } from "../../models";

import Accordion from "react-bootstrap/esm/Accordion";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { EditTableFilters } from "../../../widgets";
import FilterIcon from "@mui/icons-material/FilterListOutlined";
import { ListPageProperties } from "./ListPageProperties";
import SortIcon from "@mui/icons-material/SortOutlined";

export const PageDataSourceSettings = ({ page, onChange }: {
    page: IPage,
    onChange: (page: IPage) => void
}) => {
    if (page.sourceType !== PageSourceType.Table || !page.sourceObject)
        return <></>

    return <Accordion className="accordion-sm mt-5" flush>
        <AccordionItem eventKey="Filters" className="mb-3">
            <Accordion.Header><FilterIcon className="me-2" />
                Filters
            </Accordion.Header>
            <Accordion.Body className="bg-body">
                <EditTableFilters
                    entity={page.sourceObject}
                    filters={page.filters || []}
                    onChange={(filters) => onChange({ ...page, filters })} />
            </Accordion.Body>
        </AccordionItem>
        <AccordionItem eventKey="Sorting">
            <Accordion.Header><SortIcon className="me-2" />
                Pagination & Sorting
            </Accordion.Header>
            <Accordion.Body className="bg-body">
                <ListPageProperties page={page}
                    onChange={onChange} />
            </Accordion.Body>
        </AccordionItem>
    </Accordion>;
}
