import { AlertMessage, ProgressIndicator } from "../../../widgets";
import { IControlProps, IEntityFilter, IPageLink } from "../../../base/models";
import { PageMode, Property } from "../../../base/types";

import { DesignContextProvider } from "../../../providers";
import { PageViewer } from "../../page/page/PageViewer";
import { useGetPageMetadataQuery } from "../../../services/data";
import { useGetProperties } from "../../../base/hooks";

export const ListPartPageComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const tooltip: string | undefined = properties[Property.Tooltip];
    const pageLink: IPageLink | undefined = properties[Property.PageLink]

    const partPage = () => {
        if (properties["$$$isLoading"] === true) {
            return <></>
        }

        if (!pageLink || !pageLink.pageId)
            return <div className="text-muted">No Part Page</div>

        return <ListPartPageInner pageId={pageLink.pageId} filters={pageLink.filters || []} />
    }

    return (<div className={className} title={tooltip}>
        <DesignContextProvider>
            {partPage()}
        </DesignContextProvider>
    </div>)
}

const ListPartPageInner = ({ pageId, filters }: {
    pageId: string,
    filters: IEntityFilter[]
}) => {
    const { data: page, isLoading, isSuccess, isError, error } = useGetPageMetadataQuery(pageId);
    if (isLoading || isError || !isSuccess || !page) {
        return <>
            <ProgressIndicator show={isLoading} className="mb-2" message="Please wait ..." />
            <AlertMessage
                show={isError}
                type="Error"
                message={(error as any)?.data?.error?.message || `Failed to load the page id: ${pageId}`} />
        </>
    }

    if (filters.find(p => p.type === "Field")) {
        return <AlertMessage show={true} type="Error" message="Link filters has error">
            {JSON.stringify(filters, null, 2)}
        </AlertMessage>
    }

    return <PageViewer page={page} pageView={{
        pageMode: PageMode.View,        
        linkFilters: filters
    }} />
}