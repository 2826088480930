import * as Yup from 'yup'

import { AlertMessage, MUIIcon, Spinner } from '../../../widgets'
import { Formik, FormikHelpers } from 'formik'
import { SelectEntityId, SelectPage, SelectUser } from '../../../design/widgets'

import { IMember } from '../../../services/auth/models/Member.model'
import { ISite } from "../../../services/auth/models"
import clsx from 'clsx'
import { useCreateMemberMutation } from '../../../services/auth'

const entitySchema = Yup.object().shape({
    userId: Yup.string()
        .required('User is required'),
    entityId: Yup.string()
        .required('Entity is required'),
    dashboardPageId: Yup.string(),
    blocked: Yup.boolean(),
})

export const CreateSiteMember = ({ site, className }: {
    site: ISite,
    className?: string
}) => {
    const [createSiteMember] = useCreateMemberMutation()
    const initialValues: Partial<IMember> = {
        portalId: site.portalId
    };

    const handleSubmit = (values: Partial<IMember>, { setStatus, setSubmitting, resetForm }: FormikHelpers<Partial<IMember>>) => {
        setSubmitting(true)
        createSiteMember(values)
            .unwrap()
            .then(() => {
                resetForm()
            })
            .catch(err => {
                setStatus(err.data?.error?.message || 'Failed to add user.')
            })
            .finally(() => setSubmitting(false))
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={entitySchema}
            onSubmit={handleSubmit}>
            {({
                errors, touched, status, isSubmitting, isValid, values, getFieldProps,
                handleSubmit, setFieldValue, setFieldTouched, resetForm, setStatus }) => (
                <>
                    <tr>
                        <td className='pt-0'>
                            <form className='form' onSubmit={handleSubmit} noValidate id='create-site-member'>
                            </form>
                            <SelectUser
                                value={values.userId}
                                onBlur={() => setFieldTouched('userId', true)}
                                onChange={(userId) => { setFieldValue('userId', userId) }} />
                            <FieldError show={touched.entityId && !!errors.entityId} message={errors.entityId} />
                        </td>
                        <td className='pt-0'>
                            <SelectEntityId
                                entity={site.portalEntity}
                                value={values.entityId}
                                onBlur={() => setFieldTouched('entityId', true)}
                                onChange={(value) => { setFieldValue('entityId', value) }} />
                            <FieldError show={touched.entityId && !!errors.entityId} message={errors.entityId} />
                        </td>
                        <td className='pt-0'>
                            <SelectPage
                                siteId={site.portalId}
                                value={values.dashboardPageId}
                                onBlur={() => setFieldTouched('dashboardPageId', true)}
                                onChange={(value) => { setFieldValue('dashboardPageId', value) }} />
                            <FieldError show={touched.dashboardPageId && !!errors.dashboardPageId} message={errors.dashboardPageId} />
                        </td>
                        <td className='pt-0'>
                            <div className={clsx(className, "form-check form-check-sm form-check-custom")}>
                                <input
                                    id={'blocked'}
                                    className="form-check-input"
                                    type="checkbox"
                                    {...getFieldProps('blocked')}
                                />
                            </div>
                            <FieldError show={touched.blocked && !!errors.blocked} message={errors.blocked} />
                        </td>
                        <td className='pt-0'>
                            <div className='d-flex justify-content-center align-items-stretch flex-wrap'>
                                <button
                                    type='submit'
                                    form='create-site-member'
                                    id='submit-settings'
                                    className='btn btn-sm btn-primary fs-8 py-2 px-3 flex-fill'
                                    disabled={isSubmitting || !isValid}
                                >
                                    <span className='indicator-label'>
                                        {!isSubmitting ?
                                            <MUIIcon iconName='AddOutlined' className='fs-5 me-1' />
                                            : <Spinner show={isSubmitting} />}
                                        Add
                                    </span>
                                </button>
                                <button
                                    className='btn btn-sm btn-secondary fs-8 py-2 px-3 ms-2 flex-fill'
                                    type='button'
                                    onClick={() => {
                                        resetForm();
                                        setStatus('');
                                    }}>
                                    <MUIIcon iconName='CancelOutlined' className='fs-5 me-1' />
                                    Cancel
                                </button>
                            </div>
                        </td>
                    </tr>
                    {!!status && <>
                        <tr><td colSpan={4} className='pt-0'>
                            <AlertMessage show={!!status} message={status} type='Error' />
                        </td></tr>
                    </>}
                </>
            )}
        </Formik>
    )
}

const FieldError = ({ show, message }: { show?: boolean, message?: string }) => {
    if (!show) return <></>

    return <div className='fv-plugins-message-container'>
        <div className='fv-help-block fs-8'>
            <span role='alert'>{message}</span>
        </div>
    </div>
}