import { IBorderStyle } from "../../../../base/styles"
import { SelectBorderColor } from "./SelectBorderColor"
import { SelectBorderRadiusSize } from "./SelectBorderRadiusSize"
import { SelectBorderSize } from "./SelectBorderSize"
import { SelectBorderStyle } from "./SelectBorderStyle"
import { SelectRoundedBorder } from "./SelectRoundedBorder"
import { SelectYesNoChoice } from "./SelectYesNoChoice"
import clsx from "clsx"

export const BorderStyles = ({ value, className, onChange }:{
    value: IBorderStyle,
    className?: string,
    onChange: (value?: IBorderStyle) => void
}) => {
    return (
        <div>
            <h6 className="fs-7 fs-bold text-gray-700 mb-2">Borders</h6>
            <div className={clsx(className, "d-flex flex-wrap flex-row")}>
                <SelectYesNoChoice
                    title="Border"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, border: newValue }) }}
                    value={value?.border} />

                <SelectBorderColor
                    title="Color"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, color: newValue }) }}
                    value={value?.color} />

                <SelectBorderSize
                    title="Border Size"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, size: newValue }) }}
                    value={value?.size} />

                <SelectBorderStyle
                    title="Border Style"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, style: newValue }) }}
                    value={value?.style} />
            </div>

            <h6 className="fs-7 fs-bold text-gray-700 mt-3 mb-2">Radius</h6>
            <div className={clsx(className, "d-flex flex-wrap flex-row")}>
                <SelectRoundedBorder
                    title="Rounded Border"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, roundedBorder: newValue }) }}
                    value={value?.roundedBorder} />

                <SelectBorderRadiusSize
                    title="Rounded Size"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, roundedSize: newValue }) }}
                    value={value?.roundedSize} />
            </div>

            <h6 className="fs-7 fs-bold text-gray-700 mt-3 mb-2">Hide Borders</h6>
            <div className={clsx(className, "d-flex flex-wrap flex-row")}>
                <SelectYesNoChoice
                    title="Border Top"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, borderTop: newValue }) }}
                    value={value?.borderTop} />
                <SelectYesNoChoice
                    title="Border Right"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, borderRight: newValue }) }}
                    value={value?.borderRight} />
                <SelectYesNoChoice
                    title="Border Bottom"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, borderBottom: newValue }) }}
                    value={value?.borderBottom} />
                <SelectYesNoChoice
                    title="Border Left"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, borderLeft: newValue }) }}
                    value={value?.borderLeft} />
            </div>
        </div>)
}