import { DesignAction, DesignEvent } from "../../../base/models";
import { DesignContextProvider, useDesignContext } from "../../../providers";
import { EditPageGeneralInfo, PageDataSourceSettings, PageEditorToolbar, PropertiesPanel } from "./components";
import { IPage, IPageView, PageType } from "../models";
import { PageContextProducer, PageContextProvider } from "../context";

import { DataContextProducer } from "../context/DataContextProducer";
import { DataContextProvider } from "../context/DataContextProvider";
import { PageDesigner } from "./page-designer/PageDesigner";
import { PageTitle } from "../../../../layout/core";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";
import { ViewPageControls } from "../page/components/ViewPageControls";
import { getFilterGroups } from "../../helpers";
import { reducer } from "./reducer";
import { useReducer } from "react";

export const PageEditor = ({ page, pageView, className }: {
    page: IPage,
    pageView?: IPageView,
    className?: string,
}) => {
    const [localPage, dispatch] = useReducer(reducer, page)

    const onChange = (event: DesignEvent) => {
        dispatch(event)
    }

    const onPageChange = (page: IPage) => {
        dispatch({ type: DesignAction.Update, page })
    }

    if (!localPage) return <></>

    return (
        <DesignContextProvider>
            <PageEditorToolbar page={localPage} />
            <PageTitle key="title">{localPage.title || localPage.name}</PageTitle>
            <div className="d-flex">
                <div className="flex-fill">
                    <Tabs defaultActiveKey="general" className="fw-semibold fs-7">
                        <Tab eventKey="general" title="General" className="bg-body p-10">
                            <EditPageGeneralInfo
                                page={localPage}
                                onChange={onPageChange} />
                            <PageDataSourceSettings
                                page={localPage}
                                onChange={onPageChange} />
                        </Tab>
                        <Tab eventKey="design" title="Design" className="bg-body p-10">
                            <ToggleDesignView
                                page={localPage}
                                pageView={pageView}
                                onDesignChange={onChange} />
                        </Tab>
                    </Tabs>
                </div>
                <div>
                    <PropertiesPanel
                        page={localPage}
                        onDesignChange={onChange}
                        className="ms-1" />
                </div>
            </div>
        </DesignContextProvider>
    )
}

const ToggleDesignView = ({ page, pageView, onDesignChange }: {
    page: IPage,
    pageView?: IPageView,
    onDesignChange: (event: DesignEvent) => void
}) => {
    const { mode } = useDesignContext();

    const getComponent = () => {
        switch (mode) {
            case "Design":
                return <PageDesigner page={page} pageView={pageView} onDesignChange={onDesignChange} />
            case "Preview":
                return <ViewPageControls controls={page.controls} />
        }
    }

    const filters = getFilterGroups({ page, pageView })
    const produce = (page.type === PageType.List || page.type === PageType.ListPart) ? "RecordSet" : "Record"
    return <div className="position-relative">
        <PageContextProvider>
            <PageContextProducer page={page} pageView={pageView} />
            <DataContextProvider>
                <DataContextProducer
                    produce={produce}
                    entity={page.sourceObject}
                    systemId={pageView?.systemId}
                    fields={[]}
                    filters={filters}
                    orderBy={page.orderBy}
                    pageSize={page.recordLimit} />

                {getComponent()}
            </DataContextProvider>
        </PageContextProvider>
    </div>
}

