import { AlertMessage, Pagination, ProgressIndicator } from "../../../../widgets";
import { IPage, IQueryMultiple } from "../../models";

import { ViewPageControls } from "./ViewPageControls";
import { useDataContext } from "../../context/DataContextProvider";

export const ListPage = ({ page, className }: {
    page: IPage,
    className?: string
}) => {
    const { type, data, isLoading, isSuccess, isError } = useDataContext()

    return (
        <div className={className}>
            {(isSuccess && (type === "List" || type === "Static")) && <>
                <ViewPageControls controls={page.controls} />
                <AlertMessage show={!data || !data.count} message="No data found" />
                {(page.pagination) &&
                    <ListPagePagination page={page} />}
            </>}
            <ProgressIndicator show={isLoading} message="Loading..." />
            <AlertMessage show={isError} type="Error" message="Failed to load data" />
        </div>)
}

export const ListPagePagination = ({ page }: {
    page: IPage,
}) => {
    const { query, getMultipleRecords } = useDataContext()
    const { pageNo } = (query || {}) as IQueryMultiple
    const { type, data, isLoading, isSuccess, isError } = useDataContext();

    if (isLoading || isError || !isSuccess || type !== "List")
        return <></>

    return <Pagination
        records={data.count || 0}
        curPageNo={pageNo || 0}
        pageSize={page.recordLimit}
        onChange={(pageNo) => getMultipleRecords({ ...query as IQueryMultiple, pageNo })} />
}