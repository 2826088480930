export enum PageType {
    Card = "Card",
    List = "List",
    Root = "Root",
    ListPart = "List Part",
    CardPart = "Card Part",
    AgGrid="AgGrid"
}

export enum PageSourceType {
    Table = "Table",
    Query = "Query",
}
