import { ApiTags, rootApi } from '../../rootApi';

import { IDataEntity } from '../../../base/models';

const TAG = ApiTags.DataEntity;
const getTags = (entities: IDataEntity[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const entitiesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getEntities: builder.query<IDataEntity[], void>({
      query: () => `/Entities`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
  })
})

export const { useGetEntitiesQuery } = entitiesApi;