import { IContact, useGetContactsQuery } from "../../services/data/table/contactsApi";
import { useEffect, useState } from "react";

import { Typeahead } from 'react-bootstrap-typeahead';
import clsx from "clsx";

export const SelectContact = ({ title, value, disabled, className, onChange, onBlur }: {
    title?: string,
    value?: string,
    className?: string,
    disabled?: boolean,
    onChange: (value?: string) => void,
    onBlur?: () => void
}) => {
    const { data, isError, isLoading, isSuccess } = useGetContactsQuery();
    const [entities, setEntities] = useState<IContact[]>([]);

    useEffect(() => {
        if (isSuccess)
            setEntities(data)
    }, [isSuccess, data])

    if (isError) {
        <span className='alert-info' style={{ display: 'block' }}>
            Failed to load data
        </span>
    }

    const handleOnChange = (selected: Record<string, any>) => {
        const value = selected[0];
        if (value)
            onChange(`${value["id"]}`);
        else
            onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group")}>
            {title && <label className="fs-8 fw-bold text-gray-600">{title}</label>}
            <Typeahead
                id="id"
                labelKey={option => `${(option as any).title || ''}`}
                className="custom-typeahead typeahead-sm"
                isLoading={isLoading}
                clearButton={true}
                disabled={disabled}
                options={entities}
                onChange={handleOnChange}
                onBlur={onBlur}
                selected={entities?.filter(p => `${p.id}` === value)}
            />
        </div>
    )
}