import { AlertMessage, ProgressIndicator } from '../../widgets'
import { CreateSiteMember, UpdateSiteMember } from './components'

import { ISite } from "../../services/auth/models"
import React from 'react'
import { useGetMembersBySiteIdQuery } from '../../services/auth'

export const EditSiteUsers = ({ site, className }: {
    site: ISite,
    className?: string
}) => {
    const { data, isLoading, isFetching, isError, isSuccess } = useGetMembersBySiteIdQuery(site.portalId)

    return <>
        <ProgressIndicator show={isLoading || isFetching} message='Loading...' />
        <AlertMessage show={isError} type="Error" message='Failed to load entity relations' />

        <div className="table-responsive min-h-350px">
            <table className='table table-sm'>
                <thead className='fw-bold'>
                    <tr>
                        <th className='px-0'>User</th>
                        <th className='px-2'>User Entity</th>
                        <th className='w-175px'>Dashboard</th>
                        <th className='w-175px'>Blocked</th>
                    </tr>
                </thead>
                <tbody>
                    {isSuccess && <>
                        {data.map(member => <React.Fragment key={member.id}>
                            <UpdateSiteMember site={site} member={member} />
                        </React.Fragment>)}
                    </>}

                    <CreateSiteMember site={site} />
                </tbody>
            </table>
        </div>
    </>
}


