import * as authHelper from './AuthHelpers'

import {
  FC,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLazyGetSiteQuery, useLazyGetUserByTokenQuery } from '../../../framework/services/auth'

import { IAuthTokenModel } from './AuthModel'
import { ISite } from '../../../framework/services/auth/models'
import { IUserModel } from './UserModel'
import { LayoutSplashScreen } from '../../../layout/LayoutSplashScreen'
import { AuthProvider as MSALAuthProvider } from "../msal";
import { WithChildren } from '../../../helpers'

type AuthContextProps = {
  auth?: IAuthTokenModel,
  saveAuth: (auth?: IAuthTokenModel) => void,
  user?: IUserModel,
  setUser: (user?: IUserModel) => void,
  updateUser: (user: Partial<IUserModel>) => void,
  siteId?: string,
  site?: ISite,
  setSite: (siteId?: string) => void,
  logout: () => void
}

const initAuthContextPropsState: AuthContextProps = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  user: undefined,
  setUser: () => { },
  updateUser: () => { },
  siteId: undefined,
  setSite: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const APP_HEADER = process.env.REACT_APP_HEADER;

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<IAuthTokenModel | undefined>(authHelper.getAuth())
  const [siteId, setSiteId] = useState<string | undefined>()
  const [user, setUser] = useState<IUserModel>()
  const [getSite, { data: site }] = useLazyGetSiteQuery()

  //REACT_APP_HEADER
  useEffect(() => {
    if (!user) return;

    let siteId = authHelper.getSite(user.userId)
    if (!siteId && user.portals.length === 1)
      siteId = user.portals[0];

    setSiteId(siteId)
    // eslint-disable-next-line
  }, [user?.userId])

  useEffect(() => {
    document.title = site?.title || APP_HEADER || 'Portal Framework';
  }, [site])

  useEffect(() => {
    if (!siteId) return;
    getSite(siteId)
    // eslint-disable-next-line
  }, [siteId])

  const saveAuth = (auth: IAuthTokenModel | undefined) => {
    setAuth(auth)

    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const updateUser = (changes: Partial<IUserModel>) => {
    if (!user) return;
    setUser({ ...user, ...changes })
  }

  const setSite = (siteId?: string) => {
    if (!user) return;
    const { userId } = user;

    if (siteId)
      authHelper.setSite(userId, siteId)
    else
      authHelper.removePortal(userId)

    setSiteId(siteId)
  }

  const logout = () => {
    setUser(undefined)
    saveAuth(undefined)
  }

  return (
    <AuthContext.Provider value={{
      auth,
      saveAuth,
      user,
      setUser,
      updateUser,
      siteId,
      site,
      setSite,
      logout,
    }}>
      <MSALAuthProvider>
        {children}
      </MSALAuthProvider>
    </AuthContext.Provider>
  )
}

const useRequestUser = () => {
  const [getUser] = useLazyGetUserByTokenQuery()

  const getUserByToken = (apiToken: string): Promise<IUserModel> => {
    return getUser(apiToken)
      .unwrap()
      .then(user => {
        return Promise.resolve(user);
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

  return { getUserByToken }
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { getUserByToken } = useRequestUser()

  const requestUser = async () => {
    if (!didRequest.current) {
      if (!auth || !auth.token) {
        setShowSplashScreen(false)
        return;
      }

      setShowSplashScreen(true)
      getUserByToken(auth.token)
        .then((user) => {
          setUser(user)
        })
        .catch(() => {
          logout()
        })
        .finally(() => setShowSplashScreen(false))
    }
  }

  useEffect(() => {
    requestUser()

    return () => { didRequest.current = true }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
