import { ICornerSpacing, IResponsiveSpacing, ISpacing, SpacingUnit } from "../models";

import clsx from "clsx";

export const getSpacingClasses = (spacing?: ISpacing) => {
    let classes = "";
    if (spacing) {
        classes = clsx(classes, cornerSpacingClasses(spacing.margin, "m"));
        classes = clsx(classes, cornerSpacingClasses(spacing.padding, "p"));
    }

    return classes;
}

const cornerSpacingClasses = (corner?: ICornerSpacing, symbol?: string) => {
    let classes = "";

    if (corner) {
        classes = clsx(classes, responsiveSpacingClasses(corner.all, symbol));
        classes = clsx(classes, responsiveSpacingClasses(corner.top, `${symbol}t`));
        classes = clsx(classes, responsiveSpacingClasses(corner.bottom, `${symbol}b`));
        classes = clsx(classes, responsiveSpacingClasses(corner.left, `${symbol}s`));
        classes = clsx(classes, responsiveSpacingClasses(corner.right, `${symbol}e`));
        classes = clsx(classes, responsiveSpacingClasses(corner.x, `${symbol}x`));
        classes = clsx(classes, responsiveSpacingClasses(corner.y, `${symbol}y`));
    }

    return classes;
}

const responsiveSpacingClasses = (space?: IResponsiveSpacing, prefix?: string) => {
    let classes = "";

    if (space) {
        classes = clsx(classes, spacingClass(space.allSizes, prefix));
        classes = clsx(classes, spacingClass(space.small, prefix, "sm"));
        classes = clsx(classes, spacingClass(space.medium, prefix, "md"));
        classes = clsx(classes, spacingClass(space.large, prefix, "lg"));
        classes = clsx(classes, spacingClass(space.xLarge, prefix, "xl"));
        classes = clsx(classes, spacingClass(space.xxLarge, prefix, "xxl"));
    }

    return classes;
}

const spacingClass = (space?: SpacingUnit, prefix?: string, size?: string) => {
    let classes = "";

    switch (space) {
        case SpacingUnit.Auto: return `${prefix}${size ? `-${size}` : ''}-auto`;
        case SpacingUnit.S0: return `${prefix}${size ? `-${size}` : ''}-0`;
        case SpacingUnit.S1: return `${prefix}${size ? `-${size}` : ''}-1`;
        case SpacingUnit.S2: return `${prefix}${size ? `-${size}` : ''}-2`;
        case SpacingUnit.S3: return `${prefix}${size ? `-${size}` : ''}-3`;
        case SpacingUnit.S4: return `${prefix}${size ? `-${size}` : ''}-4`;
        case SpacingUnit.S5: return `${prefix}${size ? `-${size}` : ''}-5`;

        case SpacingUnit.S6: return `${prefix}${size ? `-${size}` : ''}-6`;
        case SpacingUnit.S7: return `${prefix}${size ? `-${size}` : ''}-7`;
        case SpacingUnit.S8: return `${prefix}${size ? `-${size}` : ''}-8`;
        case SpacingUnit.S9: return `${prefix}${size ? `-${size}` : ''}-9`;
        case SpacingUnit.S10: return `${prefix}${size ? `-${size}` : ''}-10`;
        case SpacingUnit.S11: return `${prefix}${size ? `-${size}` : ''}-11`;
        case SpacingUnit.S12: return `${prefix}${size ? `-${size}` : ''}-12`;
        case SpacingUnit.S13: return `${prefix}${size ? `-${size}` : ''}-13`;
        case SpacingUnit.S14: return `${prefix}${size ? `-${size}` : ''}-14`;
        case SpacingUnit.S15: return `${prefix}${size ? `-${size}` : ''}-15`;
        case SpacingUnit.S16: return `${prefix}${size ? `-${size}` : ''}-16`;
        case SpacingUnit.S17: return `${prefix}${size ? `-${size}` : ''}-17`;
        case SpacingUnit.S18: return `${prefix}${size ? `-${size}` : ''}-18`;
        case SpacingUnit.S19: return `${prefix}${size ? `-${size}` : ''}-19`;
        case SpacingUnit.S20: return `${prefix}${size ? `-${size}` : ''}-20`;
    }

    return classes;
}

