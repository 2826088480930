import clsx from "clsx"

export const Spinner = ({ message, show, className, messageClass }: {
    message?: string,
    className?: string,
    messageClass?: string,
    show?: boolean
}) => {
    if (show === false) return <></>

    return (
        <div className={clsx(className, 'indicator-progress mx-1')} style={{ display: 'inline-block' }}>
            <span className='spinner-border spinner-border-sm align-middle' />
            {message && <span className={clsx(messageClass, "ms-2")}>{message}</span>}
        </div>
    )
}