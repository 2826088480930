import { AlertMessage, Spinner } from "../../../widgets";
import { IControlProps, IEntityFilter, IPageLink } from "../../../base/models";
import { PageMode, Property } from "../../../base/types";
import { useEffect, useState } from "react";

import { PageLinkBinding } from "../../../base/bindings/page-link";
import { PageViewer } from "../../page/page/PageViewer";
import { useDesignContext } from "../../../providers";
import { useGetPageMetadataQuery } from "../../../services/data";
import { useGetProperties } from "../../../base/hooks";

export const CardPartPageComponent = ({ control, className }: IControlProps) => {
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const pageLink: IPageLink = properties[Property.PageLink]
    const tooltip: string | undefined = properties[Property.Tooltip];
    const { mode } = useDesignContext()
    const [pageLinkConfig, setPageLinkConfig] = useState<IPageLink>()

    useEffect(() => {
        const pageLink2 = getBinding(Property.PageLink) as PageLinkBinding
        setPageLinkConfig(pageLink2.value)
        // eslint-disable-next-line
    }, [control.bindings])

    if (mode === "Design") {
        return <div>
            <div className="alert alert-info fs-8 mb-0">
                <div className="badge badge-info mb-2">Card Part Component</div>

                {(!pageLink?.pageId) && <>
                    <p className="m-0 p-0 text-danger my-1">Page not selected.</p>
                </>}
                {(!pageLinkConfig?.systemId) && <>
                    <p className="m-0 p-0 text-danger my-1">System Id field not selected.</p>
                </>}
                <p className="mb-0">Click the preview button to check the layout</p>
            </div>
        </div>
    }

    return (<div className={className} title={tooltip}>
        {(!pageLink || !pageLink.pageId || !pageLink.systemId) && <>
            <div>Part Page is not configured properly.</div>
        </>}

        {(pageLink?.pageId && pageLink.systemId) && <>
            <CardPartPageOuter
                pageId={pageLink.pageId}
                systemId={pageLink.systemId}
                filters={pageLink.filters || []} />
        </>}
    </div>)
}

const CardPartPageOuter = ({ pageId, systemId, filters }: {
    pageId: string,
    systemId?: string,
    filters: IEntityFilter[]
}) => {
    const { data: page, isLoading, isSuccess, isError, error } = useGetPageMetadataQuery(pageId);

    return <>
        <div className="d-flex justify-content-center">
            <Spinner show={isLoading} className="mb-2" message="Please wait ..." />
        </div>
        <AlertMessage
            show={isError}
            type="Error"
            message={(error as any)?.data?.error?.message || `Failed to load the page id: ${pageId}`} />
        {(isSuccess && page) && <PageViewer page={page} pageView={{
            systemId: systemId,
            pageMode: PageMode.View,
            linkFilters: filters
        }} />}
    </>
}