import { Navigate, useParams } from 'react-router-dom'

import { CustomComponentLoader } from '../framework/design/custom-component';
import { PageTitle } from '../layout/core'
import { useState } from 'react'

export const DesignComponentWrapper = () => {
  const { componentId } = useParams();
  const [title, setTitle] = useState<string>("Component Designer")

  if (!componentId) {
    return <Navigate to='/error/404' />
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{title}</PageTitle>
      <CustomComponentLoader componentId={componentId} onLoad={(p) => setTitle(p.name)} />
    </>)
}

