import { HTMLInputTypeAttribute } from "react"
import { QueryParameter } from "../../../../base/models"
import { SelectEntityAttribute } from "../../SelectEntityAttribute"

const ParamValueTypes = [
    { id: "Const", value: "Const" },
    { id: "Field", value: "Field" },
]

export const EditQueryParameter = ({ entity, type, parameter, onChange }: {
    entity?: string,
    type: HTMLInputTypeAttribute,
    parameter: QueryParameter,
    onChange: (value: QueryParameter) => void,
}) => {
    return (
        <tr>
            <td className='py-1 pe-2'>{parameter.name}</td>
            <td className='py-1 px-2'>
                <select
                    value={parameter.type}
                    onChange={(e) => onChange({
                        ...parameter,
                        type: e.target.value as "Field" | "Const",
                        value: ''
                    })}
                    className="form-select form-select-sm">
                    {ParamValueTypes.map(item => <option key={item.id} value={item.id}>{item.value}</option>)}
                </select>
            </td>
            <td className='py-1 ps-2'>
                {(parameter.type === "Field" && entity) &&
                    <SelectEntityAttribute
                        entity={entity}
                        value={String(parameter.value)}
                        onChange={(field) => onChange({ ...parameter, value: field || '' })} />
                }

                {parameter.type !== "Field" &&
                    <input type={type}
                        value={parameter.value}
                        onChange={(e) => onChange({ ...parameter, value: e.target.value })}
                        className="form-control form-control-sm" />
                }
            </td>
        </tr>)
}

