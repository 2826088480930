import { IEntityLookupData } from '../models';
import { rootApi } from '../../../../services/rootApi';

const lookupAttributeDataApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getLookupAttributeData: builder.query<IEntityLookupData[], string>({
      query: (attributeId) => `/LookupAttributeData/Attribute(${attributeId})`,
    }),
  })
})

export const {
  useGetLookupAttributeDataQuery,
  useLazyGetLookupAttributeDataQuery,
} = lookupAttributeDataApi;