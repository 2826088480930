import { DisplayFormat, Property } from "../../../base/types";

import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import { MUIIcon } from "../../../widgets";
import clsx from "clsx";
import { formatValue } from "../../helpers";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const LabelComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const displayFormat: DisplayFormat | undefined = properties[Property.Format];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const icon: string | undefined = properties[Property.MaterialIcon]

    if (mode === "Design") {
        return (
            <span className={className} title={tooltip}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                <DefaultLabel
                    control={control}
                    propertyId={Property.Label}
                    defaultLabel="Label" />
            </span>)
    }

    return (
        <label className={className} title={tooltip}>
            {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
            <span>{formatValue(label, displayFormat)}</span>
            {children}
        </label>)
}
