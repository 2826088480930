import { AlertMessage, MUIIcon, ProgressIndicator } from "../../../framework/widgets";
import { ColDef, ICellRendererParams } from "ag-grid-community";

import { AgGridReact } from "ag-grid-react";
import { EditUser } from './components';
import { IUser } from "../../../framework/services/auth/models";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import clsx from 'clsx';
import { useGetUsersQuery } from "../../../framework/services/auth"
import { useState } from "react";

interface IUserCanvas {
    show: boolean,
    user?: IUser
}

export const Users = () => {
    const { data, isLoading, isSuccess, isError } = useGetUsersQuery();
    const [canvas, setCanvas] = useState<IUserCanvas>({ show: false });

    const handleClose = () => setCanvas({ show: false });
    const handleShow = (user: IUser) => setCanvas({ show: true, user });

    const columnTemplate: ColDef = {
        flex: 1,
        resizable: true,
        sortable: true,
        filter: true,
        cellClass: 'fs-7',
        headerClass: 'fs-7',
        minWidth: 150,
    }

    const gridColumns: ColDef[] = [
        {
            ...columnTemplate,
            field: "userName",
            headerName: "User Name",
            valueFormatter: (params) => (params.value),
            cellRenderer: (props: ICellRendererParams) => {
                const cellValue = props.valueFormatted || props.value;

                return (
                    <button type="button"
                        onClick={() => handleShow(props.data)}
                        className={clsx("btn btn-link text-primary text-hover-info py-0", props.colDef?.cellClass)}>
                        {cellValue}
                    </button>
                )
            }
        }, {
            ...columnTemplate,
            field: "email",
            headerName: "EMail",
        }, {
            ...columnTemplate,
            field: "firstName",
            headerName: "First Name",
        }, {
            ...columnTemplate,
            field: "lastName",
            headerName: "Last Name",
        }, {
            ...columnTemplate,
            field: "phoneNumber",
            headerName: "Phone Number",
        }
    ]
    return <div className="">
        <ProgressIndicator show={isLoading} message="Loading..." />
        <AlertMessage show={isError} message="Failed to load users" />
        {(isSuccess && data) && <>
            <div className='ag-theme-alpine'>
                <AgGridReact
                    headerHeight={32}
                    rowHeight={32}
                    columnDefs={gridColumns}
                    rowData={data}
                    domLayout="autoHeight"
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
        </>}

        <UserCanvas options={canvas} handleClose={handleClose} />
    </div>
}

const UserCanvas = ({ options: { user, show }, handleClose }: {
    options: IUserCanvas,
    handleClose: () => void,
}) => {
    return <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>
                <div className='d-flex align-items-center'>
                    <MUIIcon iconName='PersonOutlined' className='me-1' />
                    <span>User Info</span>
                </div>
            </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            {(user) && <EditUser
                onSave={handleClose}
                onCancel={handleClose}
                user={user} />}
        </Offcanvas.Body>
    </Offcanvas>
}

