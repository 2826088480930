import { DocumentTypeMetadata, IDocumentType } from "../models";
import { useEffect, useState } from "react";

import { FieldFilterType } from "../../../../base/types";
import { IFilterGroup } from "../../../../data";
import { useAuth } from "../../../../../modules/auth";
import { useLazyReadMultipleQuery } from "../../../../services/data/tableDataApi";

// TODO:: status fields should be optimized
export const useGetDocumentTypes = ({ categoryId }: {
    categoryId?: string,
}) => {
    const [readRecords] = useLazyReadMultipleQuery();
    const { user, siteId: portalId } = useAuth()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [data, setData] = useState<IDocumentType[]>()

    const refetch = () => {
        if (!user || !categoryId)
            return;

        const { userId } = user;
        if (!portalId) return;

        setIsSuccess(false)
        setIsError(false)
        setError(undefined)
        setIsLoading(true)

        const { tableId, fields } = DocumentTypeMetadata
        const fieldList: string[] = [
            fields.id,
            fields.categoryId,
            fields.description
        ]
        const filters: IFilterGroup[] = [{
            group: 0,
            filters: [
                { field: fields.categoryId, type: FieldFilterType.Const, value: categoryId },
            ]
        }]

        readRecords({ userId, portalId, tableId: tableId, fieldList, recordLimit: 0, filters })
            .unwrap()
            .then(data => {
                const records = data?.records || [];
                setData(records.map(p => ({
                    id: p[fields.id],
                    documentCategoryId: p[fields.categoryId],
                    description: p[fields.description],
                })));
                setIsSuccess(true)
            })
            .catch(err => {
                setIsError(true)
                setError(err.data?.error?.message || 'Failed to get data')
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        refetch()
        // eslint-disable-next-line
    }, [categoryId])

    return { data, isLoading, isSuccess, isError, error, refetch }
}
