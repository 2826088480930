import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const DropdownProperties: IProperty[] = [
    { id: Property.Dropdown, name: 'Options', type: PropertyType.Dropdown },
    { id: Property.ControlValue, name: 'Value', type: PropertyType.String },
    { id: Property.Label, name: 'Label', type: PropertyType.String },
    { id: Property.Required, name: 'Required', type: PropertyType.Boolean },
    { id: Property.Disabled, name: 'Disabled', type: PropertyType.Boolean },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String }
]