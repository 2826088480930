import { IPage, PageSourceType, PageType } from "../../models";
import React, { useState } from "react";
import { SelectEntity, SelectPageType, SelectSite, SelectSourceType } from "../../../widgets";

import { AlertMessage } from "../../../../widgets";
import clsx from "clsx";

export const EditPageGeneralInfo = ({ page, className, onChange }: {
    page: IPage,
    className?: string,
    onChange: (page: IPage) => void,
}) => {
    const [message, setMessage] = useState<string>()

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const pageName = e.target.value;
        setMessage(undefined)
        if (!pageName) {
            setMessage(`Page name should not be empty.`);
            return;
        }

        onChange({ ...page, name: pageName });
    }

    const handleTitleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(undefined);
        const pageTitle = e.target.value;

        if (!pageTitle) {
            setMessage(`Page title should not be empty.`);
            return;
        }

        onChange({ ...page, title: pageTitle });
    }

    const handleOnPageTypeChange = (pageType: PageType) => {
        onChange({ ...page, type: pageType });
    }

    const handleOnSiteChange = (portalId?: string) => {
        if (!portalId) return;

        onChange({ ...page, portalId });
    }

    const handleOnSourceTypeChange = (sourceType: PageSourceType) => {
        onChange({ ...page, sourceType: sourceType });
    }

    const handleSourceObjectChange = (sourceObject?: string) => {
        onChange({ ...page, sourceObject });
    }

    return <>
        <div className={clsx("row", className)}>
            <AlertMessage show={!!message} type="Warning" message={message} className="mb-2" />

            <div className="mb-2 col-md-5">
                <label className="fs-8 fw-bold text-gray-600">Page Name</label>
                <input type="text"
                    className="form-control form-control-sm"
                    onChange={handleOnChange}
                    value={page.name || ''} />
            </div>
            <div className="mb-2 col-md-5">
                <label className="fs-8 fw-bold text-gray-600">Page Title</label>
                <input type="text"
                    className="form-control form-control-sm"
                    onChange={handleTitleOnChange}
                    value={page.title || ''} />
            </div>
            <div className="mb-2 col-md-2">
                <SelectPageType
                    title="Page Type"
                    className=""
                    onChange={handleOnPageTypeChange}
                    value={page.type || ''} />
            </div>
            <div className="mb-2 col-md-4">
                <label className="fs-8 fw-bold text-gray-600">Site</label>
                <SelectSite
                    className="form-select-sm"
                    onChange={handleOnSiteChange}
                    value={page.portalId || ''} />
            </div>
            <div className="mb-2 col-md-4">
                <SelectSourceType
                    title="Source Type"
                    className=""
                    onChange={handleOnSourceTypeChange}
                    value={page.sourceType} />
            </div>
            <div className="mb-2 col-md-4">
                <label className="fs-8 fw-bold text-gray-600">Source Table</label>
                <SelectEntity
                    onChange={handleSourceObjectChange}
                    value={page.sourceObject} />
            </div>
        </div>
    </>
}