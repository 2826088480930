import { IDropdownQuestion } from '../models/feedback-form.model';
import clsx from 'clsx';

const DropdownQuestion = ({ question, value, onChange, onBlur }: {
    question: IDropdownQuestion,
    value?: string,
    onChange: (value: string) => void,
    onBlur: () => void
}) => {
    return (
        <div className="form-group">
            <label className={clsx("form-label", { required: question.isRequired })}>{question.title}</label>
            <select
                className="form-select form-select-sm" // Add Bootstrap classes for small form controls
                required={question.isRequired}
                value={value || ''}
                onBlur={() => onBlur()}
                onChange={(e) => onChange(e.target.value)}
            >
                <option value="" disabled>Choose an option</option>
                {question.choices.map((choice, index) => (
                    <option key={index} value={choice}>
                        {choice}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownQuestion;
