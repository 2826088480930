import { IToolbarAction } from "../models";
import React from "react";
import { ToolbarAction } from "./ToolbarAction"
import clsx from "clsx";

export const ToolbarActions = ({ actions, className, onAction }: {
    actions?: IToolbarAction[],
    className?: string,
    onAction: (id: string) => Promise<void>
}) => {
    if (!actions) return <></>

    return <div className={clsx(className, "d-flex align-items-center")}>
        {actions.map((action, i) => <React.Fragment key={i}>
            <ToolbarAction
                className="ms-5"
                action={action}
                onAction={onAction}
            />
        </React.Fragment>)}
    </div>
}

