import { DefaultTitle } from './page-title/DefaultTitle';
import { Tools } from '../../../framework/layout';
import clsx from 'clsx';
import { useLayout } from '../../core';

export const Toolbar = () => {
  const { classes } = useLayout()

  return (
    <>
      <div className='toolbar' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />
          <Tools />
        </div>
      </div>
    </>
  )
}
