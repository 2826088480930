import { rootApi } from "../../../services/rootApi";

export interface IUserNotificationToken {
    userId: string,
    token: string,
}

const notificationsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    addNotificationToken: builder.mutation<void, IUserNotificationToken>({
      query: (model) => ({
        url: `/Notifications/RegisterToken`,
        method: 'POST',
        body: model
      })
    })
  })
})

export const {
  useAddNotificationTokenMutation,
} = notificationsApi;