import { CollapsiblePanel, Panel } from "../../../widgets";

import { DocumentChecklist } from "./DocumentChecklist";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useGetProperties } from "../../../base/hooks";

export const DocumentChecklistComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const entityId: string | undefined = properties[Property.EntityId];
    const entity: string | undefined = properties[Property.Entity]
    const checklistId: string | undefined = properties[Property.ChecklistId]
    const title: string | undefined = properties[Property.Title]
    const headerClass: string | undefined = properties[Property.HeaderClass]
    const collapsible: boolean | undefined = properties[Property.Collapsible]
    const relativePath: string | undefined = properties[Property.RelativePath]

    if (collapsible) {
        return (
            <CollapsiblePanel
                eventKey="checklist"
                header={title}
                icon="FactCheckOutlined"
                headerClass={headerClass}
                className={className}>
                {(checklistId) && <>
                    <DocumentChecklist
                        entityId={entityId}
                        entity={entity}
                        relativePath={relativePath}
                        checklistId={checklistId} />
                </>}
            </CollapsiblePanel>
        )
    }

    return (
        <Panel
            header={title}
            icon="FactCheckOutlined"
            headerClass={headerClass}
            className={className}>
            {(checklistId) && <>
                <DocumentChecklist
                    entityId={entityId}
                    entity={entity}
                    relativePath={relativePath}
                    checklistId={checklistId} />
            </>}
        </Panel>)
}

