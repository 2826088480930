import { AlertMessage, MUIIcon, ProgressIndicator } from "../../../../widgets";
import { IPage, IPageView } from "../../models";

import Accordion from "react-bootstrap/esm/Accordion";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { DesignEvent } from "../../../../base/models";
import { DndContextProvider } from "../../../dnd/DndContextProvider";
import { LayoutActionCollection } from "../../../page-action";
import { LayoutControlCollection } from "../../../page-control";
import { useDataContext } from "../../context/DataContextProvider";
import { useUpdateDesignDataContext } from "../../hooks";

export const PageDesigner = ({ page, pageView, onDesignChange }: {
    page: IPage,
    pageView?: IPageView,
    onDesignChange: (event: DesignEvent) => void
}) => {
    const isDataUpdated = useUpdateDesignDataContext({ page, pageView })
    const { isLoading, isSuccess, isError, error } = useDataContext();

    if (!isDataUpdated) return <></>

    if (!isSuccess || isLoading || isError) {
        return <div>
            <ProgressIndicator show={isLoading} message="Loading data..." />
            <AlertMessage show={isError} type="Error" message={error} />
        </div>
    }

    return (<DndContextProvider>
        <Accordion defaultActiveKey="Controls" className="accordion-sm mt-5" flush>
            <AccordionItem eventKey="Controls" className="mb-3">
                <Accordion.Header>
                    <MUIIcon iconName="GridViewOutlined" className="me-1" />
                    <span>Page Controls</span>
                </Accordion.Header>
                <Accordion.Body className="bg-body">
                    <LayoutControlCollection
                        controls={page.controls}
                        onDesignChange={onDesignChange} />
                </Accordion.Body>
            </AccordionItem>
            <AccordionItem eventKey="Actions" className="mb-3">
                <Accordion.Header>
                    <MUIIcon iconName="SmartButtonOutlined" className="me-1" />
                    <span>Page Actions</span>
                </Accordion.Header>
                <Accordion.Body className="bg-body">
                    <LayoutActionCollection
                        actions={page.actions || []}
                        onDesignChange={onDesignChange} />
                </Accordion.Body>
            </AccordionItem>
        </Accordion>
    </DndContextProvider>)
}

