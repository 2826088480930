import { Alignment } from "../../../../base/styles";
import clsx from "clsx";

export const SelectAlignment = ({ title, value, className, onChange }:{
    title: string,
    value?: Alignment,
    className?: string,
    onChange: (value?: Alignment) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as Alignment;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={Alignment.Baseline}>Baseline</option>
                <option value={Alignment.Top}>Top</option>
                <option value={Alignment.Middle}>Middle</option>
                <option value={Alignment.Bottom}>Bottom</option>
                <option value={Alignment.TextBottom}>TextBottom</option>
                <option value={Alignment.TextTop}>TextTop</option>
           </select>
        </div>
    )
}