import { Binding } from "../../../base/bindings"
import { IDataSource } from "../../../base/types"
import { SelectAttribute } from "../../widgets"
import clsx from "clsx"
import { useBindingValue } from "../hooks"

export const FieldPropertyValue = ({ binding, dataSource, className, onChange }: {
    binding: Binding,
    dataSource?: IDataSource,
    className?: string,
    onChange: (binding: Binding) => void,
}) => {
    const { value, setValue } = useBindingValue(binding)

    const handlePropertyExprChange = (value?: string) => {
        onChange(setValue(value))
    }

    if (!dataSource)
        return <div className={clsx(className, 'text-danger fs-8')}>No Data Source</div>

    return <div className={className}>
        <SelectAttribute
            value={String(value)}
            attributes={dataSource.attributes}
            onChange={handlePropertyExprChange} />
    </div>
}