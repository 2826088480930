import { IEntity, Property } from "../../../base/types";
import { useCreateFeedbackMutation, useLazyGetFeedbackQuery } from "./services/feedbackApi";
import { useEffect, useState } from "react";

import FeedbackForm from "./components/FeedbackForm";
import { IControlProps } from "../../../base/models";
import { IFeedback } from "./models/IFeedback.model";
import { IFeedbackForm } from "./models/feedback-form.model";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const FeedbackComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const entity: string | undefined = properties[Property.Entity]
    const entityId: string | undefined = properties[Property.EntityId];
    const formName: string | undefined = properties[Property.FeedbackName];
    const feedbackForm: string | undefined = properties[Property.FeedbackForm]
    const [createFeedback] = useCreateFeedbackMutation()
    const [getFeedback] = useLazyGetFeedbackQuery()
    const [form, setForm] = useState<IFeedbackForm>();
    const [jsonError, setJsonError] = useState<string>();
    const [enabled, setEnable] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const { user } = useAuth()

    useEffect(() => {
        if (!feedbackForm) return;
        try {
            const formObject = JSON.parse(feedbackForm) as IFeedbackForm
            setForm(formObject)
            setJsonError(undefined);
        } catch (err) {
            setJsonError("Error converting feedback json text to FeedbackForm object");
            console.error(err);
        }
    }, [feedbackForm])

    useEffect(() => {
        if (!form || !user || !entity || !entityId || !formName)
            return;

        getFeedback({ entity, entityId, formName, userId: user.userId })
            .unwrap()
            .then((data) => {
                if (data) {
                    setEnable(false)
                    setSubmitted(true)
                } else {
                    setEnable(true)
                }
            })
        // eslint-disable-next-line
    }, [user, entity, entityId, formName, form])

    const handleOnSubmit = (values: IEntity) => {
        if (!enabled || !user || !entity || !entityId || !formName) {
            return Promise.reject("Unable to submit the feedback form. Something went wrong.")
        }

        const { contactId, userId } = user;

        const feedback: Partial<IFeedback> = {
            contactId, userId, entity, entityId, formName,
            feedbackJson: JSON.stringify(values)
        }

        return createFeedback(feedback)
            .unwrap()
            .then(() => {
                setSubmitted(true)
                return Promise.resolve();
            })
            .catch(() => {
                return Promise.reject();
            })
    }

    if (mode === "Design") {
        return <div>
            <div className="alert alert-info fs-8 mb-0">
                <div className="badge badge-info mb-2">Feedback Component</div>
                <p className="mb-0">Click the preview button to check the layout</p>
                {(jsonError) && <p className="text-danger">{jsonError}</p>}
            </div>
        </div>
    }

    return (
        <div className={className}>
            {(submitted) && <>
                <h1 className="text-gray-800">{form?.title}</h1>
                <h6 className="text-gray-600">Feedback submitted.</h6>
            </>}

            {(!submitted && enabled && form) && <>
                <FeedbackForm form={form} onSubmit={handleOnSubmit} />
            </>}
        </div>)
}

