import * as Yup from 'yup'

import { AlertMessage, MUIIcon, ProgressIndicator } from '../../widgets'
import { Formik, FormikHelpers } from 'formik'
import { SelectEntity, SelectPage } from '../../design/widgets'

import { ISite } from "../../services/auth/models"
import { useUpdateSiteMutation } from '../../services/auth'

const siteSchema = Yup.object().shape({
    title: Yup.string()
        .max(50, 'Maximum 50 symbols')
        .required('Title is required'),
    description: Yup.string()
        .max(250, 'Maximum 50 symbols')
        .required('Description is required'),
    portalEntity: Yup.string()
        .required('Entity is required'),
    imageGallery: Yup.string(),
    dashboardPageId: Yup.string()
        .required('Dashboard page is required'),
    profilePageId: Yup.string()
        .required('Profile Page is required')
})

export const SiteGeneralSettings = ({ site, className }: {
    site: ISite,
    className?: string
}) => {
    const [updateSite] = useUpdateSiteMutation()

    const handleSubmit = (values: Partial<ISite>, { setStatus, setSubmitting }: FormikHelpers<ISite>) => {
        setSubmitting(true)
        console.log(values)
        updateSite(values)
            .unwrap()
            .then((site) => {
                console.log("updated site", site)
            })
            .catch(err => {
                setStatus(err.data?.error?.message || 'Failed to update site settings.')
            })
            .finally(() => setSubmitting(false))
    }

    return (
        <Formik
            initialValues={site}
            validationSchema={siteSchema}
            onSubmit={handleSubmit}>
            {({
                errors, touched, status, isSubmitting, isValid, values,
                handleSubmit, setFieldValue, getFieldProps, setFieldTouched }) => (
                <form className='form' onSubmit={handleSubmit} noValidate id='site-settings'>
                    <AlertMessage show={!!status} message={status} type='Error' />
                    <div className='row g-2'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <label className='form-label fw-semibold fs-7 mb-1'>Title</label>
                            <input
                                placeholder='User Name'
                                {...getFieldProps('title')}
                                className='form-control form-control-sm'
                                type='text'
                                name='title'
                                autoComplete='off'
                            />
                            <FieldError show={touched.title && !!errors.title} message={errors.title} />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <label className='form-label fw-semibold fs-7 mb-1'>User's Primary Entity</label>
                            <SelectEntity
                                value={values.portalEntity}
                                onBlur={() => setFieldTouched('portalEntity', true)}
                                onChange={(entity) => { setFieldValue('portalEntity', entity) }} />
                            <FieldError show={touched.portalEntity && !!errors.portalEntity} message={errors.portalEntity} />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <label className='form-label fw-semibold fs-7 mb-1'>Description</label>
                            <textarea
                                placeholder='Description'
                                {...getFieldProps('description')}
                                className='form-control form-control-sm'
                                name='description'
                                autoComplete='off'
                            />
                            <FieldError show={touched.description && !!errors.description} message={errors.description} />
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <label className='form-label fw-semibold fs-7 mb-1'>Image Gallery</label>
                            <input
                                placeholder='Image Gallery'
                                {...getFieldProps('imageGallery')}
                                className='form-control form-control-sm'
                                type='text'
                                name='imageGallery'
                                autoComplete='off'
                            />
                            <FieldError show={touched.imageGallery && !!errors.imageGallery} message={errors.imageGallery} />
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <label className='form-label fw-semibold fs-7 mb-1'>Dashboard Page</label>
                            <SelectPage
                                siteId={site.portalId}
                                value={values.dashboardPageId}
                                onBlur={() => setFieldTouched('dashboardPageId', true)}
                                onChange={(pageId) => { setFieldValue('dashboardPageId', pageId) }} />
                            <FieldError show={touched.dashboardPageId && !!errors.dashboardPageId} message={errors.dashboardPageId} />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <label className='form-label fw-semibold fs-7 mb-1'>Profile Page</label>
                            <SelectPage
                                siteId={site.portalId}
                                value={values.profilePageId}
                                onBlur={() => setFieldTouched('profilePageId', true)}
                                onChange={(pageId) => { setFieldValue('profilePageId', pageId) }} />
                            <FieldError show={touched.profilePageId && !!errors.profilePageId} message={errors.profilePageId} />
                        </div>
                        <div className='mt-5'>
                            <button
                                type='submit'
                                id='submit-settings'
                                className='btn btn-sm btn-primary py-2'
                                disabled={isSubmitting || !isValid}
                            >
                                {!isSubmitting && <span className='indicator-label'>
                                    <MUIIcon iconName='SaveOutlined' className='me-1' />
                                    Save
                                </span>}
                                <ProgressIndicator show={isSubmitting} message='Please wait...' />
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}
const FieldError = ({ show, message }: { show?: boolean, message?: string }) => {
    if (!show) return <></>

    return <div className='fv-plugins-message-container'>
        <div className='fv-help-block fs-8'>
            <span role='alert'>{message}</span>
        </div>
    </div>
}