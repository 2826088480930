import { INPSQuestion } from '../models/feedback-form.model';
import clsx from 'clsx';

const NPSQuestion = ({ question, value, onChange, onBlur }: {
    question: INPSQuestion;
    value?: string,
    onChange: (value: string) => void,
    onBlur: () => void
}) => {
    return (
        <div className="form-group">
            <label className={clsx("form-label", { required: question.isRequired })}>{question.title}</label>
            <input
                type="range"
                min={question.minValue}
                max={question.maxValue}
                step="1"
                title={value}
                className="form-range"
                required={question.isRequired}
                value={value ? Number(value) : 0}
                onBlur={() => onBlur()}
                onChange={(e) => onChange(e.target.value)}
            />
            <div className="d-flex justify-content-between fs-7 fw-semibold">
                <span>{question.minValue}</span>
                <p>{(value && value !== "0") ? `Your score: ${value}` : 'Select a score'}</p>
                <span>{question.maxValue}</span>
            </div>
        </div>
    );
};

export default NPSQuestion;
