import { ButtonStyle, ControlSize, Property } from "../../../base/types";
import { MUIIcon, Spinner } from "../../../widgets";

import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import clsx from "clsx";
import { getButtonClass } from "./helper";
import { useDesignContext } from "../../../providers";
import { useFromData } from "../panels";
import { useGetProperties } from "../../../base/hooks";

export const ButtonComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const icon: string | undefined = properties[Property.MaterialIcon]
    const submit: boolean | undefined = properties[Property.Submit]
    const disabled: boolean | undefined = properties[Property.Disabled]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]

    const { isSubmitting } = useFromData()

    if (mode === "Design") {
        return (<div className={clsx(getButtonClass(style, size, "btn-secondary"), className)}>
            {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
            <DefaultLabel
                control={control}
                propertyId={Property.Label}
                defaultLabel="Button"
                className="text-hover-primary" />
            {children}
        </div>)
    }

    return (
        <button
            disabled={disabled === true || isSubmitting}
            type={submit ? "submit" : "button"}
            title={tooltip}
            className={clsx(getButtonClass(style, size, "btn-secondary"), className)}
        >
            <Spinner show={isSubmitting} className="me-1" />
            {(!isSubmitting && icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
            {label && <span>{label}</span>}
            {children}
        </button>)
}