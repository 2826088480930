import { IOrderBy, IQuery, IQueryMultiple } from "../models";

import { IFilterGroup } from "../../../data";
import { useDataContext } from "./DataContextProvider";
import { useEffect } from "react";
import { useReadAttributes } from "../../../base/hooks";

export const DataContextProducer = ({ produce, newRecord, entity, fields, systemId, filters, orderBy, pageSize }: {
    produce?: "Record" | "RecordSet",
    newRecord?: boolean,
    entity?: string,
    fields: string[],
    systemId?: string,
    filters: IFilterGroup[],
    orderBy?: IOrderBy,
    pageSize?: number,
}) => {
    const { readAttributes } = useReadAttributes();
    const { setData, dataSource, setDataSource, getRecord, getMultipleRecords, getFirstRecord } = useDataContext()

    useEffect(() => {
        if (!entity) {
            setData({ type: "Static", isLoading: false, isSuccess: true, isError: false })
            return;
        }

        readAttributes(entity)
            .then(attributes => setDataSource({ entity, attributes }))
        // eslint-disable-next-line
    }, [entity])

    useEffect(() => {
        if (!dataSource)
            return;

        switch (produce) {
            case "RecordSet":
                loadListData()
                break;
            case "Record":
            default:
                if (newRecord === true) {
                    setData({ type: "Card", data: {}, isLoading: false, isSuccess: true, isError: false })
                } else {
                    loadCardData();
                }
                break;
        }

        // eslint-disable-next-line
    }, [produce, dataSource, newRecord, entity, fields, systemId, filters, orderBy, pageSize])

    const getReadMultipleQuery = (): IQueryMultiple => {
        const query: IQueryMultiple = { fields, filters, orderBy, pageSize }
        return query;
    }

    const loadListData = () => {
        const query = getReadMultipleQuery()
        getMultipleRecords(query);
    }

    const loadCardData = () => {
        if (systemId) {
            const query: IQuery = { id: systemId, fields }
            getRecord(query)
        } else {
            const query = getReadMultipleQuery()
            getFirstRecord(query)
        }
    }

    return <></>
}