import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const ChatProperties: IProperty[] = [
    { id: Property.Title, name: 'Title', type: PropertyType.String },
    { id: Property.Entity, name: 'Entity', type: PropertyType.Entity },
    { id: Property.EntityId, name: 'EntityId', type: PropertyType.String },
    { id: Property.Collapsible, name: 'Collapsable', type: PropertyType.Boolean },
    { id: Property.HeaderClass, name: 'Header Class', type: PropertyType.String },
]