import { createContext, useContext, useState } from "react"

import { DndItem } from "./models";

interface IDndModel {
    item: DndItem,
    setItem: (item: DndItem) => void
}

const context = createContext<IDndModel>({
    item: { type: "Empty" },
    setItem: () => { }
})

export const DndContextProvider = ({ children }: { children?: React.ReactNode }) => {
    const [item, setItem] = useState<DndItem>({ type: "Empty" })

    return (
        <context.Provider value={{ item, setItem }}>
            {children}
        </context.Provider>
    )
}

export function useDndContext() {
    return useContext(context)
}
