import { ApiTags, rootApi } from '../../../../services/rootApi';

import { IService } from '../models';

const TAG = ApiTags.Service;
const getTags = (entities: IService[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const servicesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getService: builder.query<IService, string>({
      query: (serviceId) => `/services/${serviceId}`,
      providesTags: (result) => result ? [{ type: TAG, id: result.id }] : [TAG]
    }),

    getRootServices: builder.query<IService[], { programType?: any, genderType?: any }>({
      query: ({ programType, genderType }) => `/services/root(${programType})/(${genderType})`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getChildServices: builder.query<IService[], { parentId?: string, programType?: any, genderType?: any }>({
      query: ({ parentId, programType, genderType }) => `/services/children(${parentId})/(${programType})/(${genderType})`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
  })
})

export const {
  useGetServiceQuery,
  useLazyGetServiceQuery,
  useGetRootServicesQuery,
  useLazyGetRootServicesQuery,
  useGetChildServicesQuery,
  useLazyGetChildServicesQuery
} = servicesApi;