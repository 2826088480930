import { Spinner } from "./Spinner"
import clsx from "clsx"

export const ProgressIndicator = ({ message, show, className, messageClass, children }: {
    message?: string,
    className?: string,
    messageClass?: string,
    show?: boolean,
    children?: React.ReactNode
}) => {
    if (show === false) return <></>

    return (
        <div className={clsx("d-flex justify-content-center", className)}>
            <Spinner show={show} messageClass={messageClass} message={message} />
            {children}
        </div>
    )
}