import { ColumnSize } from "../../../../../base/styles";
import clsx from "clsx";

export const SelectColumnSize = ({ title, value, className, onChange }:{
    title: string,
    value?: ColumnSize,
    className?: string,
    onChange: (value?: ColumnSize) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as ColumnSize;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={ColumnSize.Column}>Auto</option>
                <option value={ColumnSize.Column1}>1</option>
                <option value={ColumnSize.Column2}>2</option>
                <option value={ColumnSize.Column3}>3</option>
                <option value={ColumnSize.Column4}>4</option>
                <option value={ColumnSize.Column5}>5</option>
                <option value={ColumnSize.Column6}>6</option>
                <option value={ColumnSize.Column7}>7</option>
                <option value={ColumnSize.Column8}>8</option>
                <option value={ColumnSize.Column9}>9</option>
                <option value={ColumnSize.Column10}>10</option>
                <option value={ColumnSize.Column11}>11</option>
                <option value={ColumnSize.Column12}>12</option>
            </select>
        </div>
    )
}