/* eslint-disable jsx-a11y/anchor-is-valid */

import { CollapsiblePanel, Panel } from "../../../widgets"

import { AvatarText } from "../avatar/AvatarText"
import { IChatMessage } from "../../../data"
import clsx from "clsx"
import moment from "moment"
import { useState } from "react"

export const Chat = ({ title, icon, messages, headerClass, collapsible, className, onSendMessage }: {
    className?: string,
    title?: string,
    icon?: string,
    messages: IChatMessage[],
    headerClass?: string,
    collapsible?: boolean,
    onSendMessage: (message: Partial<IChatMessage>) => void,
}) => {
    if (collapsible) {
        return (
            <CollapsiblePanel
                eventKey="chat-component"
                className={className}
                header={title}
                icon={icon}
                headerClass={headerClass}
            >
                <ChatBody messages={messages} onSendMessage={onSendMessage} />
            </CollapsiblePanel>
        )
    }

    return (
        <Panel
            className={className}
            header={title}
            icon={icon}
            headerClass={headerClass}>
            <ChatBody messages={messages} onSendMessage={onSendMessage} />
        </Panel>)
}

const ChatBody = ({ messages, onSendMessage, className }: {
    messages: IChatMessage[],
    className?: string,
    onSendMessage: (message: Partial<IChatMessage>) => void,
}) => {
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>();

    const sendMessage = async () => {
        setLoading(true);
        setErrMsg('');

        try {
            await onSendMessage({
                message,
                messageType: "Text",
            });
            setMessage('');
        } catch (err) {
            setErrMsg(`Could't sent the message`);
        }

        // send message
        setLoading(false);
    }

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            sendMessage()
        }
    }

    return (<>
        <div className={clsx(className)}>
            <div className={clsx('scroll-y me-n5 pe-5 mh-200px')}>
                {messages.slice().reverse()
                    .map((message) => (
                        <ChatMessage
                            message={message}
                            key={message.id} />
                    ))}
            </div>

            <div className='card-footer pt-4 pb-0 border-0 px-0'>
                <div className='d-flex flex-stack'>
                    <textarea
                        className='form-control form-control-flush mb-3 me-3'
                        rows={1}
                        data-kt-element='input'
                        placeholder='Type a comment'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={onEnterPress}
                    ></textarea>
                    <button
                        className='btn btn-primary'
                        type='button'
                        disabled={loading}
                        data-kt-element='send'
                        onClick={sendMessage}
                    >
                        {!loading && <span className='indicator-label'>Send</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>

        {errMsg && <div className="alert alert-warning mt-5">{errMsg}</div>}
    </>)
}

const ChatMessage = ({ message }: { message: IChatMessage }) => {
    const messageType = message.userId !== 'portal-user' ? 'in' : 'out';
    const state = messageType === 'in' ? 'info' : 'primary'
    const contentClass = `d-flex justify-content-${messageType === 'in' ? 'start' : 'end'} mb-2`;

    return (
        <div className={clsx('d-flex', contentClass, 'mb-2')}>
            <div
                className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${messageType === 'in' ? 'start' : 'end'}`
                )}
            >
                <div className='d-flex align-items-center mb-2'>
                    <UserInfo message={message} />
                </div>

                <div
                    className={clsx(
                        'p-2 px-5 rounded',
                        `bg-light-${state}`,
                        'text-gray-700 fw-bold ',
                        `text-${messageType === 'in' ? 'start' : 'end'}`
                    )}
                    dangerouslySetInnerHTML={{ __html: message.message || '' }}
                />
            </div>
        </div>
    )
}

const UserInfo = ({ message }: { message: IChatMessage }) => {
    const messageType = message.userId !== 'portal-user' ? 'in' : 'out';
    switch (messageType) {
        case 'in':
            return (
                <>
                    <AvatarText random={true} label={message.userName} />
                    <div className='ms-2'>
                        <a
                            href='#'
                            className='fs-6 fw-bolder text-gray-700 text-hover-primary me-2'
                        >
                            {message.userId !== 'portal-user' ? message.userName : 'You'}
                        </a>
                        <span className='text-muted fs-7 mb-1'>
                            {moment(message.dateTime).fromNow()}
                        </span>
                    </div>
                </>
            )
        case 'out':
            return (
                <>
                    <div className='me-2'>
                        <span className='text-muted fs-7 mb-2'>
                            {moment(message.dateTime).fromNow()}
                        </span>
                        <a
                            href='#'
                            className='fs-6 fw-bolder text-gray-700 text-hover-primary ms-2'
                        >
                            {message.userId !== 'portal-user' ? message.userName : 'You'}
                        </a>
                    </div>
                    <AvatarText random={true} label={message.userName} />
                </>
            )
    }
}

