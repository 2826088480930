import { ActionCallback, useToolbar } from "../../../layout";
import { useLayoutEffect, useRef } from "react";

import { IPage } from "../models";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useUpdatePageMetadataMutation } from "../../../services/data";

export const useDesignerToolbarActions = ({ page }: { page: IPage }) => {
    const [updatePageMetadata, { isLoading, isError, error }] = useUpdatePageMetadataMutation();
    const { mode, setMode } = useDesignContext();
    const { setButtons, setOnAction } = useToolbar();
    const onActionRef = useRef<ActionCallback>(async () => { })
    const { siteId: portalId } = useAuth()

    const onAction = async (actionId: string) => {
        switch (actionId) {
            case 'design':
                setMode(mode === "Design" ? "Preview" : "Design")
                break;
            case 'save':
                if (!page.portalId && portalId)
                    await updatePageMetadata({ ...page, portalId })
                else
                    await updatePageMetadata(page)
                break;
        }
    }

    useLayoutEffect(() => {
        onActionRef.current = onAction;
        setOnAction(onActionRef)
        // eslint-disable-next-line
    }, [onAction])


    useLayoutEffect(() => {
        setButtons([{
            id: 'design',
            label: mode === "Design" ? 'Preview' : 'Design',
            className: 'btn btn-secondary btn-sm py-2',
            icon: mode === "Design" ? 'PreviewOutlined' : 'ExposureOutlined',
        }, {
            id: 'save',
            label: 'Save',
            className: 'btn btn-primary btn-sm py-2',
            icon: 'SaveOutlined',
        }])
        // eslint-disable-next-line
    }, [isLoading, mode, page])

    return { isLoading, isError, error }
}
