import { configureStore } from '@reduxjs/toolkit'
import { rootApi } from "./rootApi"

export const store = configureStore({
  reducer: {
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: true
    })
      .concat(rootApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>