import { AttributeDataType, IAttributeMetadata, IAttributeOption, IServiceAttribute } from "../../models"

import { DocumentChecklistAttribute } from "./DocumentChecklistAttribute"
import { ReadOnlyLookupAttribute } from "./components/ReadOnlyLookupAttribute"
import clsx from "clsx"

export const ReadOnlyAttribute = ({
    metadata,
    attribute,
    entity,
    entityId,
    checklistId,
    relativePath,
    documentsOnly,
    className,
    value,
    options,
}: {
    metadata?: IAttributeMetadata,
    attribute: IServiceAttribute,
    entity?: string,
    entityId?: string,
    checklistId?: string,
    relativePath?: string
    className?: string,
    value?: string,
    options?: IAttributeOption[],
    documentsOnly: boolean
}) => {
    if (!metadata) return <></>

    const labelClass = clsx("form-label fw-semibold fs-7 mb-1", { required: attribute.required })
    const inputClass = ""
    const readOnlyInputClass = "fw-bold lh-1 mb-2"

    const getReadOnlyControl = () => {
        switch (metadata.datatype) {
            case AttributeDataType.Text:
            case AttributeDataType.EMail:
            case AttributeDataType.MobileNo:
                return <div className={readOnlyInputClass}>{value || '--'}</div>
            case AttributeDataType.MultilineText:
                return <div className={readOnlyInputClass}>{value}</div>
            case AttributeDataType.Date:
                return <div className={readOnlyInputClass}>{value}</div>
            case AttributeDataType.DateTime:
                return <div className={readOnlyInputClass}>{value}</div>
            case AttributeDataType.Number:
            case AttributeDataType.Money:
                return <div className={readOnlyInputClass}>{!value ? '' : value}</div>
            case AttributeDataType.Boolean:
                return <div className={readOnlyInputClass}>{!value ? 'No' : 'Yes'}</div>
            case AttributeDataType.Lookup:
                return <div className={readOnlyInputClass}>
                    <ReadOnlyLookupAttribute
                        attributeId={metadata.id}
                        value={value}
                    />
                </div>
            case AttributeDataType.OptionSet:
                return <div className={readOnlyInputClass}>
                    {options?.find(p => p.value === `${value}` || p.value === value)?.name}
                </div>
            case AttributeDataType.DocumentChecklist:
                return <DocumentChecklistAttribute
                    attributeId={metadata.id}
                    checklistId={checklistId}
                    entity={entity}
                    entityId={entityId}
                    relativePath={relativePath}
                    readOnly={true}
                    className={inputClass} />
            case AttributeDataType.RichText:
                return <div
                    className={readOnlyInputClass}
                    dangerouslySetInnerHTML={{ __html: value || '' }}>
                </div>
            default:
                return <>{metadata.datatype}</>
        }
    }

    const getAttributeLabel = () => {
        switch (metadata.datatype) {
            case AttributeDataType.DocumentChecklist:
                return <></>
            default:
                return <label className={labelClass}>
                    {metadata.label}
                </label>
        }
    }

    const fullWidth = metadata.datatype ===
        AttributeDataType.DocumentChecklist ||
        metadata.datatype === AttributeDataType.RichText ||
        metadata.datatype === AttributeDataType.MultilineText;

    const groupClass = clsx("col",
        { "col-lg-4 col-md-4 col-sm-12": !fullWidth },
        { "col-lg-12": fullWidth })

    if (documentsOnly === true && metadata.datatype !== AttributeDataType.DocumentChecklist)
        return <></>

    return <div className={clsx(className, groupClass)}>
        {getAttributeLabel()}
        {getReadOnlyControl()}
    </div>
}
