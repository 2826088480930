import { AlertMessage, MUIIcon, Spinner } from "../../../../widgets"

import { IPaymentButtonProps } from "../IPaymentButtonProps"
import clsx from "clsx"
import { getButtonClass } from "../../buttons/helper"
import { getPageLinkUrl } from "../../../../base/helpers"
import { submitForm } from "../../../helpers/submit-form"
import { toAbsoluteUrl } from "../../../../../helpers"
import { useAuth } from "../../../../../modules/auth"
import { useGetPayUHashMutation } from "../services"
import { useState } from "react"

export const PayUButton = ({
    orderNo, enrolmentNo, entity, entityId, amount,
    firstName, lastName, email, phoneNumber,
    successPage, failedPage, disabled, tooltip,
    style, size, icon, className, label, children
}: IPaymentButtonProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const { siteId } = useAuth()
    const [getPayUHash] = useGetPayUHashMutation()

    const handleOnClick = () => {
        if (!siteId || !orderNo || !amount || !successPage || !failedPage) return;
        setIsSubmitting(true)
        setError('')

        const successPageUrl = getPageLinkUrl(siteId, successPage);
        const failedPageUrl = getPageLinkUrl(siteId, failedPage);

        getPayUHash({
            orderNo,
            enrolmentNo,
            entity, 
            entityId,
            amount,
            firstName,
            lastName,
            email,
            phoneNumber,
            successPageUrl,
            failedPageUrl
        })
            .unwrap()
            .then(data => {
                submitForm(data.url, data.formData, "post")
            })
            .catch(() => {
                setError('Failed to generate payment request.');
                setIsSubmitting(false)
            })
            .finally()
    }

    return (
        <>
            <button
                disabled={disabled === true || isSubmitting === true || !orderNo || !amount}
                type="button"
                title={tooltip}
                onClick={handleOnClick}
                className={clsx(getButtonClass(style, size, "btn-secondary"), className)}
            >
                <Spinner show={isSubmitting} className="me-1" />
                <img
                    alt='img'
                    className='h-25px'
                    src={toAbsoluteUrl('/media/logos/payu-india.svg')}
                />

                {(!isSubmitting && icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                {label && <span>{label}</span>}
                {children}
            </button>
            <AlertMessage type="Error" show={!!error} message={error} />
        </>
    )

}