export enum ComponentType {
    Paragraph = "Paragraph",
    NormalText = "Normal Text",
    Label = "Label",
    Badge = "Badge",
    Heading1 = "Heading 1",
    Heading2 = "Heading 2",
    Heading3 = "Heading 3",
    Heading4 = "Heading 4",
    Heading5 = "Heading 5",
    Heading6 = "Heading 6",
    Hyperlink = "Hyperlink",
    CardNavigation = "Page Link",
    ListNavigation = "List Navigation",
    Accordion = "Accordion",
    AccordionItem = "Accordion-Item",
    Button = "Button",
    SubmitButton = "SubmitButton",
    ActionButton = "ActionButton",
    Checkbox = "Checkbox",
    FileInput = "FileInput",
    Date = "Date",
    DateTime = "DateTime",
    DropdownButton = "DropdownButton",
    Image = "Image",
    List = "List",
    Options = "Options",
    ProgressBar = "Progress Bar",
    Radio = "Radio",
    Repeater = "Repeater",
    RepeaterColumn = "Repeater Column",
    Section = "Section",
    Slider = "Slider",
    TextBox = "Text Box",
    NumberInput = "NumberInput",
    TextArea = "Text Area",
    Dropdown = "Dropdown",
    Time = "Time",
    Video = "Video",
    Password = "Password",
    Media = "Media",
    Custom = "Custom",
    Lookup = "Lookup",
    GoogleMaps = "Google Maps",
    YouTube = "YouTube",
    Twitter = "Twitter",
    Facebook = "Facebook",
    LInkedIn = "LInkedIn",
    Chat = "Chat",
    AvatarText = "Avatar Text",
    MaterialIcon = "Material Icon",
    DropdownPage = "Dropdown Page",
    Form = "Form",
    ListPart = "List Part",
    CardPart = "Card Part",
    SharePointDocuments = "SharePoint Documents",
    EntityDocumentChecklist = "Document Checklist",
    DocumentChecklist = "DocumentChecklist",
    Panel = "Panel",
    ResetData = "Reset Data",
    Offcanvas = "Offcanvas",
    Services = "Services",
    PieChart = "Pie Chart",
    StackedBars = "Stacked Bars",
    DataLink = "Data Link",
    Grid = "Grid",
    ServiceRequest = "Service Request",
    PayUButton = "PayUPaymentButton",
    PaytmButton = "PaytmButton",
    RazorPayButton = "RazorPayPaymentButton",
    NTTPayButton = "NTTPayButton",
    NameLookup = "NameLookup",
    HTMLViewer = "HTMLViewer",
    EnumLabel = "EnumLabel",
    Timeline = "Timeline",
    HiddenInput = "HiddenInput",
    Base64Image = "Base64Image",
    QRScanner = "QRScanner",
    QRCode = "QRCode",
    FeedbackForm = "FeedbackForm",
} 