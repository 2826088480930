import { IPage, IPageView, PageType } from "../models";
import { createContext, useContext, useState } from "react"

import { PageMode } from "../../../base/types";
import { useAuth } from "../../../../modules/auth";

interface IPageContext {
    pageView?: IPageView,

    page?: IPage,
    setPage: (page: IPage) => void,
    setPageView: (view?: IPageView) => void,
}

const context = createContext<IPageContext>({
    pageView: { pageMode: PageMode.View },
    setPage: () => { },
    setPageView: () => { }
})

export const PageContextProvider = ({ children }: {
    children?: React.ReactNode
}) => {
    const [page, setPage] = useState<IPage>();
    const [pageView, setPageView] = useState<IPageView>();
    const { site } = useAuth()

    const resetPage = (page?: IPage) => {
        setPage(page)
        setPageView(undefined)
        
        if (page?.type === PageType.Card || page?.type === PageType.List)
            document.title = `${site?.title || 'Portal Framework'} | ${page?.title || page?.name}`;
    }

    return (
        <context.Provider value={{
            page,
            setPage: resetPage,
            pageView,
            setPageView
        }}>
            {children}
        </context.Provider>
    )
}

export function usePageContext() {
    return useContext(context)
}