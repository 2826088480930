import clsx from "clsx"

export type HoverMenuItem = "Cut" | "Copy" | "Paste" | "Delete"
export const HoverMenu = ({ onClick }: {
    onClick?: (action: HoverMenuItem) => void
}) => {
    return (
        <div className={
            clsx(
                "control-hover-menu",
                "bg-dark p-2",                
                "fs-8 text-light")}>
            <button type="button" title="Cut" className="btn btn-sm p-0 me-1"
                onClick={(e) => {
                    e.stopPropagation()
                    onClick && onClick("Cut")
                }}>
                <i className="las la-cut text-hover-white fs-3"></i>
            </button>

            <button type="button" title="Copy" className="btn btn-sm p-0 me-1"
                onClick={(e) => {
                    e.stopPropagation()
                    onClick && onClick("Copy")
                }}>
                <i className="las la-copy text-hover-white fs-3"></i>
            </button>

            <button type="button" title="Paste" className="btn btn-sm p-0 me-1"
                onClick={(e) => {
                    e.stopPropagation()
                    onClick && onClick("Paste")
                }}>
                <i className="las la-clipboard text-hover-white fs-3"></i>
            </button>

            <button type="button" title="Delete" className="btn btn-sm p-0"
                onClick={(e) => {
                    e.stopPropagation()
                    onClick && onClick("Delete")
                }}>
                <i className="las la-trash-alt text-hover-white fs-3"></i>
            </button>
        </div>
    )
}