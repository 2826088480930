import { DataContextProvider, PageContextProvider, usePageContext } from "../design/page/context";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { getFilterGroups, getQueryFields } from "../design/helpers";

import { DataContextProducer } from "../design/page/context/DataContextProducer";
import { IPage } from "../design/page/models";
import { WithChildren } from "../../helpers";
import { useAuth } from "../../modules/auth";
import { useLazyGetPageMetadataQuery } from "../services/data";
import { useLazyGetSiteQuery } from "../services/auth";

type ProfilePageModel = {
    page?: IPage,
    isSuccess: boolean,
    isLoading: boolean,
}

const context = createContext<ProfilePageModel>({
    isSuccess: false,
    isLoading: false,
})

const ProfilePageProvider: FC<WithChildren> = ({ children }) => {
    const { user, siteId } = useAuth();
    const [getPageMetadata, { data: page, isSuccess, isLoading }] = useLazyGetPageMetadataQuery();
    const [getSite] = useLazyGetSiteQuery();
    const [pageId, setPageId] = useState<string>();

    useEffect(() => {
        if (!pageId) return;
        getPageMetadata(pageId, true);
        // eslint-disable-next-line
    }, [pageId])

    useEffect(() => {
        if (!user || !siteId) return;

        const { dashboardPageId } = user;

        if (dashboardPageId) {
            setPageId(dashboardPageId)
        } else {
            getSite(siteId)
                .unwrap()
                .then(({ dashboardPageId }) => setPageId(dashboardPageId))
        }

        // eslint-disable-next-line
    }, [siteId, user?.dashboardPageId])

    if (!user || !page) {
        return <>{children}</>
    }

    const fields = getQueryFields(page.controls || [], page.actions || [], page.filters || []);
    const filters = getFilterGroups({ page })

    return <PageContextProvider>
        <SetContextPage page={page} />
        <DataContextProvider>
            <DataContextProducer
                produce="Record"
                entity={page.sourceObject}
                fields={fields}
                filters={filters}
                orderBy={page.orderBy}
                pageSize={page.recordLimit} />
            <context.Provider value={{ page: page, isSuccess, isLoading }}>
                {children}
            </context.Provider>
        </DataContextProvider>
    </PageContextProvider>
}

const SetContextPage = ({ page }: { page: IPage }) => {
    const { setPage } = usePageContext()

    useEffect(() => {
        setPage(page)
        // eslint-disable-next-line
    }, [])

    return <></>
}

export { ProfilePageProvider };

export function useProfilePage() {
    return useContext(context)
}

