import * as uuid from "uuid";

import { IProperty } from "../../../base/models";
import { PropertyType } from "../../../base/types";
import { PropertyTypes } from "../../control-property";
import { useState } from "react";

export const NewComponentProperty = ({ onAdd }: {
    onAdd: (prop: IProperty) => void,
}) => {
    const [property, setProperty] = useState<IProperty>({
        id: uuid.v1(),
        name: '',
        type: PropertyType.String
    });

    const handleOnAddClick = () => {
        onAdd(property);
        setProperty({
            id: uuid.v1(),
            name: '',
            type: PropertyType.String
        });
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProperty({
            ...property,
            name: e.target.value
        })
    }

    const handleOnSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setProperty({
            ...property,
            type: e.target.value as PropertyType
        })
    }

    return (
        <tr>
            <td>
                <input type="text"
                    value={property.name}
                    onChange={handleOnChange}
                    className="form-control form-control-sm" />
            </td>
            <td>
                <select
                    value={property.type}
                    onChange={handleOnSelectChange}
                    className="form-select form-select-sm">
                    {PropertyTypes.map(item => <option key={item.id} value={item.id}>{item.value}</option>)}
                </select>
            </td>
            <td>
                <button
                    type="button"
                    onClick={handleOnAddClick}
                    className="btn btn-sm btn-primary py-2">
                    <i className="las la-plus"></i>
                    Add
                </button>
            </td>
        </tr>)
}