import { AlertMessage, MUIIcon } from "../../../widgets";
import { IControlProps, IPageLink } from "../../../base/models";
import { IEntity, PageMode, Property } from "../../../base/types";
import React, { createContext, useContext, useEffect, useState } from "react"
import { useCreateMutation, useUpdateMutation } from "../../../services/data/tableDataApi";
import { useDataContext, usePageContext } from "../../page/context";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { IFieldValue } from "../../../services/models";
import { IFilterGroup } from "../../../data";
import { SerializedError } from "@reduxjs/toolkit";
import clsx from "clsx";
import { getPageLinkUrl } from "../../../base/helpers";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";
import { useNavigate } from "react-router";

type FormDataModel = {
    systemId?: string,
    form: boolean,
    values: IEntity,
    isSubmitting: boolean,
    isSuccess: boolean,
    isError: boolean,
    error?: FetchBaseQueryError | SerializedError,
    formErrors?: string[],
    setFieldValue: (field: string, value: any) => void,
    setFormErrors: (errors: string[]) => void,
    clearFormErrors: () => void,
    saveFormData: () => Promise<IEntity>
}

const FormContext = createContext<FormDataModel>({
    values: {},
    form: false,
    isSubmitting: false,
    isSuccess: false,
    isError: false,
    setFieldValue: () => { },
    setFormErrors: () => { },
    clearFormErrors: () => { },
    saveFormData: async () => Promise.reject('Not implemented')
})

export const useFromData = () => {
    return useContext(FormContext)
}

export const FormComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { user, siteId: portalId } = useAuth();
    const navigate = useNavigate();

    const { page, pageView } = usePageContext()
    const { setData } = useDataContext()
    const [formErrors, setFormErrors] = useState<string[]>()
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const sysId: string | undefined = properties[Property.SystemId];
    const autoSave: boolean | undefined = properties[Property.AutoSave];
    const pageLink: IPageLink = properties[Property.PageLink] || {};
    const tooltip: string | undefined = properties[Property.Tooltip];
    const [values, setValues] = useState<IEntity>({})

    const [insertRecord, {
        isSuccess: isInsertSuccess,
        isError: isInsertError,
        error: insertError
    }] = useCreateMutation();
    const [modifyRecord, {
        isSuccess: isModifySuccess,
        isError: isModifyError,
        error: modifyError
    }] = useUpdateMutation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const { value: systemIdField } = getBinding(Property.SystemId) || {}


    useEffect(() => {
        if (autoSave !== true)
            return;

        if (Object.keys(values).length > 0)
            saveFormData()

        // eslint-disable-next-line
    }, [values])

    const saveFormData = (): Promise<IEntity> => {
        if (!user || !portalId) {
            return Promise.reject('Could not identify the login context')
        }

        const { userId } = user
        if (!userId) {
            return Promise.reject('Could not identify the login context')
        }

        if (!page) {
            return Promise.reject('Invalid page context')
        }

        const { sourceObject: tableId } = page
        if (!tableId) {
            return Promise.reject(`${page.name} page's source object not configured.`)
        }

        const fields: IFieldValue[] = []
        for (var field in values) {
            fields.push({ field, value: values[field], validate: true })
        }

        const filters: IFilterGroup[] = []
        if (page.filters)
            filters.push({ group: 0, filters: page.filters })

        if (pageView) {
            const { linkFilters, viewFilters } = pageView
            if (linkFilters) filters.push({ group: 2, filters: linkFilters })
            if (viewFilters) filters.push({ group: 3, filters: viewFilters })
        }

        if (pageView?.pageMode === PageMode.New || !sysId) {
            return insertRecord({ portalId, userId, tableId: tableId, filters, data: fields, triggers: true })
                .unwrap()
                .catch(err => {
                    return Promise.reject(err.data?.error?.message || 'Failed to insert record')
                })

        } else {
            return modifyRecord({ portalId, userId, tableId: tableId, sysId, filters, data: fields, triggers: true })
                .unwrap()
                .catch(err => {
                    return Promise.reject(err.data?.error?.message || 'Failed to insert record')
                })
        }
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        e.stopPropagation();

        setIsSubmitting(true)
        saveFormData()
            .then(data => {
                setData({ type: "Card", data, isSuccess: true, isError: false, isLoading: false })
                if (portalId && pageLink && pageLink.pageId) {
                    navigate(getPageLinkUrl(portalId, pageLink))
                }
            })
            .catch(error => setFormErrors([error]))
            .finally(() => setIsSubmitting(false))
    }

    const setFieldValue = (field: string, value: any) => {
        setValues({ ...values, [field]: value })
    }

    const setFormErrorsInner = (errors: string[]) => {
        setFormErrors(errors)
    }

    const clearFormErrors = () => {
        setFormErrors([])
    }

    const getFormChildren = () => {
        if (mode !== "Design") {
            return <>{children}</>
        }

        return (<div className={clsx("card border border-info rounded-0")}>
            <div className="card-header min-h-5px px-0 rounded-0 border-info">
                <div className="d-flex flex-wrap align-items-center gap-2">
                    <div className="badge badge-info m-0 rounded-0 fs-9 px-1">
                        <MUIIcon iconName="DynamicFormOutlined" className="me-1" />
                        <div className="pe-2">Form Component</div>
                    </div>
                    {(!systemIdField) && <>
                        <p className="text-danger fst-italic fw-semibold fs-9 m-0">
                            Form component's System Id property is not updated.
                        </p>
                    </>}
                </div>
            </div>
            <div className="card-body px-0 py-0 my-0">
                {children}
            </div>
        </div>
        )
    }

    return (
        <FormContext.Provider value={{
            form: true,
            values,
            isSubmitting: isSubmitting,
            isSuccess: isInsertSuccess || isModifySuccess,
            isError: isInsertError || isModifyError,
            error: insertError || modifyError,
            formErrors,
            setFormErrors: setFormErrorsInner,
            clearFormErrors,
            setFieldValue,
            saveFormData
        }}>
            <form className={className} onSubmit={onSubmit} title={tooltip}>
                {getFormChildren()}
                <FormErrors errors={formErrors} className="mt-5" />
            </form>
        </FormContext.Provider>)
}

const FormErrors = ({ errors, className }: { errors?: string[], className?: string }) => {
    if (!errors || errors.length === 0)
        return <></>

    return <div className={className}>
        {errors.map((message, i) => <React.Fragment key={i}>
            <AlertMessage type="Error" className="mb-1 fs-8" message={message} />
        </React.Fragment>)}
    </div>
}