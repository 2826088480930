import { MUIIcon } from "../../widgets";
import { MaterialIconNames } from "../components/icons/material-icon-names";
import { Option } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from 'react-bootstrap-typeahead';
import clsx from "clsx";

export const SelectMaterialIcon = ({ title, value, className, onChange }: {
    title?: string,
    value?: string,
    className?: string,
    onChange: (value?: string) => void,
}) => {
    const handleOnChange = (selected: Record<string, any>) => {
        const value = selected[0];
        if (value)
            onChange(value["id"]);
        else
            onChange(undefined);
    }

    const options = MaterialIconNames.map(name => ({ id: name, label: name }))

    const handleMenuItemRender = (option: Option) => {
        const { label }: { label: string } = option as any;
        return <>
            <div>
                <MUIIcon iconName={label} /> <span className="ms-2">{label}</span>
            </div>
        </>
    }

    const getSelected = () => {
        if (!value || !options) return []
        
        const selectedOptions = options.filter(p => p.id === value)
        if (selectedOptions.length === 0) {
            return [{label: value}]
        }

        return selectedOptions
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            {title && <label className="fs-8 fw-bold text-gray-600">{title}</label>}
            <Typeahead
                className="custom-typeahead typeahead-sm"
                options={options}
                id="id"
                labelKey="label"
                clearButton={true}
                renderMenuItemChildren={handleMenuItemRender}
                onChange={handleOnChange}
                selected={getSelected()}
            />
        </div>
    )
}