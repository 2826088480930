import { CustomComponentProperty } from "./CustomComponentProperty";
import { IProperty } from "../../../base/models";
import { NewComponentProperty } from "./NewComponentProperty";

export const CustomComponentProperties = ({ props, onChange }: {
    props: IProperty[]
    onChange: (props: IProperty[]) => void,
}) => {
    const handleOnAdd = (prop: IProperty) => {
        onChange([...props, prop]);
    }

    const handleOnUpdate = (prop: IProperty) => {
        onChange(props.map(p => p.id === prop.id ? prop : p))
    }

    const handleOnDelete = (prop: IProperty) => {
        onChange(props.filter(p => p.id !== prop.id))
    }

    return <div className="card mt-3 bg-transparent">
        <div className="card-header min-h-5px border-0 px-0">
            <h6 className="my-5">Properties</h6>
        </div>
        <div className="card-body pt-0 px-0">
            <table className="table table-sm fs-7">
                <thead className="fw-semibold">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <NewComponentProperty
                        onAdd={handleOnAdd} />

                    {props.map(item => (
                        <CustomComponentProperty
                            key={item.id}
                            property={item}
                            onDelete={handleOnDelete}
                            onUpdate={handleOnUpdate}
                        />))}
                </tbody>
            </table>
        </div>
    </div>
}