import { AlertMessage } from "../../widgets";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../modules/auth"

export const ProfilePage = () => {
    const { user, site } = useAuth()

    if (!user || !user?.contactId || !site?.profilePageId) {
        return <AlertMessage show={true}>Goto site setting to configure the user profile page.</AlertMessage>
    }

    return <>
        <Navigate to={`/site(${site.portalId})/page(${site.profilePageId})?id=${user.contactId}`}></Navigate>
    </>
}
