import { PageTitle } from "../layout/core"
import { Users } from "../modules/auth/user-management/Users"

export const UsersWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Users</PageTitle>
            <Users />
        </>)
}
