import { FontSize } from "../../../../base/styles";
import clsx from "clsx";

export const SelectFontSize = ({ title, value, className, onChange }:{
    title: string,
    value?: FontSize,
    className?: string,
    onChange: (value?: FontSize) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as FontSize;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={FontSize.FS1}>1</option>
                <option value={FontSize.FS2}>2</option>
                <option value={FontSize.FS3}>3</option>
                <option value={FontSize.FS4}>4</option>
                <option value={FontSize.FS5}>5</option>
                <option value={FontSize.FS6}>6</option>
                <option value={FontSize.FS7}>7</option>
                <option value={FontSize.FS8}>8</option>
                <option value={FontSize.FS9}>9</option>
                <option value={FontSize.FS10}>10</option>
                <option value={FontSize.Base}>Base ( 13px )</option>
                <option value={FontSize.Fluid}>Fluid ( 100% )</option>
                <option value={FontSize.FS2x}>Base x 2</option>
                <option value={FontSize.FS2qx}>Base x 2.25</option>
                <option value={FontSize.FS2hx}>Base x 2.5</option>
                <option value={FontSize.FS2tx}>Base x 2.75</option>
                <option value={FontSize.FS3x}>Base x 3</option>
                <option value={FontSize.FS3qx}>Base x 3.25</option>
                <option value={FontSize.FS3hx}>Base x 3.5</option>
                <option value={FontSize.FS3tx}>Base x 3.75</option>
                <option value={FontSize.FS4x}>Base x 4</option>
                <option value={FontSize.FS4qx}>Base x 4.25</option>
                <option value={FontSize.FS4hx}>Base x 4.5</option>
                <option value={FontSize.FS4tx}>Base x 4.75</option>
                <option value={FontSize.FS5x}>Base x 5</option>
                <option value={FontSize.FS5qx}>Base x 5.25</option>
                <option value={FontSize.FS5hx}>Base x 5.5</option>
                <option value={FontSize.FS5tx}>Base x 5.75</option>
            </select>
        </div>
    )
}