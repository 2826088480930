import { Navigate } from 'react-router-dom'
import { ProgressIndicator } from '../../framework/widgets'
import { useAuth } from './core/Auth'
import { useEffect } from 'react'
import { useMsalSignOut } from './msal'

export const Logout = () => {
  const { logout, auth } = useAuth()
  const { loading, signOut } = useMsalSignOut()

  useEffect(() => {
    if (!auth)
      return;

    if (auth.authType !== "MSAL") {
      logout()
      return;
    }

    signOut().catch((err) => console.error(err))

    // eslint-disable-next-line
  }, [auth])

  if (!auth) {
    return <>
      <Navigate to={'/auth/login'} />
    </>
  }

  return <div className='d-flex align-items-center justify-content-center h-100 w-100'>
    <ProgressIndicator show={loading} message='Please wait...' />
  </div>
}
