import { useEffect, useState } from "react";

import { MUIIcon } from "../../../../../widgets";
import Modal from "react-bootstrap/esm/Modal";
import { SPListItems } from "../../../../components/sharepoint-documents/components/SPListItems";
import clsx from "clsx";
import { useAuth } from "../../../../../../modules/auth";
import { useLazyGetSiteQuery } from "../../../../../services/auth";

export const UrlText = ({ value, className, onChange }: {
    value: string,
    className?: string,
    onChange: (value: any) => void,
}) => {
    const [localValue, setLocalValue] = useState<string>(value);
    const [show, showDialog] = useState<boolean>(false)

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    useEffect(() => {
        onChange(localValue)
        // eslint-disable-next-line
    }, [localValue])

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const expression = e.target.value;
        setLocalValue(expression)
    }

    return <div className="input-group input-group-sm">
        <input
            type="url"
            onChange={handleTextAreaChange}
            value={localValue}
            className={clsx(className, "form-control form-control-sm form-control-xs py-2")} />
        <button
            className="input-group-text btn btn-secondary btn-sm px-2 py-2"
            onClick={() => showDialog(true)}>
            <MUIIcon iconName="InsertLinkOutlined" />
        </button>
        <DocumentsDialog show={show}
            hideModal={() => showDialog(false)}
            onSelect={(path) => setLocalValue(path)}
        />
    </div>;
}

const DocumentsDialog = ({ show, hideModal, onSelect }: {
    show: boolean,
    hideModal: () => void,
    onSelect?: (path: string) => void
}) => {
    const { siteId } = useAuth();
    const [getSite, { data: site }] = useLazyGetSiteQuery();

    useEffect(() => {
        if (!siteId) return;
        getSite(siteId)
        // eslint-disable-next-line
    }, [siteId])

    const handleOnSelect = (path: string) => {
        if (!onSelect) return;
        onSelect(path)
        hideModal()
    }

    return <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
        <Modal.Header closeButton className="bg-gray-300 border-0 py-3">
            <h4 className="my-0">Image Gallery</h4>
        </Modal.Header>
        <Modal.Body>
            {site?.imageGallery &&
                <SPListItems
                    relativePath={site.imageGallery}
                    allowSelect={true}
                    onSelect={handleOnSelect}
                    allowDelete={true}
                    allowUpload={true}
                    createFolder={true} />
            }
        </Modal.Body>
    </Modal>
}