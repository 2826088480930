export const getUniquePropertyValues = <T>(collection: T[], propertyName: keyof T): T[keyof T][] => {
    const uniqueValues: T[keyof T][] = [];
    const seenValues: Set<T[keyof T]> = new Set();

    for (const item of collection) {
        const value = item[propertyName];

        if (!seenValues.has(value)) {
            uniqueValues.push(value);
            seenValues.add(value);
        }
    }

    return uniqueValues;
};

export const getMaxPropertyValue = <T>(collection: T[], propertyName: keyof T): T[keyof T] | undefined => {
    if (collection.length === 0) {
        return undefined; // Return undefined if the collection is empty
    }

    return collection.reduce((maxValue, currentItem) => {
        const propertyValue = currentItem[propertyName];

        if (propertyValue > maxValue) {
            return propertyValue;
        } else {
            return maxValue;
        }
    }, collection[0][propertyName]);
}

export const getMinPropertyValue = <T>(collection: T[], propertyName: keyof T): T[keyof T] | undefined => {
    if (collection.length === 0) {
        return undefined;
    }

    return collection.reduce((minValue, currentItem) => {
        const propertyValue = currentItem[propertyName];

        if (propertyValue < minValue) {
            return propertyValue;
        } else {
            return minValue;
        }
    }, collection[0][propertyName]);
}

export const getAveragePropertyValue = <T>(collection: T[], propertyName: keyof T): number | undefined => {
    if (collection.length === 0) {
        return undefined;
    }

    const sum = collection.reduce((accumulator, currentItem) => {
        const propertyValue = currentItem[propertyName];

        if (typeof propertyValue === 'number') {
            return accumulator + propertyValue;
        } else {
            return accumulator;
        }
    }, 0);

    return sum / collection.length;
}

export const getSumOfPropertyValue = <T>(collection: T[], propertyName: keyof T): number | undefined => {
    if (collection.length === 0) {
        return undefined;
    }

    const sum = collection.reduce((accumulator, currentItem) => {
        const propertyValue = currentItem[propertyName];

        if (typeof propertyValue === 'number') {
            return accumulator + propertyValue;
        } else {
            return accumulator;
        }
    }, 0);

    return sum;
}