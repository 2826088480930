import { Binding } from "../../../base/bindings";
import { PropertyType } from "../../../base/types";
import { useCallback } from "react";

export const useBindingValue = (binding: Binding) => {
    const getValue = useCallback(() => {
        switch (binding.propType) {
            case PropertyType.PageLink:
                return binding.value.pageId;
            case PropertyType.Dropdown:
            case PropertyType.String:
            case PropertyType.Entity:
            case PropertyType.DisplayFormat:
            case PropertyType.MaterialIcon:
            case PropertyType.Action:
            case PropertyType.Page:
            case PropertyType.Media:
            case PropertyType.MediaSet:
            case PropertyType.Number:
            case PropertyType.Integer:
            case PropertyType.Date:
            case PropertyType.DateTime:
            case PropertyType.Time:
            case PropertyType.Boolean:
                return binding.value;
            default:
                throw new Error(`Binding not implemented.`);
        }
    }, [binding])

    const setValue = (value: any): Binding => {
        switch (binding.propType) {
            case PropertyType.PageLink:
                return { ...binding, value: { ...binding.value, pageId: value } }
            case PropertyType.Dropdown:
                return { ...binding, value }
            case PropertyType.String:
            case PropertyType.Entity:
            case PropertyType.MaterialIcon:
            case PropertyType.Action:
            case PropertyType.Page:
            case PropertyType.Media:
            case PropertyType.MediaSet:
                return (binding.type === "Const") ?
                    { ...binding, value: String(value) } :
                    { ...binding, value }
            case PropertyType.Number:
            case PropertyType.Integer:
                return (binding.type === "Const") ?
                    { ...binding, value: Number(value) } :
                    { ...binding, value }
            case PropertyType.Date:
            case PropertyType.DateTime:
            case PropertyType.Time:
            case PropertyType.DisplayFormat:
                return { ...binding, value }
            case PropertyType.Boolean:
                return (binding.type === "Const") ?
                    { ...binding, value: Boolean(value) } :
                    { ...binding, value }
            default:
                throw new Error(`Binding not implemented.`);
        }
    }

    return { value: getValue(), setValue }
}
