import { MessagePayload, Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";
import { createContext, useContext, useEffect, useRef, useState } from "react"

import { initializeApp } from "firebase/app";
import { useAddNotificationTokenMutation } from "./services";
import { useAuth } from "../../../modules/auth";
import { useToaster } from "../toast";

const FIREBASE_MESSAGING = process.env.REACT_APP_FIREBASE_MESSAGING;

const firebaseConfig = {
    apiKey: "AIzaSyCP9uhrTJneH_OTqZYjuT_VKkJ_iwerBfA",
    authDomain: "slcm-notifications.firebaseapp.com",
    projectId: "slcm-notifications",
    storageBucket: "slcm-notifications.appspot.com",
    messagingSenderId: "94702624389",
    appId: "1:94702624389:web:b7e89e9f70c16731ef13ec"
};

interface INotificationContext {
    granted: boolean,
    token?: string
}

const context = createContext<INotificationContext>({
    granted: false,
})

export const NotificationsProvider = ({ children }: { children?: React.ReactNode }) => {
    if (!FIREBASE_MESSAGING || FIREBASE_MESSAGING !== "true")
        return <>{children}</>

    return <NotificationsProviderInner>{children}</NotificationsProviderInner>
}

const NotificationsProviderInner = ({ children }: { children?: React.ReactNode }) => {
    const [granted, setGranted] = useState<boolean>(false)
    const [token, setToken] = useState<string>()
    const [messaging, setMessaging] = useState<Messaging>()
    const { showToaster } = useToaster()
    const didRequest = useRef(false)
    const { user } = useAuth()
    const [addNotificationToken] = useAddNotificationTokenMutation()

    useEffect(() => {
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        setMessaging(messaging);
    }, [])

    const getFirebaseToken = () => {
        if(!messaging) return;

        Notification.requestPermission()
            .then((permission) => {
                if (permission === "granted") {
                    setGranted(true)
                    getToken(messaging)
                        .then((token) => {
                            addNotificationToken({ userId: user?.userId!, token: token })
                            setToken(token)
                            onMessage(messaging, onNotification)
                        })
                }
            })
            .catch(err => {
                console.log('err')
                console.log(err)
            })
    }

    useEffect(() => {
        if (!user) return;

        if (!didRequest.current) {
            getFirebaseToken()
        }

        return () => { didRequest.current = true }
        // eslint-disable-next-line
    }, [user])

    const onNotification = ({ notification }: MessagePayload) => {
        if (!notification)
            return;

        const { body, title } = notification;
        showToaster({ header: title, message: body, delay: 5000 })
    }

    return (
        <context.Provider value={{
            granted,
            token
        }}>
            {children}
        </context.Provider>
    )
}

export function useNotifications() {
    return useContext(context)
}
