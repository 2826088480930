import * as Yup from 'yup'

import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

import { Spinner } from '../../../framework/widgets'
import clsx from 'clsx'
import { useAuth } from '../core/Auth'
import { useChangePasswordMutation } from '../../../framework/services/auth'
import { useFormik } from 'formik'

const initialValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    newPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('New password is required'),
    confirmPassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('newPassword')], "Password and Confirm Password didn't match"),
        }),
})

export function ChangePassword() {
    const [loading, setLoading] = useState(false)
    const [changePassword] = useChangePasswordMutation()
    const { siteId, user } = useAuth()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            if (!user) return;

            const { email } = user
            const { password, newPassword } = values

            setStatus('')
            setLoading(true)
            changePassword({ email, password, newPassword })
                .unwrap()
                .then((auth) => {                    
                    navigate(`/site(${siteId})/dashboard`)
                })
                .catch((err) => {
                    setStatus('Could not change your password, please try again.')
                })
                .finally(() => {
                    setLoading(false)
                    setSubmitting(false)
                })
        },
    })

    return (
        <>
            <form
                className='form w-350px mx-auto fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    <h1 className='text-dark mb-3'>Change Password</h1>
                </div>

                {formik.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}


                <div className='mb-5 d-flex flex-wrap'>
                    <div className='px-5 flex-fill'>
                        <div className='mb-1'>
                            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                            <div className='position-relative mb-3'>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control form-control-sm',
                                        { 'is-invalid': formik.touched.password && formik.errors.password, },
                                        { 'is-valid': formik.touched.password && !formik.errors.password, }
                                    )}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='mb-1'>
                            <label className='form-label fw-bolder text-dark fs-6'>New Password</label>
                            <div className='position-relative mb-3'>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('newPassword')}
                                    className={clsx(
                                        'form-control form-control-sm',
                                        { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword, },
                                        { 'is-valid': formik.touched.newPassword && !formik.errors.newPassword, }
                                    )}
                                />
                                {formik.touched.newPassword && formik.errors.newPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.newPassword}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='text-muted'>
                            Use 8 or more characters with a mix of letters, numbers & symbols.
                        </div>
                    </div>
                    <div className='px-5 flex-fill'>
                        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                        <input
                            type='password'
                            placeholder='Password confirmation'
                            autoComplete='off'
                            {...formik.getFieldProps('confirmPassword')}
                            className={clsx(
                                'form-control form-control-sm',
                                { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword, },
                                { 'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword, }
                            )}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.confirmPassword}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                        type='submit'
                        disabled={loading}
                        id='kt_password_reset_submit'
                        className='btn btn-lg btn-primary fw-bolder me-4'
                    >
                        {loading && <Spinner className='me-2' />}
                        <span className='indicator-label'>Submit</span>
                    </button>

                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-light-primary fw-bolder'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancel
                        </button>
                    </Link>{' '}
                </div>
            </form>
        </>
    )
}
