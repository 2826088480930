export const getQueryExprParameters = (query: string) => {
    const nodes: { text: string, type: 'Open' | 'Close' | 'Text' }[] = []
    let i = 0;
    while (i < query.length) {
        const ch = query[i];
        i++;

        if (ch === '{') {
            let braces = ch

            while (i < query.length) {
                const ch2 = query[i];
                if (ch2 !== '{')
                    break;

                i++;
                braces += ch2;
            }

            nodes.push({ text: braces, type: 'Open' })
        } else if (ch === '}') {
            let braces = ch

            while (i < query.length) {
                const ch2 = query[i];
                if (ch2 !== '}')
                    break;

                i++;
                braces += ch2;
            }

            nodes.push({ text: braces, type: 'Close' })
        } else {
            let text = ch;
            while (i < query.length) {
                const ch2 = query[i];
                if (ch2 === '}' || ch2 === '{')
                    break;
                i++;
                text += ch2;
            }

            nodes.push({ text, type: 'Text' })
        }
    }

    let isToken = false;
    let token = ''
    const tokens = []
    for (var j = 0; j < nodes.length; j++) {
        const node = nodes[j]
        switch (node.type) {
            case 'Open':
                if (isToken) token = '';
                if (node.text.length % 2 !== 0) {
                    isToken = true;
                }
                break;
            case 'Close':
                if (isToken && node.text.length % 2 !== 0) {
                    isToken = false;
                    if (token) tokens.push(token)
                    token = ''
                }
                break;
            case 'Text':
                if (isToken) {
                    token += node.text;
                }
                break;
        }
    }

    return tokens;
}