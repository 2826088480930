import { MUIIcon, Spinner } from "../../../widgets"
import { useSendMobileOTPMutation, useVerifyOTPMutation } from "./services"

import { FieldError } from "../services/components/service-attribute/components/FieldError"
import { IEntity } from "../../../base/types"
import { useFormikContext } from "formik"
import { useState } from "react"

export const OtpVerifiedMobile = ({ fieldId, verifiedFieldId }: {
    fieldId: string,
    verifiedFieldId: string,
}) => {
    const [otp, setOTP] = useState<string>('')
    const [requestedMobile, setRequestedMobile] = useState<string>('')
    const [requested, setRequested] = useState<boolean>(false)
    const { values, errors, setFieldValue, setFieldTouched, setFieldError } = useFormikContext<IEntity>()

    const [sendOTP, { isLoading: sending }] = useSendMobileOTPMutation()
    const [verifyOTP, { isLoading: verifying }] = useVerifyOTPMutation()

    const handleBlur = () => {
        setFieldTouched(fieldId, true)
        handleSendOTP();
    }

    const handleMobileChange = (mobile: string) => {
        setFieldValue(verifiedFieldId, false)
        setRequested(false);
        setOTP('')

        setFieldValue(fieldId, mobile)
    }

    const handleSendOTP = () => {
        const mobile = values[fieldId]
        if (!mobile) return;

        setFieldError(verifiedFieldId, undefined)
        sendOTP({ identifier: mobile })
            .unwrap()
            .then(() => {
                setRequestedMobile(mobile)
                setFieldValue(verifiedFieldId, false)
                setRequested(true)
            })
    }

    const handleVerifyOTP = () => {
        const mobile = values[fieldId]
        if (!mobile || !requested || !otp || requestedMobile !== mobile)
            return;

        setFieldError(verifiedFieldId, undefined)

        verifyOTP({ identifier: mobile, otp })
            .unwrap()
            .then((result) => {
                setFieldValue(verifiedFieldId, result.verified)
                if (!result.verified) {
                    setFieldError(verifiedFieldId, 'OTP entered is not correct.')
                }
            })
    }

    const { [fieldId]: mobile, [verifiedFieldId]: verified } = values;

    return <>
        <div className="d-flex flex-row flex-wrap gap-2">
            <div className="flex-fill d-flex align-items-center gap-2">
                <input
                    className="form-control form-control-sm"
                    type="mobile"
                    value={mobile || ''}
                    readOnly={sending}
                    onBlur={handleBlur}
                    onChange={(e) => handleMobileChange(e.target.value)}
                />
                {(verified) && <>
                    <MUIIcon iconName="VerifiedOutlined" className=" text-primary" />
                </>}
            </div>
            {(!verified) && <>
                <div className="d-flex flex-row mw-300px align-items-center gap-2">
                    <div className="flex-fill">
                        <input
                            className="form-control form-control-sm fw-semibold"
                            disabled={!mobile}
                            type="text"
                            placeholder="Enter OTP"
                            value={otp || ''}
                            onBlur={handleVerifyOTP}
                            onChange={(e) => setOTP(e.target.value)}
                        />
                    </div>
                    <div className="d-flex flex-row gap-2">
                        <button
                            type="button"
                            disabled={!mobile || sending || !!otp}
                            onClick={() => handleSendOTP()}
                            className="btn btn-sm btn-primary py-1 fw-semibold">
                            <div className="d-flex align-items-center gap-2">
                                <label>Resend</label>
                                {(!sending) ? <>
                                    <MUIIcon iconName="SendOutlined" className="fs-7" />
                                </> : <>
                                    <Spinner show={true} />
                                </>}
                            </div>
                        </button>
                        <button
                            type="button"
                            disabled={!mobile || !requested || !otp}
                            onClick={() => handleVerifyOTP()}
                            className="btn btn-sm btn-primary py-1 fw-semibold">
                            <div className="d-flex align-items-center gap-2">
                                {(!verifying) ? <>
                                    <MUIIcon iconName="CheckOutlined" className="fs-7" />
                                </> : <>
                                    <Spinner show={true} />
                                </>}
                                <label>Verify</label>
                            </div>
                        </button>
                    </div>
                </div>
            </>}
        </div>
        <FieldError show={!!errors[verifiedFieldId]} message={errors[verifiedFieldId] as string} />
    </>
}