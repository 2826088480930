import { useEffect, useState } from 'react';

import { AsideMenuActions } from '../../../framework/layout';
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { useAuth } from '../../../modules/auth';
import { useIntl } from 'react-intl'

export function AsideMenuMain() {
  const intl = useIntl()
  const { user, siteId } = useAuth()
  const [allowDesign, setAllowDesign] = useState<boolean>(false)

  useEffect(() => {
    if (!user) return
    const allow = user.roles.indexOf('SUPER') !== -1
    setAllowDesign(allow)
  }, [user])

  return (
    <>
      <AsideMenuItem
        to={`/site(${siteId})/dashboard`}
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        materialIcon='DashboardOutlined'
      />
      <AsideMenuActions />

      {(allowDesign === true) && <>
        <div className='flex-fill d-flex align-items-end '>
          <div className='flex-fill bg-light py-5'>
            <AsideMenuItemWithSub
              to=''
              title="Site Content"
              fontIcon='bi-chat-left'
              materialIcon="DashboardCustomizeOutlined"
            >
              <AsideMenuItem
                to={`/site(${siteId})/site-pages`}
                title="Site Pages"
                materialIcon="PagesOutlined"
              />
              <AsideMenuItem
                to={`/site(${siteId})/components`}
                title="Components"
                materialIcon="SettingsInputComponentOutlined"
              />
            </AsideMenuItemWithSub>
            <AsideMenuItem
              to={`/users`}
              title="Users"
              materialIcon="PeopleAltOutlined"
            />
          </div>
        </div>
      </>}
    </>
  )
}

