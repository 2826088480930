import React from "react";
import clsx from "clsx";
import { useGetSitesQuery } from "../../services/auth";

export const SelectSite = ({ value, disabled, props, className, onChange }: {
    value?: string,
    disabled?: boolean
    className?: string,
    props?: React.SelectHTMLAttributes<HTMLSelectElement>,
    onChange: (value?: string) => void,
}) => {
    const { data: sites, isError, isLoading, isFetching } = useGetSitesQuery();

    if (isLoading || isFetching) {
        return (
            <div>
                <span className='indicator-progress' style={{ display: "block" }}>
                    <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
            </div>)
    }

    if (isError) {
        return <div className="alert alert-danger fs-7">Failed to load sites list</div>
    }

    return (
        <select
            disabled={disabled}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={clsx(className, "form-select")}
            {...props}>
            <option></option>
            {sites?.map(item => <React.Fragment key={item.portalId}>
                <option value={item.portalId}>{item.title}</option>
            </React.Fragment>)}
        </select>
    )
}