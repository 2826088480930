import { IProperty } from "../../../base/models"
import { PropertyType } from "../../../base/types"
import { PropertyTypes } from "../../control-property"
import React from "react"

export const CustomComponentProperty = ({ property, onUpdate, onDelete }: {
    property: IProperty,
    onUpdate: (prop: IProperty) => void,
    onDelete: (prop: IProperty) => void,
}) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onUpdate({
            ...property,
            name: e.target.value
        })
    }

    const handleOnSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onUpdate({
            ...property,
            type: e.target.value as PropertyType
        })
    }

    return (
        <tr>
            <td>
                <input type="text"
                    value={property.name}
                    onChange={handleOnChange}
                    className="form-control form-control-sm" />
            </td>
            <td>
                <select
                    value={property.type}
                    onChange={handleOnSelectChange}
                    className="form-select form-select-sm">
                    {PropertyTypes.map(item => <option key={item.id} value={item.id}>{item.value}</option>)}
                </select>
            </td>
            <td>
                <button
                    type="button"
                    onClick={() => onDelete(property)}
                    className="btn btn-sm btn-danger py-2">
                    <i className="las la-trash"></i>
                    Delete
                </button>
            </td>
        </tr>)
}
