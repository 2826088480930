import { ApiTags, rootApi } from '../../../../services/rootApi';

import { IServiceRequest } from '../models';

const TAG = ApiTags.ServiceRequest;

const serviceRequestsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getServiceRequest: builder.query<IServiceRequest, string>({
      query: (requestId) => `/ServiceRequests/${requestId}`,
      providesTags: (result) => result ? [{ type: TAG, id: result.id }] : [TAG]
    }),

    createServiceRequest: builder.mutation<IServiceRequest, Partial<IServiceRequest>>({
      query: (request) => {
        return {
          url: `/ServiceRequests`,
          method: 'POST',
          body: request
        }
      },

      invalidatesTags: (result, err, args) => {
        if (err) return [];
        return [{ type: TAG, id: args.id }]
      }
    }),

    updateServiceRequest: builder.mutation<IServiceRequest, Partial<IServiceRequest>>({
      query: (request) => {
        return {
          url: `/ServiceRequests/${request.id}`,
          method: 'PUT',
          body: request
        }
      },

      invalidatesTags: (result, err, args) => {
        if (err) return [];
        return [{ type: TAG, id: args.id }]
      }
    })
  })
})

export const {
  useGetServiceRequestQuery,
  useLazyGetServiceRequestQuery,
  useCreateServiceRequestMutation,
  useUpdateServiceRequestMutation,
} = serviceRequestsApi;