import { ApiStatus } from "../services/models"

export const Loading = ({ status }:{
    status: ApiStatus
}) => {
    switch (status) {
        case "Idle":
        case "Pending":
            return (
                <div className="d-flex align-items-center justify-content-center m-10">
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        <span>Please wait...</span>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                </div>
            )
        case "Rejected":
            return (
                <div className="d-flex align-items-center justify-content-center m-10">
                    <div className="alert alert-danger">
                        Failed to load data ...
                    </div>
                </div>)
        default:
            return <>{status}</>
    }
}