import { ApiTags, rootApi } from '../../../../services/rootApi';

import { IServiceStage } from '../models';

const TAG = ApiTags.ServiceStage;
const getTags = (entities: IServiceStage[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const serviceStagesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getStages: builder.query<IServiceStage[], void>({
      query: () => `/ServiceStages`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
    getServiceStages: builder.query<IServiceStage[], string>({
      query: (serviceId) => `/ServiceStages/service(${serviceId})`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
  })
})

export const {
  useGetStagesQuery,
  useLazyGetStagesQuery,
  useGetServiceStagesQuery,
  useLazyGetServiceStagesQuery,
} = serviceStagesApi;