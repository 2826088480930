import { ApiTags, rootApi } from '../../../../services/rootApi';
import { INTTTokenRequest, INTTTokenResponse, IPayUHashRequest, IPayUHashResponse, IPaytmAuthRequest, IPaytmResponse, IRazorPayHashRequest, IRazorPayHashResponse } from '../models';

const TAG = ApiTags.Payment;

const paymentsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getPayUHash: builder.mutation<IPayUHashResponse, IPayUHashRequest>({
      query: (model) => ({
        url: '/Payments/PayU',
        method: 'POST',
        body: model
      }),
      invalidatesTags: [TAG]
    }),

    getPaytmToken: builder.mutation<IPaytmResponse, IPaytmAuthRequest>({
      query: (model) => ({
        url: '/Payments/Paytm',
        method: 'POST',
        body: model
      }),
      invalidatesTags: [TAG]
    }),

    getRazorPayHash: builder.mutation<IRazorPayHashResponse, IRazorPayHashRequest>({
      query: (model) => ({
        url: '/Payments/RazorPay',
        method: 'POST',
        body: model
      }),
      invalidatesTags: [TAG]
    }),
    getNTTToken: builder.mutation<INTTTokenResponse, INTTTokenRequest>({
      query: (model) => ({
        url: '/Payments/NTT',
        method: 'POST',
        body: model
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetPayUHashMutation,
  useGetPaytmTokenMutation,
  useGetRazorPayHashMutation,
  useGetNTTTokenMutation
} = paymentsApi;