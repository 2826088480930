import { AlertMessage, Spinner, When } from "../../../../widgets"
import { IDocumentChecklistItem, IEntityChecklistItem_BC, IEntityDocumentChecklist } from "../models"
import React, { useCallback } from "react"
import { useCreateEntityDocumentChecklistItem, useGetDocumentChecklistItems, useGetEntityDocumentChecklistItems } from "../hooks"

import { ChecklistItem } from "./ChecklistItem"
import { EntityChecklistItem } from "./EntityChecklistItem"
import clsx from "clsx"
import { useUploadDocument } from "../../../../services/data/sharepoint"

export const ChecklistItems = ({ entityDocumentChecklist, relativePath, className }: {
    entityDocumentChecklist: IEntityDocumentChecklist,
    relativePath: string,
    className?: string,
}) => {
    const { data, isLoading, isSuccess, isError, error } = useGetDocumentChecklistItems(entityDocumentChecklist.documentChecklistId)
    const {
        data: entityItems,
        isLoading: isEntityItemsLoading,
        isSuccess: isEntityItemsSuccess,
        isError: isEntityItemsError,
        error: entityItemsError,
    } = useGetEntityDocumentChecklistItems(entityDocumentChecklist.id)

    const { uploadDocument } = useUploadDocument()
    const { createRecord } = useCreateEntityDocumentChecklistItem()

    const uploadChecklistDocument = (documentChecklistItem: IDocumentChecklistItem, documentTypeId: string, file: File): Promise<void> => {
        return uploadDocument({ file, folderRelativeUrl: relativePath })
            .then((document) => {
                return createRecord({
                    entityDocumentChecklistId: entityDocumentChecklist.id,
                    documentChecklistItemId: documentChecklistItem.id,
                    documentTypeId,
                    url: document.serverRelativeUrl
                })

                    .then(() => Promise.resolve())
                    .catch(() => Promise.reject('Could not upload the document.'))
            })
            .catch(error => {
                console.error(error)
                return Promise.reject(error)
            })
    }

    const getEntityChecklistItem = useCallback((itemId: string) => {
        if (!entityItems || entityItems.length === 0)
            return;

        const item = entityItems.find(p => p.documentChecklistItemId === itemId)
        return item;
    }, [entityItems])

    return (
        <div className={clsx(className)}>
            <AlertMessage show={isError} type="Error" message={error} />
            <AlertMessage show={isEntityItemsError} type="Error" message={entityItemsError} />
            <Spinner show={isLoading || isEntityItemsLoading} />
            <When condition={isSuccess && isEntityItemsSuccess}>
                {(data && entityItems) && <div className="d-flex">
                    {data.map(item => <React.Fragment key={item.id}>
                        <ChecklistItemSwitch
                            checklistItem={item}
                            entityCheckListItem={getEntityChecklistItem(item.id)}
                            onUpload={(typeId, file) => uploadChecklistDocument(item, typeId, file)} />
                    </React.Fragment>)}
                </div>}
            </When>
        </div>
    )
}

const ChecklistItemSwitch = ({ checklistItem, entityCheckListItem, onUpload }: {
    checklistItem: IDocumentChecklistItem,
    entityCheckListItem?: IEntityChecklistItem_BC,
    onUpload?: (documentTypeId: string, file: File) => Promise<void>
}) => {
    if (entityCheckListItem) {
        return <EntityChecklistItem
            className="me-3 mb-3"
            documentCategoryId={checklistItem.documentCategoryId}
            checklistItem={entityCheckListItem} />
    }

    return <ChecklistItem
        className="me-3 mb-3"
        checklistItem={checklistItem}
        onUpload={onUpload} />
}
