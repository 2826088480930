import { useEffect, useState } from 'react';

import { IMultipleChoiceQuestion } from '../models/feedback-form.model';
import clsx from 'clsx';

const MultipleChoiceQuestion = ({ question, value, onChange, onBlur }: {
    question: IMultipleChoiceQuestion;
    value?: string,
    onChange: (value: string) => void,
    onBlur: () => void
}) => {
    const [options, setOptions] = useState(value?.split(",") || [])

    useEffect(() => {
        onChange(options.join(","))
        // eslint-disable-next-line
    }, [options])

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const option = e.target.value.toLowerCase();

        var item = options.find(p => p.toLowerCase() === option)
        if (checked && !item)
            setOptions([...options, e.target.value])
        else if (!checked && item)
            setOptions(options.filter(p => p.toLowerCase() !== option))
    }

    const isChecked = (choice: string): boolean => {
        return options.find(p => p.toLowerCase() === choice.toLowerCase()) !== undefined;
    }

    return (
        <div className="form-group">
            <label className={clsx("form-label", { required: question.isRequired })}>{question.title}</label>
            <div className="form-check">
                {question.choices.map((choice, index) => (
                    <div key={index} className='my-2'>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={question.id}
                            id={`${question.id}-${index}`}
                            value={choice}
                            onChange={handleOnChange}
                            onBlur={() => onBlur()}
                            checked={isChecked(choice)}
                        />
                        <label className="form-check-label" htmlFor={`${question.id}-${index}`}>{choice}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MultipleChoiceQuestion;
