import { IPage, IPageView } from "../models";

import { PageToolbar } from "./components";
import { PageViewer } from "./PageViewer";
import clsx from "clsx";

export const PageWithToolbar = ({ page, pageView, className }: {
    page: IPage,
    pageView: IPageView,
    className?: string,
}) => {
    return (
        <div className={clsx(className, "position-relative")}>
            <PageViewer page={page} pageView={pageView}>
                <PageToolbar page={page} />
            </PageViewer>
        </div>
    )
}
