import { AlertMessage, MUIIcon, Spinner } from "../../../widgets"
import { ButtonStyle, ControlSize, Property } from "../../../base/types"
import { IControlProps, IPageLink } from "../../../base/models"
import { useEffect, useState } from "react"

import { DefaultLabel } from "../../widgets"
import { Link } from "react-router-dom"
import Modal from "react-bootstrap/esm/Modal"
import { PageWithToolbar } from "../../page"
import clsx from "clsx"
import { getButtonClass } from "../buttons/helper"
import { getPageLinkUrl } from "../../../base/helpers"
import { useAuth } from "../../../../modules/auth"
import { useDesignContext } from "../../../providers"
import { useGetPageMetadataQuery } from "../../../services/data/pagesMetadataApi"
import { useGetProperties } from "../../../base/hooks"

export const ListNavigationComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { siteId } = useAuth()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const pageLink: IPageLink | undefined = properties[Property.PageLink]
    const modelDialog: boolean = properties[Property.ModelDialog] || false;
    const icon: string | undefined = properties[Property.MaterialIcon]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]


    const [showDialog, setShowDialog] = useState<boolean>(false)

    const openPageDialog = () => {
        if (!pageLink || !pageLink.pageId) return;
        setShowDialog(true)
    }

    if (mode === "Design") {
        return (
            <div className={clsx(getButtonClass(style, size, "btn btn-link btn-sm"), className)} title={tooltip}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                <DefaultLabel
                    control={control}
                    propertyId={Property.Label}
                    defaultLabel="Navigate to List"
                    className="text-hover-primary" />
                {children}
            </div>)
    }

    if (modelDialog) {
        return <>
            <button
                type="button"
                title={tooltip}
                className={clsx(getButtonClass(style, size, "btn btn-link btn-sm"), className)}
                disabled={!pageLink}
                onClick={openPageDialog}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                {label && <span>{label}</span>}
                {children}
            </button>

            {pageLink?.pageId && <ListPageDialog
                pageLink={pageLink}
                show={showDialog}
                hideModal={() => setShowDialog(false)} />}
        </>
    }

    return (
        <>
            <Link
                className={clsx(getButtonClass(style, size, "btn btn-link btn-sm text-primary py-0"), className)}
                to={(siteId && pageLink) ? getPageLinkUrl(siteId, pageLink) : ''}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                {label && <span>{label}</span>}
                {children}
            </Link>
        </>)
}

export const ListPageDialog = ({ pageLink, show, hideModal }: {
    pageLink: IPageLink,
    show: boolean,
    hideModal: () => void
}) => {
    const { data: page, isLoading, isSuccess, isError, error } = useGetPageMetadataQuery(pageLink.pageId!);

    useEffect(() => {
        console.log("ListPageDialog pageLink", pageLink)
    }, [pageLink])

    return <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
        <Modal.Header closeButton className="bg-gray-300 border-0 py-3">
            <h4 className="my-0">{page?.title || page?.name}</h4>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex justify-content-center">
                <Spinner show={isLoading} className="mb-2" message="Please wait ..." />
            </div>
            <AlertMessage
                show={isError}
                type="Error"
                message={(error as any)?.data?.error?.message || `Failed to load the page id: ${pageLink.pageId}`} />
            {isSuccess && <PageWithToolbar page={page} pageView={{
                systemId: pageLink.systemId,
                pageMode: pageLink.pageMode,
                linkFilters: pageLink.filters
            }} />}
        </Modal.Body>
    </Modal>
}

