import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const SPDocumentsProperties: IProperty[] = [
    { id: Property.Title, name: 'Title', type: PropertyType.String },
    { id: Property.RelativePath, name: 'Relative Path', type: PropertyType.String },
    { id: Property.EnsurePath, name: 'Create Folder', type: PropertyType.Boolean },
    { id: Property.Collapsible, name: 'Collapsable', type: PropertyType.Boolean },
    { id: Property.HeaderClass, name: 'Header Class', type: PropertyType.String },
    { id: Property.InsertAllowed, name: 'Allow Upload', type: PropertyType.Boolean },
    { id: Property.DeleteAllowed, name: 'Allow Delete', type: PropertyType.Boolean },
]