import { useDeleteSPFileMutation } from "../sharePointFilesApi"
import { useState } from "react"

export const useDeleteDocument = () => {
    const [deleteFile, {isLoading, isSuccess, isError}] = useDeleteSPFileMutation()
    const [error, setError] = useState()

    const deleteDocument = (relativeUrl: string) => {
        deleteFile(relativeUrl)
            .unwrap()
            .catch((error) => {
                setError(error)
            })
    }

    return { deleteDocument, isLoading, isSuccess, isError, error }
}