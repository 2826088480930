import { useDataContext, usePageContext } from "../context";
import { useLazyReadMultipleQuery, useLazyReadQuery } from "../../../services/data/tableDataApi";

import { IFilterGroup } from "../../../data";
import { getQueryFields } from "../../helpers";
import { useAuth } from "../../../../modules/auth";

export const useResetData = () => {
    const { page, pageView } = usePageContext()
    const { user, siteId } = useAuth()
    const [readRecord] = useLazyReadQuery();
    const [readRecords] = useLazyReadMultipleQuery();
    const { setData } = useDataContext()

    const reset = () => {
        if (!page) return;
        if (!user || !siteId) return;

        const { userId } = user;
        const { sourceObject: entity } = page;

        if (!entity) return;

        setData({ type: "Static", isLoading: true, isSuccess: false, isError: false })
        const fieldList = getQueryFields(page.controls || [], page.actions || [], page.filters || []);
        if (pageView?.systemId) {
            readRecord({ userId, portalId: siteId, tableId: entity, sysId: pageView?.systemId, fieldList })
                .unwrap()
                .then(data => {
                    setData({ type: "Card", data, isLoading: false, isSuccess: true, isError: false })
                })
                .catch(err => {
                    const error = err.data?.error?.message || 'Failed to get data'
                    setData({ type: "Static", isLoading: false, isSuccess: false, isError: true, error })
                })
        } else {
            const filters: IFilterGroup[] = [];
            if (page.filters)
                filters.push({ group: 0, filters: page.filters });
            if (pageView?.linkFilters)
                filters.push({ group: 2, filters: pageView?.linkFilters });
            if (pageView?.viewFilters)
                filters.push({ group: 3, filters: pageView?.viewFilters });

            readRecords({ userId, portalId: siteId, tableId: entity, fieldList, recordLimit: 1, filters })
                .unwrap()
                .then(data => {
                    const records = data?.records || [];
                    const record = Array.isArray(records) ? (records[0] || undefined) : records;
                    setData({ type: "Card", data: record, isLoading: false, isSuccess: true, isError: false })
                })
                .catch(err => {
                    const error = err.data?.error?.message || 'Failed to get data'
                    setData({ type: "Static", isLoading: false, isSuccess: false, isError: true, error })
                })
        }
    }

    return { reset }
}