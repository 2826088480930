import { PropertyType } from "../../../base/types";

export const PropertyTypes: { id: PropertyType, value: string }[] = [
    { id: PropertyType.Boolean, value: "Boolean" },
    { id: PropertyType.Date, value: "Date" },
    { id: PropertyType.DateTime, value: "DateTime" },
    { id: PropertyType.Integer, value: "Integer" },
    { id: PropertyType.Number, value: "Number" },
    { id: PropertyType.String, value: "String" },
    { id: PropertyType.Time, value: "Time" },

    { id: PropertyType.Page, value: "Page" },
    { id: PropertyType.PageLink, value: "PageLink" },
    { id: PropertyType.Media, value: "Media" },
    { id: PropertyType.MediaSet, value: "MediaSet" },
    { id: PropertyType.Entity, value: "Entity" },
    { id: PropertyType.MaterialIcon, value: "Material Icon" },
    { id: PropertyType.Dropdown, value: "Dropdown Items" },
]
