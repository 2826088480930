import { IAuthTokenModel } from "./AuthModel"

const AUTH_KEY = 'AUTH-TOKEN'
const SITE_KEY = 'ACTIVE-SITE-ID'

const getAuth = (): IAuthTokenModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: IAuthTokenModel = JSON.parse(lsValue) as IAuthTokenModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: IAuthTokenModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getSite = (userId: string): string | undefined => {
  if (!localStorage) {
    return
  }

  const value: string | null = localStorage.getItem(`${userId}::${SITE_KEY}`)

  try {
    if (value) {
      return value
    }
  } catch (error) {
    console.error('LOCAL STORAGE PARSE ERROR', error)
  }
}

const setSite = (userId: string, siteId: string) => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.setItem(`${userId}::${SITE_KEY}`, siteId)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeSite = (userId: string) => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(`${userId}::${SITE_KEY}`)
  } catch (error) {
    console.error('LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export { getAuth, setAuth, removeAuth, getSite, setSite, removeSite as removePortal, AUTH_KEY as AUTH_LOCAL_STORAGE_KEY }
