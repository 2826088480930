import { FilterFunction } from '../../base/types';
import { Option } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from 'react-bootstrap-typeahead';
import clsx from "clsx";

interface IFuncInfo {
    func: FilterFunction,
    name: string,
    description: string
}

const functions: IFuncInfo[] = [
    { func: FilterFunction.Last7Days, name: "Last 7 Days", description: "Function to evaluate whether the value is within the last seven days including today." },
    { func: FilterFunction.LastMonth, name: "Last Month", description: "Function to evaluate whether the value is within the last fiscal year." },
    { func: FilterFunction.LastWeek, name: "Last Week", description: "Function to evaluate whether the value is within the last week." },
    { func: FilterFunction.LastYear, name: "Last Year", description: "Function to evaluate whether the value is within the last year." },
    { func: FilterFunction.Next7Days, name: "Next 7 Days", description: "Function to evaluate whether the value is within the next 7 days." },
    { func: FilterFunction.NextMonth, name: "Next Month", description: "Function to evaluate whether the value is in the next month." },
    { func: FilterFunction.NextWeek, name: "Next Week", description: "Function to evaluate whether the value is in the next week." },
    { func: FilterFunction.ThisMonth, name: "This Month", description: "Function that evaluates whether the value is within the current month." },
    { func: FilterFunction.ThisWeek, name: "This Week", description: "Function that evaluates whether the value is within the current week." },
    { func: FilterFunction.ThisYear, name: "This Year", description: "Function that evaluates whether the value is within the current year." },
    { func: FilterFunction.Today, name: "Today", description: "Function that evaluates whether the value equals today's date." },
    { func: FilterFunction.Tomorrow, name: "Tomorrow", description: "Function that evaluates whether the value equals tomorrow's date." },
    { func: FilterFunction.Yesterday, name: "Yesterday", description: "Function that evaluates whether the value equals yesterday's date." },
    
    { func: FilterFunction.OnOrAfterToday, name: "On or After Today", description: "Function to evaluate whether the value is on or after today." },
    { func: FilterFunction.OnOrBeforeToday, name: "On or Before Today", description: "Function to evaluate whether the value is on or before today." },
    { func: FilterFunction.AfterToday, name: "After Today", description: "Function to evaluate whether the value is after today." },
    { func: FilterFunction.BeforeToday, name: "Before Today", description: "Function to evaluate whether the value is before today." },
    
    { func: FilterFunction.UserContactId, name: "User Contact", description: "Login user's contact." },
    { func: FilterFunction.UserEntityId, name: "User Entity", description: "Login user's site entity (ex: Vendor)." },
    { func: FilterFunction.UserName, name: "User Name", description: "Login user name." },
    { func: FilterFunction.UserId, name: "User Id", description: "Login user id." },
    { func: FilterFunction.UserEmail, name: "User Email", description: "Login user's email address." },
    { func: FilterFunction.UserPhone, name: "User Phone", description: "Login user's phone number." },
]

export const SelectFilterFunction = ({ value, disabled, isLoading, className, onChange, onBlur }: {
    value?: string,
    isLoading?: boolean,
    className?: string,
    disabled?: boolean,
    onChange: (value?: string) => void,
    onBlur?: () => void
}) => {
    const handleOnChange = (selected: Option[]) => {
        if (selected.length === 0)
            onChange(undefined);

        const value = selected[0] as IFuncInfo || undefined;
        onChange(value?.func);
    }

    const handleMenuItemRender = (option: Option) => {
        const { name, description } = option as IFuncInfo;

        return <>
            <div className='d-flex flex-column'>
                <label className="fw-bold m-0 p-0">{name}</label>
                <p className="fs-9 text-wrap fw-semibold m-0 p-0 text-gray-600">{description}</p>
            </div>
        </>
    }

    return (
        <div className={clsx(className, "field-group")}>
            <Typeahead
                id="functions"
                labelKey='name'
                className="custom-typeahead typeahead-sm"
                isLoading={isLoading}
                clearButton={true}
                disabled={disabled}
                renderMenuItemChildren={handleMenuItemRender}
                options={functions}
                onChange={handleOnChange}
                onBlur={onBlur}
                selected={functions?.filter(p => `${p.func}` === value) || [{ func: value, name: value }]}
            />
        </div>
    )
}