import { GrowShrink } from "../../../../../base/styles";
import clsx from "clsx";

export const SelectGrowShrink = ({ title, value, className, onChange }:{
    title: string,
    value?: GrowShrink,
    className?: string,
    onChange: (value?: GrowShrink) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as GrowShrink;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={GrowShrink.Grow1}>Grow 1</option>
                <option value={GrowShrink.Grow0}>Grow 0</option>
                <option value={GrowShrink.Shrink1}>Shrink 1</option>
                <option value={GrowShrink.Shrink0}>Shrink 0</option>
            </select>
        </div>
    )
}