import { ApiTags, rootApi } from "../../../../services/rootApi";

import { IDocumentChecklist } from "../models";

const TAG = ApiTags.DocumentChecklist;
const documentChecklistApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getDocumentChecklist: builder.query<IDocumentChecklist, string>({
      query: (id) => `/DocumentChecklists/${id}`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    })
  })
})

export const {
  useGetDocumentChecklistQuery,
  useLazyGetDocumentChecklistQuery
} = documentChecklistApi;