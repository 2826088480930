import * as Yup from 'yup'

import { AlertMessage, MUIIcon, Spinner } from '../../../widgets'
import { Formik, FormikHelpers } from 'formik'
import { IEntityRelation, ISite } from "../../../services/auth/models"
import { useDeleteEntityRelationMutation, useUpdateEntityRelationMutation } from '../../../services/auth'

import { SelectEntity } from '../../../design/widgets'
import { SelectEntityAttribute } from '../../../design/widgets/SelectEntityAttribute'
import { useState } from 'react'

const entityRelationSchema = Yup.object().shape({
    entityId: Yup.string()
        .required('Entity is required'),
    fieldId: Yup.string()
        .required('Field is required'),
    mappingFieldId: Yup.string()
        .required('Mapping Field is required')
})

export const UpdateEntityRelation = ({ site, relation, className }: {
    site: ISite,
    relation: IEntityRelation,
    className?: string
}) => {
    const [editEntity, setEditEntity] = useState<boolean>(false)
    const [updateEntityRelation] = useUpdateEntityRelationMutation()
    const [deleteEntityRelation] = useDeleteEntityRelationMutation()

    const handleSubmit = (values: Partial<IEntityRelation>, { setStatus, setSubmitting }: FormikHelpers<IEntityRelation>) => {
        setSubmitting(true)
        setStatus()
        updateEntityRelation({ siteId: relation.portalId, relationId: relation.id, entity: values })
            .unwrap()
            .then(() => setEditEntity(false))
            .catch(err => {
                setStatus(err.data?.error?.message || 'Failed to update entity relation.')
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    const handleDelete = ({ setStatus, setSubmitting }: {
        setStatus: (status?: string) => void,
        setSubmitting: (isSubmitting: boolean) => void
    }) => {
        setSubmitting(true)
        setStatus()
        deleteEntityRelation({ siteId: relation.portalId, relationId: relation.id })
            .unwrap()
            .then((entityRelation) => {
                setEditEntity(false);
            })
            .catch(err => {
                setStatus(err.data?.error?.message || 'Failed to delete entity relation.')
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <>
            <Formik
                initialValues={relation}
                validationSchema={entityRelationSchema}
                onSubmit={handleSubmit}>
                {({
                    errors, touched, status, isSubmitting, isValid, values,
                    handleSubmit, setFieldValue, setFieldTouched, resetForm, setStatus, setSubmitting }) => (
                    <>
                        <tr>
                            <td className='pt-0'>
                                <form onSubmit={handleSubmit} noValidate id={`entity-relation-${relation.id}`} />
                                <SelectEntity
                                    disabled={!editEntity}
                                    value={values.entityId}
                                    onBlur={() => setFieldTouched('entityId', true)}
                                    onChange={(entity) => { setFieldValue('entityId', entity) }} />
                                <FieldError show={touched.entityId && !!errors.entityId} message={errors.entityId} />
                            </td>
                            <td className='pt-0'>
                                {values.entityId && <>
                                    <SelectEntityAttribute
                                        entity={values.entityId}
                                        disabled={!editEntity}
                                        value={values.fieldId}
                                        onBlur={() => setFieldTouched('fieldId', true)}
                                        onChange={(value) => { setFieldValue('fieldId', value) }} />
                                    <FieldError show={touched.fieldId && !!errors.fieldId} message={errors.fieldId} />
                                </>}
                            </td>
                            <td className='pt-0'>
                                <SelectEntityAttribute
                                    entity={site.portalEntity}
                                    disabled={!editEntity}
                                    value={values.mappingFieldId}
                                    onBlur={() => setFieldTouched('mappingFieldId', true)}
                                    onChange={(value) => { setFieldValue('mappingFieldId', value) }} />
                                <FieldError show={touched.mappingFieldId && !!errors.mappingFieldId} message={errors.mappingFieldId} />
                            </td>
                            <td className='pt-0'>
                                <div className='d-flex justify-content-center align-items-stretch flex-wrap'>
                                    {!editEntity &&
                                        <>
                                            <button
                                                className='btn btn-sm btn-primary fs-8 py-2 px-3 flex-fill'
                                                type='button'
                                                onClick={() => setEditEntity(true)}>
                                                <MUIIcon iconName='EditOutlined' className='fs-5 me-1' />
                                                Edit
                                            </button>
                                            <button
                                                className='btn btn-sm btn-danger fs-8 py-2 px-3 ms-2 flex-fill'
                                                disabled={isSubmitting}
                                                type='button'
                                                onClick={() => handleDelete({ setStatus, setSubmitting })}>
                                                {!isSubmitting ?
                                                    <MUIIcon iconName='DeleteOutlined' className='fs-5 me-1' />
                                                    : <Spinner show={isSubmitting} />}
                                                Delete
                                            </button>
                                        </>
                                    }
                                    {editEntity && <>
                                        <button
                                            type='submit'
                                            form={`entity-relation-${relation.id}`}
                                            id='submit-settings'
                                            className='btn btn-sm btn-primary fs-8 py-2 px-3 flex-fill'
                                            disabled={isSubmitting || !isValid}
                                        >
                                            <span className='indicator-label'>
                                                {!isSubmitting ?
                                                    <MUIIcon iconName='SaveOutlined' className='fs-5 me-1' />
                                                    : <Spinner show={isSubmitting} />}
                                                Save
                                            </span>
                                        </button>
                                        <button
                                            className='btn btn-sm btn-secondary fs-8 py-2 px-3 ms-2 flex-fill'
                                            type='button'
                                            onClick={() => {
                                                resetForm();
                                                setStatus('');
                                                setEditEntity(false)
                                            }}>
                                            <MUIIcon iconName='CancelOutlined' className='fs-5 me-1' />
                                            Cancel
                                        </button>
                                    </>}
                                </div>
                            </td>
                        </tr>
                        {!!status && <>
                            <tr><td colSpan={4} className='pt-0'>
                                <AlertMessage show={!!status} message={status} type='Error' />
                            </td></tr>
                        </>}
                    </>
                )}
            </Formik>
        </>
    )
}

const FieldError = ({ show, message }: { show?: boolean, message?: string }) => {
    if (!show) return <></>

    return <div className='fv-plugins-message-container'>
        <div className='fv-help-block fs-8'>
            <span role='alert'>{message}</span>
        </div>
    </div>
}