import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const ServiceRequestProperties: IProperty[] = [
    { id: Property.Entity, name: 'Service Request Entity', type: PropertyType.Entity },
    { id: Property.ServiceId, name: 'Service Id', type: PropertyType.String },
    { id: Property.ServiceRequestId, name: 'Service Request Id', type: PropertyType.String },
    { id: Property.RelativePath, name: 'Relative Path', type: PropertyType.String },
    { id: Property.ModifyAllowed, name: 'Edit Mode', type: PropertyType.Boolean },
    { id: Property.ChangeStatus, name: 'Change Status', type: PropertyType.Boolean },
    { id: Property.HideHeader, name: 'Hide Header', type: PropertyType.Boolean },
    { id: Property.PageLink, name: 'Navigate To', type: PropertyType.PageLink },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.EditOnly, name: 'Edit Only', type: PropertyType.Boolean },
    { id: Property.DocumentsOnly, name: 'Documents Only', type: PropertyType.Boolean },
]
