import { AlertMessage, ProgressIndicator, When } from "../../../../widgets"

import { EntityDocumentChecklist } from "./EntityDocumentChecklist"
import React from "react"
import clsx from "clsx"
import { useGetEntityDocumentChecklists } from "../hooks"

export const EntityDocumentChecklists = ({ entityId, entity, relativePath, className }: {
    entity?: string,
    entityId?: string,
    relativePath?: string,
    className?: string,
}) => {
    const { data, isLoading, isSuccess, isError, error } = useGetEntityDocumentChecklists({ entity, entityId })

    if (!entity || !entityId || !relativePath) {
        return (
            <AlertMessage
                show={true}
                type="Warning"
                className={className}
                message="Please configure the component bindings." />
        )
    }

    return (
        <div className={clsx(className)}>
            <ProgressIndicator show={isLoading} message="Please wait..." />
            <AlertMessage show={isError} type="Error" message={error} />

            <When condition={isSuccess}>
                {(data) && <div className="d-flex">
                    {data.map((item) => <React.Fragment key={item.documentChecklistId}>
                        <EntityDocumentChecklist
                            className="flex-column mx-2"
                            entityDocumentChecklist={item}
                            relativePath={relativePath} />
                    </React.Fragment>)}
                </div>}
            </When>
        </div>
    )
}

