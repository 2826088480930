import { DndItem } from "./models";
import clsx from "clsx";
import { useDndContext } from "./DndContextProvider";

export const Droppable = ({ layout, className, title, onClick, onDropItem }: {
    layout: "Auto" | "Vertical" | "Horizontal",
    title?: string,
    className?: string,
    onClick?: (e: React.MouseEvent) => void,
    onDropItem?: (item: DndItem) => void
}) => {
    const { item } = useDndContext()

    const onDragOver = (e: React.DragEvent) => {
        e.currentTarget.classList.add("drag-over")
        e.preventDefault();
        e.stopPropagation()
    }

    const onDragLeave = (e: React.DragEvent) => {
        e.currentTarget.classList.remove("drag-over")
        e.stopPropagation()
    }

    const onDrop = (e: React.DragEvent) => {
        e.currentTarget.classList.remove("drag-over")
        e.preventDefault();
        e.stopPropagation()

        onDropItem && onDropItem(item)
    }

    const layoutClass = layout === "Vertical" ? "pfx-layout-vertical" : layout === "Horizontal" ? "pfx-layout-horizontal" : "pfx-layout-auto"

    return (
        <div
            className={clsx(className, "pfx-control__droppable", layoutClass)}
            title={title}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}>
            <div className="pfx-control__droppable--focus" title={title} onClick={onClick} />
        </div>)
}