import { ApiTags, rootApi } from '../rootApi';

import { ApiResReadMultiple } from '../models';
import { IMemberAccess } from './models/MemberAccess.model';

const TAG = ApiTags.MemberAccess;
const getTags = (entities: IMemberAccess[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const memberAccessApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getMemberAccesses: builder.query<IMemberAccess[], void>({
      query: () => '/PortalMemberAccess',
      transformResponse: (res: ApiResReadMultiple<IMemberAccess>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getMemberAccess: builder.query<IMemberAccess, string>({
      query: (id) => `/PortalMemberAccess(${id})`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    createMemberAccess: builder.mutation<IMemberAccess, IMemberAccess>({
      query: (entity) => ({
        url: '/PortalMemberAccess',
        method: 'POST',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updateMemberAccess: builder.mutation<IMemberAccess, Partial<IMemberAccess>>({
      query: (entity) => ({
        url: `/PortalMemberAccess(${entity.portalId})`,
        method: 'PATCH',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deleteMemberAccess: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/PortalMemberAccess(${id})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetMemberAccessesQuery,
  useGetMemberAccessQuery,
  useCreateMemberAccessMutation,
  useUpdateMemberAccessMutation,
  useDeleteMemberAccessMutation,
} = memberAccessApi;