import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const PaytmButtonProperties: IProperty[] = [
    { id: Property.Label, name: 'Label', type: PropertyType.String },
    { id: Property.OrderNo, name: 'Order No.', type: PropertyType.String },
    { id: Property.Amount, name: 'Amount', type: PropertyType.Number },
    { id: Property.Currency, name: 'Currency', type: PropertyType.String },
    { id: Property.MaterialIcon, name: 'Icon', type: PropertyType.MaterialIcon },    
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.Disabled, name: 'Disabled', type: PropertyType.Boolean },
    { id: Property.BackgroundStyle, name: 'Style', type: PropertyType.ButtonStyle },
    { id: Property.Size, name: 'Size', type: PropertyType.ControlSize },
    { id: Property.SuccessPage, name: 'Success Page', type: PropertyType.PageLink },   
    { id: Property.FailedPage, name: 'Failed Page', type: PropertyType.PageLink },   
]
