import { BadgeStyle } from "../../../../../base/types";

export const BadgeStyleValue = ({ label, value, className, onChange }: {
    label: string,
    value: any
    className?: string,
    onChange: (value?: BadgeStyle) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as BadgeStyle;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return <select
        onChange={handleOnSelectionChange}
        value={value || ""}
        className="form-select form-select-sm form-control-xs">
        <option value={BadgeStyle.None}></option>
        <option value={BadgeStyle.Primary}>Primary</option>
        <option value={BadgeStyle.Secondary}>Secondary</option>
        <option value={BadgeStyle.Success}>Success</option>
        <option value={BadgeStyle.Danger}>Danger</option>
        <option value={BadgeStyle.Warning}>Warning</option>
        <option value={BadgeStyle.Info}>Info</option>
        <option value={BadgeStyle.Light}>Light</option>
        <option value={BadgeStyle.Dark}>Dark</option>
    </select>
}
