import { HoverBackgroundColor } from "../../../../base/styles";
import clsx from "clsx";

export const SelectHoverColor = ({ title, value, className, onChange }:{
    title: string,
    value?: HoverBackgroundColor,
    className?: string,
    onChange: (value?: HoverBackgroundColor) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as HoverBackgroundColor;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option className="bg-transparent"></option>
                <option className="bg-primary text-light" value={HoverBackgroundColor.Primary}>Primary</option>
                <option className="bg-secondary text-dark" value={HoverBackgroundColor.Secondary}>Secondary</option>
                <option className="bg-success text-dark" value={HoverBackgroundColor.Success}>Success</option>
                <option className="bg-danger text-light" value={HoverBackgroundColor.Danger}>Danger</option>
                <option className="bg-warning text-dark" value={HoverBackgroundColor.Warning}>Warning</option>
                <option className="bg-info text-light" value={HoverBackgroundColor.Info}>Info</option>
                <option className="bg-light text-dark" value={HoverBackgroundColor.Light}>Light</option>
                <option className="bg-dark text-light"  value={HoverBackgroundColor.Dark}>Dark</option>                
                <option className="bg-white text-dark" value={HoverBackgroundColor.White}>White</option>

                <option className="bg-light-primary text-dark" value={HoverBackgroundColor.LightPrimary}>Light Primary</option>
                <option className="bg-light-success text-dark" value={HoverBackgroundColor.LightSuccess}>Light Success</option>
                <option className="bg-light-info text-dark" value={HoverBackgroundColor.LightInfo}>Light Info</option>
                <option className="bg-light-warning text-dark" value={HoverBackgroundColor.LightWarning}>Light Warning</option>
                <option className="bg-light-danger text-dark" value={HoverBackgroundColor.LightDanger}>Light Danger</option>
                <option className="bg-light-dark text-light" value={HoverBackgroundColor.LightDark}>Light Dark</option>
            </select>
        </div>
    )
}