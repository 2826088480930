import { MUIIcon, Spinner } from "../../../../widgets";

import { IToolbarAction } from "../../models";
import clsx from "clsx";
import { useState } from "react";

export const ActionLink = ({ action, className, onAction }: {
    action: IToolbarAction,
    className?: string,
    onAction: (id: string) => Promise<void>,
}) => {
    const { label, icon } = action
    const [executing, setExecuting] = useState<boolean>(false)

    const handleOnClick = () => {
        setExecuting(true)
        onAction?.(action.id)
            .finally(() => setExecuting(false))
    }

    return (
        <button
            type="button"
            className={clsx("dropdown-item fs-7 fw-semibold", action.className, className)}
            disabled={executing}
            onClick={handleOnClick}
        >
            {executing && <Spinner />}
            {(!executing && icon) && <MUIIcon iconName={icon} className="fs-5 me-1" />}
            {label}
        </button>
    )
}
