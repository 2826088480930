import { ToolbarActions } from "./toolbar-actions";
import { useToolbar } from "./ToolbarProvider";

export const Tools = () => {
    const { buttons, onAction } = useToolbar();

    const handleOnAction = async (id: string) => {
        return onAction?.current(id)
    }

    return <div data-identifier="toolbar-actions">
        <ToolbarActions actions={buttons} onAction={handleOnAction} />
    </div>
}