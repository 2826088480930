import { DropdownOption, Property } from "../../../base/types";
import React, { useEffect, useState } from "react";

import { BooleanBinding } from "../../../base/bindings/boolean";
import { IControlProps } from "../../../base/models";
import clsx from "clsx";
import { useDesignContext } from "../../../providers";
import { useFromData } from "../panels/FormComponent";
import { useGetProperties } from "../../../base/hooks";

export const RadioGroupComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const inputValue: string | undefined = properties[Property.ControlValue];
    const required: boolean = properties[Property.Required] || false;
    const disabled: boolean = properties[Property.Disabled] || false;
    const dropdown: DropdownOption[] = properties[Property.Dropdown] || [];

    const [binding, setBinding] = useState<BooleanBinding>()
    const [value, setValue] = useState<any>()
    const { form, values, setFieldValue } = useFromData()

    useEffect(() => {
        const binding = getBinding(Property.ControlValue) as BooleanBinding
        if (!binding)
            return;

        setBinding(binding)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (mode !== "Preview")
            return;

        setValue(Boolean(inputValue))
        // eslint-disable-next-line
    }, [inputValue])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field")
            return;

        setValue(values[binding.value])
        // eslint-disable-next-line
    }, [values])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field")
            return;

        setFieldValue(binding.value, value)
        // eslint-disable-next-line
    }, [value])

    return (
        <div className={className}>
            {label &&
                <label
                    title={tooltip}
                    className={clsx("form-label text-gray-600 fs-7", { "required": required })}>
                    {label}
                </label>}
            <div className="d-flex">
                {dropdown.map((item, i) => <React.Fragment key={i}>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name={control.controlId}
                            id={`${item.value}`}
                            title={tooltip}
                            onChange={(e) => setValue(item.value)}
                            disabled={disabled}
                            checked={value === item.value} />
                        <label className="form-check-label me-2" htmlFor={`${item.value}`}>
                            {item.label}
                        </label>
                    </div>
                </React.Fragment>)}
            </div>
        </div>
    )
}