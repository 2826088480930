import { Checkbox } from "../../Checkbox"
import { ResponsiveFill } from "../../../../../base/styles"
import clsx from "clsx"
import { useState } from "react"

export const EditFill = ({ title, value, className, onChange }: {
    title: string,
    value: ResponsiveFill,
    className?: string,
    onChange: (value?: ResponsiveFill) => void
}) => {
    const [responsive, setResponsive] = useState<boolean>();

    return (
        <div className={clsx(className)}>
            <div className="d-flex align-items-end">
                <Checkbox
                    title={title}
                    className="flex-grow-1 pe-2"
                    onChange={(newValue) => { onChange({ ...value, allSizes: newValue }) }}
                    value={value?.allSizes === true} />
                <Checkbox
                    title="Responsive"
                    className="fs-8 fw-semibold"
                    value={responsive === true}
                    onChange={setResponsive} />
            </div>

            {responsive &&
                <div className="d-flex flex-row flex-wrap mx-5 my-3">
                    <Checkbox
                        title="Small"
                        className="w-50 pe-2 mb-1"
                        onChange={(newValue) => { onChange({ ...value, small: newValue }) }}
                        value={value?.small === true} />
                    <Checkbox
                        title="Medium"
                        className="w-50 pe-2 mb-1"
                        onChange={(newValue) => { onChange({ ...value, medium: newValue }) }}
                        value={value?.medium === true} />
                    <Checkbox
                        title="Large"
                        className="w-50 pe-2 mb-1"
                        onChange={(newValue) => { onChange({ ...value, large: newValue }) }}
                        value={value?.large === true} />
                    <Checkbox
                        title="Ex. Large"
                        className="w-50 pe-2 mb-1"
                        onChange={(newValue) => { onChange({ ...value, xLarge: newValue }) }}
                        value={value?.xLarge === true} />
                    <Checkbox
                        title="Ex. Large +"
                        className="w-50 pe-2 mb-1"
                        onChange={(newValue) => { onChange({ ...value, xxLarge: newValue }) }}
                        value={value?.xxLarge === true} />
                </div>}
        </div>)
}