import { IPage } from "../../models"
import { ViewPageControls } from "./ViewPageControls"

export const StaticPage = ({ page, className }: {
    page: IPage,
    className?: string
}) => {
    return (
        <div className={className}>
            <ViewPageControls controls={page.controls} />
        </div>)
}
