import * as Yup from 'yup'

import { SignInButton, useAuthProviders } from '../msal'

import { Link } from 'react-router-dom'
import { MsalStatus } from '../msal/msal-status'
import clsx from 'clsx'
import { useAuth } from '../core/Auth'
import { useFormik } from 'formik'
import { useLoginMutation } from '../../../framework/services/auth'
import { useState } from 'react'

const loginSchema = Yup.object().shape({
  userName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('User name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  userName: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setUser } = useAuth()
  const [login] = useLoginMutation()
  const { authTypes } = useAuthProviders()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setSubmitting(true)

      login({ userName: values.userName, password: values.password })
        .unwrap()
        .then((result) => {
          saveAuth(result.auth)
          setUser(result.user)
        })
        .catch(err => {
          saveAuth(undefined)
          setStatus(err.data?.error?.message || 'The login details are incorrect')
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false)
        })
    },
  })

  if (MsalStatus.isInProgress()) {
    return <div className='d-flex flex-column align-items-center justify-content-center h-100 w-100'>
      <SignInButton className='btn-sm w-250px' />
    </div>
  }

  return (
    <>
      <div className='d-xl-none mt-20'></div>
      <div className='d-flex flex-row flex-wrap gap-5 mt-10 ms-20'>
        <div className='mw-200px d-none'>
          <form
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <h5 className='mb-0 fs-7 text-gray-800'>Sign in as Parent</h5>
            <hr className='p-1 m-1' />
            {/* begin::Form group */}
            <div className='fv-fow mb-8'>
              <label className='form-label fs-7 fw-bold text-dark mb-1'>User Name</label>
              <input
                placeholder='User Name'
                {...formik.getFieldProps('userName')}
                className={clsx(
                  'form-control form-control-sm form-control-solid',
                  { 'is-invalid': formik.touched.userName && formik.errors.userName },
                  { 'is-valid': formik.touched.userName && !formik.errors.userName, }
                )}
                type='userName'
                name='userName'
                autoComplete='off'
              />
              {formik.touched.userName && formik.errors.userName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.userName}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-fow mb-5'>
              <div className='d-flex justify-content-between mt-n5'>
                <label className='form-label fs-7 fw-bold text-dark mb-1'>Password</label>
              </div>
              <input
                type='password'
                autoComplete='off'
                placeholder='Password'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-sm form-control-solid',
                  { 'is-invalid': formik.touched.password && formik.errors.password, },
                  { 'is-valid': formik.touched.password && !formik.errors.password, }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex flex-row align-items-center gap-3'>
              <div className='min-w-75px'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-sm btn-primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Sign in</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              <div className='flex-fill d-flex flex-wrap flex-row align-items-center gap-1 fs-8'>
                <Link
                  to='/auth/forgot-password'
                  className='link-primary fw-bold fst-italic'
                >
                  Forgot Password ?
                </Link>
                <Link to='/auth/registration'
                  className='link-primary fw-bold fst-italic'
                >
                  Create an Account ?
                </Link>
              </div>
            </div>
          </form>
        </div>
        {(authTypes.indexOf("MSAL") !== -1) && <>
          <div className=''>
            <h5 className='mb-0 fs-7 text-gray-800'>Sign in as Student</h5>
            <hr className='p-1 m-1' />
            <SignInButton className='btn-sm' />
          </div>
        </>}
      </div>
    </>
  )
}