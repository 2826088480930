import clsx from "clsx"

export const BooleanValue = ({ label, value, className, onChange }: {
    label: string,
    value: any
    className?: string,
    onChange: (value: any) => void,
}) => {
    return <div className={clsx(className, "form-check form-check-sm form-check-custom")}>
        <input
            id={label}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            className="form-check-input"
            type="checkbox" />
        {label &&
            <label className="fs-8 fw-bold text-gray-600 ms-2"
                htmlFor={label}>
                {label}
            </label>}
    </div>
}
