import clsx from "clsx"

export const TextAttribute = ({
    value,
    required,
    placeholder,
    readOnly,
    className,
    onChange,
    onBlur,
}: {
    value?: string,
    required?: boolean,
    placeholder?: string,
    readOnly?: boolean,
    className?: string,
    onChange: (value: any) => void,
    onBlur: () => void
}) => {
    return <>
        <input
            type="text"
            required={required}
            placeholder={placeholder}
            readOnly={readOnly}
            value={value || ''}
            onBlur={() => onBlur()}
            onChange={(e) => onChange(e.target.value)}
            className={clsx(className, 'form-control form-control-sm')} />
    </>
}
