import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const CheckboxProperties: IProperty[] = [
    { id: Property.Label, name: 'Label', type: PropertyType.String },
    { id: Property.ControlValue, name: 'Value', type: PropertyType.Boolean },
    { id: Property.Required, name: 'Required', type: PropertyType.Boolean },
    { id: Property.Readonly, name: 'Readonly', type: PropertyType.Boolean },
    { id: Property.Disabled, name: 'Disabled', type: PropertyType.Boolean },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String }
]