import * as uuid from "uuid";

import { ActionCallback, useToolbar } from "../../../layout";
import { useEffect, useRef } from "react";

import { ComponentType } from "../../../base/types";
import { useCreateComponentMutation } from "../../../services/data/componentsApi";
import { useNavigate } from "react-router-dom";

export const useComponentsToolbarActions = () => {
    const navigate = useNavigate();
    const { setButtons, setOnAction } = useToolbar()
    const [createComponent, { isLoading, isError, error }] = useCreateComponentMutation();

    const onToolbarAction = async (actionId: string) => {
        switch (actionId) {
            case 'add-new-page':
                await createNewComponent()
                break;
        }
    }

    const onActionRef = useRef<ActionCallback>(onToolbarAction)
    useEffect(() => {
        onActionRef.current = onToolbarAction
        setOnAction(onActionRef)

        setButtons([{
            id: 'add-new-component',
            label: 'Add New Component',
            className: 'btn btn-primary btn-sm py-2',
            icon: 'AddOutlined',
        }])
        // eslint-disable-next-line
    }, [])

    const createNewComponent = () => {
        const componentId = uuid.v1();

        return createComponent({
            id: componentId,
            name: '<< Component Name >>',
            type: ComponentType.Custom,
        })
            .unwrap()
            .then(component => {
                navigate(`/design-component(${component.id})`)
            })
    }

    return { isLoading, isError, error }
}
