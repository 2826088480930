import { displayPrintClass, responsiveDisplayClasses } from './display';

import { IControlStyle } from '../models';
import { alignmentClass } from './alignment';
import { backgroundClasses } from './background';
import { borderStyleClasses } from './borders';
import clsx from "clsx";
import { getLayoutClasses } from "./layout";
import { getSpacingClasses } from './spacing';
import { textStyleClasses } from './text';
import { viewPortSizingClasses } from './sizing';

export const getControlClasses = (styles?: IControlStyle) => {
    let classes = "";

    if (styles) {
        classes = clsx(classes, getLayoutClasses(styles));
        classes = clsx(classes, getOtherClasses(styles));
    }

    return classes;
}

export const getOtherClasses = (styles?: IControlStyle) => {
    let classes = "";

    if (styles) {
        classes = clsx(classes, alignmentClass(styles?.alignment));
        classes = clsx(classes, viewPortSizingClasses(styles?.viewportSizing));
        classes = clsx(classes, responsiveDisplayClasses(styles?.display));
        classes = clsx(classes, displayPrintClass(styles?.printDisplay));
        classes = clsx(classes, backgroundClasses(styles?.background));
        classes = clsx(classes, borderStyleClasses(styles?.borders));
        classes = clsx(classes, textStyleClasses(styles?.text));
        classes = clsx(classes, getSpacingClasses(styles?.spacing));
    }

    return classes;
}



