import { AlertMessage, ProgressIndicator } from "../framework/widgets"

import { PageTitle } from "../layout/core"
import { PageWithToolbar } from "../framework/design/page"
import { useGetPageFromUrl } from "../framework/design/page/hooks"
import { useParams } from "react-router-dom"

export const ViewPageWrapper = () => {
    const { pageId } = useParams();

    return (
        <div className="position-relative">
            {(!pageId) ? <>
                <ProgressIndicator show={true} className="mb-2" message="Please wait ..." />
            </> : <>
                <ViewPageInner pageId={pageId} />
            </>}
        </div>)
}

const ViewPageInner = ({ pageId }: { pageId: string }) => {
    const { page, pageView, isLoading, isSuccess, isError, error } = useGetPageFromUrl(pageId)

    if (isLoading || !isSuccess || !page || !pageView) {
        return <>
            <ProgressIndicator show={isLoading} className="mb-2" message="Please wait ..." />
            <AlertMessage show={isError} type="Error" message={error} />
        </>
    }

    return <>
        <PageTitle key="title" breadcrumbs={[]}>{page.title || page.name}</PageTitle>
        {(pageId === page.id) && <>
            <PageWithToolbar page={page} pageView={pageView} />
        </>}
    </>
}