import { IPage, IPageView } from "../models";

import { useEffect } from "react";
import { usePageContext } from "./PageContextProvider";

export const PageContextProducer = ({ page, pageView }: {
    page: IPage, pageView?: IPageView
}) => {
    const { setPage, setPageView } = usePageContext();

    useEffect(() => {
        setPage(page)
        setPageView(pageView)
        // eslint-disable-next-line
    }, [])

    return <></>
}
