import { BadgeStyle, DisplayFormat, Property } from "../../../base/types";

import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import { MUIIcon } from "../../../widgets";
import clsx from "clsx";
import { formatValue } from "../../helpers";
import { getBadgeClass } from "./badge-class";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const BadgeComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const text: string | undefined = properties[Property.Text];
    const displayFormat: DisplayFormat | undefined = properties[Property.Format];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const icon: string | undefined = properties[Property.MaterialIcon]
    const style: BadgeStyle | undefined = properties[Property.BackgroundStyle]
    const roundedPill: boolean | undefined = properties[Property.RoundedPill]

    if (mode === "Design") {
        return (
            <span className={clsx(getBadgeClass(style, roundedPill, "bg-primary"), className)} title={tooltip}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!text })} />}
                <DefaultLabel
                    control={control}
                    propertyId={Property.Text}
                    defaultLabel="Badge"
                    className="text-hover-primary" />
            </span>)
    }

    return (
        <div className={clsx(getBadgeClass(style, roundedPill, "bg-primary"), className)} title={tooltip}>
            {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!text })} />}
            <span>{formatValue(text, displayFormat)}</span>
            {children}
        </div>)
}
