import { ActionType } from "../models";
import Modal from "react-bootstrap/esm/Modal";
import React from "react";

const ActionTypes = [
    ActionType.Action, 
    ActionType.ActionGroup
]

export const ActionTypesDialog = ({ show, onSelect, onHideModal }: {
    show: boolean,
    onSelect: (type: ActionType) => void,
    onHideModal: () => void,
}) => {
    const handleOnSelect = (type: ActionType) => {
        onSelect(type);
        onHideModal();
    }

    return (
        <div>
            <Modal show={show} onHide={onHideModal} dialogClassName="min-w-75">
                <Modal.Header closeButton>
                    <Modal.Title>Action Types</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap justify-content-start">
                        {ActionTypes.map(type =>
                            <React.Fragment key={type}>
                                <ActionTypeButton type={type} onSelect={handleOnSelect} />
                            </React.Fragment>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const ActionTypeButton = ({ type, onSelect }: {
    type: ActionType,
    onSelect: (type: ActionType) => void
}) => {
    return <div className="w-xl-25 py-1 px-2" >
        <button
            type="button"
            title={type}
            onClick={() => onSelect(type)}
            className="btn btn-outline btn-outline-primary w-100">
            <div className="d-flex align-items-center text-truncate">
                <i className="las la-object-group text-gray-700 fs-1"></i>
                <div className='fw-semibold text-start text-gray-700 flex-fill fs-7'>
                    {type}
                </div>
            </div>
        </button>
    </div>
}