import { useEffect, useState } from "react";

import clsx from "clsx";

export const MultilineText = ({ value, className, onChange }: {
    value: string,
    className?: string,
    onChange: (value: any) => void,
}) => {
    const [localValue, setLocalValue] = useState<string>(value);

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    useEffect(() => {
        onChange(localValue)
        // eslint-disable-next-line
    }, [localValue])

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const expression = e.target.value;
        setLocalValue(expression)
    }

    return <textarea
        onChange={handleTextAreaChange}
        value={localValue}
        rows={1}
        className={clsx(className, "form-control form-control-sm form-control-xs")} />;
}
