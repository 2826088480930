import * as Yup from 'yup'

import { AttributeDataType, IAttributeMetadata, IAttributeOption, IServiceAttribute, IServiceRequest, IServiceRequestAttribute, IServiceStage, ServiceRequestStatus } from '../models'
import { Form, Formik, FormikHelpers } from "formik"
import { useEffect, useState } from 'react'

import { IEntity } from '../../../../base/types'
import { IServiceMetadata } from "../services/useGetServiceMetadata"
import { KTSVG } from "../../../../../helpers"
import React from 'react'
import { ServiceAttribute } from './service-attribute/ServiceAttribute'
import { ServiceReqHelper } from '../helper'
import { Spinner } from "../../../../widgets"
import clsx from 'clsx'
import { useAuth } from '../../../../../modules/auth'

export const ServiceRequestWizard = ({ metadata, schemas, initValues, entity, relativePath, request, hideHeader, changeStatus, onChange, onComplete, stageId }: {
    metadata: IServiceMetadata,
    request?: IServiceRequest,
    schemas: Yup.ObjectSchema<any>[],
    initValues: any,
    stageId?: string,
    entity?: string,
    relativePath?: string,
    hideHeader?: boolean,
    changeStatus?: boolean,
    onChange: (request: Partial<IServiceRequest>) => Promise<IServiceRequest>,
    onComplete: () => void,
}) => {
    const [serviceRequest, setServiceRequest] = useState<IServiceRequest | undefined>(request)
    const [stages, setStages] = useState<IServiceStage[]>([])
    const [totalSteps, setTotalSteps] = useState<number>(-1)
    const [currStepIndex, setCurrStepIndex] = useState<number>(-1)
    const { user } = useAuth()

    useEffect(() => {
        const stages = metadata
            .stages
            .sort((a, b) => (a.stageNumber || 0) - (b.stageNumber || 0))

        setStages(stages)
        setTotalSteps(stages.length)

        gotoStage(stages, request?.stageId)

        // eslint-disable-next-line
    }, [])

    const gotoStage = (stages: IServiceStage[], stageId?: string) => {
        const index = stages.findIndex(p => p.id === stageId)

        if (!stageId || index === -1) {
            setCurrStepIndex(0)
            if (schemas.length > 0)
                setCurrStepIndex(0)
            return;
        }

        if (schemas.length > index)
            setCurrStepIndex(index)
    }

    const prevStep = () => {
        if (currStepIndex > 0)
            setCurrStepIndex(currStepIndex - 1)
    }

    const submitStep = (values: IEntity, { setStatus, setSubmitting }: FormikHelpers<Partial<IEntity>>) => {
        if (currStepIndex === -1 || currStepIndex >= totalSteps) {
            return
        }

        try {
            let nextStepIndex = currStepIndex + 1 < totalSteps ?
                currStepIndex + 1 :
                currStepIndex;

            const currentStageId = stages[nextStepIndex].id
            let statusCode: ServiceRequestStatus = serviceRequest?.statusCode || ServiceRequestStatus.Draft;

            if (statusCode && statusCode !== ServiceRequestStatus.Draft) {
                gotoStage(stages, currentStageId)
                setSubmitting(false)
                return;
            }

            const requestorId = user?.contactId;
            if (changeStatus && currStepIndex === totalSteps - 1) {
                statusCode = ServiceRequestStatus.Submitted;
            }

            setSubmitting(true)
            const { service } = metadata

            var attributes: IServiceRequestAttribute[] = []

            for (var key in values) {
                const attributeId = key;
                const attribute = metadata.attributes.find(p => p.id === attributeId)
                if (attribute?.datatype === AttributeDataType.DocumentChecklist ||
                    attribute?.datatype === AttributeDataType.RichText)
                    continue;

                const serviceAttribute = metadata.serviceAttributes.find(p => p.attributeId === attributeId)
                const serviceRequestAttribute = serviceRequest?.attributes?.find(p => p.serviceAttributeId === serviceAttribute?.id);
                attributes.push({
                    id: serviceRequestAttribute?.id,
                    serviceAttributeId: serviceAttribute?.id,
                    attributeValue: String(values[attributeId]),
                    name: attribute?.label,
                    serviceRequestId: serviceRequest?.id,
                })
            }

            let request: Partial<IServiceRequest> = {
                id: serviceRequest?.id,
                serviceId: service.id,
                stageId: currentStageId,
                name: service.name,
                requestorId,
                statusCode,
                userId: user?.userId,
                attributes,
            }

            return onChange(request)
                .then((request) => {
                    setServiceRequest(request)
                    if (currStepIndex === totalSteps - 1) {
                        onComplete()
                    } else {
                        gotoStage(stages, request.stageId)
                    }

                    return Promise.resolve()
                })
                .catch((err) => {
                    setStatus('Failed to save request details');
                    return Promise.reject('Failed to save request details')
                })
                .finally(() => {
                    setSubmitting(false)
                })
        } catch (err) {
            console.error(err)
        }
    }

    const isReadOnly = serviceRequest?.statusCode &&
        serviceRequest?.statusCode !== ServiceRequestStatus.Draft;

    const stepperIconClass = (index: number) => {
        const commonClass = clsx(
            "w-40px h-40px rounded",
            "fw-bold fs-5",
            "d-flex align-items-center justify-content-center");

        if (index === currStepIndex)
            return clsx(commonClass, "bg-primary text-white");
        else
            return clsx(commonClass, "bg-secondary text-gray-600");
    }

    const stepperLabelClass = (index: number) => {
        return index === currStepIndex ? "text-primary" : "text-gray-600";
    }

    const renderHeader = () => {
        if (hideHeader === true) return <></>

        return <>
            <div className='d-flex mb-5'>
                <div className='flex-fill'>
                    <h1 className='text-gray-800 mb-0'>{metadata.service.name}</h1>
                    {(serviceRequest?.requestNumber) && <>
                        <div className='fs-7 text-gray-600 fw-bold'>
                            <span>Request Number:</span>
                            <span className='text-gray-700 fw-bolder ms-2'>{serviceRequest?.requestNumber}</span>
                        </div>
                    </>}
                </div>

                <div className='d-flex flex-column fs-8'>
                    <label className='me-3 fw-bold'>Status</label>
                    <div className='badge badge-info px-2 py-1'>{ServiceReqHelper.status(request?.statusCode)}</div>
                </div>
            </div>
        </>
    }

    return (
        <div>
            {renderHeader()}

            <div
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper'
            >
                <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                    <div className='px-6 px-lg-10 px-xxl-15 py-10'>
                        <div className='stepper-nav'>
                            {stages.map((stage, index) => <React.Fragment key={stage.id}>
                                <div
                                    className={clsx('stepper-item mb-3', { "current": index === currStepIndex })}>
                                    <div className='stepper-line w-40px'></div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-5'>
                                            <div className={stepperIconClass(index)}>
                                                <span>{index + 1}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={clsx(stepperLabelClass(index), 'fs-4 fw-bold')}>{stage.name}</div>
                                            <div className='fs-8 fw-semibold text-gray-600'>{stage.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>)}
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
                    <Formik
                        validationSchema={schemas[currStepIndex]}
                        initialValues={initValues}
                        onSubmit={submitStep}>
                        {({ errors, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                            <Form className='py-10 w-100 w-xl-700px px-9' noValidate id='kt_create_service_request'>
                                {stages.map((stage, index) => <React.Fragment key={stage.id}>
                                    {(index === currStepIndex) && <>
                                        <div className={clsx({ current: index === currStepIndex })}
                                            data-kt-stepper-element='content'>
                                            <div className='row flex-fill g-3'>
                                                {metadata.serviceAttributes
                                                    .filter(p => p.serviceStageId === stage.id)
                                                    .sort((a, b) => (a.order || 0) - (b.order || 0))
                                                    .map(attr => <React.Fragment key={attr.id}>
                                                        <ServiceAttributeWrapper
                                                            metadata={metadata}
                                                            attribute={attr}
                                                            touched={touched[attr.attributeId] === true}
                                                            error={errors[attr.attributeId] as string}
                                                            value={values[attr.attributeId]}
                                                            entity={entity}
                                                            readOnly={isReadOnly}
                                                            entityId={serviceRequest?.id}
                                                            relativePath={relativePath}
                                                            onChange={(value) => setFieldValue(attr.attributeId, value)}
                                                            onBlur={() => setFieldTouched(attr.attributeId, true)}
                                                        />
                                                    </React.Fragment>)}
                                            </div>
                                        </div>
                                    </>}
                                </React.Fragment>)}

                                <div className='d-flex flex-stack pt-10'>
                                    <div className='mr-2'>
                                        {currStepIndex > 0 &&
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr063.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                Back
                                            </button>
                                        }
                                    </div>
                                    <div>
                                        <button type='submit'
                                            disabled={isSubmitting}
                                            className='btn btn-lg btn-primary me-3'>
                                            <span className='indicator-label'>
                                                {isSubmitting && <Spinner className='me-2' show={isSubmitting} />}
                                                {(currStepIndex !== totalSteps - 1) ? 'Continue' : 'Submit'}

                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-3 ms-2 me-0'
                                                />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

const ServiceAttributeWrapper = ({ metadata, attribute, touched, error, value, entity, entityId, readOnly, relativePath, onChange, onBlur }: {
    metadata: IServiceMetadata,
    attribute: IServiceAttribute,
    touched: boolean,
    error?: string,
    value?: string,
    entity?: string,
    entityId?: string,
    readOnly?: boolean,
    relativePath: string | undefined,
    onChange: (value: string) => void,
    onBlur: () => void,
}) => {
    const [attributeMetadata, setAttributeMetadata] = useState<IAttributeMetadata>()
    const [options, setOptions] = useState<IAttributeOption[]>([])
    const [checklistId, setChecklistId] = useState<string>()

    useEffect(() => {
        const options = metadata.attributeOptions.filter(p => p.attributeId === attribute.attributeId)
        setOptions(options)

        const attributeMetadata = metadata.attributes.find(p => p.id === attribute.attributeId)
        setAttributeMetadata(attributeMetadata)

        if (attributeMetadata?.datatype === AttributeDataType.DocumentChecklist) {
            setChecklistId(attributeMetadata.documentChecklist)
        }

        // eslint-disable-next-line
    }, [])

    return <>
        <ServiceAttribute
            attribute={attribute}
            touched={touched}
            error={error}
            value={value}
            entity={entity}
            readOnly={readOnly}
            entityId={entityId}
            checklistId={checklistId}
            relativePath={relativePath}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            metadata={attributeMetadata} />
    </>
}

