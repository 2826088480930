import { FieldFilterType, Property } from "../../../base/types";
import { IControlProps, ITableLookup } from "../../../base/models";

import { Spinner } from "../../../widgets";
import { StringBinding } from "../../../base/bindings/string";
import clsx from "clsx";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useEffect } from "react";
import { useGetProperties } from "../../../base/hooks";
import { useLazyReadMultipleQuery } from "../../../services/data/tableDataApi";
import { useState } from "react";

export const NameLookupComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const tableLookup: ITableLookup = properties[Property.TableLookup] || {};
    const inputValue: string | undefined = properties[Property.ControlValue];

    const [binding, setBinding] = useState<StringBinding>()
    const [value, setValue] = useState<string>()

    useEffect(() => {
        const binding = getBinding(Property.ControlValue) as StringBinding
        if (!binding)
            return;

        setBinding(binding)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (mode !== "Preview" || inputValue === undefined)
            return;

        setValue(inputValue)
        // eslint-disable-next-line
    }, [inputValue, mode])

    useEffect(() => {
        if (mode !== "Design" || !binding)
            return;

        setValue(`{${binding.value} - Name}`)
        // eslint-disable-next-line
    }, [binding])

    if (mode === "Design") {
        return <label className={clsx(className)}>
            {value}
        </label>
    }

    return (
        <>
            <LookupDisplayName
                tableLookup={tableLookup}
                value={value}
                className={className}
            />
        </>
    )
}

export const LookupDisplayName = ({ tableLookup, value, className }: {
    tableLookup: ITableLookup,
    value?: string,
    className?: string
}) => {
    const { tableId, idField, displayField } = tableLookup;
    const { user, siteId: portalId } = useAuth();
    const [readRecords, { data, isLoading }] = useLazyReadMultipleQuery()
    const [name, setName] = useState<string>()

    useEffect(() => {
        if (!user || !value || !idField || !displayField)
            return;

        const { userId } = user;
        if (!userId || !portalId)
            return;

        if (!tableId || !idField || !displayField)
            return;

        const fieldList = [idField, displayField]
        readRecords({
            userId, portalId,
            tableId,
            fieldList,
            recordLimit: 1,
            filters: [{ group: 0, filters: tableLookup.filters || [] },
            { group: 1, filters: [{ field: idField, type: FieldFilterType.Const, value }] }],
        })

        // eslint-disable-next-line
    }, [user, tableLookup, value])

    useEffect(() => {
        if (!data) return;

        const record = data.records.find(p => p[idField!] === value)
        if (record)
            setName(record[displayField!] || '');
        // eslint-disable-next-line
    }, [data])

    return (
        <label className={clsx(className)}>
            <Spinner show={isLoading} />
            {name}
        </label>
    )
}

