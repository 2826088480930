import { EntityDocumentChecklistItemMetadata } from '../../models';
import { useAuth } from "../../../../../../modules/auth";
import { useDeleteMutation } from "../../../../../services/data/tableDataApi";
import { useState } from "react";

// TODO:: status fields should be optimized
export const useDeleteEntityDocumentChecklistItem = () => {
    const [removeRecord] = useDeleteMutation();
    const { user, siteId: portalId } = useAuth()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const deleteRecord = (systemId: string) => {
        if (!user)
            return Promise.reject('');

        const { userId } = user;
        if (!portalId)
            return Promise.reject('');;

        setIsSuccess(false)
        setIsError(false)
        setError(undefined)
        setIsLoading(true)

        const { tableId } = EntityDocumentChecklistItemMetadata

        return removeRecord({ portalId, userId, sysId: systemId, tableId: tableId, triggers: true })
            .unwrap()
            .then((p) => {
                setIsSuccess(true)
                return
            })
            .catch(err => {
                setIsError(true)
                setError(err.data?.error?.message || 'Failed to delete the record')
                return Promise.reject(err)
            })
            .finally(() => setIsLoading(false))
    }

    return { isLoading, isSuccess, isError, error, deleteRecord }
}
