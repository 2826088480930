import { ISPListItem, useDeleteFolder } from "../../../../services/data/sharepoint";
import { MUIIcon, Spinner } from "../../../../widgets";

import clsx from "clsx";
import { toAbsoluteUrl } from "../../../../../helpers";

export const ViewSPFolder = ({ listItem, className, onOpen }: {
    listItem: ISPListItem,
    className?: string,
    onOpen?: () => void,
}) => {
    const { deleteFolder, isLoading: isDeleting } = useDeleteFolder()
    const onClickDelete = () => {
        deleteFolder(listItem.serverRelativeUrl)
    }

    return (
        <>
            <div className={clsx(className, 'card card-body border p-0')}>
                <div className={clsx(
                    'd-flex align-items-center',
                    'cursor-pointer')
                }>
                    <div className='d-flex align-items-center align-self-stretch px-5 bg-light rounded-start'>
                        <span className='symbol symbol-35px'>
                            <img src={toAbsoluteUrl('/media/svg/files/folder-document.svg')} alt='' />
                        </span>
                    </div>
                    <div className='d-flex flex-column flex-fill px-3 p-2'>
                        <button
                            type="button"
                            className="btn btn-link p-0 w-150px w-175px text-truncate text-start"
                            onClick={onOpen}
                            title={listItem.name}>
                            <span className='fw-bolder fs-7 text-hover-primary'>{listItem.name}</span>
                        </button>
                        <div className="my-1">
                            <span className="fs-9 fw-semibold text-gray-600">
                                <MUIIcon className="fs-6 me-1" iconName="FlagOutlined" />
                                Folder
                            </span>
                        </div>
                    </div>
                    <div className='d-flex align-self-start p-2 '>
                        <button
                            disabled={isDeleting}
                            className='btn btn-sm p-0 text-hover-primary'
                            title="Delete folder"
                            onClick={onClickDelete}>
                            <Spinner show={isDeleting} />
                            {(!isDeleting) && <MUIIcon
                                className="fs-6 fw-bold"
                                iconName="CloseOutlined" />}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
