import { IAttributeOption } from "../../../models"
import React from "react"
import clsx from "clsx"

export const OptionSetAttribute = ({
    value,
    required,
    placeholder,
    options,
    readOnly,
    onChange,
    onBlur,
    className,
}: {
    value?: string,
    required?: boolean,
    placeholder?: string,
    className?: string,
    options?: IAttributeOption[],
    readOnly?: boolean,
    onChange: (value: any) => void,
    onBlur: () => void
}) => {
    return <>
        <select
            required={required}
            disabled={readOnly}
            placeholder={placeholder}
            value={value || ''}
            onBlur={() => onBlur()}
            onChange={(e) => onChange(e.target.value)}
            className={clsx(className, 'form-select form-select-sm')}>
            <option></option>
            {options?.map(option => <React.Fragment key={option.id}>
                <option value={option.value}>{option.name}</option>
            </React.Fragment>)}
        </select>
    </>
}
