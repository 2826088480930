import { useUploadFileLargeMutation } from "../sharePointFilesApi";

export const useUploadDocument = () => {
    const [uploadFileLarge] = useUploadFileLargeMutation();
    const uploadDocument = ({ file, folderRelativeUrl }: {
        file: File, folderRelativeUrl: string
    }) => {
        const formData = new FormData();
        formData.append(file.name, file)
        return uploadFileLarge({
            fileName: file.name,
            data: formData,
            overwrite: true,
            folderRelativeUrl
        }).unwrap()
    }

    return { uploadDocument }
}