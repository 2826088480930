import { AlertMessage, Spinner, When } from '../../../../widgets';
import React, { useEffect, useState } from 'react'

import { BreadCrump } from './BreadCrump';
import { SPUploadDocument } from './SPUploadDocument';
import { ViewSPFile } from './ViewSPFile';
import { ViewSPFolder } from './ViewSPFolder';
import clsx from 'clsx';
import { useLazyGetFolderItemsQuery } from '../../../../services/data/sharepoint';

export const SPListItems = ({ relativePath, createFolder, allowDelete, allowUpload, allowSelect, className, onSelect }: {
    relativePath: string,
    createFolder?: boolean,
    allowDelete?: boolean,
    allowUpload?: boolean,
    allowSelect?: boolean,
    className?: string,
    onSelect?: (path: string) => void
}) => {
    const [getFolderItems, { data: listItems, isLoading, isFetching, isSuccess, isError }] = useLazyGetFolderItemsQuery();

    const [path, setPath] = useState<string[]>([])
    const [folderPath, setFolderPath] = useState<string>('')

    useEffect(() => {
        const sitePath = relativePath.endsWith("/") ?
            relativePath.substring(0, relativePath.length - 1) :
            relativePath;

        const folderPath = [sitePath, ...path].join("/")
        setFolderPath(folderPath)
        getFolderItems({ createFolder: createFolder === true, folderRelativeUrl: folderPath })
        // eslint-disable-next-line
    }, [relativePath, path])

    return (
        <div className={clsx(className)}>
            <div className="d-flex">
                <BreadCrump
                    path={path}

                    setPath={(path) => setPath(path)} />
                <div className="ms-auto mb-1">
                    <button
                        onClick={() => {
                            getFolderItems({ createFolder: true, folderRelativeUrl: folderPath })
                        }}
                        disabled={isLoading || isFetching}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 py-0'>
                        {(isLoading || isFetching) ?
                            <Spinner show={isLoading || isFetching} /> :
                            <i className="las la-redo-alt fs-2"></i>}
                    </button>
                </div>
            </div>
            <div className="card-body pt-0 px-0 py-3">
                <When condition={isSuccess}>
                    <div className='d-flex flex-wrap mt-5'>
                        {listItems?.map(listItem => (
                            <div className='me-3 mb-3' key={listItem.uniqueId}>
                                {(listItem.type === "Folder") ?
                                    <ViewSPFolder
                                        listItem={listItem}
                                        onOpen={() => setPath([...path, listItem.name])} /> :
                                    <ViewSPFile
                                        listItem={listItem}
                                        allowSelect={allowSelect}
                                        onSelect={onSelect}
                                        allowDelete={allowDelete}
                                    />}
                            </div>
                        ))}
                    </div>
                    {allowUpload && <div className='my-2'>
                        <SPUploadDocument
                            folderRelativeUrl={folderPath} />
                    </div>}
                </When>
                <AlertMessage type='Error' className='my-1' show={isError} message={'Failed to fetch documents from the server'} />
            </div>
        </div>
    )
}