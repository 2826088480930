import { getSpacingClasses, textStyleClasses } from "../../../base/styles/helpers";

import Accordion from "react-bootstrap/esm/Accordion";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useGetProperties } from "../../../base/hooks";

export const AccordionItemComponent = ({ control, className, children }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const title: string | undefined = properties[Property.Heading];

    const spacingClasses = getSpacingClasses(control.styles?.spacing);

    const style = {
        "--bs-accordion-btn-bg": control.styles?.background?.color || "var(--kt-accordion-button-bg)",
        "--bs-accordion-active-bg": control.styles?.background?.color || "var(--kt-accordion-button-active-bg)",
        "--bs-accordion-active-color": "--",
        "--bs-accordion-btn-padding": spacingClasses || "1.5rem",
        "--bs-accordion-btn-padding-x": spacingClasses || "1.5rem",
        "--bs-accordion-btn-padding-y": spacingClasses || "1.5rem",
    } as React.CSSProperties;

    const textClasses = textStyleClasses(control.styles?.text)

    return (
        <Accordion.Item eventKey={control.controlId}>
            <Accordion.Header className={className} style={style}>
                <span className={textClasses}>{title}</span>
            </Accordion.Header>
            <Accordion.Body>
                {children}
            </Accordion.Body>
        </Accordion.Item>)
}

