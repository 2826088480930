import { HTMLInputTypeAttribute, useEffect } from "react";

import { BooleanBinding } from "../../../base/bindings/boolean";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import clsx from "clsx";
import { useDesignContext } from "../../../providers";
import { useFromData } from "../panels/FormComponent";
import { useGetProperties } from "../../../base/hooks";
import { useState } from "react";

type Props = { type: HTMLInputTypeAttribute }
export const CheckboxComponent = ({ control, type, className }: IControlProps & Props) => {
    const { mode } = useDesignContext()
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const inputValue: boolean | undefined = properties[Property.ControlValue];
    const required: boolean = properties[Property.Required] || false;
    const readonly: boolean = properties[Property.Readonly] || false;
    const disabled: boolean = properties[Property.Disabled] || false;

    const [binding, setBinding] = useState<BooleanBinding>()
    const [value, setValue] = useState<boolean>()
    const { form, values, setFieldValue } = useFromData()

    useEffect(() => {
        const binding = getBinding(Property.ControlValue) as BooleanBinding
        if (!binding)
            return;

        setBinding(binding)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (mode !== "Preview")
            return;

        if (inputValue !== undefined)
            setValue(inputValue)
        // eslint-disable-next-line
    }, [inputValue])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field")
            return;

        if (values[binding.value] !== undefined)
            setValue(values[binding.value])
        // eslint-disable-next-line
    }, [values])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field")
            return;

        if (value !== undefined)
            setFieldValue(binding.value, value)
        // eslint-disable-next-line
    }, [value])

    const onCheck = (value: boolean) => {
        setValue(value)
        // eslint-disable-next-line
    }

    return <div className={clsx(className, "form-check")}>
        {label &&
            <label
                title={tooltip}
                className={clsx("form-check-label text-gray-600 fs-7", { "required": required })}
                htmlFor={control.controlId}>
                {label}
            </label>}

        <input
            onChange={(e) => onCheck(e.target.checked)}
            readOnly={readonly}
            disabled={disabled || binding?.type !== "Field"}
            required={required}
            type={type}
            id={control.controlId}
            title={tooltip}
            checked={value === true}
            className="form-check-input" />
    </div>
}