import { Property, PropertyType } from "../../../../../base/types";

import { IProperty } from "../../../../../base/models";

export const PieChartProperties: IProperty[] = [
    { id: Property.Donut, name: 'Donut', type: PropertyType.Boolean },
    { id: Property.ShowToolbar, name: 'Show Toolbar', type: PropertyType.Boolean },
    { id: Property.ShowLabels, name: 'Show Labels', type: PropertyType.Boolean },
    { id: Property.LabelsField, name: 'Labels Field', type: PropertyType.String },
    { id: Property.ValuesField, name: 'Values Field', type: PropertyType.String },
    { id: Property.UnknownLabel, name: 'Unknown Label', type: PropertyType.String },
    { id: Property.AggregateMethod, name: 'Method', type: PropertyType.AggregateMethod },
    { id: Property.Monochrome, name: 'Monochrome', type: PropertyType.Boolean },
    { id: Property.Gradient, name: 'Gradient', type: PropertyType.Boolean },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },    
    { id: Property.Height, name: 'Height', type: PropertyType.Number },
    { id: Property.ColorMap, name: 'Color Map', type: PropertyType.String },
]
