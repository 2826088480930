import { IEntityFilter, ITableLookup } from "../../../base/models"
import { useEffect, useState } from "react"

import { EditTableFilters } from "../table-filters"
import { IDataSource } from "../../../base/types"
import Modal from "react-bootstrap/esm/Modal"
import { SelectEntity } from "../SelectEntity"
import { SelectEntityAttribute } from "../SelectEntityAttribute"

export const EditTableLookup = ({ dataSource, tableLookup, className, onChange }: {
    tableLookup: ITableLookup,
    dataSource?: IDataSource,
    className?: string,
    onChange: (tableLookup: ITableLookup) => void,
}) => {
    const { tableId: entity, idField, displayField, filters } = tableLookup;
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [tableFilters, setTableFilters] = useState<IEntityFilter[]>(filters || [])

    const handleOnPageChange = (entity?: string) => {
        onChange({ ...tableLookup, tableId: entity });
    }

    const handleOnFiltersChange = (filters: IEntityFilter[]) => {
        setTableFilters(filters)
        onChange({ ...tableLookup, filters });
    }

    const hideModal = () => {
        setShowDialog(false);
    }

    return <div className={'p-2'}>
        <div className="">
            <div className="field-group">
                <label className="fs-8 fw-bold text-gray-600 flex-fill">Table</label>
                <SelectEntity value={entity} onChange={handleOnPageChange} />
            </div>

            {entity &&
                <>
                    <div className="field-group mt-3">
                        <label className="fs-8 fw-bold text-gray-600 flex-fill">Value Field</label>
                        <SelectEntityAttribute entity={entity} value={idField} onChange={(value) => {
                            onChange({ ...tableLookup, idField: value });
                        }} />
                    </div>
                    <div className="field-group mt-3">
                        <label className="fs-8 fw-bold text-gray-600 flex-fill">Display Field</label>
                        <SelectEntityAttribute entity={entity} value={displayField} onChange={(value) => {
                            onChange({ ...tableLookup, displayField: value });
                        }} />
                    </div>
                    <div className="fs-8 mt-3">
                        <div className="d-flex align-items-center mb-3">
                            <p className="flex-fill fw-bold text-gray-600 fs-8 mb-1">Filters</p>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm py-2"
                                onClick={() => setShowDialog(true)}>
                                Edit Filters
                            </button>
                        </div>
                        {tableFilters.map((item, i) => <p key={i} className="m-0">
                            <span className="fw-semibold text-primary">{item.field}</span> =
                            <span className="fw-bold ms-2">{item.type}</span>
                            (<span className="fw-semibold text-primary">{item.value}</span>)</p>)}
                    </div>

                    <TableFiltersModel
                        hideModal={hideModal}
                        show={showDialog}
                        filters={tableFilters}
                        entity={entity}
                        dataSource={dataSource}
                        onChange={handleOnFiltersChange}
                    />
                </>
            }
        </div>
    </div>
}

const TableFiltersModel = ({ entity, dataSource, filters, show, hideModal, onChange }: {
    entity: string,
    dataSource?: IDataSource,
    filters: IEntityFilter[],
    show: boolean,
    hideModal: () => void,
    onChange: (filters: IEntityFilter[]) => void
}) => {
    const [localFilters, setLocalFilters] = useState<IEntityFilter[]>([...filters]);

    useEffect(() => {
        setLocalFilters(filters)
    }, [entity, filters, show])

    const handleChanges = (filters: IEntityFilter[]) => {
        setLocalFilters(filters)
    }

    return <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
        <Modal.Header closeButton className="bg-secondary border-0 py-5">
            <h4 className="my-0">Table Filters</h4>
        </Modal.Header>
        <Modal.Body>
            <EditTableFilters
                filters={localFilters}
                entity={entity}
                dataSource={dataSource}
                onChange={handleChanges} />
        </Modal.Body>
        <Modal.Footer className="border-0 py-5">
            <button type="button"
                onClick={() => {
                    onChange(localFilters)
                    hideModal()
                }}
                className="btn btn-sm btn-primary">OK</button>
            <button type="button"
                onClick={hideModal}
                className="btn btn-sm btn-secondary">Cancel</button>
        </Modal.Footer>
    </Modal>
}
