import { ActionProperties } from "../../../page-action"
import { ControlProperties } from "../../../page-control/property-panel/ControlProperties"
import { DesignEvent } from "../../../../base/models"
import { IPage } from "../../models"
import clsx from "clsx"
import { useDesignContext } from "../../../../providers"

export const PropertiesPanel = ({ page, className, onDesignChange }: {
    page: IPage,
    className?: string,
    onDesignChange: (event: DesignEvent) => void
}) => {
    const { context } = useDesignContext()

    if (!context)
        return <></>

    return (
        <div className={clsx("control-properties", className)}>
            {(context.type === "Control") && <>
                <ControlProperties
                    dataSource={context.dataSource}
                    control={context.control}
                    onDesignChange={onDesignChange} />
            </>}
            {(context.type === "Action") && <>
                <ActionProperties
                    dataSource={context.dataSource}
                    action={context.action}
                    onDesignChange={onDesignChange} />
            </>}
        </div>
    )
}
