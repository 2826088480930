import { ApiTags, rootApi } from "../../../../services/rootApi";

import { IFeedback } from "../models/IFeedback.model";

const TAG = ApiTags.Feedback;
interface IFeedbackQuery {
    entity: string,
    entityId: string,
    formName: string,
    userId: string,
}

const feedbackApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getFeedback: builder.query<IFeedback, IFeedbackQuery>({
            query: (request) => ({
                url: `/Feedbacks`,
                headers: { 'info-query': JSON.stringify(request) }
            }),
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
        }),
        createFeedback: builder.mutation<IFeedback, Partial<IFeedback>>({
            query: (feedback) => ({
                url: "/Feedbacks",
                method: "POST",
                body: feedback
            }),
            invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
        }),
    })
})

export const {
    useGetFeedbackQuery,
    useLazyGetFeedbackQuery,
    useCreateFeedbackMutation,
} = feedbackApi;