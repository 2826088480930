import { ApiTags, rootApi } from '../../rootApi';

import { IDataAttribute } from '../../../base/models';

const TAG = ApiTags.DataAttribute;
const getTags = (entities: IDataAttribute[]) => [...entities.map((p) => ({ type: TAG, id: p.attribute })), TAG]

const entityAttributesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    readAttributes: builder.query<IDataAttribute[], {siteId: string, entity: string}>({
      query: ({siteId, entity}) => `/${siteId}/EntityAttributes/entity(${entity})`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
  })
})

export const {
  useLazyReadAttributesQuery,
  useReadAttributesQuery
} = entityAttributesApi;