import * as Yup from 'yup'

import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { Spinner } from '../../../framework/widgets'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useRequestPasswordMutation } from '../../../framework/services/auth'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [requestPassword] = useRequestPasswordMutation()
  const [linkSent, setLinkSent] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus('')
      setLoading(true)
      requestPassword(values.email)
        .unwrap()
        .then(() => setLinkSent(true))
        .catch((err) => {
          setStatus(err.data || 'The login details are incorrect')
        })
        .finally(() => {
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  return (
    <form
      className='form px-20 pt-10'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-3'>
        <h4 className='text-dark mb-1'>Forgot Password ?</h4>
        <div className='text-gray-700 fw-semibold fs-8'>
          Enter your email to reset your password.
        </div>
      </div>
      <div className='row mw-250px gx-10 gy-10'>
        <div className='col-12 d-xl-none mt-20' />
        <div>

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {linkSent && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Password reset link sent. Please check your email</div>
            </div>
          )}

          {!linkSent && <>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bold fs-7 text-dark'>Email</label>
              <input
                type='email'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-sm form-control-solid',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  { 'is-valid': formik.touched.email && !formik.errors.email, }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex pb-lg-0'>
              <button
                type='submit'
                disabled={loading}
                id='kt_password_reset_submit'
                className='btn btn-sm btn-primary fw-bolder me-4'
              >
                {loading && <Spinner className='me-2' />}
                <span className='indicator-label'>Submit</span>
              </button>

              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-sm btn-light-primary fw-bolder'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>
            </div>
          </>}
        </div>
      </div>
    </form>
  )
}
