/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC } from 'react'
import { useLayout } from '../../core'

const Footer: FC = () => {
  const { classes } = useLayout()

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-end`}
      >
        {/* begin::Copyright */}
        <div className='text-dark fw-semibold fs-8'>
          <span className='text-muted me-1'>Powered by</span>
          <a
            href='https://www.alletec.com/'
            target='_blank'
            rel="noreferrer"
            className='text-gray-800 text-hover-primary'>
            All e Technologies Ltd.
          </a>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
