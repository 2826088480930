import { AlertMessage, ProgressIndicator } from "../../widgets";
import { useEffect, useState } from "react";

import { CustomComponentProperties } from "./components";
import { DesignContextProvider } from "../../providers";
import { ICustomComponent } from "./models";
import { IDataAttribute } from "../../base/models";
import { LayoutControlCollection } from "../page-control";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";
import clsx from "clsx";
import { useDataContext } from "../page/context";
import { useDesignerToolbarActions } from "./hooks";

export const EditCustomComponent = ({ component, className }: {
    component: ICustomComponent,
    className?: string,
}) => {
    const [localComponent, setComponent] = useState<ICustomComponent>(component)

    return (
        <DesignContextProvider>
            <DesignContextProducer component={localComponent} />
            <ComponentEditorToolbar component={localComponent} />
            <Tabs defaultActiveKey="general" className="fw-semibold fs-7">
                <Tab eventKey="general" title="General" className="border p-3">
                    <div className="m-2 row">
                        <div className="mb-2 col-xl-6">
                            <label className="fs-8 fw-bold text-gray-600">Name</label>
                            <input type="text"
                                className="form-control form-control-xs"
                                onChange={(e) => setComponent({ ...localComponent, name: e.target.value })}
                                value={localComponent.name} />
                        </div>

                        <CustomComponentProperties
                            props={localComponent.props || []}
                            onChange={(props) => setComponent({ ...localComponent, props: props })} />
                    </div>
                </Tab>
                <Tab eventKey="layout" title="Component Layout" className="border p-3">
                    <div className="page-designer d-flex mt-3">
                        <div className={clsx("flex-fill", className)}>
                            <LayoutControlCollection
                                onDesignChange={() => { }}
                                controls={localComponent?.controls || []}
                            />
                        </div>
                        <div>
                            {/* <PropertiesPanel onDesignChange={() => { }} /> */}
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </DesignContextProvider>)
}

export const DesignContextProducer = ({ component }: { component: ICustomComponent }) => {
    const { setDataSource } = useDataContext();

    useEffect(() => {
        const { props } = component
        if (!props) return

        const fields = props.map<IDataAttribute>(p => ({
            id: p.id,
            attribute: p.name,
            displayName: p.name,
            type: p.type
        }))

        setDataSource({ entity: '', attributes: fields })
        // eslint-disable-next-line
    }, [])

    return <></>
}

export const ComponentEditorToolbar = ({ component }: { component: ICustomComponent }) => {
    const { isLoading, isError, error } = useDesignerToolbarActions({ component })

    return <>
        <ProgressIndicator show={isLoading} className="mb-2" message="Please wait ..." />
        <AlertMessage
            show={isError}
            type="Error"
            message={(error as any)?.data?.error?.message || 'Failed to save component changes'} />
    </>
}
