import { IFileUploadQuestion } from '../models/feedback-form.model';
import clsx from 'clsx';

const FileUploadQuestion = ({ question, value, onChange, onBlur }: {
    question: IFileUploadQuestion;
    value?: string,
    onChange: (value: string) => void,
    onBlur: () => void
}) => {
    return (
        <div className="form-group">
            <label className={clsx("form-label", { required: question.isRequired })}>{question.title}</label>
            <input
                type="file"
                className="form-control form-control-sm"
                required={question.isRequired}
                value={value || ''}
                onBlur={() => onBlur()}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
};

export default FileUploadQuestion;
