import { ButtonStyle, ControlSize } from "../../../base/types";

import clsx from "clsx";

const buttonStyleClass = (style?: ButtonStyle) => {
    switch (style) {
        case ButtonStyle.None: return "";
        case ButtonStyle.Primary: return "btn-primary";
        case ButtonStyle.Secondary: return "btn-secondary";
        case ButtonStyle.Success: return "btn-success";
        case ButtonStyle.Danger: return "btn-danger";
        case ButtonStyle.Warning: return "btn-warning";
        case ButtonStyle.Info: return "btn-info";
        case ButtonStyle.Light: return "btn-light";
        case ButtonStyle.Dark: return "btn-dark";
        case ButtonStyle.Link: return "btn-link";
        case ButtonStyle.OutlinePrimary: return "btn-outline-primary";
        case ButtonStyle.OutlineSecondary: return "btn-outline-secondary";
        case ButtonStyle.OutlineSuccess: return "btn-outline-success";
        case ButtonStyle.OutlineDanger: return "btn-outline-danger";
        case ButtonStyle.OutlineWarning: return "btn-outline-warning";
        case ButtonStyle.OutlineInfo: return "btn-outline-info";
        case ButtonStyle.OutlineLight: return "btn-outline-light";
        case ButtonStyle.OutlineDark: return "btn-outline-dark";
    }
}

const buttonSizeClass = (size?: ControlSize) => {
    switch (size) {
        case ControlSize.Default: return "";
        case ControlSize.Large: return "btn-lg";
        case ControlSize.Medium: return "btn-md";
        case ControlSize.Small: return "btn-sm";
    }
}

export const getButtonClass = (style?: ButtonStyle, size?: ControlSize, defaultClass?: string) => {
    const btnCls = clsx(buttonStyleClass(style), buttonSizeClass(size))
    return clsx("btn", btnCls || defaultClass)
}

