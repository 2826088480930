import { SizeUnit } from "../../../../base/styles";
import clsx from "clsx";

export const SelectSizeUnit = ({ title, value, className, onChange }:{
    title: string,
    value?: SizeUnit,
    className?: string,
    onChange: (value?: SizeUnit) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as SizeUnit;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={SizeUnit.Auto}>Auto</option>
                <option value={SizeUnit.S25}>25 %</option>
                <option value={SizeUnit.S50}>50 %</option>
                <option value={SizeUnit.S75}>75 %</option>
                <option value={SizeUnit.S100}>100 %</option>
            </select>
        </div>
    )
}