import { TextAlignment } from "../../../../../base/styles";
import clsx from "clsx";

export const SelectTextAlignment = ({ title, value, className, onChange }:{
    title: string,
    value?: TextAlignment,
    className?: string,
    onChange: (value?: TextAlignment) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as TextAlignment;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={TextAlignment.Start}>Start</option>
                <option value={TextAlignment.Center}>Center</option>
                <option value={TextAlignment.End}>End</option>
            </select>
        </div>
    )
}