import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'

import { ChangePassword } from '../modules/auth/components/ChangePassword'
import { MasterLayout } from '../layout/MasterLayout'
import { ProfilePageWrapper } from '../pages/ProfilePageWrapper'
import { SiteRoutes } from './SiteRoutes'
import { SiteSelectionWrapper } from '../pages/SiteSelectionWrapper'
import { UsersWrapper } from '../pages/UsersWrapper'
import { useAuth } from '../modules/auth'

const PrivateRoutes = () => {
  const { siteId } = useAuth()

  const NavigateLandingPage = () => {
    const [searchParams] = useSearchParams();
    const next = searchParams.get("next");

    if (siteId)
      return <Navigate to={next || `/site(${siteId})/dashboard`} />
    else
      return <Navigate to={next || `/sites`} />
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<NavigateLandingPage />} />
        {/* Pages */}
        <Route path='sites' element={<SiteSelectionWrapper />} />
        <Route path='users' element={<UsersWrapper />} />
        {/* <Route path='site-settings(:siteId)' element={<SiteSettingsWrapper />} /> */}
        <Route path='profile' element={<ProfilePageWrapper />} />
        <Route path='change-password' element={<ChangePassword />} />
        <Route path='site(:siteId)/*' element={<SiteRoutes />} />

        {siteId ?
          <Route index element={<Navigate to={`/site(${siteId})/dashboard`} />} /> :
          <Route index element={<Navigate to="/sites" />} />
        }
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }

