import { ApiTags, rootApi } from "../rootApi";

import { ApiResponse } from "../models";
import { IPage } from "../../design/page/models";
import { migratePageMetadata } from "../../design/page/upgrade";
import { resetPageSequenceNos } from "../../design/page/helpers";

const TAG = ApiTags.PageMetadata;
const PageTag = ApiTags.Page;

const pagesMetadataApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getPageMetadata: builder.query<IPage, string>({
            query: (pageId) => `/PageMetadata/pages(${pageId})`,
            transformResponse: (res: ApiResponse, meta, pageId) => {
                const page = JSON.parse(res.value) as IPage;
                return migratePageMetadata(page);
            },
            providesTags: (result) => result ? [
                { type: TAG, id: result.id },
                { type: PageTag, id: result.id }, TAG] : [TAG]
        }),

        updatePageMetadata: builder.mutation<IPage, IPage>({
            query: (page) => {
                const pageCopy = resetPageSequenceNos(page);
                const request = { id: page.id, metadata: JSON.stringify(pageCopy) }

                return {
                    url: `/PageMetadata/pages(${page.id})`,
                    method: 'PATCH',
                    body: request
                }
            },
            invalidatesTags: (result) => result ? [
                { type: TAG, id: result.id },
                { type: PageTag, id: result.id }, TAG] : [TAG]
        }),
    })
});

export const {
    useGetPageMetadataQuery,
    useLazyGetPageMetadataQuery,
    useUpdatePageMetadataMutation,
} = pagesMetadataApi;
