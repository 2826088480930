declare global {
    interface Window {
        Paytm: any;
    }
}

const loadScript = (FILE_URL: string, async = true, type = "text/javascript") => {
    return new Promise((resolve, reject) => {
        try {
            const scriptEle = document.createElement("script");
            scriptEle.type = type;
            scriptEle.async = async;
            scriptEle.src = FILE_URL;

            scriptEle.addEventListener("load", (ev) => {
                resolve({ status: true });
            });

            scriptEle.addEventListener("error", (ev) => {
                reject({
                    status: false,
                    message: `Failed to load the script ＄{FILE_URL}`
                });
            });

            document.body.appendChild(scriptEle);
        } catch (error) {
            reject(error);
        }
    });
};

export const invokePaymentPage = ({ scriptInfo, data }: {
    scriptInfo: { host: string, mid: string },
    data: {
        "orderId": string, /* update order id */
        "token": string, /* update token value */
        "tokenType": "TXN_TOKEN",
        "amount": string /* update amount */
    }
}) => {
    const url = `${scriptInfo.host}/merchantpgpui/checkoutjs/merchants/${scriptInfo.mid}.js`;
    
    return loadScript(url)
        .then(() => {
            if (!window.Paytm || !window.Paytm.CheckoutJS) {
                return Promise.reject("Paytm CheckoutJS not loaded.")
            }

            return window.Paytm.CheckoutJS.onLoad(() => {
                var config = {
                    "root": "",
                    "flow": "DEFAULT",
                    "data": data,
                    "handler": {
                        "notifyMerchant": function (eventName: string, data: any) {
                        }
                    }
                };

                return window.Paytm.CheckoutJS.init(config)
                    .then(() => {
                        window.Paytm.CheckoutJS.invoke()
                        return Promise.resolve()
                    })
                    .catch((err: any) => {
                        return Promise.reject(err)
                    })
            });
        })
        .catch(() => Promise.reject("Paytm CheckoutJS not loaded."));
}