import { AccordionComponent, AccordionItemComponent } from "../components/accordion";
import { ActionButtonComponent, ButtonComponent, DropdownButtonComponent, ResetDataComponent, SubmitButtonComponent } from "../components/buttons";
import { BadgeComponent, EnumLabelComponent, HeadingComponent, LabelComponent, NormalTextComponent, ParagraphComponent } from "../components/text";
import { CardPartPageComponent, DropdownPageComponent, ListPartPageComponent } from "../components/part-pages";
import { CheckboxComponent, DropdownComponent, HiddenInputComponent, InputComponent, NameLookupComponent, RadioGroupComponent, TextAreaComponent } from "../components/inputs";
import { FormComponent, PanelComponent, SectionComponent } from "../components/panels";
import { GoogleMapsComponent, ImageComponent, MediaComponent, TwitterComponent, VideoComponent, YouTubeComponent } from "../components";
import { HyperLinkComponent, ListNavigationComponent, OffcanvasComponent, RecordNavigationComponent } from "../components/links";
import { NTTButtonComponent, PayUButtonComponent, PaytmButtonComponent } from "../components/pg-integration";
import { RepeaterColumn, RepeaterComponent } from "../components/repeater";

import { AvatarTextComponent } from "../components/avatar/AvatarTextComponent";
import { Base64ImageComponent } from "../components/media/Base64ImageComponent";
import { ChatComponent } from "../components/chat";
import { ComponentType } from "../../base/types";
import { CustomComponent } from "../components/custom-component";
import { DataLinkComponent } from "../components/data-link";
import { DocumentChecklistComponent } from "../components/document-checklist/DocumentChecklistComponent";
import { EntityDocumentChecklistComponent } from "../components/document-checklist/EntityDocumentChecklistComponent";
import ErrorBoundary from "../../widgets/ErrorBoundary";
import { FeedbackComponent } from "../components/feedback/FeedbackComponent";
import { GridComponent } from "../components/grid/GridComponent";
import { HTMLViewerComponent } from "../components/text/HTMLViewerComponent";
import { IControlProps } from "../../base/models";
import { ListComponent } from "../components/lists";
import { LookupComponent } from "../components/inputs/LookupComponent";
import { MaterialIconComponent } from "../components/icons";
import { PieChartComponent } from "../components/carts";
import { QRCodeComponent } from "../components/qr-scanner/QRCodeComponent";
import { QRScannerComponent } from "../components/qr-scanner/QRScannerComponent";
import { RazorPayButtonComponent } from "../components/pg-integration/RazorPayButtonComponent";
import { SPDocumentsComponent } from "../components/sharepoint-documents";
import { ServiceRequestComponent } from "../components/services";
import { ServicesComponent } from "../components/services/ServicesComponent";
import { StackedBarsComponent } from "../components/carts/stacked-bars/StackedBarsComponent";
import { TimelineComponent } from "../components/timeline";
import { memo } from "react";

export const PageControl = memo(({ control, additionalProps, className, orientation: layout, children, onDesignChange }: IControlProps) => {
    const getControl = () => {
        const props: IControlProps = {
            control,
            additionalProps,
            className,
            orientation: layout,
            children,
            onDesignChange,
        }

        const { componentType } = control
        switch (componentType) {
            case ComponentType.Custom:
                return <CustomComponent {...props} />
            case ComponentType.Form:
                return <FormComponent {...props} />
            case ComponentType.Accordion:
                return <AccordionComponent {...props} />
            case ComponentType.AccordionItem:
                return <AccordionItemComponent {...props} />
            case ComponentType.TextArea:
                return <TextAreaComponent {...props} />
            case ComponentType.TextBox:
                return <InputComponent {...props} type="text" />
            case ComponentType.NumberInput:
                return <InputComponent {...props} type="number" />
            case ComponentType.Password:
                return <InputComponent {...props} type="password" />
            case ComponentType.DateTime:
                return <InputComponent {...props} type="datetime-local" />
            case ComponentType.Date:
                return <InputComponent {...props} type="date" />
            case ComponentType.Time:
                return <InputComponent {...props} type="time" />
            case ComponentType.FileInput:
                return <InputComponent {...props} type="file" />
            case ComponentType.Checkbox:
                return <CheckboxComponent {...props} type="checkbox" />
            case ComponentType.Radio:
                return <RadioGroupComponent {...props} />
            case ComponentType.Dropdown:
                return <DropdownComponent {...props} />
            case ComponentType.Lookup:
                return <LookupComponent {...props} />
            case ComponentType.Label:
                return <LabelComponent {...props} />
            case ComponentType.Badge:
                return <BadgeComponent {...props} />
            case ComponentType.Heading1:
                return <HeadingComponent as="h1" {...props} />
            case ComponentType.Heading2:
                return <HeadingComponent as="h2" {...props} />
            case ComponentType.Heading3:
                return <HeadingComponent as="h3" {...props} />
            case ComponentType.Heading4:
                return <HeadingComponent as="h4" {...props} />
            case ComponentType.Heading5:
                return <HeadingComponent as="h5" {...props} />
            case ComponentType.Heading6:
                return <HeadingComponent as="h6" {...props} />
            case ComponentType.Paragraph:
                return <ParagraphComponent {...props} />
            case ComponentType.NormalText:
                return <NormalTextComponent {...props} />
            case ComponentType.Hyperlink:
                return <HyperLinkComponent {...props} />
            case ComponentType.CardNavigation:
                return <RecordNavigationComponent {...props} />
            case ComponentType.ListNavigation:
                return <ListNavigationComponent {...props} />
            case ComponentType.Offcanvas:
                return <OffcanvasComponent {...props} />
            case ComponentType.Image:
                return <ImageComponent {...props} />
            case ComponentType.Video:
                return <VideoComponent {...props} />
            case ComponentType.Media:
                return <MediaComponent {...props} />
            case ComponentType.Button:
                return <ButtonComponent {...props} />
            case ComponentType.SubmitButton:
                return <SubmitButtonComponent {...props} />
            case ComponentType.ActionButton:
                return <ActionButtonComponent {...props} />
            case ComponentType.DropdownButton:
                return <DropdownButtonComponent {...props} />
            case ComponentType.Section:
                return <SectionComponent {...props} />
            case ComponentType.Panel:
                return <PanelComponent {...props} />
            case ComponentType.List:
                return <ListComponent {...props} />
            case ComponentType.Repeater:
                return <RepeaterComponent  {...props} />
            case ComponentType.RepeaterColumn:
                return <RepeaterColumn {...props} />
            case ComponentType.GoogleMaps:
                return <GoogleMapsComponent {...props} />
            case ComponentType.YouTube:
                return <YouTubeComponent {...props} />
            case ComponentType.Twitter:
                return <TwitterComponent {...props} />
            case ComponentType.Chat:
                return <ChatComponent {...props} />
            case ComponentType.SharePointDocuments:
                return <SPDocumentsComponent {...props} />
            case ComponentType.AvatarText:
                return <AvatarTextComponent {...props} />
            case ComponentType.MaterialIcon:
                return <MaterialIconComponent {...props} />
            case ComponentType.DropdownPage:
                return <DropdownPageComponent {...props} />
            case ComponentType.ListPart:
                return <ListPartPageComponent {...props} />
            case ComponentType.CardPart:
                return <CardPartPageComponent {...props} />
            case ComponentType.EntityDocumentChecklist:
                return <EntityDocumentChecklistComponent {...props} />
            case ComponentType.DocumentChecklist:
                return <DocumentChecklistComponent {...props} />
            case ComponentType.ResetData:
                return <ResetDataComponent {...props} />
            case ComponentType.Services:
                return <ServicesComponent {...props} />
            case ComponentType.PieChart:
                return <PieChartComponent {...props} />
            case ComponentType.StackedBars:
                return <StackedBarsComponent {...props} />
            case ComponentType.DataLink:
                return <DataLinkComponent {...props} />
            case ComponentType.Grid:
                return <GridComponent {...props} />
            case ComponentType.ServiceRequest:
                return <ServiceRequestComponent {...props} />
            case ComponentType.PayUButton:
                return <PayUButtonComponent {...props} />
            case ComponentType.PaytmButton:
                return <PaytmButtonComponent {...props} />
            case ComponentType.RazorPayButton:
                return <RazorPayButtonComponent {...props} />
            case ComponentType.NTTPayButton:
                return <NTTButtonComponent {...props} />
            case ComponentType.NameLookup:
                return <NameLookupComponent {...props} />
            case ComponentType.HTMLViewer:
                return <HTMLViewerComponent {...props} />
            case ComponentType.EnumLabel:
                return <EnumLabelComponent {...props} />
            case ComponentType.Timeline:
                return <TimelineComponent {...props} />
            case ComponentType.HiddenInput:
                return <HiddenInputComponent {...props} />
            case ComponentType.Base64Image:
                return <Base64ImageComponent {...props} />
            case ComponentType.QRScanner:
                return <QRScannerComponent {...props} />
            case ComponentType.QRCode:
                return <QRCodeComponent {...props} />
            case ComponentType.FeedbackForm:
                return <FeedbackComponent {...props} />
            default:
                return (
                    <div className="card">
                        <div className="card-body p-2">
                            {control.componentType} not implemented
                        </div>
                    </div>)
        }
    }

    return <>
        <ErrorBoundary>
            {getControl()}
        </ErrorBoundary>
    </>
})