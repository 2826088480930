import { ActionLink } from "./ActionLink"
import { ActionMenu } from "./ActionMenu"
import Dropdown from "react-bootstrap/esm/Dropdown"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import { IToolbarAction } from "../../models"

export const ActionMenuItem = ({ action, className, onAction }: {
    action: IToolbarAction,
    className?: string,
    onAction: (id: string) => Promise<void>
}) => {
    if (!action.children || action.children.length === 0) {
        return <DropdownItem as={() => (<ActionLink className={className} action={action} onAction={onAction} />)} />
    } else {
        return (
            <Dropdown title={action.label} className="me-2">
                <ActionMenu className={className} actions={action.children} onAction={onAction} />
            </Dropdown>)
    }
}

