import { IDocumentType } from "./DocumentType"

export enum FileType {
    PDF = 365970000,
    JPEG = 365970001,
    PNG = 365970002,
    JPG = 365970003,    
    WORD = 365970004,
    BMP = 365970005
}

export interface IAllowedFileType {
    id: string,
    documentCategoryId: string,
    fileType?: FileType,
    minDocumentSize?: number,
}

export interface IDocumentCategory {
    id: string,
    description: string,
    parentCategoryId: string,
    maxDocumentSize: number,
    minDocumentSize: number
    documentTypes?: IDocumentType[],
    allowedFileTypes?: IAllowedFileType[],
}

export const DocumentCategoryMetadata = {
    tableId: "33101671",
    fields: {
        id: "Id",
        description: "Description",
        parentCategoryId: "Parent Category Id",
        maxDocumentSize: "Max Document Size (KB)",
        minDocumentSize: "Min Document Size (KB)",
    }
}
