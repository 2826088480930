import * as uuid from "uuid";

import clsx from "clsx";
import { useState } from "react";

export const Checkbox = ({ title, value, className, onChange }:{
    title: string,
    value: boolean,
    className?: string,
    onChange: (value?: boolean) => void,
}) => {
    const [inputId] = useState<string>(uuid.v1());

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.checked;
        newValue === true ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group")}>
            <div className="form-check form-check-sm d-flex align-items-center fs-8 fw-semibold text-muted">
                <input
                    className="form-check-input form-check-input-sm me-2"
                    type="checkbox"
                    onChange={handleOnChange}
                    checked={value}
                    id={inputId} />

                <label className="form-check-label fw-bold text-gray-600" htmlFor={inputId}>
                    {title}
                </label>
            </div>
        </div>
    )
}