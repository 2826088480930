import { Alignment } from "../models";

export const alignmentClass = (alignment?: Alignment) => {
    switch (alignment) {
        case Alignment.Baseline: return 'align-baseline';
        case Alignment.Top: return 'align-top';
        case Alignment.Middle: return 'align-middle';
        case Alignment.Bottom: return 'align-bottom';
        case Alignment.TextBottom: return 'align-text-bottom';
        case Alignment.TextTop: return 'align-text-top';
    }
}

