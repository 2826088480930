import { AccordionItemProperties, AccordionProperties } from "../../components/accordion";
import { ActionButtonProperties, ButtonProperties, DropdownButtonProperties, ResetDataProperties, SubmitButtonProperties } from "../../components/buttons";
import { BadgeProperties, HTMLViewerProperties, HeadingProperties, LabelProperties, NormalTextProperties, ParagraphProperties } from "../../components/text";
import { CheckboxProperties, DropdownProperties, HiddenInputProperties, InputProperties, LookupProperties, NameLookupProperties, RadioGroupProperties } from "../../components/inputs";
import { DocumentChecklistProperties, EntityDocumentChecklistProperties } from "../../components/document-checklist/properties";
import { DropdownPageProperties, PartPageProperties } from "../../components/part-pages/properties";
import { FormProperties, PanelProperties, SectionProperties } from "../../components/panels";
import { GoogleMapsProperties, ImageProperties, MediaProperties, TwitterProperties, VideoProperties, YouTubeProperties } from "../../components";
import { HyperLinkProperties, ListNavigationProperties, OffcanvasProperties, RecordNavigationProperties } from "../../components/links";
import { IPageControl, IProperty } from "../../../base/models";
import { PayUButtonProperties, PaytmButtonProperties } from "../../components/pg-integration/properties";
import { RepeaterColumnProperties, RepeaterProperties } from "../../components/repeater";
import { ServiceRequestProperties, ServicesProperties } from "../../components/services/properties";
import { useEffect, useState } from "react";

import { AvatarTextProperties } from "../../components/avatar";
import { Base64ImageProperties } from "../../components/media/properties/Base64ImageProperties";
import { ChatProperties } from "../../components/chat";
import { ComponentType } from "../../../base/types";
import { DataLinkProperties } from "../../components/data-link";
import { EnumLabelProperties } from "../../components/text/properties/EnumLabelProperties";
import { FeedbackFormProperties } from "../../components/feedback/properties/FeedbackFormProperties";
import { GridProperties } from "../../components/grid/properties";
import { MaterialIconProperties } from "../../components/icons/properties";
import { NTTButtonProperties } from "../../components/pg-integration/properties/NTTButtonProperties";
import { PieChartProperties } from "../../components/carts";
import { QRCodeProperties } from "../../components/qr-scanner/properties/QRCodeProperties";
import { QRScannerProperties } from "../../components/qr-scanner/properties/QRScannerProperties";
import { RazorPayButtonProperties } from "../../components/pg-integration/properties/RazorPayButtonProperties";
import { SPDocumentsProperties } from "../../components/sharepoint-documents";
import { StackedBarsProperties } from "../../components/carts/stacked-bars";
import { TimelineComponentProperties } from "../../components/timeline/properties";
import { useLazyGetComponentMetadataQuery } from "../../../services/data/componentsMetadataApi";

export const useGetComponentProperties = () => {
    const [properties, setProperties] = useState<IProperty[]>([]);
    const [getComponentMetadata, { data: component, isSuccess }] = useLazyGetComponentMetadataQuery();

    useEffect(() => {
        if (isSuccess) {
            setProperties(component?.props || [])
        }
    }, [isSuccess, component])

    const getProperties = (control: IPageControl) => {
        switch (control.componentType) {
            case ComponentType.Paragraph:
                setProperties(ParagraphProperties);
                break;
            case ComponentType.NormalText:
                setProperties(NormalTextProperties);
                break;
            case ComponentType.Label:
                setProperties(LabelProperties);
                break;
            case ComponentType.Badge:
                setProperties(BadgeProperties);
                break;
            case ComponentType.Heading1:
            case ComponentType.Heading2:
            case ComponentType.Heading3:
            case ComponentType.Heading4:
            case ComponentType.Heading5:
            case ComponentType.Heading6:
                setProperties(HeadingProperties);
                break;
            case ComponentType.Hyperlink:
                setProperties(HyperLinkProperties);
                break;
            case ComponentType.CardNavigation:
                setProperties(RecordNavigationProperties);
                break;
            case ComponentType.ListNavigation:
                setProperties(ListNavigationProperties);
                break;
            case ComponentType.Offcanvas:
                setProperties(OffcanvasProperties);
                break;
            case ComponentType.Accordion:
                setProperties(AccordionProperties);
                break;
            case ComponentType.AccordionItem:
                setProperties(AccordionItemProperties);
                break;
            case ComponentType.Button:
                setProperties(ButtonProperties);
                break;
            case ComponentType.Dropdown:
                setProperties(DropdownProperties);
                break;
            case ComponentType.SubmitButton:
                setProperties(SubmitButtonProperties);
                break;
            case ComponentType.ActionButton:
                setProperties(ActionButtonProperties);
                break;
            case ComponentType.DropdownButton:
                setProperties(DropdownButtonProperties);
                break;
            case ComponentType.Radio:
                setProperties(RadioGroupProperties);
                break;
            case ComponentType.Checkbox:
                setProperties(CheckboxProperties);
                break;
            case ComponentType.DateTime:
            case ComponentType.Date:
            case ComponentType.Time:
            case ComponentType.TextBox:
            case ComponentType.NumberInput:
            case ComponentType.Password:
            case ComponentType.FileInput:
                setProperties(InputProperties);
                break;
            case ComponentType.Lookup:
                setProperties(LookupProperties);
                break;
            case ComponentType.TextArea:
                setProperties(InputProperties);
                break;
            case ComponentType.Image:
                setProperties(ImageProperties);
                break;
            case ComponentType.Video:
                setProperties(VideoProperties);
                break;
            case ComponentType.Media:
                setProperties(MediaProperties);
                break;
            case ComponentType.Section:
                setProperties(SectionProperties);
                break;
            case ComponentType.Panel:
                setProperties(PanelProperties);
                break;
            case ComponentType.Form:
                setProperties(FormProperties);
                break;
            case ComponentType.Repeater:
                setProperties(RepeaterProperties);
                break;
            case ComponentType.RepeaterColumn:
                setProperties(RepeaterColumnProperties);
                break;
            case ComponentType.GoogleMaps:
                setProperties(GoogleMapsProperties);
                break;
            case ComponentType.YouTube:
                setProperties(YouTubeProperties);
                break;
            case ComponentType.Twitter:
                setProperties(TwitterProperties);
                break;
            case ComponentType.ListPart:
            case ComponentType.CardPart:
                setProperties(PartPageProperties);
                break;
            case ComponentType.Custom:
                getComponentMetadata(control.componentId);
                break;
            case ComponentType.Chat:
                setProperties(ChatProperties);
                break;
            case ComponentType.SharePointDocuments:
                setProperties(SPDocumentsProperties);
                break;
            case ComponentType.AvatarText:
                setProperties(AvatarTextProperties);
                break;
            case ComponentType.MaterialIcon:
                setProperties(MaterialIconProperties);
                break;
            case ComponentType.DropdownPage:
                setProperties(DropdownPageProperties);
                break;
            case ComponentType.EntityDocumentChecklist:
                setProperties(EntityDocumentChecklistProperties);
                break;
            case ComponentType.DocumentChecklist:
                setProperties(DocumentChecklistProperties);
                break;
            case ComponentType.ResetData:
                setProperties(ResetDataProperties);
                break;
            case ComponentType.Services:
                setProperties(ServicesProperties);
                break;
            case ComponentType.PieChart:
                setProperties(PieChartProperties);
                break;
            case ComponentType.StackedBars:
                setProperties(StackedBarsProperties);
                break;
            case ComponentType.DataLink:
                setProperties(DataLinkProperties);
                break;
            case ComponentType.ServiceRequest:
                setProperties(ServiceRequestProperties);
                break;
            case ComponentType.PayUButton:
                setProperties(PayUButtonProperties);
                break;
            case ComponentType.PaytmButton:
                setProperties(PaytmButtonProperties);
                break;
            case ComponentType.RazorPayButton:
                setProperties(RazorPayButtonProperties);
                break;
            case ComponentType.NTTPayButton:
                setProperties(NTTButtonProperties);
                break;
            case ComponentType.Grid:
                setProperties(GridProperties);
                break;
            case ComponentType.NameLookup:
                setProperties(NameLookupProperties);
                break;
            case ComponentType.HTMLViewer:
                setProperties(HTMLViewerProperties);
                break;
            case ComponentType.EnumLabel:
                setProperties(EnumLabelProperties);
                break;
            case ComponentType.Timeline:
                setProperties(TimelineComponentProperties);
                break;
            case ComponentType.HiddenInput:
                setProperties(HiddenInputProperties);
                break;
            case ComponentType.Base64Image:
                setProperties(Base64ImageProperties);
                break;
            case ComponentType.QRScanner:
                setProperties(QRScannerProperties);
                break;
            case ComponentType.QRCode:
                setProperties(QRCodeProperties);
                break;
            case ComponentType.FeedbackForm:
                setProperties(FeedbackFormProperties);
                break;
            default:
                console.error(`Properties not implemented for ${control.componentType}`);
                setProperties([]);
                break;
        }
    }

    return { getProperties, properties }
}
