import { EditEntityRelations } from '../framework/sites/entity-relations'
import { FC } from 'react'
import { PageTitle } from '../layout/core'
import { useAuth } from '../modules/auth'

export const SecurityFiltersWrapper: FC = () => {
  const { site } = useAuth()

  if (!site) return <></>

  return (
    <>
      <PageTitle breadcrumbs={[]}>{`${`${site?.title} Security Filters` || 'Loading...'}`}</PageTitle>
      <div>
        <EditEntityRelations site={site} />
      </div>
    </>)
}

