import { ButtonStyle, ControlSize, Property } from "../../../base/types";
import { MUIIcon, Spinner } from "../../../widgets";

import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import clsx from "clsx"
import { getButtonClass } from "./helper";
import { useDesignContext } from "../../../providers";
import { useFromData } from "../panels/FormComponent"
import { useGetProperties } from "../../../base/hooks";

export const SubmitButtonComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const icon: string | undefined = properties[Property.MaterialIcon]
    const disabled: boolean | undefined = properties[Property.Disabled]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]

    const { isSubmitting } = useFromData()

    if (mode === "Design") {
        return (
            <div className={clsx(getButtonClass(style, size), className)}>
                <div className="d-flex">
                    {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                    <DefaultLabel
                        control={control}
                        propertyId={Property.Label}
                        defaultLabel={icon ? '' : "Submit"}
                        className="text-hover-primary" />
                </div>
                {children}
            </div>)
    }

    return (
        <button
            title={tooltip}
            disabled={isSubmitting || disabled === true}
            className={clsx(getButtonClass(style, size), className)}>
            <div className="d-flex">
                <div>
                    <Spinner show={isSubmitting} className="me-1" />
                    {(!isSubmitting && icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                    <span>{label}</span>
                </div>
                <div className="flex-fill">
                    {children}
                </div>
            </div>
        </button>)
}