import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const BadgeProperties: IProperty[] = [
    { id: Property.MaterialIcon, name: 'Icon', type: PropertyType.MaterialIcon },
    { id: Property.Text, name: 'Text', type: PropertyType.String },
    { id: Property.Format, name: 'Format', type: PropertyType.DisplayFormat },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.BackgroundStyle, name: 'Style', type: PropertyType.BadgeStyle },
    { id: Property.RoundedPill, name: 'Rounded-Pill', type: PropertyType.Boolean },
]