import { YesNoChoice } from "../../../../base/styles";
import clsx from "clsx";

export const SelectYesNoChoice = ({ title, value, className, onChange }:{
    title: string,
    value?: YesNoChoice,
    className?: string,
    onChange: (value?: YesNoChoice) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as YesNoChoice;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={YesNoChoice.Yes}>Border</option>
                <option value={YesNoChoice.No}>No Border</option>
            </select>
        </div>
    )
}