import "./styles.scss"

import { useEffect, useState } from "react"

import { Link } from "react-router-dom"
import { MUIIcon } from "../../../../widgets"
import clsx from "clsx"
import { useAuth } from "../../../../../modules/auth"

export const DesignButton = ({ pageId }: { pageId: string }) => {
    const { user, siteId } = useAuth()
    const [allowDesign, setAllowDesign] = useState<boolean>(false)

    useEffect(() => {
        if (!user) return
        const allow = user.roles.indexOf('SUPER') !== -1
        setAllowDesign(allow)
    }, [user])

    if (allowDesign !== true) return <></>

    return (
        <Link
            target="_blank"
            to={`/site(${siteId})/design-page(${pageId})`}
            className={clsx("page-designer-floating-button",
                "bg-info border border bg-hover-primary shadow",
                "fw-semibold text-white")}
            title="Open in designer"
        >
            <div>
                <MUIIcon iconName="DesignServicesOutlined" className="fs-6" />
            </div>
            <div className="page-designer-floating-button--label">
                Designer
            </div>
        </Link>
    )
}
