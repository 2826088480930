import { CollapsiblePanel, Panel } from "../../../widgets";

import { EntityDocumentChecklists } from "./components/EntityDocumentChecklists";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useGetProperties } from "../../../base/hooks";

export const EntityDocumentChecklistComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const entityId: string | undefined = properties[Property.EntityId];
    const entity: string | undefined = properties[Property.Entity]
    const title: string | undefined = properties[Property.Title]
    const headerClass: string | undefined = properties[Property.HeaderClass]
    const collapsible: boolean | undefined = properties[Property.Collapsible]
    const relativePath: string | undefined = properties[Property.RelativePath]

    if (collapsible) {
        return (
            <CollapsiblePanel
                eventKey="checklist"
                header={title}
                icon="FactCheckOutlined"
                headerClass={headerClass}
                className={className}>
                <EntityDocumentChecklists
                    entityId={entityId}
                    entity={entity}
                    relativePath={relativePath} />
            </CollapsiblePanel>
        )
    }

    return (
        <Panel
            header={title}
            icon="FactCheckOutlined"
            headerClass={headerClass}
            className={className}>
            <EntityDocumentChecklists
                entityId={entityId}
                entity={entity}
                relativePath={relativePath} />
        </Panel>)
}

