import { DefaultDashboard } from './DefaultDashboard'
import { PageMode } from '../framework/base/types'
import { PageTitle } from '../_metronic/layout/core'
import { PageWithToolbar } from '../framework/design/page'
import { ProgressIndicator } from '../framework/widgets'
import { useProfilePage } from '../framework/providers'

export const DashboardWrapper = () => {
  const { page, isLoading } = useProfilePage();

  if (isLoading) {
    return <ProgressIndicator show={isLoading} />
  }

  if (!page?.id) {
    return <DefaultDashboard />
  }

  return (
    <div className="position-relative">
      <PageTitle breadcrumbs={[]}>{page.title || page.name}</PageTitle>
      <PageWithToolbar page={page} pageView={{ pageMode: PageMode.View }} />
    </div>
  )
}

