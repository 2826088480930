import { IControlProps } from "../../../base/models";
import { MUIIcon } from "../../../widgets";
import { Property } from "../../../base/types";
import { useGetProperties } from "../../../base/hooks";

export const MaterialIconComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const icon: string | undefined = properties[Property.MaterialIcon];

    return (<MUIIcon iconName={icon || 'InsertEmoticonOutlinedIcon'} className={className} />)
}