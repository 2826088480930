import { ILikertQuestion } from '../models/feedback-form.model';
import clsx from 'clsx';

const LikertQuestion = ({ question, value, onChange, onBlur }: {
    question: ILikertQuestion;
    value?: string,
    onChange: (value: string) => void,
    onBlur: () => void
}) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
    }

    const isChecked = (choice: string): boolean => {
        return value?.toLowerCase() === choice.toLowerCase()
    }

    return (
        <div className="form-group">
            <label className={clsx("form-label", { required: question.isRequired })}>{question.title}</label>
            <div >
                {question.choices.map((choice, index) => (
                    <div key={index} className="form-check form-check-inline">
                        <input
                            type="radio"
                            className="form-check-input"
                            name={question.id}
                            id={`${question.id}-${index}`}
                            value={choice}
                            required={question.isRequired}
                            onChange={handleOnChange}
                            onBlur={() => onBlur()}
                            checked={isChecked(choice)}
                        />
                        <label className="form-check-label" htmlFor={`${question.id}-${index}`}>{choice}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LikertQuestion;
