import { PageType } from "../page/models";
import clsx from "clsx";

const PageTypes: string[] = ["List", "Card", "Root", "List Part", "Card Part","AgGrid"]

export const SelectPageType = ({ title, value, className, onChange }: {
    title: string,
    value: PageType,
    className?: string,
    onChange: (value: PageType) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as PageType;
        onChange(newValue);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm">
                <option></option>
                {PageTypes.map(type =>
                    <option key={type} value={type}>{type}</option>)}
            </select>
        </div>
    )
}