import { IEntityFilter, IPageLink } from "../../../base/models"
import { useEffect, useState } from "react"

import { EditTableFilters } from "../table-filters"
import { IDataSource } from "../../../base/types"
import Modal from "react-bootstrap/esm/Modal"
import { SelectAttribute } from "../SelectAttribute"
import { SelectPage } from "../SelectPage"
import { SelectPageMode } from "../SelectPageMode"
import clsx from "clsx"
import { useAuth } from "../../../../modules/auth"
import { useGetPageMetadataQuery } from "../../../services/data/pagesMetadataApi"

export const EditPageLink = ({ dataSource, pageLink, className, onChange }: {
    pageLink: IPageLink,
    dataSource?: IDataSource,
    className?: string,
    onChange: (pageLink: IPageLink) => void,
}) => {
    const { siteId } = useAuth()
    const { pageId, pageMode, systemId, filters } = pageLink;
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [pageFilters, setPageFilters] = useState<IEntityFilter[]>(filters || [])

    const handleOnFiltersChange = (filters: IEntityFilter[]) => {
        setPageFilters(filters)
        onChange({ ...pageLink, filters });
    }

    const hideModal = () => {
        setShowDialog(false);
    }
    const fields = dataSource ? dataSource.attributes : [];

    return <div className={clsx(className, "card mt-0 bg-transparent mx-0")}>
        <div className="card-body py-0 px-0">
            <div className="d-flex flex-wrap justify-content-stretch gap-3">
                <div className="flex-fill min-w-200px" >
                    <label className="form-label fs-8 mb-1 required">Page</label>
                    {(siteId) && <>
                        <SelectPage
                            siteId={siteId}
                            value={pageId}
                            onChange={(pageId) => onChange({ ...pageLink, pageId })} />
                    </>}
                </div>
                <div className="flex-fill min-w-200px">
                    <label className="form-label fs-8 mb-1 required *">Page Mode</label>
                    <SelectPageMode
                        value={pageMode}
                        onChange={(mode) => onChange({ ...pageLink, pageMode: mode })} />
                </div>
                <div className="flex-fill min-w-200px">
                    <label className="form-label fs-8 mb-1">System Id</label>
                    <SelectAttribute
                        attributes={fields}
                        value={systemId}
                        onChange={(value) => onChange({ ...pageLink, systemId: value })} />
                </div>
            </div>

            <div className="fs-8 mt-3">
                <p className="flex-fill fw-bold text-gray-600 fs-8 mb-1">Filters:</p>
                {pageFilters.map((item, i) => <p key={i} className="m-0">
                    <span className="fw-semibold text-primary">{item.field}</span> =
                    <span className="fw-bold ms-2">{item.type}</span>
                    (<span className="fw-semibold text-primary">{item.value}</span>)</p>)}

                <div className="d-flex align-items-end mt-3">
                    <button
                        type="button"
                        className="btn btn-primary btn-sm py-2"
                        onClick={() => setShowDialog(true)}>
                        Edit Filters
                    </button>
                </div>
            </div>

            {(pageId) && <TableFiltersModel
                hideModal={hideModal}
                show={showDialog}
                filters={pageFilters}
                pageId={pageId}
                dataSource={dataSource}
                onChange={handleOnFiltersChange}
            />}
        </div>
    </div>
}

const TableFiltersModel = ({ pageId, dataSource, filters, show, hideModal, onChange }: {
    pageId: string,
    dataSource?: IDataSource,
    filters: IEntityFilter[],
    show: boolean,
    hideModal: () => void,
    onChange: (filters: IEntityFilter[]) => void
}) => {
    const [localFilters, setLocalFilters] = useState<IEntityFilter[]>([...filters]);
    const { data: page } = useGetPageMetadataQuery(pageId);

    useEffect(() => {
        setLocalFilters(filters)
    }, [pageId, filters, show])

    const handleChanges = (filters: IEntityFilter[]) => {
        setLocalFilters(filters)
    }

    if (!page?.sourceObject) return <></>

    return <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
        <Modal.Header closeButton className="bg-secondary border-0 py-5">
            <h4 className="my-0">Table Filters</h4>
        </Modal.Header>
        <Modal.Body>
            <EditTableFilters
                filters={localFilters}
                entity={page?.sourceObject}
                dataSource={dataSource}
                onChange={handleChanges} />
        </Modal.Body>
        <Modal.Footer className="border-0 py-5">
            <button type="button"
                onClick={() => {
                    onChange(localFilters)
                    hideModal()
                }}
                className="btn btn-sm btn-primary">OK</button>
            <button type="button"
                onClick={hideModal}
                className="btn btn-sm btn-secondary">Cancel</button>
        </Modal.Footer>
    </Modal>
}
