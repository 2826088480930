import { IValueExpression } from "../../../../base/models"
import React from "react"
import clsx from "clsx"

export const ViewExpression = ({ expression, className }: {
    expression: IValueExpression,
    className?: string
}) => {
    if (!expression.query) return <></>

    return <div className={clsx(className, "fs-9")}>
        <label className="fw-bold fs-8 mb-2">Expression</label>
        <div className="mb-1 w-100 text-truncate">
            <span className="text-muted fs-9 text-truncate">{expression.query}</span>
        </div>
        <div className="text-truncate">
            {expression.parameters.map(p => <React.Fragment key={p.name}>
                <p className="m-0 mb-1 p-0">
                    <span className="text-primary fw-semibold">{p.name}</span>
                    <span className="badge badge-light mx-2 fs-9">{p.type}</span>
                    <span className="fw-semibold">{p.value}</span>
                </p>
            </React.Fragment>)}
        </div>
    </div>
}
