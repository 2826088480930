import { toAbsoluteUrl } from "../../../../../helpers";

export const DocumentImage = ({ fileName }: { fileName: string }) => {
    const file_name_array = fileName.split(".");
    const file_extension = file_name_array[file_name_array.length - 1];

    switch (file_extension) {
        case "xlsx":
        case "xls":
            return <img src={toAbsoluteUrl('/media/svg/files/xlsx.svg')} alt='' />;
        case "docx":
        case "doc":
            return <img src={toAbsoluteUrl('/media/svg/files/doc.svg')} alt='' />;
        case "gif":
            return <img src={toAbsoluteUrl('/media/svg/files/gif.svg')} alt='' />;
        case "pdf":
            return <img src={toAbsoluteUrl('/media/svg/files/pdf.svg')} alt='' />;
        case "png":
            return <img src={toAbsoluteUrl('/media/svg/files/png.svg')} alt='' />;
        case "jpg":
        case "jpeg":
            return <img src={toAbsoluteUrl('/media/svg/files/jpg.svg')} alt='' />;
        case "bmp":
            return <img src={toAbsoluteUrl('/media/svg/files/bmp.svg')} alt='' />;
        case "txt":
            return <img src={toAbsoluteUrl('/media/svg/files/txt.jpg')} alt='' />;
        case "ppt":
        case "pptx":
            return <img src={toAbsoluteUrl('/media/svg/files/ppt.svg')} alt='' />;
        default:
            return <img src={toAbsoluteUrl('/media/svg/files/doc.svg')} alt='' />
    }
}
