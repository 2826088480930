import { useEffect, useState } from "react";

export type EventType = "ShiftUp" | "ShiftDown" | "Delete" | "Copy" | "Paste" | "Cut";

export const useKeyboardHandler = <T extends HTMLElement>(ref: React.RefObject<T>) => {
    const [keyCode, setKeyCode] = useState<EventType>()

    const handleOnKeyDown = (e: KeyboardEvent) => {
        let handled = false;

        switch (e.code) {
            case "Delete":
                handled = true;
                setKeyCode(e.code)
                break;
            case "ArrowDown":
                if (e.shiftKey === true) {
                    handled = true;
                    setKeyCode("ShiftDown")
                }
                break;
            case "ArrowUp":
                if (e.shiftKey === true) {
                    handled = true;
                    setKeyCode("ShiftUp")
                }
                break;
            case "KeyC":
                if (e.ctrlKey === true) {
                    handled = true;
                    setKeyCode("Copy")
                }
                break;
            case "KeyV":
                if (e.ctrlKey === true) {
                    handled = true;
                    setKeyCode("Paste")
                }
                break;
            case "KeyX":
                if (e.ctrlKey === true) {
                    handled = true;
                    setKeyCode("Cut")
                }
                break;
            // default:
            //     console.log(`code: ${e.code}, shiftKey: ${e.shiftKey}, ctrlKey: ${e.ctrlKey} `)
        }

        if (handled) {
            e.stopPropagation()
            e.preventDefault()
        }
    }

    useEffect(() => {
        const node = ref.current

        if (node) {
            node.addEventListener("keydown", handleOnKeyDown);
        }

        return () => {
            if (node) {
                node.removeEventListener("keydown", handleOnKeyDown);
            }
        }

        // eslint-disable-next-line
    }, [ref])

    return { keyCode, reset: () => setKeyCode(undefined) }
}
