import { BadgeStyle } from "../../../base/types";
import clsx from "clsx";

const styleClass = (style?: BadgeStyle) => {
    switch (style) {
        case BadgeStyle.None: return "";
        case BadgeStyle.Primary: return "bg-primary";
        case BadgeStyle.Secondary: return "bg-secondary";
        case BadgeStyle.Success: return "bg-success";
        case BadgeStyle.Danger: return "bg-danger";
        case BadgeStyle.Warning: return "bg-warning text-dark";
        case BadgeStyle.Info: return "bg-info text-dark";
        case BadgeStyle.Light: return "bg-light text-dark";
        case BadgeStyle.Dark: return "bg-dark";
    }
}

export const getBadgeClass = (style?: BadgeStyle, roundedPill?: boolean, defaultClass?: string) => {
    const styleCls = styleClass(style)
    return clsx("badge", styleCls || defaultClass, { "rounded-pill": roundedPill })
}

