import { ActionCallback, useToolbar } from "../../../layout";
import { IEntity, Property } from "../../../base/types";
import { IPage, PageType } from "../models";
import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { IPageAction } from "../../page-action/models";
import { IPageLink } from "../../../base/models";
import { IToolbarAction } from "../../../layout/toolbar/models";
import { decodeTableFilters } from "../../../data/helpers";
import { getPageLinkUrl } from "../../../base/helpers";
import { getProperties } from "../../../base/hooks";
import { useAuth } from "../../../../modules/auth";
import { useDataContext } from "../context/DataContextProvider";

export const usePageToolbarActions = ({ page }: { page: IPage }) => {
    const { siteId } = useAuth()
    const { setButtons, setOnAction } = useToolbar();
    const { type, data, isSuccess, isError } = useDataContext()
    const [actionIndex, setActionIndex] = useState<Record<string, IPageAction>>({})
    const onActionRef = useRef<ActionCallback>(async () => { })
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const filters = decodeTableFilters(searchParams.get("filters"));

    useLayoutEffect(() => {
        if (page.type === PageType.ListPart || page.type === PageType.CardPart || page.type === PageType.Root)
            return;

        const actionIndex = getPageActionIndex(page.actions)
        setActionIndex(actionIndex)

        const actions = getPageActions(page.actions)
        setButtons(actions)

        // eslint-disable-next-line
    }, [page.actions])

    const getRecord = () => {
        if (!data || !isSuccess || isError) return {}

        switch (type) {
            case "List":
                return data.records.length > 0 ? data.records[0] : {}
            case "Card":
                return data
            default:
                return {}
        }
    }

    const onAction = async (actionId: string) => {
        const row: IEntity = getRecord();
        const action = actionIndex[actionId]

        if (!siteId) {
            console.log('No site selected');
            return; 
        }

        if (!action) {
            console.log(`No action found, No of actions: ${actionIndex}, action id: ${actionId}.`)
            return;
        }

        const properties = getProperties({ bindings: action.bindings || [], row, filters })
        const pageLink: IPageLink = properties[Property.PageLink]
        navigate(getPageLinkUrl(siteId, pageLink))
    }

    useLayoutEffect(() => {
        if (page.type === PageType.ListPart || page.type === PageType.CardPart || page.type === PageType.Root)
            return;

        onActionRef.current = onAction;
        setOnAction(onActionRef)
        // eslint-disable-next-line
    }, [onAction])

    const getPageActions = (actions?: IPageAction[]): IToolbarAction[] | undefined => {
        if (!actions || actions.length === 0) return;

        return actions.map<IToolbarAction>(action => ({
            id: action.actionId,
            label: action.name,
            icon: action.icon,
            children: getPageActions(action.actions)
        }))
    }
}

const getPageActionIndex = (actions?: IPageAction[]): Record<string, IPageAction> => {
    if (!actions || actions.length === 0) return {}
    let index: Record<string, IPageAction> = {}

    for (let i = 0; i < actions.length; i++) {
        const action = actions[i]
        index[action.actionId] = action
        index = { ...index, ...getPageActionIndex(action.actions) }
    }

    return index
} 
