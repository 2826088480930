import { FieldFilterType, IDataSource } from "../../../../base/types";

import { IEntityFilter } from "../../../../base/models";
import { SelectEntityAttribute } from "../../SelectEntityAttribute";
import { SelectFilterFunction } from "../../SelectFilterFunction";
import { useState } from "react";

const FilterTypes = [
    { id: FieldFilterType.Const, value: "Const" },
    { id: FieldFilterType.Field, value: "Field" },
    { id: FieldFilterType.Filter, value: "Filter" },
    { id: FieldFilterType.Function, value: "Function" }
]

export const NewTableFilter = ({ entity, dataSource, onAdd }: {
    entity: string,
    dataSource?: IDataSource,
    onAdd: (filter: IEntityFilter) => void,
}) => {
    const [filter, setFilter] = useState<IEntityFilter>({
        field: '',
        type: FieldFilterType.Const,
        value: ''
    });

    const handleOnAddClick = () => {
        onAdd(filter);
        setFilter({
            field: '',
            type: FieldFilterType.Const,
            value: ''
        });
    }

    const handleOnFieldChange = (field?: string) => {
        setFilter({
            ...filter,
            field: field || ''
        })
    }

    const handleOnFieldValueChange = (field?: string) => {
        setFilter({
            ...filter,
            value: field || ''
        })
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            value: e.target.value
        })
    }

    const handleOnSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            type: e.target.value as FieldFilterType
        })
    }

    return (
        <tr>
            <td>
                <SelectEntityAttribute entity={entity} value={filter.field} onChange={handleOnFieldChange} />
            </td>
            <td>
                <select
                    value={filter.type}
                    onChange={handleOnSelectChange}
                    className="form-select form-select-sm">
                    {FilterTypes.map(item => <option key={item.id} value={item.id}>{item.value}</option>)}
                </select>
            </td>
            <td>
                {filter.type === FieldFilterType.Field &&
                    <>{(dataSource && dataSource.entity) &&
                        <SelectEntityAttribute
                            entity={dataSource.entity}
                            value={filter.value}
                            onChange={handleOnFieldValueChange} />}
                    </>
                }

                {filter.type === FieldFilterType.Function &&
                    <>
                        <SelectFilterFunction
                            value={filter.value}
                            onChange={handleOnFieldValueChange} />
                    </>
                }

                {(filter.type === FieldFilterType.Const || filter.type === FieldFilterType.Filter) &&
                    <input type="text"
                        value={filter.value}
                        onChange={handleOnChange}
                        className="form-control form-control-sm" />
                }
            </td>
            <td>
                <button
                    type="button"
                    onClick={handleOnAddClick}
                    className="btn btn-sm btn-primary py-2">
                    <i className="las la-plus"></i>
                    Add
                </button>
            </td>
        </tr>)
}