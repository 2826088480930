import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { AlertMessage } from "../../../../framework/widgets";
import { InteractionStatus } from "@azure/msal-browser";
import { LoginRequest } from "../authConfig";
import { MsalSignInButton } from "./MsalSignInButton";
import { MsalStatus } from "../msal-status";
import clsx from "clsx";
import { useAuth } from "../../core/Auth";
import { useMsalLoginMutation } from "../services";

export const AuthenticatedSignIn = ({ className }: { className?: string }) => {
    const isAuthenticated = useIsAuthenticated()
    const { instance, accounts, inProgress } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);
    const [msalLogin] = useMsalLoginMutation()
    const { saveAuth, setUser, logout } = useAuth();
    const [status, setStatus] = useState<string>('')

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        if (!accounts || accounts.length === 0) {
            return;
        }

        if (MsalStatus.isInProgress()) {
            MsalStatus.clear()
            handleLogin()
        }

        // eslint-disable-next-line
    }, [isAuthenticated, accounts])

    const handleLogin = () => {
        if (!isAuthenticated) {
            setStatus('Authentication error')
            return;
        }

        if (!accounts || accounts.length === 0) {
            setStatus('Authentication error')
            return;
        }

        const account = accounts[0];
        setStatus('')
        setLoading(true)
        logout()
        instance.acquireTokenSilent({ ...LoginRequest, account })
            .then(({ accessToken, account }) => {
                return msalLogin({ userName: account?.username!, token: accessToken })
                    .unwrap()
                    .then(authUser => {
                        setUser(authUser.user)
                        saveAuth(authUser.auth);
                    });
            })
            .catch((err) => {
                console.error(err)
                setStatus('You are not authorized to access this site.')
                unauthenticatedLogin()
            })
            .finally(() => setLoading(false));
    }

    const unauthenticatedLogin = () => {
        logout()
        setLoading(true)
        MsalStatus.start()
        instance.loginRedirect(LoginRequest)
            .catch((err) => {
                console.error(err)
                MsalStatus.clear()
                setStatus('Login failed')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return <>
        <MsalSignInButton
            loading={loading || inProgress !== InteractionStatus.None}
            className={clsx(className, "btn-secondary my-1")}
            onClick={unauthenticatedLogin} />

        <AlertMessage className="my-2" type="Error" show={!!status} message={status} />
    </>
}
