import { ComponentType } from "../../../base/types";
import { IPageComponent } from "../../../base/models";

export const ComponentList: IPageComponent[] = [
    { id: "f5e8a323-d69a-4240-8851-146ee67050a2", name: "Form", type: ComponentType.Form },
    { id: "617c1a86-4327-4a1f-953b-0d0cc3ec4555", name: "Button", type: ComponentType.Button },
    { id: "0234cf56-a6d7-4032-95d9-f63462ae4321", name: "Submit Button", type: ComponentType.SubmitButton },
    { id: "cef5a854-5746-4a1c-a8be-d4e412858285", name: "Action Button", type: ComponentType.ActionButton },
    { id: "407eea64-86e4-479d-8202-cde9dd2a05f8", name: "Dropdown Button", type: ComponentType.DropdownButton },
    { id: "e408dbf4-f696-4e1b-a5a8-1b41c3fe33f4", name: "Text Box", type: ComponentType.TextBox },
    { id: "98bb86a5-35bf-42de-acb2-256d7b39080e", name: "Number", type: ComponentType.NumberInput },
    { id: "3d0caecc-4f17-4061-bf12-8660621c8695", name: "Password", type: ComponentType.Password },
    { id: "d65d1b0b-1804-4b40-b3e1-17df59182121", name: "DateTime", type: ComponentType.DateTime },
    { id: "1893467f-2312-48a2-a999-648da73ffce2", name: "Date", type: ComponentType.Date },
    { id: "1f76c023-2538-4269-b1c6-4457fb35025e", name: "Time", type: ComponentType.Time },
    { id: "108b73bb-e18d-410b-9861-09e49ed9fd77", name: "Checkbox", type: ComponentType.Checkbox },
    { id: "434dfe9b-af11-4465-8b63-42f3a28a98b7", name: "File Input", type: ComponentType.FileInput },
    { id: "b51a68c0-ede3-4fa0-855b-58f812808874", name: "Radio", type: ComponentType.Radio },
    { id: "985b0ad8-bf42-41ec-b701-466e7866e4c3", name: "Dropdown", type: ComponentType.Dropdown },
    { id: "bcc2b5d9-a932-4a69-a582-8f8240d7514b", name: "Lookup", type: ComponentType.Lookup },
    { id: "ba6ca76c-1fed-4586-a39d-12874d283346", name: "Text Area", type: ComponentType.TextArea },
    { id: "43e4f2ad-08c5-47ae-aae5-22c096bc2a9b", name: "Paragraph", type: ComponentType.Paragraph },
    { id: "431e5c30-eebe-4931-b426-8a11950f967c", name: "Label", type: ComponentType.Label },
    { id: "234e5c30-eebe-4931-b426-8a11950f98ed", name: "Badge", type: ComponentType.Badge },
    { id: "35c0815e-58a5-40e9-8ec8-97d1d1bdbf49", name: "Normal Text", type: ComponentType.NormalText },
    { id: "fc9e4e07-e8af-4bbf-97ff-29cbc1ab1d30", name: "Accordion Item", type: ComponentType.AccordionItem },
    { id: "93a4d18e-78f6-410e-a64a-4fa94657ca2c", name: "Heading 1", type: ComponentType.Heading1 },
    { id: "93582524-4f28-4af6-9eb4-5e282caee8dd", name: "Heading 2", type: ComponentType.Heading2 },
    { id: "fa04369e-a3cb-4123-ac1d-ae178394b196", name: "Heading 3", type: ComponentType.Heading3 },
    { id: "5de167c5-4389-41a6-ac58-cda55efc304b", name: "Heading 4", type: ComponentType.Heading4 },
    { id: "bdb9c562-6501-4925-a4ce-820f67dc23f4", name: "Heading 5", type: ComponentType.Heading5 },
    { id: "795a4d58-bfa8-4e7f-847f-81b64e568393", name: "Heading 6", type: ComponentType.Heading6 },
    { id: "24068773-b1be-4da3-91f8-8b2647c6003e", name: "Repeater", type: ComponentType.Repeater },
    { id: "ba57c34d-69f8-4e26-a520-3bbdeb1e75ec", name: "Repeater Column", type: ComponentType.RepeaterColumn },
    { id: "21cf51b1-c765-4cf1-8169-b23681a7442e", name: "Navigate To Record", type: ComponentType.CardNavigation },
    { id: "63e43928-f3db-4456-9f24-b37193fc7c6e", name: "Navigate To List", type: ComponentType.ListNavigation },
    { id: "1cd84e2f-d110-431c-a96f-b7e6555411af", name: "Hyperlink", type: ComponentType.Hyperlink },
    { id: "e564934c-471d-42aa-abba-c22fb0d12475", name: "Section", type: ComponentType.Section },
    { id: "323cafaf-623b-49e6-bce5-d246878ce045", name: "Accordion", type: ComponentType.Accordion },
    { id: "65c0ca48-a38b-4df6-99bd-e0d66c2ccb47", name: "List", type: ComponentType.List },
    { id: "441e3c90-fae6-449d-a27b-70bda9abf63b", name: "Image", type: ComponentType.Image },
    { id: "52f1a506-d798-43be-a80f-f868ad5d1d55", name: "Video", type: ComponentType.Video },
    { id: "cbf31e83-3dba-405d-8ad0-9e76bf398a6c", name: "Media", type: ComponentType.Media },
    { id: "0a648ec0-bdaa-4f53-bdf3-7d7b4dfa3197", name: "Google Maps", type: ComponentType.GoogleMaps },
    { id: "f2cce836-2e08-4d28-bedc-2c02e28e0b53", name: "YouTube", type: ComponentType.YouTube },
    { id: "3caf0e35-048f-4f68-95fc-26d998e89a5d", name: "Twitter", type: ComponentType.Twitter },
    { id: "4051f6eb-37e4-4a71-9a22-9ba1e19b3a74", name: "Facebook", type: ComponentType.Facebook },
    { id: "3cb892c0-57a0-4e25-8475-bd5f472919c9", name: "LInkedIn", type: ComponentType.LInkedIn },
    { id: "18b25576-48a3-48a9-8591-0a0d3465121a", name: "Chat", type: ComponentType.Chat },
    { id: "3d462d72-9908-4ce0-800c-ebc2f3746e96", name: "Avatar Text", type: ComponentType.AvatarText },
    { id: "ec95f4ae-19b9-4b8b-90c1-89498e7996ac", name: "Material Icon", type: ComponentType.MaterialIcon },
    { id: "ae9dc206-6c74-47f2-b0c1-526d0c604651", name: "Dropdown Page", type: ComponentType.DropdownPage },
    { id: "281fd504-f2a0-42ab-8b7f-8f4400a7cd58", name: "List Part", type: ComponentType.ListPart },
    { id: "6f9fe856-5aab-472e-a482-ec3c733169bc", name: "Card Part", type: ComponentType.CardPart },
    { id: "11eb5373-c00f-4793-aed4-a4b7013d80a4", name: "SharePoint Documents", type: ComponentType.SharePointDocuments },
    { id: "984cd3e4-e23e-4ee2-80b6-f1462cadbb32", name: "Entity Document Checklist", type: ComponentType.EntityDocumentChecklist },
    { id: "3529d1e9-1def-4efd-9289-489da1763130", name: "Document Checklist", type: ComponentType.DocumentChecklist },
    { id: "56b57dc2-f9a3-4f78-8d5a-6f9c6fc67ed3", name: "Panel", type: ComponentType.Panel },
    { id: "568110cc-3b96-4c24-b315-3971449ef177", name: "Reset Data", type: ComponentType.ResetData },
    { id: "23443928-f3db-4456-9f24-b37193fc7345", name: "Offcanvas", type: ComponentType.Offcanvas },
    { id: "34543928-f3db-4456-9f24-b37193fw3678", name: "Grid", type: ComponentType.Grid },
    { id: "f53e446e-f289-4eec-ae8c-0dd58a1a687c", name: "Services", type: ComponentType.Services },
    { id: "db6a198b-4de1-40a7-b297-d39b9625a1f7", name: "Pie Chart", type: ComponentType.PieChart },
    { id: "9709982b-c2cd-408b-8f39-1d27dd70d2c2", name: "Stacked Bars", type: ComponentType.StackedBars },
    { id: "264ab93f-afc7-4986-94a3-b573ff9b700a", name: "Data Link", type: ComponentType.DataLink },
    { id: "775c4b16-9d53-4381-9984-6abbe3067f4b", name: "Service Request", type: ComponentType.ServiceRequest },
    { id: "73304858-d24c-4d01-ba38-5024962978ee", name: "PayU Button", type: ComponentType.PayUButton },
    { id: "40a18a42-e320-4114-a1aa-6b265cbf5b9c", name: "Paytm Button", type: ComponentType.PaytmButton },
    { id: "c79126c9-6a81-4225-9e21-7d23eb17ddf3", name: "NTT Pay Button", type: ComponentType.NTTPayButton },
    { id: "f1b819d8-e1cc-46f9-9f9d-9b0ef39b1ea8", name: "RazorPay Button", type: ComponentType.RazorPayButton },
    { id: "2edd5628-2baa-4e4c-a32b-31d54e8f853e", name: "Name Lookup", type: ComponentType.NameLookup },
    { id: "c7d81dff-a32c-4373-8b72-c0c9ab5f1f6f", name: "HTML Viewer", type: ComponentType.HTMLViewer },
    { id: "b3803db5-9971-4d41-9714-71df596415b2", name: "Enum Label", type: ComponentType.EnumLabel },
    { id: "020bc16d-06d5-4e03-8472-502c77641714", name: "Timeline", type: ComponentType.Timeline },
    { id: "b54e4d2c-1d02-47e4-86fe-daebaf80bc58", name: "HiddenInput", type: ComponentType.HiddenInput },
    { id: "bf46b7d8-bda1-440e-a62c-1b857019d033", name: "Image (Base64)", type: ComponentType.Base64Image },
    { id: "7585ffb6-25f4-43fa-877c-504fad12a2e3", name: "QR Scanner", type: ComponentType.QRScanner },
    { id: "f9bb2d33-e610-4c0b-937b-4bb13259fc23", name: "QR Code", type: ComponentType.QRCode },
    { id: "579baffa-db00-4f18-b971-0e1ece4126e1", name: "Feedback Form", type: ComponentType.FeedbackForm },
];