import * as MuiIcons from "@mui/icons-material";

import { createElement } from "react";

export const MUIIcon = ({ iconName, className }: {
    iconName: string,
    className?: string
}) => {
    const icon = (MuiIcons as any)[iconName];
    return <>{icon && createElement(icon, { className })}</>
}