import { ApiTags, rootApi } from "../../../../services/rootApi";

import { IEntityChecklistItem } from "../models";

const TAG = ApiTags.DocumentChecklist;
const getTags = (entities: IEntityChecklistItem[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const entityChecklistItemsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getEntityChecklistItems: builder.query<IEntityChecklistItem[], { entity: string, entityId: string }>({
      query: ({ entity, entityId }) => `/EntityChecklistItems/${entity}/${entityId}`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
    createEntityChecklistItem: builder.mutation<IEntityChecklistItem, Partial<IEntityChecklistItem>>({
      query: (entity) => ({
        url: `/EntityChecklistItems`,
        method: "POST",
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),
    updateEntityChecklistItem: builder.mutation<IEntityChecklistItem, { id: string, entity: Partial<IEntityChecklistItem> }>({
      query: ({ id, entity }) => ({
        url: `/EntityChecklistItems/${id}`,
        method: "PATCH",
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),
    deleteEntityChecklistItem: builder.mutation<void, string>({
      query: (entityId: string) => ({
        url: `/EntityChecklistItems/${entityId}`,
        method: "DELETE",
      }),
      invalidatesTags: [TAG]
    }),

  })
})

export const {
  useGetEntityChecklistItemsQuery,
  useLazyGetEntityChecklistItemsQuery,
  useCreateEntityChecklistItemMutation,
  useUpdateEntityChecklistItemMutation,
  useDeleteEntityChecklistItemMutation
} = entityChecklistItemsApi;