import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const PanelProperties: IProperty[] = [
    { id: Property.Title, name: 'Title', type: PropertyType.String },
    { id: Property.Collapsible, name: 'Collapsable', type: PropertyType.Boolean },
    { id: Property.MaterialIcon, name: 'Icon', type: PropertyType.MaterialIcon },
    { id: Property.HeaderClass, name: 'Header Class', type: PropertyType.String },
    { id: Property.Hidden, name: 'Hidden', type: PropertyType.Boolean },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String }
]

