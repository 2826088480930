import { DateFormat } from "../components/timeline/helper";
import { DisplayFormat } from "../../base/types";
import moment from "moment";

export const formatValue = (value: any, format?: DisplayFormat) => {
    if (!value || !format) return value;

    switch (format) {
        case DisplayFormat.Number:
            return Number(value)
        case DisplayFormat.Money:
            return Number(value).toLocaleString(navigator.language)
        case DisplayFormat.Money2:
            return Number(value)
                .toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        case DisplayFormat.Money3:
            return Number(value)
                .toLocaleString(navigator.language, { maximumFractionDigits: 3, minimumFractionDigits: 3 });
        case DisplayFormat.Money4:
            return Number(value)
                .toLocaleString(navigator.language, { maximumFractionDigits: 4, minimumFractionDigits: 4 });
        case DisplayFormat.Date_1:
            return moment(value).format("MM-DD-YY")
        case DisplayFormat.Date_2:
            return moment(value).format("MM-DD-YYYY")
        case DisplayFormat.Date_3:
            return moment(value).format("DD-MM-YY")
        case DisplayFormat.Date_4:
            return moment(value).format("DD-MM-YYYY")
        case DisplayFormat.Date_5:
            return moment(value).format("DD-MMM-YYYY")
        case DisplayFormat.Date_6:
            return moment(value).format("DD MMM YYYY")
        case DisplayFormat.Date_7:
            return moment(value).format("DD MMMM YYYY")

        case DisplayFormat.Time_1: // HH:MM (24 hrs)a
            return DateFormat.time24HH_MM(value)
        case DisplayFormat.Time_2: //  HH:MM:SS (24 hrs)
            return DateFormat.time24HH_MM_SS(value)
        case DisplayFormat.Time_3: // HH:MM AM (12hrs)
            return DateFormat.time12HH_MM(value)
        case DisplayFormat.Time_4: // HH:MM:SS AM (12hrs)
            return DateFormat.time12HH_MM_SS(value)
        default:
            return value;
    }
}