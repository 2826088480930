import { IPage, PageType } from "../../models"

import { ToolbarPageActions } from "../../../../layout/toolbar"
import { usePageToolbarActions } from "../../hooks"

export const PageToolbar = ({ page }: { page: IPage }) => {
    usePageToolbarActions({ page })

    if (page.actions && (page.type === PageType.CardPart || page.type === PageType.ListPart))
        return <ToolbarPageActions className="mb-2" actions={page.actions} />

    return <></>
}
