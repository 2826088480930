import { DocumentCategoryMetadata, IDocumentCategory } from "../models";
import { useEffect, useState } from "react";

import { FieldFilterType } from "../../../../base/types";
import { IFilterGroup } from "../../../../data";
import { useAuth } from "../../../../../modules/auth";
import { useLazyReadMultipleQuery } from "../../../../services/data/tableDataApi";

// TODO:: status fields should be optimized
export const useGetDocumentCategory = (categoryId?: string) => {
    const [readCategories] = useLazyReadMultipleQuery();
    const { user, siteId: portalId } = useAuth()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [data, setData] = useState<IDocumentCategory>()

    const getDocumentCategory = (categoryId: string) => {
        if (!user)
            return Promise.reject();

        const { userId } = user;
        if (!portalId)
            return Promise.reject();

        setIsSuccess(false)
        setIsError(false)
        setError(undefined)
        setIsLoading(true)

        const { tableId, fields } = DocumentCategoryMetadata
        const fieldList: string[] = [
            fields.id,
            fields.description,
            fields.parentCategoryId,
            fields.maxDocumentSize,
            fields.minDocumentSize]
        const filters: IFilterGroup[] = [{
            group: 0,
            filters: [
                { field: fields.id, type: FieldFilterType.Const, value: categoryId }
            ]
        }]

        readCategories({ userId, portalId, tableId: tableId, filters, fieldList, recordLimit: 1 })
            .unwrap()
            .then(data => {
                const records = data?.records || [];
                if (records.length > 0) {
                    const record = records[0];
                    const category: IDocumentCategory = {
                        id: record[fields.id],
                        description: record[fields.description],
                        parentCategoryId: record[fields.parentCategoryId],
                        maxDocumentSize: record[fields.maxDocumentSize],
                        minDocumentSize: record[fields.minDocumentSize]
                    }

                    setData(category);
                    setIsSuccess(true)

                    return category;
                } else {
                    setData(undefined)
                    setIsError(true)
                    setError(`Document Checklist Id: ${categoryId} not found.`)
                    return Promise.reject()
                }
            })
            .catch(err => {
                setIsError(true)
                setError(err.data?.error?.message || 'Failed to get data')
                return Promise.reject()
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (categoryId)
            getDocumentCategory(categoryId)
        // eslint-disable-next-line
    }, [categoryId])

    return { data, isLoading, isSuccess, isError, error, getDocumentCategory }
}
