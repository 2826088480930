import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const SectionComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const hidden: boolean | undefined = properties[Property.Hidden]
    const tooltip: string | undefined = properties[Property.Tooltip];

    if (mode === "Preview" && hidden === true) {
        return <></>
    }

    return (
        <div className={className} title={tooltip}>
            {children}
        </div>)
}