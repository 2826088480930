import { IControlProps } from "../../../base/models";
import { MUIIcon } from "../../../widgets";
import { Property } from "../../../base/types";
import { StringBinding } from "../../../base/bindings/string";
import { useDesignContext } from "../../../providers";
import { useEffect } from "react";
import { useFromData } from "../panels/FormComponent";
import { useGetProperties } from "../../../base/hooks";
import { useState } from "react";

export const HiddenInputComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const inputValue: string | undefined = properties[Property.ControlValue];
    const [formField, setFormField] = useState<string>()

    const [value, setValue] = useState<any>()
    const { form, setFieldValue } = useFromData()

    useEffect(() => {
        const formBinding = getBinding(Property.Field) as StringBinding
        if (formBinding.type === "Field") {
            setFormField(formBinding.value);
        }

        // eslint-disable-next-line
    }, [properties])

    useEffect(() => {
        if (mode !== "Preview" || inputValue === undefined)
            return;

        setValue(inputValue)
        // eslint-disable-next-line
    }, [inputValue])


    useEffect(() => {
        if (!form || !formField || value === undefined)
            return;

        setFieldValue(formField, value)
        // eslint-disable-next-line
    }, [value])

    if (mode === "Design") {
        return (
            <div className="alert alert-info bg-transparent mb-0 p-0  rounded-0 d-flex flex-wrap align-items-center">
                <div className={"badge badge-info text-nowrap rounded-0 fs-9"}>
                    <MUIIcon iconName="VisibilityOffOutlined" className="me-1" />
                    <span>Hidden</span>
                </div>
                <div className="fs-9 fw-bold fst-italic px-1">
                    {`{${formField}}`}
                </div>
            </div>)
    }

    return (
        <input id={control.controlId} value={value || ''} type={"hidden"} />
    )
}