import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom";

import { ComponentsWrapper } from "../pages/ComponentsWrapper";
import { DashboardWrapper } from "../pages/DashboardWrapper";
import { DesignComponentWrapper } from "../pages/DesignComponentWrapper";
import { DesignPageWrapper } from "../pages/DesignPageWrapper";
import { SecurityFiltersWrapper } from "../pages/SecurityFiltersWrapper";
import { SitePagesWrapper } from "../pages/SitePagesWrapper";
import { SiteSettingsWrapper } from "../pages/SiteSettingsWrapper";
import { SiteUsersWrapper } from "../pages/SiteUsersWrapper";
import { ViewPageWrapper } from "../pages/ViewPageWrapper";
import { useAuth } from "../modules/auth";
import { useEffect } from "react";

const SiteRoutes = () => {
  return (
    <Routes>
      <Route element={<SiteLayout />}>
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='settings' element={<SiteSettingsWrapper />} />
        <Route path='filters' element={<SecurityFiltersWrapper />} />
        <Route path='users' element={<SiteUsersWrapper />} />
        <Route path='page(:pageId)' element={<ViewPageWrapper />} />
        <Route path='site-pages' element={<SitePagesWrapper />} />
        <Route path='components' element={<ComponentsWrapper />} />
        <Route path='design-page(:pageId)' element={<DesignPageWrapper />} />
        <Route path='design-component(:componentId)' element={<DesignComponentWrapper />} />

        <Route index element={<Navigate to="dashboard" />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes >
  )
}

export { SiteRoutes }

const SiteLayout = () => {
  const { user } = useAuth()
  const { siteId } = useParams()
  const { siteId: activeSiteId, setSite } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) return;
    const { portals } = user;

    if (siteId && portals.indexOf(siteId) === -1) {
      navigate('/error/404')
      return;
    }

    if (activeSiteId !== siteId) {
      setSite(siteId)
    }

    // eslint-disable-next-line
  }, [user, activeSiteId, siteId])

  return <>
    <Outlet />
  </>
}

