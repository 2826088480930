import { ChangeEvent, useState } from "react"
import { EnumOption, Enumeration } from "../../../base/types/enumeration";

import Modal from "react-bootstrap/esm/Modal"
import React from "react";
import clsx from "clsx";

export const EditEnumeration = ({ value, className, onChange }: {
    value: Enumeration,
    className?: string,
    onChange: (options: Enumeration) => void,
}) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const hideModal = () => {
        setShowDialog(false);
    }

    return <>
        <div className="fs-8 mb-2">
            <div>
                <p className="fw-bold text-gray-600 mb-1">Type: <span>{value.type}</span></p>
                <p className="fw-bold text-gray-600 mb-1">Values:</p>
                {value.items.map((item, i) => <p key={i} className="fw-semibold m-0">
                    <span className="text-gray-600">{item.key}</span>
                    <span className="mx-2">:</span>
                    <span className="text-primary">{item.value}</span>
                </p>)}
            </div>
            <div className="d-flex align-items-center mt-3">
                <button
                    type="button"
                    className={clsx(className, "btn btn-primary btn-sm py-2")}
                    onClick={() => setShowDialog(true)}>
                    Edit Values
                </button>
            </div>


        </div>
        <EnumValuesModelDialog
            hideModal={hideModal}
            show={showDialog}
            value={value}
            onChange={onChange}
        />
    </>
}

const EnumValuesModelDialog = ({ value, show, hideModal, onChange }: {
    value: Enumeration,
    show: boolean,
    hideModal: () => void,
    onChange: (value: Enumeration) => void
}) => {
    const [localEnum, setLocalEnum] = useState<Enumeration>(value);

    const handleValueTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as "string" | "number";
        setLocalEnum({ type: newValue, items: [] })
    }

    return <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
        <Modal.Header closeButton className="bg-secondary border-0 py-3">
            <h4 className="my-0">Dropdown Options</h4>
        </Modal.Header>
        <Modal.Body>
            <div className="mb-5">
                <label className="form-label fs-7">Data Type</label>
                <select
                    className="form-select form-select-sm"
                    onChange={handleValueTypeChange}
                    value={localEnum.type || "string"}
                >
                    <option value="string">String</option>
                    <option value="number">Number</option>
                </select>
            </div>
            <EnumValuesTable
                value={localEnum}
                onChange={setLocalEnum} />
        </Modal.Body>
        <Modal.Footer className="border-0 py-5">
            <button type="button"
                onClick={() => {
                    onChange(localEnum)
                    hideModal()
                }}
                className="btn btn-sm btn-primary">OK</button>
            <button type="button"
                onClick={hideModal}
                className="btn btn-sm btn-secondary">Cancel</button>
        </Modal.Footer>
    </Modal>
}

const EnumValuesTable = ({ value, onChange }: {
    value: Enumeration,
    onChange: (value: Enumeration) => void,
}) => {
    const handleOnAdd = (option: EnumOption<string | number>) => {
        if (value.type === "string") {
            const newItems = [...value.items, option as EnumOption<string>]
            onChange({ ...value, items: newItems });
        } else if (value.type === "number") {
            const newItems = [...value.items, option as EnumOption<number>]
            onChange({ ...value, items: newItems });
        }
    }

    const handleOnUpdate = (newOption: EnumOption<string | number>) => {
        if (value.type === "string") {
            const newItems = value.items.map(oldOption => oldOption.key === newOption.key ? newOption as EnumOption<string> : oldOption)
            onChange({ ...value, items: newItems });
        } else if (value.type === "number") {
            const newItems = value.items.map(oldOption => oldOption.key === newOption.key ? newOption as EnumOption<number> : oldOption)
            onChange({ ...value, items: newItems });
        }
    }

    const handleOnDelete = (key: string | number) => {
        if (value.type === "string") {
            const newItems = value.items.filter(option => option.key !== key)
            onChange({ ...value, items: newItems });
        } else if (value.type === "number") {
            const newItems = value.items.filter(option => option.key !== key)
            onChange({ ...value, items: newItems });
        }
    }

    return (<table className="table table-sm table-borderless fs-7">
        <thead className="fw-bold">
            <tr>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
                <th scope="col">&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            {value.items.map(option => (
                <React.Fragment key={option.key}>
                    <EditOptionRow
                        value={value}
                        option={option}
                        onDelete={handleOnDelete}
                        onUpdate={handleOnUpdate}
                    />
                </React.Fragment>))}

            <NewOptionRow value={value} onAdd={handleOnAdd} />
        </tbody>
    </table>)
}

const EditOptionRow = ({ value, option, onUpdate, onDelete }: {
    value: Enumeration,
    option: EnumOption<string | number>,
    onUpdate: (prop: EnumOption<string | number>) => void,
    onDelete: (key: string | number) => void,
}) => {
    const getTypedKey = (v?: string) => {
        if (value.type === "string")
            return v || ''
        else if (value.type === "number")
            return Number(v || 0) || 0

        return ''
    }

    return (
        <tr>
            <td className="py-1">
                <input type="text"
                    value={option.key}
                    onChange={(e) => onUpdate({ ...option, key: getTypedKey(e.target.value) })}
                    className="form-control form-control-sm" />
            </td>
            <td className="py-1">
                <input type="text"
                    value={option.value}
                    onChange={(e) => onUpdate({ ...option, value: e.target.value })}
                    className="form-control form-control-sm" />
            </td>
            <td className="py-1">
                <button
                    type="button"
                    onClick={() => onDelete(option.key)}
                    className="btn btn-sm btn-danger py-2">
                    <i className="las la-trash"></i>
                    Delete
                </button>
            </td>
        </tr>)
}

const NewOptionRow = ({ value, onAdd }: {
    value: Enumeration,
    onAdd: (option: EnumOption<string | number>) => void
}) => {
    const initOption: EnumOption<string | number> = value.type === "string" ? { key: '', value: '' } : { key: 0, value: '' };
    const [option, setOption] = useState<EnumOption<string | number>>(initOption);

    const getTypedKey = (v?: string) => {
        if (value.type === "string")
            return v || ''
        else if (value.type === "number")
            return Number(v || 0) || 0

        return ''
    }

    return (
        <tr>
            <td className="py-1">
                <input type="text"
                    value={option.key}
                    onChange={(e) => setOption({ ...option, key: getTypedKey(e.target.value) })}
                    className="form-control form-control-sm" />
            </td>
            <td className="py-1">
                <input type="text"
                    value={option.value}
                    onChange={(e) => setOption({ ...option, value: e.target.value })}
                    className="form-control form-control-sm" />
            </td>
            <td className="py-1">
                <button
                    type="button"
                    onClick={() => {
                        onAdd(option);
                        setOption(initOption);
                    }}
                    className="btn btn-sm btn-primary py-2">
                    <i className="las la-plus"></i>
                    Add
                </button>
            </td>
        </tr>)
}