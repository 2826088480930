import { ApiTags, rootApi } from '../rootApi';

import { ApiResReadMultiple } from '../models';
import { IUserRole } from './models/UserRole.model';

const TAG = ApiTags.UserRole;
const getTags = (entities: IUserRole[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const userRolesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getUserRoles: builder.query<IUserRole[], void>({
      query: () => '/portalUserRoles',
      transformResponse: (res: ApiResReadMultiple<IUserRole>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getUserRole: builder.query<IUserRole, string>({
      query: (id) => `/portalUserRoles(${id})`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    createUserRole: builder.mutation<IUserRole, IUserRole>({
      query: (userRole) => ({
        url: '/portalUserRoles',
        method: 'POST',
        body: userRole
      }),
      invalidatesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updateUserRole: builder.mutation<IUserRole, Partial<IUserRole>>({
      query: (userRole) => ({
        url: `/portalUserRoles(${userRole.id})`,
        method: 'PATCH',
        body: userRole
      }),
      invalidatesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deleteUserRole: builder.mutation<void, string>({
      query: (userRoleId: string) => ({
        url: `/portalUserRoles(${userRoleId})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetUserRolesQuery,
  useGetUserRoleQuery,
  useCreateUserRoleMutation,
  useUpdateUserRoleMutation,
  useDeleteUserRoleMutation,
} = userRolesApi;