import { ButtonStyle, ControlSize, PageMode, Property } from "../../../base/types"

import { DefaultLabel } from "../../widgets"
import { IControlProps } from "../../../base/models"
import { IPage } from "../../page/models"
import { Link } from "react-router-dom"
import { MUIIcon } from "../../../widgets"
import Modal from "react-bootstrap/esm/Modal"
import { PageWithToolbar } from "../../page"
import clsx from "clsx"
import { getButtonClass } from "../buttons/helper"
import { useAuth } from "../../../../modules/auth"
import { useDesignContext } from "../../../providers"
import { useGetProperties } from "../../../base/hooks"
import { useLazyGetPageMetadataQuery } from "../../../services/data/pagesMetadataApi"
import { useState } from "react"

export const RecordNavigationComponent = ({ control, className, children }: IControlProps) => {
    const { siteId } = useAuth()
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const recordId: string | undefined = properties[Property.RecordId];
    const pageId: string | undefined = properties[Property.PageId];
    const modelDialog: boolean = properties[Property.ModelDialog] || false;
    const icon: string | undefined = properties[Property.MaterialIcon]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]

    const [getPageMetadata, { data: page, isLoading }] = useLazyGetPageMetadataQuery();
    const [showDialog, setShowDialog] = useState<boolean>(false)

    const openPageDialog = () => {
        if (!pageId) return;

        if (page && page.id === pageId) {
            setShowDialog(true)
            return;
        }

        getPageMetadata(pageId, true)
            .unwrap()
            .then(() => setShowDialog(true))
    }

    if (mode === "Design") {
        return (
            <div className={clsx(getButtonClass(style, size, "btn btn-link btn-sm text-primary py-0"), className)} title={tooltip}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                <DefaultLabel
                    control={control}
                    propertyId={Property.Label}
                    defaultLabel="Navigate to Record"
                    className="text-hover-primary" />
                {children}
            </div>)
    }

    if (modelDialog) {
        return <>
            <button
                type="button"
                title={tooltip}
                className={clsx(getButtonClass(style, size, "btn btn-link btn-sm text-primary py-0"), className)}
                disabled={!pageId || isLoading}
                onClick={openPageDialog}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                {label && <span>{label}</span>}
                {children}

                {isLoading && <span className='indicator-progress' style={{ display: 'inline-block' }}>
                    <span className='spinner-border spinner-border-sm align-middle ms-1'></span>
                </span>}
            </button>

            <CardPageDialog
                page={page}
                recordId={recordId}
                show={showDialog}
                hideModal={() => setShowDialog(false)} />
        </>
    }

    const cardPageUrl = () => {
        if (!pageId) return "#"

        if (recordId)
            return `/site(${siteId})/page(${pageId})?id=${recordId}`

        return `/site(${siteId})/page(${pageId})?mode=New`
    }

    return (
        <Link
            className={clsx(getButtonClass(style, size, "btn btn-link btn-sm text-primary py-0"), className)}
            to={cardPageUrl()}>
            {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
            {label && <span>{label}</span>}
            {children}
        </Link>)
}

export const CardPageDialog = ({ page, recordId, show, hideModal }: {
    page?: IPage,
    recordId?: string,
    show: boolean,
    hideModal: () => void
}) => {
    return <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
        <Modal.Header closeButton className="bg-gray-300 border-0 py-3">
            <h4 className="my-0">{page?.title || page?.name}</h4>
        </Modal.Header>
        <Modal.Body>
            {page && <PageWithToolbar page={page} pageView={{
                pageMode: PageMode.Edit,
                systemId: recordId
            }} />}
        </Modal.Body>
    </Modal>
}
