const STATUS_KEY = "MSAL-AUTHENTICATION-STATUS"

const start = () => {
    localStorage.setItem(STATUS_KEY, "in-progress")
}

const isInProgress = () => {
    var value = localStorage.getItem(STATUS_KEY)
    return value === "in-progress"
}

const clear = () => {
    localStorage.removeItem(STATUS_KEY)
}

export const MsalStatus  = {
    start,
    isInProgress,
    clear: clear
}