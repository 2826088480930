import { INTTTokenResponse } from "./models";

declare global {
    interface Window {
        AtomPaynetz: any;
    }
}

const loadScript = (FILE_URL: string, async = true, type = "text/javascript") => {
    return new Promise((resolve, reject) => {
        try {
            const scriptEle = document.createElement("script");
            scriptEle.type = type;
            scriptEle.async = async;
            scriptEle.src = FILE_URL;

            scriptEle.addEventListener("load", (ev) => {
                resolve({ status: true });
            });

            scriptEle.addEventListener("error", (ev) => {
                reject({
                    status: false,
                    message: `Failed to load the script ＄{FILE_URL}`
                });
            });

            document.body.appendChild(scriptEle);
        } catch (error) {
            reject(error);
        }
    });
};

export const openPay = (tokenResponse: INTTTokenResponse) => {
    const url = tokenResponse.checkoutJsUrl;

    return loadScript(url)
        .then(() => {
            if (!window.AtomPaynetz) {
                return Promise.reject("AtomPaynetz not loaded.")
            }
            
            const options = {
                "atomTokenId": tokenResponse.token,
                "merchId": tokenResponse.mid,
                "custEmail": tokenResponse.eMail,
                "custMobile": tokenResponse.phoneNumber,
                "returnUrl": tokenResponse.callbackUrl
            }

            new window.AtomPaynetz(options, 'uat');            
        })
        .catch(() => Promise.reject("Paytm CheckoutJS not loaded."));
}