import { IPageAction } from "../../../../design/page-action/models";
import React from "react";
import { ToolbarPageAction } from "./ToolbarPageAction"
import clsx from "clsx";

export const ToolbarPageActions = ({ actions, className }: {
    actions: IPageAction[],
    className?: string
}) => {
    return <div className={clsx(className, "d-flex align-items-center")}>
        {actions.map((action, i) => <React.Fragment key={i}>
            <ToolbarPageAction key={i} action={action} />
        </React.Fragment>)}
    </div>
}

