import { useEffect } from "react"

export const LayoutSplashScreen = () => {
    useEffect(() => {
        const splashScreen = document.getElementById('layout-splash-screen')
        splashScreen?.classList.remove('invisible')
        return () => { splashScreen?.classList.add('invisible') }
    }, [])

    return null
}