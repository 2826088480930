import { ControlSize } from "../../../../../base/types";

export const ControlSizeValue = ({ label, value, className, onChange }: {
    label: string,
    value: any
    className?: string,
    onChange: (value?: ControlSize) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as ControlSize;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return <select
        onChange={handleOnSelectionChange}
        value={value || ""}
        className="form-select form-select-sm form-control-xs">
        <option value={ControlSize.Default}></option>
        <option value={ControlSize.Large}>Large</option>
        <option value={ControlSize.Medium}>Medium</option>
        <option value={ControlSize.Small}>Small</option>
    </select>
}
