import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useGetProperties } from "../../../base/hooks";

export const YouTubeComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const videoId: string | undefined = properties[Property.VideoId];
    const tooltip: string | undefined = properties[Property.Tooltip];

    return (
        <div className={className} title={tooltip}>
            {videoId && <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />}
        </div>
    )
}