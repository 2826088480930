import "./avatar.scss";

import clsx from "clsx";

export const AvatarText = ({ label, title, size, random, className }: {
    label: string,
    size?: number,
    random: boolean,
    title?: string,
    className?: string,
}) => {
    let backgroundColor = {};
    const words = label && label.length > 0 ? label.split(" ") : [];
    const avatarText = words.map(word => word.substring(0, 1)).join('').substring(0, size || 3);

    if (random) {
        const charCodeRed = avatarText.charCodeAt(0);
        const charCodeGreen = avatarText.charCodeAt(1) || charCodeRed;

        const red = Math.pow(charCodeRed, 7) % 200;
        const green = Math.pow(charCodeGreen, 7) % 200;
        const blue = (red + green) % 200;
        backgroundColor = { backgroundColor: `rgb(${red},${green},${blue})` };
    }

    return (<div
        title={title}
        className={clsx("avatar", className)}
        data-label={avatarText.toUpperCase()}
        style={backgroundColor} />)
}