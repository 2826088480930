import { Display, IResponsiveDisplay } from "../models";

import clsx from "clsx";

export const responsiveDisplayClasses = (display?: IResponsiveDisplay) => {
    let classes = "";

    if (display) {
        classes = clsx(classes, displayClass(display.allSizes));
        classes = clsx(classes, displayClass(display.small, "sm"));
        classes = clsx(classes, displayClass(display.medium, "md"));
        classes = clsx(classes, displayClass(display.large, "lg"));
        classes = clsx(classes, displayClass(display.xLarge, "xl"));
    }

    return classes;
}

export const displayPrintClass = (display?: Display) => {
    switch (display) {
        case Display.None: return `d-print-none`;
        case Display.Inline: return `d-print-inline`;
        case Display.InlineBlock: return `d-print-inline-block`;
        case Display.Block: return `d-print-block`;
        case Display.Table: return `d-print-table`;
        case Display.TableCell: return `d-print-table-cell`;
        case Display.TableRow: return `d-print-table-row`;
        case Display.Flex: return `d-print-flex`;
        case Display.InlineFlex: return `d-print-inline-flex`;
    }
}

const displayClass = (display?: Display, size?: string) => {
    switch (display) {
        case Display.None: return `d${size ? `-${size}` : ''}-none`;
        case Display.Inline: return `d${size ? `-${size}` : ''}-inline`;
        case Display.InlineBlock: return `d${size ? `-${size}` : ''}-inline-block`;
        case Display.Block: return `d${size ? `-${size}` : ''}-block`;
        case Display.Table: return `d${size ? `-${size}` : ''}-table`;
        case Display.TableCell: return `d${size ? `-${size}` : ''}-table-cell`;
        case Display.TableRow: return `d${size ? `-${size}` : ''}-table-row`;
        case Display.Flex: return `d${size ? `-${size}` : ''}-flex`;
        case Display.InlineFlex: return `d${size ? `-${size}` : ''}-inline-flex`;
    }
}
