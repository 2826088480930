import { ApiTags, rootApi } from '../../../../services/rootApi';

import { IAttributeOption } from '../models';

const TAG = ApiTags.ServiceAttribute;
const getTags = (entities: IAttributeOption[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const serviceAttributeOptionsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getAttributeOptions: builder.query<IAttributeOption[], void>({
      query: () => `/AttributeOptions`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
  })
})

export const {
  useGetAttributeOptionsQuery,
  useLazyGetAttributeOptionsQuery,
} = serviceAttributeOptionsApi;