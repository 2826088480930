import { TextTransform } from "../../../../base/styles";
import clsx from "clsx";

export const SelectTextTransform = ({ title, value, className, onChange }:{
    title: string,
    value?: TextTransform,
    className?: string,
    onChange: (value?: TextTransform) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as TextTransform;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={TextTransform.Lowercase}>Lowercase</option>
                <option value={TextTransform.Uppercase}>Uppercase</option>
                <option value={TextTransform.Capitalize}>Capitalize</option>
            </select>
        </div>
    )
}