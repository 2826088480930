import * as Yup from 'yup'

import { AttributeDataType, IServiceRequest, ServiceRequestStatus } from "./models";

import { IServiceMetadata } from "./services/useGetServiceMetadata";
import moment from 'moment';

const getFormSchema = (metadata: IServiceMetadata) => {
    const { stages, serviceAttributes, attributes } = metadata;

    const schemas: Yup.ObjectSchema<any>[] = [];
    stages
        .sort((a, b) => (a.stageNumber || 0) - (b.stageNumber || 0))
        .forEach(stage => {
            const schema: any = {};
            const stageAttributes = serviceAttributes.filter(p => p.serviceStageId === stage.id)
            stageAttributes.forEach(serviceAttribute => {
                const attribute = attributes.find(p => p.id === serviceAttribute.attributeId)!

                let regEx: RegExp | undefined = undefined;
                if (attribute.regExpression) {
                    const expr = /\/*\//;
                    if (expr.test(attribute.regExpression)) {
                        // eslint-disable-next-line
                        regEx = eval(attribute.regExpression)
                    } else {
                        regEx = new RegExp(attribute.regExpression)
                    }
                }

                switch (attribute.datatype) {
                    case AttributeDataType.EMail:
                        let emailSchema = Yup.string()
                            .label(attribute.label)
                            .email()

                        if (serviceAttribute.required)
                            emailSchema = emailSchema
                                .required(`${attribute.label} is required.`);

                        if (regEx) {
                            emailSchema = emailSchema
                                .matches(regEx, `Please enter a valid value in ${attribute.label}.`)
                        }

                        schema[attribute.id] = emailSchema;

                        if (serviceAttribute.isVerificationRequired) {
                            schema[`${attribute.id}_verified`] = Yup.boolean()
                                .label(attribute.label)
                                .equals([true], 'You need to enter the correct OTP.')
                                .required('Email verification is required');
                        }

                        break;
                    case AttributeDataType.MobileNo:
                        let mobileNoSchema = Yup.string()
                            .label(attribute.label)
                            .matches(/^([+]\d{2}[ ])?\d{10}$/, `${attribute.label} must be a valid mobile phone number.`)

                        if (serviceAttribute.required)
                            mobileNoSchema = mobileNoSchema
                                .required(`${attribute.label} is required.`);

                        if (regEx) {
                            mobileNoSchema = mobileNoSchema
                                .matches(regEx, `Please enter a valid value in ${attribute.label}.`)
                        }

                        schema[attribute.id] = mobileNoSchema

                        if (serviceAttribute.isVerificationRequired) {
                            schema[`${attribute.id}_verified`] = Yup.boolean()
                                .label(attribute.label)
                                .equals([true], 'You need to enter the correct OTP.')
                                .required('Mobile number verification is required');
                        }
                        break;
                    case AttributeDataType.Text:
                    case AttributeDataType.MultilineText:
                        let stringSchema = Yup.string()
                            .label(attribute.label)

                        if (serviceAttribute.required)
                            stringSchema = stringSchema
                                .required(`${attribute.label} is required.`)

                        if (regEx) {
                            stringSchema = stringSchema
                                .matches(regEx, `Please enter a valid value in ${attribute.label}.`)
                        }

                        schema[attribute.id] = stringSchema;
                        break;
                    case AttributeDataType.Lookup:
                        let lookupSchema = Yup.string()
                            .label(attribute.label)

                        if (serviceAttribute.required)
                            lookupSchema = lookupSchema
                                .required(`${attribute.label} is required.`)

                        schema[attribute.id] = lookupSchema;
                        break;
                    case AttributeDataType.Number:
                    case AttributeDataType.OptionSet:
                        let numberSchema = Yup.number()
                            .label(attribute.label)

                        if (serviceAttribute.required)
                            numberSchema = numberSchema
                                .required(`${attribute.label} is required.`)

                        schema[attribute.id] = numberSchema;
                        break;
                    case AttributeDataType.Money:
                        if (serviceAttribute.required) {
                            const moneySchema = Yup.boolean()
                                .equals([true], `To proceed, you need to make the payment`)
                                .label(attribute.label)
                            schema[attribute.id] = moneySchema
                        } else {
                            const booleanSchema = Yup.boolean()
                                .equals([true, false])
                                .label(attribute.label)
                            schema[attribute.id] = booleanSchema
                        }
                        break;
                    case AttributeDataType.Boolean:
                        if (serviceAttribute.required) {
                            const booleanSchema = Yup.boolean()
                                .equals([true], `${attribute.label} is required.`)
                                .label(attribute.label)
                            schema[attribute.id] = booleanSchema
                        } else {
                            const booleanSchema = Yup.boolean()
                                .equals([true, false])
                                .label(attribute.label)
                            schema[attribute.id] = booleanSchema
                        }

                        break;
                    case AttributeDataType.Date:
                        let dateSchema = Yup.date()
                            .label(attribute.label)

                        if (serviceAttribute.required)
                            dateSchema = dateSchema
                                .required(`${attribute.label} is required.`)

                        schema[attribute.id] = dateSchema;
                        break;
                    case AttributeDataType.DateTime:
                        let timeSchema = Yup.date()
                            .label(attribute.label)

                        if (serviceAttribute.required)
                            dateSchema = timeSchema
                                .required(`${attribute.label} is required.`)

                        schema[attribute.id] = timeSchema;
                        break;
                    case AttributeDataType.DocumentChecklist:
                        schema[attribute.id] = Yup.bool()
                            .label(attribute.label)
                            .equals([true], 'All required documents must be uploaded.')
                            .required(`${attribute.label} is required.`)
                        break;
                    default:
                        break;
                }
            })

            schemas.push(Yup.object().shape(schema))
        })

    return schemas;
}

const getServiceRequestInits = (metadata: IServiceMetadata) => {
    const values: any = {};
    metadata.serviceAttributes.forEach(serviceAttribute => {
        const attribute = metadata.attributes.find(p => p.id === serviceAttribute.attributeId)!
        if (attribute.datatype === AttributeDataType.DocumentChecklist) {
            values[attribute.id] = attribute.documentChecklist;
            return;
        }

        switch (attribute.datatype) {
            case AttributeDataType.Text:
            case AttributeDataType.MultilineText:
            case AttributeDataType.Lookup:
            case AttributeDataType.EMail:
            case AttributeDataType.MobileNo:
                values[attribute.id] = ''
                break;
            case AttributeDataType.Number:
            case AttributeDataType.OptionSet:
            case AttributeDataType.Money:
                values[attribute.id] = 0;
                break;
            case AttributeDataType.Boolean:
                values[attribute.id] = false;
                break;
            case AttributeDataType.Date:
                values[attribute.id] = '';
                break;
            default:
                break;
        }
    })

    return values;
}

const getServiceRequestValues = (serviceRequest: IServiceRequest, metadata: IServiceMetadata) => {
    const values: any = {};

    metadata.serviceAttributes.forEach(serviceAttribute => {
        const attribute = metadata.attributes.find(p => p.id === serviceAttribute.attributeId)!
        if (!attribute || attribute.datatype === AttributeDataType.RichText)
            return;

        if (attribute.datatype === AttributeDataType.DocumentChecklist) {
            values[attribute.id] = attribute.documentChecklist;
            return;
        }

        const requestAttribute = serviceRequest.attributes?.find(p => p.serviceAttributeId === serviceAttribute.id)
        // if (requestAttribute?.attributeValue) {
        const value = requestAttribute?.attributeValue || '';
        switch (attribute.datatype) {
            case AttributeDataType.Text:
            case AttributeDataType.MultilineText:
            case AttributeDataType.Lookup:
            case AttributeDataType.EMail:
            case AttributeDataType.MobileNo:
                values[attribute.id] = value
                break;
            case AttributeDataType.OptionSet:
                if (!value)
                    values[attribute.id] = ''
                else
                    values[attribute.id] = Number(value);
                break;
            case AttributeDataType.Number:
                if (!value)
                    values[attribute.id] = 0
                else
                    values[attribute.id] = Number(value);
                break;
            case AttributeDataType.Money:
            case AttributeDataType.Boolean:
                if (value.toLowerCase() === 'true' || value.toLowerCase() === 'yes' || Number(value) > 0)
                    values[attribute.id] = true
                else
                    values[attribute.id] = false;
                break;
            case AttributeDataType.Date:
                values[attribute.id] = value;
                break;
            case AttributeDataType.DateTime:
                values[attribute.id] = moment(value).format("DD-MM-YYYY hh:mm a");
                break;
            default:
                console.log(`Attribute Type ${attribute.datatype} not handled`)
                break;
        }
        // }
    })

    return values;
}

const getRequestStatusText = (statusCode?: ServiceRequestStatus) => {
    switch (statusCode) {
        case ServiceRequestStatus.InProgress: return "Inprogress";
        case ServiceRequestStatus.Submitted: return "Submitted";
        case ServiceRequestStatus.Approved: return "Approved";
        case ServiceRequestStatus.OnHold: return "On Hold";
        case ServiceRequestStatus.Cancelled: return "Cancelled";
        case ServiceRequestStatus.Rejected: return "Rejected";
        case undefined:
        case ServiceRequestStatus.Draft: return "Draft";
    }
}

export const ServiceReqHelper = {
    schema: getFormSchema,
    initValues: getServiceRequestInits,
    values: getServiceRequestValues,
    status: getRequestStatusText
}