export interface IDocumentChecklistItem {
    id: string,
    documentChecklistId: string,
    lineNo: number,
    documentCategoryId: string,
    description: string,
    required: boolean
}

export const DocumentChecklistItemMetadata = {
    tableId: "33101673",
    fields: {
        id: "Id",
        documentChecklistId: "Document Checklist Id",
        lineNo: "Line No.",
        documentCategoryId: "Document Category Id",
        description: "Description",
        required: "Required"
    }
}
