import { EntityDocumentChecklistMetadata, IEntityDocumentChecklist } from "../models";
import { useEffect, useState } from "react";

import { FieldFilterType } from "../../../../base/types";
import { IFilterGroup } from "../../../../data";
import { useAuth } from "../../../../../modules/auth";
import { useLazyReadMultipleQuery } from "../../../../services/data/tableDataApi";

// TODO:: status fields should be optimized
export const useGetEntityDocumentChecklists = ({ entity, entityId }: {
    entity?: string,
    entityId?: string
}) => {
    const [readRecords] = useLazyReadMultipleQuery();
    const { user, siteId: portalId } = useAuth()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [data, setData] = useState<IEntityDocumentChecklist[]>()

    const refetch = () => {
        if (!user || !entity || !entityId)
            return;

        const { userId } = user;
        if (!portalId) return;

        setIsSuccess(false)
        setIsError(false)
        setError(undefined)
        setIsLoading(true)

        const { tableId, fields } = EntityDocumentChecklistMetadata
        const fieldList: string[] = [
            fields.id,
            fields.tableId,
            fields.recordId,
            fields.recSystemId,
            fields.documentChecklistId,
            fields.status
        ]
        const filters: IFilterGroup[] = [{
            group: 0,
            filters: [
                { field: fields.tableId, type: FieldFilterType.Const, value: entity },
                { field: fields.recSystemId, type: FieldFilterType.Const, value: entityId },
            ]
        }]

        readRecords({ userId, portalId, tableId: tableId, fieldList, recordLimit: 0, filters })
            .unwrap()
            .then(data => {
                const records = data?.records || [];
                setData(records.map(p => ({
                    id: p[fields.id],
                    tableId: p[fields.tableId],
                    recordId: p[fields.recordId],
                    recSystemId: p[fields.recSystemId],
                    documentChecklistId: p[fields.documentChecklistId],
                    status: p[fields.status]
                })));
                setIsSuccess(true)
            })
            .catch(err => {
                setIsError(true)
                setError(err.data?.error?.message || 'Failed to get data')
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        refetch()
        // eslint-disable-next-line
    }, [entity, entityId])

    return {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    }
}
