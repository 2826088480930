import { AlertMessage } from "../../widgets";
import { SelectAttributes } from "./SelectAttributes";
import clsx from "clsx";
import { useEffect } from "react";
import { useReadAttributes } from "../../base/hooks";

export const SelectEntityAttributes = ({ entity, values, className, onChange }: {
    entity: string,
    values: string[],
    className?: string,
    onChange: (values?: string[]) => void,
}) => {
    const { readAttributes, attributes, isError, isLoading, error } = useReadAttributes();

    useEffect(() => {
        readAttributes(entity)
        // eslint-disable-next-line
    }, [entity])

    if (isError || !attributes) {
        return <AlertMessage type="Error" show={isError} message={error} />
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <SelectAttributes
                isLoading={isLoading}
                values={values}
                attributes={attributes}
                onChange={onChange} />
        </div>
    )
}