import { DropdownOption, Property } from "../../../base/types";
import React, { useEffect, useState } from "react";

import { IControlProps } from "../../../base/models";
import { StringBinding } from "../../../base/bindings/string";
import clsx from "clsx";
import { useDesignContext } from "../../../providers";
import { useFromData } from "../panels/FormComponent";
import { useGetProperties } from "../../../base/hooks";

export const DropdownComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const inputValue: string | undefined = properties[Property.ControlValue];
    const required: boolean = properties[Property.Required] || false;
    const disabled: boolean = properties[Property.Disabled] || false;
    const dropdown: DropdownOption[] = properties[Property.Dropdown] || [];

    const [binding, setBinding] = useState<StringBinding>()
    const [value, setValue] = useState<any>()
    const { form, values, setFieldValue } = useFromData()

    useEffect(() => {
        const binding = getBinding(Property.ControlValue) as StringBinding
        if (!binding)
            return;

        setBinding(binding)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (mode !== "Preview")
            return;

        if (inputValue !== undefined)
            setValue(inputValue)
        // eslint-disable-next-line
    }, [inputValue])

    useEffect(() => {
        if (mode !== "Design" || !binding)
            return;

        switch (binding.type) {
            case "Expression":
                setValue(`{${binding.value.query}}`)
                break;
            case "Const":
                setValue(`"${binding.value}"`)
                break;
            case "Field":
                setValue(`{${binding.value}}`)
                break;
        }

        // eslint-disable-next-line
    }, [binding])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field")
            return;

        if (values[binding.value] !== undefined)
            setValue(values[binding.value])
        // eslint-disable-next-line
    }, [values])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field")
            return;

        setFieldValue(binding.value, value)
        // eslint-disable-next-line
    }, [value])

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        setValue(value)

        // eslint-disable-next-line
    }

    return (
        <div className={className}>
            {label &&
                <label
                    title={tooltip}
                    htmlFor={control.controlId}
                    className={clsx("form-label text-gray-600 fs-7", { "required": required })} >
                    {label}
                </label>}
            <select
                className={"form-select form-select-sm"}
                disabled={disabled || binding?.type !== "Field"}
                value={value || ''}
                title={tooltip}
                id={control.controlId}
                onChange={onSelect}>
                {dropdown.map((item, i) => <React.Fragment key={item.value}>
                    <option value={item.value}>{item.label}</option>
                </React.Fragment>)}
            </select>
        </div>
    )
}