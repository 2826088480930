import { FieldFilterType, IEntity, PropertyType } from "../types";
import { IDataLink, IEntityFilter, IPageLink, ITableLookup, IValueExpression } from "../models";
import { useCallback, useEffect, useState } from "react";
import { useDataContext, usePageContext } from "../../design/page/context";

import { Binding } from "../bindings";
import { useFromData } from "../../design/components/panels";

export const useGetProperties = ({ bindings, debug }: { bindings: Binding[], debug?: boolean }) => {
    const [properties, setProperties] = useState<IEntity>({});
    const { type, data: contextData, isSuccess, isLoading, isError } = useDataContext()
    const { page, pageView } = usePageContext()
    const { form, values } = useFromData()

    useEffect(() => {
        if (debug) {
            console.log(`
                    page: ${page?.name}, 
                    sourceObject: ${page?.sourceObject},        
                    type: ${type}, 
                    isSuccess: ${isSuccess},
                    isLoading: ${isLoading}, 
                    isError: ${isError}
                    `)
        }

        if (!page && bindings) {
            return;
        }

        if (!page || !bindings) {
            return;
        }

        if (page?.sourceObject && !isSuccess) {
            return;
        }

        if ((type !== "Static") && (isLoading || isError || !isSuccess)) {
            return;
        }

        try {
            let row: IEntity = {}
            switch (type) {
                case "List":
                    row = contextData.records.length > 0 ? contextData.records[0] : {}
                    break;
                case "Card":
                    row = contextData
                    break;
                case "Static":
                    row = {}
                    break;
                default:
                    console.error(`unknown type : ${type}`)
            }

            if (form && values) {
                row = { ...row, ...values }
            }

            const filters = [...(pageView?.linkFilters || []), ...(pageView?.viewFilters || [])];
            const props = getProperties({ bindings, row, filters, debug });
            setProperties(props);
        } catch (err) {
            console.error(err)
        }
        // eslint-disable-next-line
    }, [isSuccess, bindings, pageView, values])

    const getBinding = useCallback((propId: string) => {
        return bindings?.find(p => p.propId === propId)
    }, [bindings])

    const getPropertyValue = <T>(propertyId: string ) => {
        return bindings.find(p => p.propId === propertyId)?.value as (T | undefined);
    }
    
    return { properties, getBinding, getPropertyValue }
}

export const getProperties = ({ bindings, row, filters: pageFilters, debug }: {
    bindings: Binding[], row: IEntity, filters: IEntityFilter[], debug?: boolean
}) => {
    const data: IEntity = {}

    bindings?.forEach(item => {
        switch (item.propType) {
            case PropertyType.PageLink: {
                const filters = resolveFilters(item.value.filters || [], row, pageFilters);
                let pageLink = { ...item.value, filters }
                const systemId = (row && pageLink.systemId) ? row[pageLink.systemId] : undefined;
                data[item.propId] = { ...pageLink, systemId } as IPageLink;
            } break;
            case PropertyType.DataLink: {
                let filters = resolveFilters(item.value.filters || [], row, pageFilters);
                let dataLink = { ...item.value, filters: filters } as IDataLink
                const systemId = (row && dataLink.systemId) ? row[dataLink.systemId] : undefined;
                data[item.propId] = { ...dataLink, systemId } as IDataLink;
            } break;
            case PropertyType.Dropdown:
                data[item.propId] = item.value;
                break;
            case PropertyType.TableLookup:
                const tableLookup = item.value;
                if (tableLookup && tableLookup.tableId) {
                    const filters = tableLookup.filters?.map<IEntityFilter>(filter => (
                        filter.type === FieldFilterType.Field && row[filter.value] ?
                            { ...filter, type: FieldFilterType.Const, value: row[filter.value] } :
                            filter));
                    const tableId = (item.type === "Const") ?
                        tableLookup.tableId : row[tableLookup.tableId];

                    const idField = (item.type === "Const") ?
                        tableLookup.idField :
                        tableLookup.idField ? row[tableLookup.idField] : undefined;

                    const displayField = (item.type === "Const") ?
                        tableLookup.displayField :
                        tableLookup.displayField ? row[tableLookup.displayField] : undefined;

                    data[item.propId] = { tableId, idField, displayField, filters } as ITableLookup;
                }
                break;
            default:
                if (item.type === "Const") {
                    data[item.propId] = item.value;
                } else if (item.type === "Field") {
                    data[item.propId] = item.value ?
                        getFieldValue(item.value, row, pageFilters) : undefined;
                } else if (item.type === "Expression") {
                    switch (item.value.type) {
                        case "String":
                            data[item.propId] = getStringExprValue(item.value, row, pageFilters);
                            break;
                        case "Boolean":
                            data[item.propId] = getBooleanExprValue(item.value, row, pageFilters)
                            break;
                        case "Number":
                            data[item.propId] = getNumberExprValue(item.value, row, pageFilters)
                            break;
                    }
                }
        }
    });

    return data;
}

const getFieldValue = (name: string, row: IEntity, filters: IEntityFilter[]) => {
    let value = row ? row[name] : undefined
    if (value !== undefined) {
        return value;
    }

    const filter = filters.find(p => p.field === name && p.type === FieldFilterType.Const)
    if (filter) {
        return filter.value
    }
}

const getStringExprValue = (expression: IValueExpression, row: IEntity, filters: IEntityFilter[]) => {
    let queryResult = expression.query;

    try {
        expression.parameters.forEach(param => {
            const value = param.type === "Field" ?
                getFieldValue(param.value, row, filters) :
                param.value;
            queryResult = queryResult.replaceAll(`{${param.name}}`, String(value));
        });

        return queryResult;
    } catch (err) {
        console.error("expression", queryResult)
        console.error(err)
    }
}

const getBooleanExprValue = (expression: IValueExpression, row: IEntity, filters: IEntityFilter[]) => {
    let queryResult = expression.query;

    try {
        expression.parameters.forEach(param => {
            const value = param.type === "Field" ?
                getFieldValue(param.value, row, filters) : param.value;

            if (typeof value === "string")
                queryResult = queryResult.replaceAll(`{${param.name}}`, `"${value}"`)
            else
                queryResult = queryResult.replaceAll(`{${param.name}}`, String(value));
        });

        // eslint-disable-next-line
        return Boolean(eval(queryResult));
    } catch (err) {
        console.error("expression", queryResult)
        console.error(err)
    }
}

const getNumberExprValue = (expression: IValueExpression, row: IEntity, filters: IEntityFilter[]) => {
    let queryResult = expression.query;

    try {
        expression.parameters.forEach(param => {
            const value = param.type === "Field" ?
                getFieldValue(param.value, row, filters) : param.value;

            if (typeof value === "string")
                queryResult = queryResult.replaceAll(`{${param.name}}`, `"${value}"`)
            else
                queryResult = queryResult.replaceAll(`{${param.name}}`, String(value));
        });

        // eslint-disable-next-line
        return Number(eval(queryResult));
    } catch (err) {
        console.error("expression", queryResult)
        console.error(err)
    }
}

const resolveFilters = (filters: IEntityFilter[], data: IEntity, viewFilters: IEntityFilter[]) => {
    return filters.map(filter => {
        if (filter.type !== FieldFilterType.Field)
            return filter;

        // Field value from data
        const value = data[filter.value];
        if (value !== undefined) {
            return { ...filter, type: FieldFilterType.Const, value }
        }

        // Field value from view        
        const viewFilter = viewFilters.find(p => p.field === filter.field)
        if (viewFilter !== undefined) {
            if (viewFilter.type === FieldFilterType.Const)
                return { ...filter, type: FieldFilterType.Const, value: viewFilter.value }
        }

        throw Error(`${filter.value} field value not found in data`)
    });
}


export const resolvePageLink = (pageLink: IPageLink, row: IEntity, pageFilters: IEntityFilter[]) => {
    const filters = resolveFilters(pageLink.filters || [], row, pageFilters);
    let newPageLink = { ...pageLink, filters }
    const systemId = pageLink.systemId ? row[pageLink.systemId] : undefined;
    return { ...newPageLink, systemId } as IPageLink;
}