import { Typeahead } from "react-bootstrap-typeahead";
import clsx from "clsx";
import { useEffect } from "react";
import { useLazyGetSitePagesQuery } from "../../services/data";

export const SelectPage = ({ title, siteId, value, className, onChange, onBlur }: {
    siteId: string,
    title?: string,
    value?: string,
    className?: string,
    onChange: (value: string) => void,
    onBlur?: () => void,
}) => {
    const [getPages, { data: pages, isLoading, isFetching, isError }] = useLazyGetSitePagesQuery();

    useEffect(() => {
        if (!siteId) return;
        getPages(siteId)
        // eslint-disable-next-line
    }, [siteId])

    if (isError) {
        <span className='alert-info' style={{ display: 'block' }}>
            Failed to load page list
        </span>
    }

    const handleOnChange = (selected: Record<string, any>) => {
        const option = selected[0];
        if (option) {
            onChange(option["id"]);
        } else {
            onChange('')
        }
    }

    const getSelected = () => {
        if (!value || !pages || isLoading) return []
        const selectedPages = pages.filter(p => p.id === value)
        if (selectedPages.length === 0) {
            return [{ name: value }]
        }

        return selectedPages
    }

    return (
        <div className={clsx(className, "field-group")}>
            {title && <label className="fs-8 fw-bold text-gray-600">{title}</label>}
            <Typeahead
                id="page"
                className="custom-typeahead typeahead-sm fs-8"
                labelKey={option => `${(option as any).name}`}
                isLoading={isLoading || isFetching}
                clearButton={true}
                options={pages || []}
                filterBy={['name']}
                onChange={handleOnChange}
                onBlur={onBlur}
                selected={getSelected()}
            />
        </div>
    )
}