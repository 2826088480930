import { ApiTags, rootApi } from "../rootApi";

import { ApiResReadMultiple } from "../models";
import { IChatMessage } from "../../data";

const TAG = ApiTags.ChatMessage;
const getTags = (entities: IChatMessage[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const chatMessagesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getMessages: builder.query<IChatMessage[], void>({
      query: () => '/ChatMessages',
      transformResponse: (res: ApiResReadMultiple<IChatMessage>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getMessagesById: builder.query<IChatMessage[], { entity: string, entityId: string }>({
      query: ({ entity, entityId }) => `/ChatMessages/entity(${entity})/${entityId}`,
      transformResponse: (res: ApiResReadMultiple<IChatMessage>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getMessage: builder.query<IChatMessage, string>({
      query: (id) => `/ChatMessages(${id})`,
      providesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    createMessage: builder.mutation<IChatMessage, Partial<IChatMessage>>({
      query: (message) => ({
        url: '/ChatMessages',
        method: 'POST',
        body: message
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updateMessage: builder.mutation<IChatMessage, Partial<IChatMessage>>({
      query: (message) => ({
        url: `/ChatMessages(${message.id})`,
        method: 'PATCH',
        body: message
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deleteMessage: builder.mutation<void, string>({
      query: (messageId: string) => ({
        url: `/ChatMessages(${messageId})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    })
  })
})

export const {
  useGetMessagesQuery,
  useGetMessageQuery,
  useGetMessagesByIdQuery,
  useCreateMessageMutation,
  useUpdateMessageMutation,
  useDeleteMessageMutation,
} = chatMessagesApi;