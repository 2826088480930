import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const RecordNavigationProperties: IProperty[] = [
    { id: Property.MaterialIcon, name: 'Icon', type: PropertyType.MaterialIcon },
    { id: Property.Label, name: 'Label', type: PropertyType.String },
    { id: Property.PageId, name: 'Page', type: PropertyType.Page },
    { id: Property.RecordId, name: 'Record Id', type: PropertyType.String },
    { id: Property.ModelDialog, name: 'Show as Model Dialog', type: PropertyType.Boolean },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.BackgroundStyle, name: 'Style', type: PropertyType.ButtonStyle },
    { id: Property.Size, name: 'Size', type: PropertyType.ControlSize },
    
]
