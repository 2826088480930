import { AlertMessage, MUIIcon, ProgressIndicator } from "../../../widgets"
import { ButtonStyle, ControlSize, Placement, Property } from "../../../base/types"
import { IControlProps, IPageLink } from "../../../base/models"
import { useEffect, useState } from "react"

import { DefaultLabel } from "../../widgets"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { PageViewer } from "../../page/page/PageViewer"
import clsx from "clsx"
import { getButtonClass } from "../buttons/helper"
import { useDesignContext } from "../../../providers"
import { useGetProperties } from "../../../base/hooks"
import { useLazyGetPageMetadataQuery } from "../../../services/data/pagesMetadataApi"

export const OffcanvasComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const icon: string | undefined = properties[Property.MaterialIcon]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]
    const tooltip: string | undefined = properties[Property.Tooltip]
    const heading: string | undefined = properties[Property.Heading]
    const placement: Placement | undefined = properties[Property.Placement]
    const pageLink: IPageLink | undefined = properties[Property.PageLink]

    const [showDialog, setShowDialog] = useState<boolean>(false)

    if (mode === "Design") {
        return (
            <div className={clsx(getButtonClass(style, size, "btn btn-link btn-sm"), className)} title={tooltip}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                <DefaultLabel
                    control={control}
                    propertyId={Property.Label}
                    defaultLabel="Offcanvas"
                    className="text-hover-primary" />
                {children}
            </div>)
    }

    const showOffCanvas = () => {
        setShowDialog(true)
    }

    return (
        <>
            <button
                type="button"
                className={clsx(getButtonClass(style, size, "btn btn-link btn-sm"), className)}
                onClick={showOffCanvas}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                {label && <span>{label}</span>}
                {children}
            </button>

            <OffCanvasModel
                heading={heading}
                placement={placement}
                pageLink={pageLink}
                show={showDialog}
                hideModal={() => setShowDialog(false)} />
        </>)
}

export const OffCanvasModel = ({ show, heading, placement, pageLink, hideModal }: {
    show: boolean,
    heading?: string,
    placement?: Placement,
    pageLink?: IPageLink,
    hideModal: () => void
}) => {
    const [getPageMetadata, { data: page, isLoading, isSuccess, isError, error }] = useLazyGetPageMetadataQuery();

    useEffect(() => {
        if (!pageLink?.pageId) return;
        getPageMetadata(pageLink.pageId, true);
        // eslint-disable-next-line
    }, [pageLink?.pageId])

    const getPlacement = () => {
        switch (placement) {
            case Placement.Start: return "start";
            case Placement.End: return "end";
            case Placement.Top: return "top";
            case Placement.Bottom: return "bottom";
            default: return "start"
        }
    }

    return <Offcanvas show={show} placement={getPlacement()} onHide={hideModal}>
        <Offcanvas.Header closeButton className="border-0 py-3">
            <Offcanvas.Title className="my-0">{heading}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <ProgressIndicator show={isLoading} message="Please wait..." />
            <AlertMessage
                show={isError}
                type="Error"
                message={(error as any)?.data?.error?.message || `Failed to load the page`} />
            {(isSuccess && page && pageLink) && <PageViewer page={page} pageView={{
                systemId: pageLink.systemId,
                pageMode: pageLink.pageMode,
                linkFilters: pageLink.filters
            }} />}
        </Offcanvas.Body>
    </Offcanvas>
}