import { ApiTags, rootApi } from '../rootApi';

import { ApiResReadMultiple } from '../models';
import { IAccessRole } from './models/AccessRole.model';

const TAG = ApiTags.AccessRole;
const getTags = (entities: IAccessRole[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const accessRolesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getAccessRoles: builder.query<IAccessRole[], void>({
      query: () => '/PortalAccessRoles',
      transformResponse: (res: ApiResReadMultiple<IAccessRole>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getAccessRole: builder.query<IAccessRole, string>({
      query: (id) => `/PortalAccessRoles(${id})`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    createAccessRole: builder.mutation<IAccessRole, IAccessRole>({
      query: (entity) => ({
        url: '/PortalAccessRoles',
        method: 'POST',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updateAccessRole: builder.mutation<IAccessRole, Partial<IAccessRole>>({
      query: (entity) => ({
        url: `/PortalAccessRoles(${entity.portalId})`,
        method: 'PATCH',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deleteAccessRole: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/PortalAccessRoles(${id})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetAccessRolesQuery,
  useGetAccessRoleQuery,
  useCreateAccessRoleMutation,
  useUpdateAccessRoleMutation,
  useDeleteAccessRoleMutation,
} = accessRolesApi;