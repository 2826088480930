import { ApiTags, rootApi } from '../../../../services/rootApi';

import { IServiceAttribute } from '../models';

const TAG = ApiTags.ServiceAttribute;
const getTags = (entities: IServiceAttribute[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const serviceAttributesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getServiceAttributes: builder.query<IServiceAttribute[], string>({
      query: (serviceId) => `/ServiceAttributes/service(${serviceId})`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
  })
})

export const {
  useGetServiceAttributesQuery,
  useLazyGetServiceAttributesQuery,
} = serviceAttributesApi;