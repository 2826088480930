import { Typeahead } from 'react-bootstrap-typeahead';
import clsx from "clsx";
import { useEffect } from 'react';
import { useLazyGetPortalActionsByTableIdQuery } from '../../services/data/portal-action';

export const SelectPortalAction = ({ entity, value, className, onChange }: {
    entity?: string,
    value?: string,
    className?: string,
    onChange: (value?: string) => void,
}) => {
    const [getActions, { data: actions, isLoading }] = useLazyGetPortalActionsByTableIdQuery()

    useEffect(() => {
        if (!entity) return;
        getActions(entity)
        // eslint-disable-next-line
    }, [entity])

    const handleOnChange = (selected: Record<string, any>) => {
        const value = selected[0];
        if (value)
            onChange(value["functionName"]);
        else
            onChange(undefined);
    }

    const getSelected = () => {
        if (!value || !actions || isLoading) return []
        const selectedActions = actions.filter(p => p.functionName === value)
        if (selectedActions.length === 0) {
            return [{ name: value }]
        }

        return selectedActions
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <Typeahead
                id="functionName"
                className="custom-typeahead typeahead-sm fs-8"
                labelKey={'name'}
                isLoading={isLoading}
                clearButton={true}
                options={actions || []}
                onChange={handleOnChange}
                selected={getSelected()}
            />
        </div>
    )
}