import { useEffect, useState } from 'react';

import { IRatingQuestion } from '../models/feedback-form.model';
import clsx from 'clsx';

const RatingQuestion = ({ question, value, onChange, onBlur }: {
    question: IRatingQuestion;
    value?: string,
    onChange: (value: string) => void,
    onBlur: () => void
}) => {
    const [rating, setRating] = useState(value ? Number(value) : 0);
    const [hover, setHover] = useState(0);

    useEffect(() => {
        onChange((rating > 0) ? `${rating}` : '')
        // eslint-disable-next-line
    }, [rating])

    return (
        <div className="form-group">
            <label className={clsx("form-label", { required: question.isRequired })}>{question.title}</label>
            <div className="star-rating">
                {[...Array(question.scale)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on" : "off"}
                            onClick={() => setRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span className="star">&#9733;</span>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default RatingQuestion;