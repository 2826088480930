import { IDataAttribute } from "../../base/models";
import { Option } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from 'react-bootstrap-typeahead';
import clsx from "clsx";

export const SelectAttributes = ({ attributes, values, isLoading, className, onChange }: {
    attributes: IDataAttribute[],
    values: string[],
    isLoading?: boolean,
    className?: string,
    onChange: (value: string[]) => void,
}) => {
    const handleOnChange = (selected: Option[]) => {
        const values = selected.map(p => (p as IDataAttribute).attribute);
        if (values)
            onChange(values);
        else
            onChange([]);
    }

    const handleMenuItemRender = (option: Option) => {
        const { attribute, displayName }: { attribute: string, displayName: string } = option as any;

        return <>
            <div className='d-flex flex-column'>
                <label className="fw-bold m-0 p-0">{displayName}</label>
                <label className="fs-8 fw-semibold m-0 p-0 text-gray-600">{attribute}</label>
            </div>
        </>
    }

    const selected = values ? attributes?.filter(field => values.find(p => p === field.attribute)) : [];

    return (
        <div className={clsx(className, "mb-2")}>
            <Typeahead
                id="attribute"
                size="sm"
                className="custom-typeahead typeahead-sm fs-8"
                labelKey='displayName'
                isLoading={isLoading}
                multiple
                renderMenuItemChildren={handleMenuItemRender}
                options={attributes}
                onChange={handleOnChange}
                selected={selected}
            />
        </div>
    )
}