import { DisplayFormat, Property } from "../../../base/types";

import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import { MUIIcon } from "../../../widgets";
import clsx from "clsx";
import { formatValue } from "../../helpers";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

type Props = { as: React.ElementType }
export const HeadingComponent = ({ as: AsComponent, control, className, children }: IControlProps & Props) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const text: string | undefined = properties[Property.Heading];
    const displayFormat: DisplayFormat | undefined = properties[Property.Format];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const icon: string | undefined = properties[Property.MaterialIcon]

    if (mode === "Design") {
        return (
            <AsComponent className={className} title={tooltip}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!text })} />}
                <DefaultLabel
                    control={control}
                    propertyId={Property.Heading}
                    defaultLabel="Heading" />
                {children}
            </AsComponent>)
    }

    return (
        <AsComponent className={className} title={tooltip}>
            {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!text })} />}
            <span>{formatValue(text, displayFormat)}</span>
            {children}
        </AsComponent>)
}