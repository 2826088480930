import { AlertMessage, ProgressIndicator } from '../../widgets'
import { CreateEntityRelation, UpdateEntityRelation } from './components'

import { ISite } from "../../services/auth/models"
import React from 'react'
import { useReadEntityRelationsQuery } from '../../services/auth'

export const EditEntityRelations = ({ site, className }: {
    site: ISite,
    className?: string
}) => {
    const { data, isLoading, isFetching, isError, isSuccess } = useReadEntityRelationsQuery({ siteId: site.portalId })

    return <>
        <ProgressIndicator show={isLoading || isFetching} message='Loading...' />
        <AlertMessage show={isError} type="Error" message='Failed to load entity relations' />

        <div className="table-responsive min-h-350px">
            <table className='table table-sm'>
                <thead className='fw-bold'>
                    <tr>
                        <th className='px-0'>Entity</th>
                        <th className='px-2'>Attribute</th>
                        <th className='px-2'>User Entity's Attribute</th>
                        <th className='w-175px'></th>
                    </tr>
                </thead>
                <tbody>
                    {isSuccess && <>
                        {data.map(relation => <React.Fragment key={relation.id}>
                            <UpdateEntityRelation site={site} relation={relation} />
                        </React.Fragment>)}
                    </>}
                    <CreateEntityRelation site={site} />
                </tbody>
            </table>
        </div>
    </>
}


