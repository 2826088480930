import { EditSiteUsers } from "../framework/sites/site-users"
import { PageTitle } from "../layout/core"
import { useAuth } from "../modules/auth"

export const SiteUsersWrapper = () => {
    const { site } = useAuth()

    if (!site) return <></>

    return (
        <>
            <PageTitle breadcrumbs={[]}>{`${`${site?.title} Users` || 'Loading...'}`}</PageTitle>
            <div>
                <EditSiteUsers site={site} />
            </div>
        </>)
}