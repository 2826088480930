import { AlertMessage, ProgressIndicator } from "../../../widgets";
import { IControlProps, IPageLink } from "../../../base/models";
import { useEffect, useState } from "react";

import { Property } from "../../../base/types";
import { getPageLinkUrl } from "../../../base/helpers";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";
import { useNavigate } from "react-router-dom";
import { useScanMutation } from "./services/qrScannerApi";

export const QRScannerComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const entity: string | undefined = properties[Property.Entity];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const pageLink: IPageLink | undefined = properties[Property.PageLink];
    const action: string | undefined = properties[Property.PortalAction];

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const navigate = useNavigate()
    const { siteId } = useAuth()
    const queryParameters = new URLSearchParams(window.location.search)
    const referenceNo = queryParameters.get("id") || undefined;
    const [scan] = useScanMutation()

    useEffect(() => {
        if (mode === "Design")
            return;

        if (!siteId || !entity || !action || !referenceNo)
            return;

        executeScan()

        // eslint-disable-next-line
    }, [siteId, entity, action, referenceNo])

    const executeScan = () => {
        if (mode === "Design")
            return;

        if (isLoading)
            return;

        if (!siteId || !entity || !action || !referenceNo) {
            console.log(`siteId: ${siteId}, entity: ${entity}, referenceNo: ${referenceNo}, action: ${action}`)
            setError('QR Scan inputs are incomplete.')
            return;
        }

        setError('')
        setLoading(true)
        scan({ siteId, entity, referenceNo, action })
            .unwrap()
            .then((data) => {
                if (data.status === true) {
                    setError('QR Scan completed')
                    if (siteId && pageLink && pageLink.pageId) {
                        navigate(getPageLinkUrl(siteId, pageLink))
                    }
                } else {
                    var message = JSON.parse(data.message);
                    setError(message.error.message || data.message)
                }
            })
            .catch((err) => {
                console.log("error", err);
                setError('QR Scan rejected')
            })
            .finally(() => setLoading(false))
    }

    if (mode === "Design") {
        return <div>
            <div className="alert alert-info fs-8 mb-0">
                <div className="badge badge-info mb-2">QR Scanner Component</div>

                {(!pageLink?.pageId) && <>
                    <p className="m-0 p-0 text-danger my-1">Page not selected.</p>
                </>}
            </div>
        </div>
    }

    return (
        <div className={className} title={tooltip}>
            <ProgressIndicator show={isLoading} message="Please wait..." />
            <AlertMessage show={!!error} message={error} />
            <button type="button" className="btn btn-primary" onClick={executeScan}>Try Again</button>
        </div>
    )
}