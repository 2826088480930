import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const ServicesProperties: IProperty[] = [
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.PageId, name: 'Request Page', type: PropertyType.Page },
    { id: Property.Hidden, name: 'Hidden', type: PropertyType.Boolean },
    { id: Property.ProgramType, name: 'Program Type', type: PropertyType.String },
    { id: Property.GenderType, name: 'Gender Type', type: PropertyType.String },
]
