import { ActionCallback, useToolbar } from "../../../layout";
import { useLayoutEffect, useRef } from "react";

import { ICustomComponent } from "../models";
import { useDesignContext } from "../../../providers";
import { useUpdateComponentMetadataMutation } from "../../../services/data/componentsMetadataApi";

export const useDesignerToolbarActions = ({ component }: { component: ICustomComponent }) => {
    const [updateComponentMetadata, { isError, error, isLoading }] = useUpdateComponentMetadataMutation();
    const { mode, setMode } = useDesignContext();
    const { setButtons, setOnAction } = useToolbar();

    const onToolbarAction = async (actionId: string) => {
        switch (actionId) {
            case 'design':
                setMode(mode === "Design" ? "Preview" : "Design")
                break;
            case 'save':
                await updateComponentMetadata(component)
                break;
        }
    }

    const onActionRef = useRef<ActionCallback>(onToolbarAction)

    useLayoutEffect(() => {
        onActionRef.current = onToolbarAction
        setOnAction(onActionRef)

        setButtons([{
            id: 'design',
            label: mode === "Design" ? 'Preview' : 'Design',
            className: 'btn btn-secondary btn-sm py-2',
            icon: mode === "Design" ? 'PreviewOutlined' : 'ExposureOutlined',
        }, {
            id: 'save',
            label: 'Save',
            className: 'btn btn-primary btn-sm py-2',
            icon: 'SaveOutlined',
        }])

        // eslint-disable-next-line
    }, [isLoading, mode, component])

    return { isLoading, isError, error }
}
