import { AlertMessage, Spinner, UploadDocumentButton, When } from "../../../../widgets"

import React from "react"
import clsx from "clsx"
import { useGetDocumentTypes } from "../hooks"

export const DocumentTypeSelection = ({ categoryId, className, onUpload }: {
    categoryId: string,
    className?: string,
    onUpload?: (documentTypeId: string, file: File) => Promise<void>
}) => {
    const { data, isLoading, isSuccess, isError, error } = useGetDocumentTypes({ categoryId })
    
    const handleUpload = (documentTypeId: string, file: File): Promise<void> => {
        if (onUpload)
            return onUpload(documentTypeId, file)

        return Promise.reject();
    }

    return (
        <div className={clsx(className)}>
            <AlertMessage show={isError} type="Error" message={error} />
            <Spinner show={isLoading} />
            <When condition={isSuccess}>
                {data && <>
                    {data.length > 1 && <div className="text-muted fs-8 fst-italic mb-1">
                        <span>Click any one of the below documents.</span>
                    </div>}
                    <div className="d-flex">
                        {data.map(item => <React.Fragment key={item.id}>
                            <UploadDocumentButton
                                title={item.description}
                                subtitle={`Upload ${item.description.toLowerCase()}`}
                                className="me-5 mb-5"
                                onUpload={(file) => handleUpload(item.id, file)} />
                        </React.Fragment>)}
                    </div>
                </>}
            </When>
        </div>
    )
}

