import { IPage, PageType } from "../../models";

import { Checkbox } from "../../../styles/components/Checkbox";
import { SelectEntityAttributes } from "../../../widgets";

export const ListPageProperties = ({ page, onChange, className }: {
    page: IPage,
    className?: string,
    onChange: (page: IPage) => void,
}) => {
    const onChangePagination = (value?: boolean) => {
        onChange({ ...page, pagination: value });
    }

    const onChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value || 0);
        onChange({ ...page, recordLimit: value });
    }

    const onChangeSortFields = (values?: string[]) => {
        const orderBy = page.orderBy || { fields: [] };
        onChange({ ...page, orderBy: { ...orderBy, fields: values || [] } })
    }

    const onChangeDescending = (value?: boolean) => {
        const orderBy = page.orderBy || { descending: false, fields: [] };
        onChange({ ...page, orderBy: { ...orderBy, descending: value } })
    }

    if (!page.sourceObject || (page.type !== PageType.List && page.type !== PageType.ListPart))
        return <>Not applicable</>

    return <>
        <div className="row">
            <div className="col-xl-6 row">
                <div className="mb-2 col-xl-12">
                    <Checkbox
                        title="Enable Pagination"
                        value={page.pagination === true}
                        onChange={onChangePagination} />
                </div>
                <div className="mb-2 col-xl-12">
                    <label className="field-label fw-bold text-gray-600 fs-8">Page Size</label>

                    <div className={"field-group"}>
                        <input type="number"
                            onChange={onChangePageSize}
                            value={page.recordLimit}
                            className="form-control form-control-sm form-control-xs " />
                    </div>
                </div>
            </div>
            <div className="col-xl-6 row">
                <div className="col-xl-12">
                    <div className={"field-group"}>
                        <label className="fw-bold text-gray-600 fs-8">Sort by</label>
                        <SelectEntityAttributes
                            values={page.orderBy?.fields || []}
                            entity={page.sourceObject}
                            onChange={onChangeSortFields} />
                    </div>
                </div>
                <div className="mb-2 col-xl-12">
                    <Checkbox
                        title="Descending Order"
                        value={page.orderBy?.descending === true}
                        onChange={onChangeDescending} />
                </div>
            </div>
        </div>
    </>
}