import { AuthenticatedSignIn, UnauthenticatedSignIn } from "./components";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { useAuthProviders } from "./AuthProvider";

export const SignInButton = ({ className }: { className?: string }) => {
    const { authTypes } = useAuthProviders()

    if (authTypes.indexOf("MSAL") === -1)
        return <></>

    return (
        <>
            <UnauthenticatedTemplate>
                <UnauthenticatedSignIn className={className} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <AuthenticatedSignIn className={className} />
            </AuthenticatedTemplate>
        </>
    )
}