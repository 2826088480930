import { MediaImage } from "../framework/design/components";
import { PageTitle } from "../layout/core";
import { ProgressIndicator } from "../framework/widgets";
import { useAuth } from "../modules/auth";
import { useEffect } from "react";
import { useLazyGetSiteQuery } from "../framework/services/auth";

export const DefaultDashboard = () => {
    const { user, siteId } = useAuth();
    const [getSite, { data: site, isLoading }] = useLazyGetSiteQuery()

    useEffect(() => {
        if (!siteId) return;

        getSite(siteId);
        // eslint-disable-next-line
    }, [user])

    if (isLoading)
        return <ProgressIndicator show={isLoading} message='Please wait...' />

    return <>
        <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>

        <div className="d-flex flex-row align-items-center my-10 position-relative">
            {site?.logo && <MediaImage className="w-100px" mediaId={site?.logo} />}
            <h2 className='text-gray-600 mt-10'>{site?.title}</h2>
            {site?.description ?
                <p>{site?.description}</p> :
                <p className='text-muted fs-7'>
                    No dashboard assigned to your profile. Please contact the support team.
                </p>
            }
        </div>
    </>
}
