import { DataContextProvider, useDataContext } from "../../page/context";
import { IDataSource, IEntity, Property } from "../../../base/types";

import { IControlProps } from "../../../base/models";
import React from "react";
import { useDesignContext } from "../../../providers";
import { useEffect } from "react";
import { useGetProperties } from "../../../base/hooks";

export const ListComponent = (props: IControlProps) => {
    const { control, className, children } = props
    const { properties } = useGetProperties({ bindings: control.bindings });
    const tooltip: string | undefined = properties[Property.Tooltip];
    const { mode } = useDesignContext()

    if (mode === "Design") {
        return (
            <div className={className}>
                {children}
            </div>)
    }

    return (
        <div className={className} title={tooltip}>
            <ListRows {...props}>
                {children}
            </ListRows>
        </div>
    )
}

export const ListRows = ({ children }: { children?: React.ReactNode }) => {
    const { type, data, isLoading, isSuccess, isError, dataSource } = useDataContext()
    if (isLoading || isError || !isSuccess || !data || type !== "List")
        return <></>

    return <>
        {data.records.map((row, index) => <React.Fragment key={index}>
            <DataContextProvider>
                <ListRowProducer data={row} dataSource={dataSource} />
                <div>{children}</div>
            </DataContextProvider>
        </React.Fragment>)}
    </>
}

const ListRowProducer = ({ data, dataSource }: { data: IEntity, dataSource?: IDataSource }) => {
    const { setData, setDataSource } = useDataContext()

    useEffect(() => {
        if (!dataSource) return;
        
            setDataSource(dataSource)            
        // eslint-disable-next-line
    }, [dataSource])

    useEffect(() => {           
        setData({ type: "Card", data, isLoading: false, isSuccess: true, isError: false })
        // eslint-disable-next-line
    }, [data])

    return <></>
}

