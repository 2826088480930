import { ApiTags, rootApi } from '../rootApi';

import { ApiResReadMultiple } from '../models';
import { IUser } from './models/User.model';

const TAG = ApiTags.User;
const getTags = (entities: IUser[]) => [...entities.map((p) => ({ type: TAG, id: p.userId })), TAG]
const usersApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getUsers: builder.query<IUser[], void>({
      query: () => '/portalUsers',
      transformResponse: (res: ApiResReadMultiple<IUser>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getUser: builder.query<IUser, string>({
      query: (id) => `/portalUsers(${id})`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.userId }, TAG] : [TAG]
    }),

    createUser: builder.mutation<IUser, IUser>({
      query: (user) => ({
        url: '/portalUsers',
        method: 'POST',
        body: user
      }),
      invalidatesTags: (result) =>
        result ? [{ type: TAG, id: result.userId }, TAG] : [TAG]
    }),

    updateUser: builder.mutation<IUser, { id: string, user: Partial<IUser> }>({
      query: ({ id, user }) => ({
        url: `/portalUsers/${id}`,
        method: 'PATCH',
        body: user
      }),
      invalidatesTags: (result) =>
        result ? [{ type: TAG, id: result.userId }, TAG] : [TAG]
    }),

    deleteUser: builder.mutation<void, string>({
      query: (userId: string) => ({
        url: `/portalUsers/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApi;