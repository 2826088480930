import { IPage } from "../models";
import { IPageControl } from "../../../base/models";

export const resetPageSequenceNos = (page: IPage): IPage => {
    const sequence = { value: 0 };
    return {
        ...page,
        controls: !page.controls ? undefined : resetSequenceNoInner(page.controls, sequence)
    }
}

const resetSequenceNoInner = (controls: IPageControl[], sequence: { value: number }) => {
    const newControls: IPageControl[] = []
    controls.forEach(ctrl => {
        newControls.push({
            ...ctrl,
            sequence: getNextSequenceNo(sequence),
            controls: !ctrl.controls ? undefined : resetSequenceNoInner(ctrl.controls, sequence)
        });
    })

    return newControls;
}

const getNextSequenceNo = (sequence: { value: number }) => {
    sequence.value += 1;
    return sequence.value;
}