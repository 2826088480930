import { PanelTitle } from "./components"
import clsx from "clsx"

export const Panel = ({ header, title, icon, headerClass, className, children }: {
    header?: string,
    title?: string,
    icon?: string,
    headerClass?: string,
    className?: string,
    children?: React.ReactNode
}) => {

    return (
        <div className={clsx(className, "card")}>
            <PanelTitle
                header={header}
                title={title}
                icon={icon}
                headerClass={headerClass}
                className={"card-header border-0 min-h-5px py-2"}
            />
            <div className="card-body px-10 pt-0 pb-5">
                {children}
            </div>
        </div>)
}