import { ApiTags, rootApi } from '../../../../services/rootApi';

import { IAttributeMetadata } from '../models';

const TAG = ApiTags.AttributeMetadata;
const getTags = (entities: IAttributeMetadata[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const attributesMetadataApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getAttributes: builder.query<IAttributeMetadata[], void>({
      query: () => `/AttributesMetadata`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
  })
})

export const {
  useGetAttributesQuery,
  useLazyGetAttributesQuery,
} = attributesMetadataApi;