import { AlertMessage, MUIIcon, Spinner } from "../../../../widgets"

import { IPaymentButtonProps } from "../IPaymentButtonProps"
import clsx from "clsx"
import { getButtonClass } from "../../buttons/helper"
import { getPageLinkUrl } from "../../../../base/helpers"
import { openPay } from "../ntt-helper"
import { toAbsoluteUrl } from "../../../../../helpers"
import { useAuth } from "../../../../../modules/auth"
import { useGetNTTTokenMutation } from "../services"
import { useState } from "react"

export const NTTButton = ({
    orderNo,
    enrolmentNo,
    firstName,
    email,
    phoneNumber,
    entity,
    entityId,
    amount,
    successPage,
    failedPage,
    disabled,
    tooltip,
    style,
    size,
    className,
    icon,
    label,
    currency,
    children
}: IPaymentButtonProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const { siteId, user } = useAuth()
    const [getNTTToken] = useGetNTTTokenMutation()

    const handleOnClick = () => {
        if (!siteId || !user || !successPage || !failedPage) return;
        setIsSubmitting(true)
        setError('')

        const successPageUrl = getPageLinkUrl(siteId, successPage);
        const failedPageUrl = getPageLinkUrl(siteId, failedPage);

        getNTTToken({
            orderNo,
            enrolmentNo,
            name: firstName || '',
            email: email || '',
            phoneNumber: phoneNumber || '',
            entity,
            entityId,
            amount,
            successPageUrl,
            failedPageUrl,
            currency: currency || "INR"
        })
            .unwrap()
            .then(data => {
                openPay(data)
            })
            .catch(() => {
                setError('Failed to generate payment request.');
                setIsSubmitting(false)
            })
            .finally()
    }

    return (
        <>
            <button
                disabled={disabled === true || isSubmitting === true || !user || !amount}
                type="button"
                title={tooltip}
                onClick={handleOnClick}
                className={clsx(getButtonClass(style, size, "btn-secondary"), className)}
            >
                <Spinner show={isSubmitting} className="me-1" />
                <img
                    alt='img'
                    className='h-20px'
                    src={toAbsoluteUrl('/media/logos/Ntt-logo-blck-01.png')}
                />
                {(!isSubmitting && icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                {label && <span>{label}</span>}
                {children}
            </button>
            <AlertMessage type="Error" show={!!error} message={error} />
        </>
    )
}