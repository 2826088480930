import { FontSize, FontStyle, FontWeight, IResponsiveTextAlignment, LetterSpacing, LineHeight, TextAlignment, TextColor, TextDecoration, TextHoverColor, TextOpacity, TextTransform, TextWrap } from '../models';

import { ITextStyle } from '../models';
import clsx from 'clsx';

export const textStyleClasses = (textStyle?: ITextStyle) => {
    let classes = '';

    if (textStyle) {
        classes = clsx(classes, responsiveTextAlignmentClasses(textStyle.alignment));
        classes = clsx(classes, textWrapClass(textStyle.wrap));
        classes = clsx(classes, fontWeightClass(textStyle.weight));
        classes = clsx(classes, textBreakClass(textStyle.textBreak));
        classes = clsx(classes, textTransformClass(textStyle.transform));
        classes = clsx(classes, textSizeClass(textStyle.size));
        classes = clsx(classes, fontStyleClass(textStyle.style));
        classes = clsx(classes, textHeightClass(textStyle.height));
        classes = clsx(classes, fontMonoClass(textStyle.fontMono));
        classes = clsx(classes, textDecorationClass(textStyle.decoration));
        classes = clsx(classes, textResetClass(textStyle.reset));
        classes = clsx(classes, textColorClass(textStyle.color));
        classes = clsx(classes, textHoverColorClass(textStyle.hoverColor));
        classes = clsx(classes, textOpacityClass(textStyle.opacity));
        classes = clsx(classes, letterSpacingClass(textStyle.letterSpacing));
        classes = clsx(classes, textJustifyClass(textStyle.justify));
    }

    return classes;
}

const textHoverColorClass = (color?: TextHoverColor) => {
    switch (color) {
        case TextHoverColor.Primary: return 'text-hover-primary';
        case TextHoverColor.Secondary: return 'text-hover-secondary';
        case TextHoverColor.Success: return 'text-hover-success';
        case TextHoverColor.Danger: return 'text-hover-danger';
        case TextHoverColor.Warning: return 'text-hover-warning';
        case TextHoverColor.Info: return 'text-hover-info';
        case TextHoverColor.Light: return 'text-hover-light';
        case TextHoverColor.Dark: return 'text-hover-dark';
        case TextHoverColor.White: return 'text-hover-white';

        case TextHoverColor.InversePrimary: return 'text-hover-inverse-primary';
        case TextHoverColor.InverseSecondary: return 'text-hover-inverse-secondary';
        case TextHoverColor.InverseSuccess: return 'text-hover-inverse-success';
        case TextHoverColor.InverseInfo: return 'text-hover-inverse-info';
        case TextHoverColor.InverseLight: return 'text-hover-inverse-light';
        case TextHoverColor.InverseWarning: return 'text-hover-inverse-warning';
        case TextHoverColor.InverseDanger: return 'text-hover-inverse-danger';
        case TextHoverColor.InverseDark: return 'text-hover-inverse-dark';
        case TextHoverColor.InverseWhite: return 'text-hover-inverse-white';
    }
}
const letterSpacingClass = (letterSpacing?: LetterSpacing) => {
    switch (letterSpacing) {
        case LetterSpacing.S1: return 'ls-1';
        case LetterSpacing.S2: return 'ls-2';
        case LetterSpacing.S3: return 'ls-3';
        case LetterSpacing.S4: return 'ls-4';
        case LetterSpacing.S5: return 'ls-5';
    }
}

const textJustifyClass = (justify?: boolean) => {
    return justify === true ? 'text-justify' : '';
}

const fontWeightClass = (weight?: FontWeight) => {
    switch (weight) {
        case FontWeight.Bold: return 'fw-bold';
        case FontWeight.Bolder: return 'fw-bolder';
        case FontWeight.Semibold: return 'fw-semibold';
        case FontWeight.Normal: return 'fw-normal';
        case FontWeight.Light: return 'fw-light';
        case FontWeight.Lighter: return 'fw-lighter';
    }
}

const textOpacityClass = (opacity?: TextOpacity) => {
    switch (opacity) {
        case TextOpacity.Opacity75: return 'text-Opacity-75';
        case TextOpacity.Opacity50: return 'text-Opacity-50';
        case TextOpacity.Opacity25: return 'text-Opacity-25';
        case TextOpacity.Opacity10: return 'text-Opacity-10';
    }
}

const textColorClass = (color?: TextColor) => {
    switch (color) {
        case TextColor.Primary: return 'text-primary';
        case TextColor.Secondary: return 'text-secondary';
        case TextColor.Success: return 'text-success';
        case TextColor.Danger: return 'text-danger';
        case TextColor.Warning: return 'text-warning';
        case TextColor.Info: return 'text-info';
        case TextColor.Light: return 'text-light';
        case TextColor.Dark: return 'text-dark';
        case TextColor.Body: return 'text-body';
        case TextColor.Muted: return 'text-muted';
        case TextColor.White: return 'text-white';
        case TextColor.Black50: return 'text-black-50';
        case TextColor.White50: return 'text-white-50';
        case TextColor.Gray100: return 'text-gray-100';
        case TextColor.Gray200: return 'text-gray-200';
        case TextColor.Gray300: return 'text-gray-300';
        case TextColor.Gray400: return 'text-gray-400';
        case TextColor.Gray500: return 'text-gray-500';
        case TextColor.Gray600: return 'text-gray-600';
        case TextColor.Gray700: return 'text-gray-700';
        case TextColor.Gray800: return 'text-gray-800';
        case TextColor.Gray900: return 'text-gray-900';

        case TextColor.LightPrimary: return 'text-light-primary';
        case TextColor.LightSuccess: return 'text-light-success';
        case TextColor.LightInfo: return 'text-light-info';
        case TextColor.LightWarning: return 'text-light-warning';
        case TextColor.LightDanger: return 'text-light-danger';
        case TextColor.LightDark: return 'text-light-dark';

        case TextColor.InversePrimary: return 'text-inverse-primary';
        case TextColor.InverseSecondary: return 'text-inverse-secondary';
        case TextColor.InverseSuccess: return 'text-inverse-success';
        case TextColor.InverseInfo: return 'text-inverse-info';
        case TextColor.InverseLight: return 'text-inverse-light';
        case TextColor.InverseWarning: return 'text-inverse-warning';
        case TextColor.InverseDanger: return 'text-inverse-danger';
        case TextColor.InverseDark: return 'text-inverse-dark';
        case TextColor.InverseWhite: return 'text-inverse-white';
    }
}

const textResetClass = (reset?: boolean) => {
    return reset === true ? 'text-reset' : '';
}

const textDecorationClass = (decoration?: TextDecoration) => {
    switch (decoration) {
        case TextDecoration.Underline: return 'text-decoration-underline';
        case TextDecoration.LineThrough: return 'text-decoration-line-through';
    }
}

const fontMonoClass = (fontMono?: boolean) => {
    return fontMono === true ? 'font-monospace' : '';
}

const textHeightClass = (height?: LineHeight) => {
    switch (height) {
        case LineHeight.Normal: return 'lh-1';
        case LineHeight.Small: return 'lh-sm';
        case LineHeight.Base: return 'lh-base';
        case LineHeight.Large: return 'lh-lg';
        case LineHeight.xLarge: return 'lh-xl';
        case LineHeight.xxLarge: return 'lh-xxl';
    }
}

const fontStyleClass = (style?: FontStyle) => {
    switch (style) {
        case FontStyle.Italic: return 'fst-italic';
        case FontStyle.Normal: return 'fst-normal';
    }
}

const textSizeClass = (size?: FontSize) => {
    switch (size) {
        case FontSize.FS1: return 'fs-1';
        case FontSize.FS2: return 'fs-2';
        case FontSize.FS3: return 'fs-3';
        case FontSize.FS4: return 'fs-4';
        case FontSize.FS5: return 'fs-5';
        case FontSize.FS6: return 'fs-6';
        case FontSize.FS7: return 'fs-7';
        case FontSize.FS8: return 'fs-8';
        case FontSize.FS9: return 'fs-9';
        case FontSize.FS10: return 'fs-10';
        case FontSize.Base: return 'base';
        case FontSize.Fluid: return 'fluid';
        case FontSize.FS2x: return 'fs-2x';
        case FontSize.FS2qx: return 'fs-2qx';
        case FontSize.FS2hx: return 'fs-2hx';
        case FontSize.FS2tx: return 'fs-2tx';
        case FontSize.FS3x: return 'fs-3x';
        case FontSize.FS3qx: return 'fs-3qx';
        case FontSize.FS3hx: return 'fs-3hx';
        case FontSize.FS3tx: return 'fs-3tx';
        case FontSize.FS4x: return 'fs-4x';
        case FontSize.FS4qx: return 'fs-4qx';
        case FontSize.FS4hx: return 'fs-4hx';
        case FontSize.FS4tx: return 'fs-4tx';
        case FontSize.FS5x: return 'fs-5x';
        case FontSize.FS5qx: return 'fs-5qx';
        case FontSize.FS5hx: return 'fs-5hx';
        case FontSize.FS5tx: return 'fs-5tx';
    }
}

const textTransformClass = (transform?: TextTransform) => {
    switch (transform) {
        case TextTransform.Lowercase: return 'text-lowercase';
        case TextTransform.Uppercase: return 'text-uppercase';
        case TextTransform.Capitalize: return 'text-capitalize';
    }
}

const textBreakClass = (textBreak?: boolean) => {
    return textBreak === true ? 'text-break' : '';
}

const textWrapClass = (wrap?: TextWrap) => {
    switch (wrap) {
        case TextWrap.Wrap: return 'text-wrap';
        case TextWrap.NoWrap: return 'text-nowrap';
    }
}

export const responsiveTextAlignmentClasses = (alignment?: IResponsiveTextAlignment) => {
    let classes = '';
    if (alignment) {
        classes = clsx(classes, textAlignmentClass(alignment.allSizes));
        classes = clsx(classes, textAlignmentClass(alignment.small, 'sm'));
        classes = clsx(classes, textAlignmentClass(alignment.medium, 'md'));
        classes = clsx(classes, textAlignmentClass(alignment.large, 'lg'));
        classes = clsx(classes, textAlignmentClass(alignment.xLarge, 'xl'));
    }

    return classes;
}

const textAlignmentClass = (alignment?: TextAlignment, size?: string) => {
    switch (alignment) {
        case TextAlignment.Start: return `text${size ? `-${size}` : ''}-start`;
        case TextAlignment.Center: return `text${size ? `-${size}` : ''}-center`;
        case TextAlignment.End: return `text${size ? `-${size}` : ''}-end`;
    }
}
