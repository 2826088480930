import Accordion from "react-bootstrap/esm/Accordion"
import AccordionItem from "react-bootstrap/esm/AccordionItem"
import { PanelTitle } from "./components"
import clsx from "clsx"

export const CollapsiblePanel = ({ eventKey, header, title, icon, headerClass, className, children }: {
    eventKey: string,
    header?: string,
    title?: string,
    icon?: string,
    headerClass?: string,
    className?: string,
    children?: React.ReactNode
}) => {
    const style = { "--bs-accordion-btn-padding-x": "2.25rem" } as React.CSSProperties;
    return (
        <Accordion defaultActiveKey={eventKey} className={clsx(className, "accordion-sm")} flush>
            <AccordionItem eventKey={eventKey} className="mb-3">
                <Accordion.Header style={style}>
                    <PanelTitle
                        header={header}
                        title={title}
                        icon={icon}
                        headerClass={headerClass}
                    />
                </Accordion.Header>
                <Accordion.Body className="px-10 bg-body">
                    {children}
                </Accordion.Body>
            </AccordionItem>
        </Accordion>
    )
}

