import { ApiTags, rootApi } from "../rootApi";

import { ApiResReadMultiple } from "../models";
import { IPage } from "../../design/page/models";

const TAG = ApiTags.Page;
const getTags = (entities: IPage[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const pagesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getPages: builder.query<IPage[], void>({
      query: () => "/pages",
      transformResponse: (res: ApiResReadMultiple<IPage>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),
    
    getSitePages: builder.query<IPage[], string>({
      query: (siteId) => `/pages/site(${siteId})`,
      transformResponse: (res: ApiResReadMultiple<IPage>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getPage: builder.query<IPage, string>({
      query: (id) => `/pages/${id}`,
      providesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    createPage: builder.mutation<IPage, IPage>({
      query: (page) => ({
        url: "/pages",
        method: "POST",
        body: page
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updatePage: builder.mutation<IPage, Partial<IPage>>({
      query: (page) => ({
        url: `/pages/${page.id}`,
        method: "PATCH",
        body: page
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deletePage: builder.mutation<void, string>({
      query: (pageId: string) => ({
        url: `/pages/${pageId}`,
        method: "DELETE",
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetPagesQuery,
  useGetPageQuery,
  useGetSitePagesQuery,
  useLazyGetSitePagesQuery,
  useCreatePageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,
} = pagesApi;