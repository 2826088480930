import { EditPageLink, EditTableLookup, SelectDisplayFormat, SelectEntity, SelectMaterialIcon, SelectPage, SelectPortalAction } from "../../widgets";
import { IDataSource, PropertyType } from "../../../base/types";

import { Binding } from "../../../base/bindings";
import { EditDataLink } from "../../widgets/data-link";
import { EditDropdownButton } from "../../widgets/dropdown";
import { EditEnumeration } from "../../widgets/enum-values/EditEnumValues";
import { GridOptionsValue } from "../../widgets/grid-options/GridOptionsValue";
import { IProperty } from "../../../base/models";
import { PropertyValue } from "./property-value";
import { useAuth } from "../../../../modules/auth";

export const ConstPropertyValue = ({ binding, property, dataSource, className, onChange }: {
    binding: Binding
    property: IProperty,
    dataSource?: IDataSource,
    className?: string,
    onChange: (binding: Binding) => void,
}) => {
    const { siteId } = useAuth()
    const entity = dataSource ? dataSource.entity : undefined;

    if (binding.type !== "Const")
        return <></>

    switch (binding.propType) {
        case PropertyType.PageLink:
            return <EditPageLink
                className={className}
                dataSource={dataSource}
                pageLink={binding.value}
                onChange={(value) => onChange({ ...binding, value })} />
        case PropertyType.GridOptions:
            return <GridOptionsValue
                className={className}
                dataSource={dataSource}
                options={binding.value}
                onChange={(value) => onChange({ ...binding, value })} />
        case PropertyType.DataLink:
            return <EditDataLink
                className={className}
                dataSource={dataSource}
                dataLink={binding.value}
                onChange={(value) => onChange({ ...binding, value })} />
        case PropertyType.Dropdown:
            return <EditDropdownButton
                options={binding.value}
                onChange={(options) => onChange({ ...binding, value: options })} />
        case PropertyType.Enumeration:
            return <EditEnumeration
                value={binding.value}
                onChange={(value) => onChange({ ...binding, value: value })} />
        case PropertyType.TableLookup:
            return <EditTableLookup
                className={className}
                dataSource={dataSource}
                tableLookup={binding.value}
                onChange={(value) => onChange({ ...binding, value })} />
        case PropertyType.Entity:
            return <SelectEntity
                className={className}
                onChange={(value) => { onChange({ ...binding, value: value || '' }) }}
                value={binding.value} />
        case PropertyType.Action:
            return <SelectPortalAction
                className={className}
                entity={entity}
                onChange={(value) => { onChange({ ...binding, value: value || '' }) }}
                value={binding.value} />
        case PropertyType.MaterialIcon:
            return <SelectMaterialIcon
                className={className}
                onChange={(value) => { onChange({ ...binding, value: value || '' }) }}
                value={binding.value} />
        case PropertyType.DisplayFormat:
            return <SelectDisplayFormat
                className={className}
                value={binding.value}
                onChange={(value) => { onChange({ ...binding, value }) }} />
        case PropertyType.Page:
            return <>
                {(siteId) && <SelectPage
                    siteId={siteId}
                    className={className}
                    value={binding.value}
                    onChange={(value) => { onChange({ ...binding, value }) }} />}
            </>
        default:
            return <PropertyValue
                label={property.name}
                dataType={property.type}
                value={binding.value}
                onChange={(value) => { onChange({ ...binding, value }) }} />
    }
}