import { ColumnOrder, ColumnSize, FlexDirection, FlexHorizontalAlign, FlexVerticalAlign, FlexWrap, GrowShrink, IControlStyle, LayoutType, SelfAlign, ZIndex } from "../models";

import { IPageControl } from "../../models";
import clsx from "clsx";
import { sizingClasses } from "./sizing";

export const getLayoutClasses = (styles?: IControlStyle) => {
    let classes = "";

    if (!styles) return classes;

    classes = clsx(classes, getElementLayoutClasses(styles));
    classes = clsx(classes, getContainerLayoutClasses(styles));
    return classes;
}

const columnSizeClass = (columnSize?: ColumnSize, size?: string) => {
    switch (columnSize) {
        case ColumnSize.Column:
            return `col${size ? `-${size}` : ''}`;
        case ColumnSize.Column1:
            return `col${size ? `-${size}` : ''}-1`;
        case ColumnSize.Column2:
            return `col${size ? `-${size}` : ''}-2`;
        case ColumnSize.Column3:
            return `col${size ? `-${size}` : ''}-3`;
        case ColumnSize.Column4:
            return `col${size ? `-${size}` : ''}-4`;
        case ColumnSize.Column5:
            return `col${size ? `-${size}` : ''}-5`;
        case ColumnSize.Column6:
            return `col${size ? `-${size}` : ''}-6`;
        case ColumnSize.Column7:
            return `col${size ? `-${size}` : ''}-7`;
        case ColumnSize.Column8:
            return `col${size ? `-${size}` : ''}-8`;
        case ColumnSize.Column9:
            return `col${size ? `-${size}` : ''}-9`;
        case ColumnSize.Column10:
            return `col${size ? `-${size}` : ''}-10`;
        case ColumnSize.Column11:
            return `col${size ? `-${size}` : ''}-11`;
        case ColumnSize.Column12:
            return `col${size ? `-${size}` : ''}-12`;
    }
}

// Layout classes effects on itself
export const getElementLayoutClasses = (styles?: IControlStyle) => {
    let classes = "";
    if (styles?.layout) {
        const {
            align,
            fill,
            growShrink,
            order,
            columnSize,
        } = styles?.layout;

        if (columnSize) {
            classes = clsx(classes, columnSizeClass(columnSize.allSizes));
            classes = clsx(classes, columnSizeClass(columnSize.small, "sm"));
            classes = clsx(classes, columnSizeClass(columnSize.medium, "md"));
            classes = clsx(classes, columnSizeClass(columnSize.large, "lg"));
            classes = clsx(classes, columnSizeClass(columnSize.xLarge, "xl"));
            classes = clsx(classes, columnSizeClass(columnSize.xxLarge, "xxl"));
        }

        if (align) {
            classes = clsx(classes, selfAlignClass(align.allSizes));
            classes = clsx(classes, selfAlignClass(align.small, "sm"));
            classes = clsx(classes, selfAlignClass(align.small, "md"));
            classes = clsx(classes, selfAlignClass(align.large, "lg"));
            classes = clsx(classes, selfAlignClass(align.xLarge, "xl"));
            classes = clsx(classes, selfAlignClass(align.xLarge, "xxl"));
        }

        if (fill) {
            classes = clsx(classes, flexFillClass(fill.allSizes));
            classes = clsx(classes, flexFillClass(fill.small, "sm"));
            classes = clsx(classes, flexFillClass(fill.small, "md"));
            classes = clsx(classes, flexFillClass(fill.large, "lg"));
            classes = clsx(classes, flexFillClass(fill.xLarge, "xl"));
            classes = clsx(classes, flexFillClass(fill.xLarge, "xxl"));
        }

        if (growShrink) {
            classes = clsx(classes, growShrinkClass(growShrink.allSizes));
            classes = clsx(classes, growShrinkClass(growShrink.small, "sm"));
            classes = clsx(classes, growShrinkClass(growShrink.small, "md"));
            classes = clsx(classes, growShrinkClass(growShrink.large, "lg"));
            classes = clsx(classes, growShrinkClass(growShrink.xLarge, "xl"));
            classes = clsx(classes, growShrinkClass(growShrink.xLarge, "xxl"));
        }

        if (order) {
            classes = clsx(classes, orderClass(order.allSizes));
            classes = clsx(classes, orderClass(order.small, "sm"));
            classes = clsx(classes, orderClass(order.medium, "md"));
            classes = clsx(classes, orderClass(order.large, "lg"));
            classes = clsx(classes, orderClass(order.xLarge, "xl"));
            classes = clsx(classes, orderClass(order.xxLarge, "xxl"));
        }
        if (order) {
            classes = clsx(classes, orderClass(order.allSizes));
            classes = clsx(classes, orderClass(order.small, "sm"));
            classes = clsx(classes, orderClass(order.medium, "md"));
            classes = clsx(classes, orderClass(order.large, "lg"));
            classes = clsx(classes, orderClass(order.xLarge, "xl"));
            classes = clsx(classes, orderClass(order.xxLarge, "xxl"));
        }

        if (!!styles.layout?.zIndex)
            classes = clsx(classes, zIndexClass(styles.layout.zIndex));
    }

    if (styles?.sizing) {
        classes = clsx(classes, sizingClasses(styles.sizing));
    }

    return classes;
}

// Layout classes effects child elements
export const getContainerLayoutClasses = (styles?: IControlStyle) => {
    let classes = "";
    if (!styles?.layout) return classes;

    const {
        layoutType,
        direction,
        horizontalAlign,
        verticalAlign,
        wrap
    } = styles?.layout;

    switch (layoutType) {
        case LayoutType.Grid:
            classes = clsx(classes, "row");
            break;
        case LayoutType.Flex:
            classes = clsx(classes, "d-flex");
            break;
    }

    if (direction) {
        classes = clsx(classes, flexDirectionClass(direction.allSizes));
        classes = clsx(classes, flexDirectionClass(direction.small, "sm"));
        classes = clsx(classes, flexDirectionClass(direction.small, "md"));
        classes = clsx(classes, flexDirectionClass(direction.large, "lg"));
        classes = clsx(classes, flexDirectionClass(direction.xLarge, "xl"));
        classes = clsx(classes, flexDirectionClass(direction.xLarge, "xxl"));
    }

    if (horizontalAlign) {
        classes = clsx(classes, flexHorizontalAlignClass(horizontalAlign.allSizes));
        classes = clsx(classes, flexHorizontalAlignClass(horizontalAlign.small, "sm"));
        classes = clsx(classes, flexHorizontalAlignClass(horizontalAlign.medium, "md"));
        classes = clsx(classes, flexHorizontalAlignClass(horizontalAlign.large, "lg"));
        classes = clsx(classes, flexHorizontalAlignClass(horizontalAlign.xLarge, "xl"));
        classes = clsx(classes, flexHorizontalAlignClass(horizontalAlign.xxLarge, "xxl"));
    }

    if (verticalAlign) {
        classes = clsx(classes, flexVerticalAlignClass(verticalAlign.allSizes));
        classes = clsx(classes, flexVerticalAlignClass(verticalAlign.small, "sm"));
        classes = clsx(classes, flexVerticalAlignClass(verticalAlign.medium, "md"));
        classes = clsx(classes, flexVerticalAlignClass(verticalAlign.large, "lg"));
        classes = clsx(classes, flexVerticalAlignClass(verticalAlign.xLarge, "xl"));
        classes = clsx(classes, flexVerticalAlignClass(verticalAlign.xxLarge, "xxl"));
    }

    if (wrap) {
        classes = clsx(classes, flexWrapClass(wrap.allSizes));
        classes = clsx(classes, flexWrapClass(wrap.small, "sm"));
        classes = clsx(classes, flexWrapClass(wrap.medium, "md"));
        classes = clsx(classes, flexWrapClass(wrap.large, "lg"));
        classes = clsx(classes, flexWrapClass(wrap.xLarge, "xl"));
        classes = clsx(classes, flexWrapClass(wrap.xxLarge, "xxl"));
    }

    return classes;
}

const zIndexClass = (zIndex?: ZIndex) => {
    switch (zIndex) {
        case ZIndex.n1: return 'z-index-n1';
        case ZIndex.n2: return 'z-index-n2';
        case ZIndex.Z0: return 'z-index-0';
        case ZIndex.Z1: return 'z-index-1';
        case ZIndex.Z2: return 'z-index-2';
        case ZIndex.Z3: return 'z-index-3';
    }
}

const orderClass = (order?: ColumnOrder, size?: string) => {
    switch (order) {
        case ColumnOrder.Order0:
            return `order${size ? `-${size}` : ''}-0`;
        case ColumnOrder.Order1:
            return `order${size ? `-${size}` : ''}-1`;
        case ColumnOrder.Order2:
            return `order${size ? `-${size}` : ''}-2`;
        case ColumnOrder.Order3:
            return `order${size ? `-${size}` : ''}-3`;
        case ColumnOrder.Order4:
            return `order${size ? `-${size}` : ''}-4`;
        case ColumnOrder.Order5:
            return `order${size ? `-${size}` : ''}-5`;
    }
}

const growShrinkClass = (growShrink?: GrowShrink, size?: string) => {
    switch (growShrink) {
        case GrowShrink.Grow0:
            return `flex-grow${size ? `-${size}` : ''}-0`;
        case GrowShrink.Grow1:
            return `flex-grow${size ? `-${size}` : ''}-1`;
        case GrowShrink.Shrink1:
            return `flex-shrink${size ? `-${size}` : ''}-1`;
        case GrowShrink.Shrink0:
            return `flex-shrink${size ? `-${size}` : ''}-0`;
    }
}

const flexFillClass = (fill?: boolean, size?: string) => {
    return (fill === true) ? clsx(`flex${size ? `-${size}` : ''}-fill`) : "";
}

const selfAlignClass = (align?: SelfAlign, size?: string) => {
    switch (align) {
        case SelfAlign.Start:
            return `align-self${size ? `-${size}` : ''}-start`;
        case SelfAlign.End:
            return `align-self${size ? `-${size}` : ''}-end`;
        case SelfAlign.Center:
            return `align-self${size ? `-${size}` : ''}-center`;
        case SelfAlign.Baseline:
            return `align-self${size ? `-${size}` : ''}-baseline`;
        case SelfAlign.Stretch:
            return `align-self${size ? `-${size}` : ''}-stretch`;
    }
}

const flexWrapClass = (align?: FlexWrap, size?: string) => {
    switch (align) {
        case FlexWrap.NoWrap:
            return `flex${size ? `-${size}` : ''}-nowrap`;
        case FlexWrap.Wrap:
            return `flex${size ? `-${size}` : ''}-wrap`;
        case FlexWrap.WrapReverse:
            return `flex${size ? `-${size}` : ''}-wrap-reverse`;
    }
}

const flexVerticalAlignClass = (align?: FlexVerticalAlign, size?: string) => {
    switch (align) {
        case FlexVerticalAlign.Start:
            return `align-items${size ? `-${size}` : ''}-start`;
        case FlexVerticalAlign.End:
            return `align-items${size ? `-${size}` : ''}-end`;
        case FlexVerticalAlign.Center:
            return `align-items${size ? `-${size}` : ''}-center`;
        case FlexVerticalAlign.Baseline:
            return `align-items${size ? `-${size}` : ''}-baseline`;
        case FlexVerticalAlign.Stretch:
            return `align-items${size ? `-${size}` : ''}-stretch`;
    }
}

const flexHorizontalAlignClass = (align?: FlexHorizontalAlign, size?: string) => {
    switch (align) {
        case FlexHorizontalAlign.Start:
            return `justify-content${size ? `-${size}` : ''}-start`;
        case FlexHorizontalAlign.End:
            return `justify-content${size ? `-${size}` : ''}-end`;
        case FlexHorizontalAlign.Center:
            return `justify-content${size ? `-${size}` : ''}-center`;
        case FlexHorizontalAlign.Between:
            return `justify-content${size ? `-${size}` : ''}-between`;
        case FlexHorizontalAlign.Around:
            return `justify-content${size ? `-${size}` : ''}-around`;
        case FlexHorizontalAlign.Evenly:
            return `justify-content${size ? `-${size}` : ''}-evenly`;
    }
}

const flexDirectionClass = (direction?: FlexDirection, size?: string) => {
    switch (direction) {
        case FlexDirection.Row:
            return `flex${size ? `-${size}` : ''}-row`;
        case FlexDirection.RowReverse:
            return `flex${size ? `-${size}` : ''}-row-reverse`;
        case FlexDirection.Column:
            return `flex${size ? `-${size}` : ''}-column`;
        case FlexDirection.ColumnReverse:
            return `flex${size ? `-${size}` : ''}-column-reverse`;
    }
}

export const getLayoutType = (control: IPageControl) => {
    if (control.styles?.layout?.layoutType === LayoutType.Flex) {
        if (!control.styles?.layout?.direction?.allSizes ||
            control.styles?.layout?.direction?.allSizes === FlexDirection.Row ||
            control.styles?.layout?.direction?.allSizes === FlexDirection.RowReverse)
            return "Horizontal"
        else
            return "Vertical"
    } else if (control.styles?.layout?.layoutType === LayoutType.Grid)
        if (!control.styles?.layout.columnSize?.allSizes || control.styles?.layout.columnSize?.allSizes === ColumnSize.Column12)
            return "Vertical"
        else
            return "Horizontal"
    else
        return "Auto"
}
