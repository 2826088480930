import { IEntityFilter, IPageLink } from "../models";

const encodeTableFilters = (filters: IEntityFilter[]) => {
    const encoded = filters.map(filter => `${encodeURI(filter.field)}=${filter.type}(${encodeURI(filter.value)})`);
    return JSON.stringify(encoded);
}

export const getPageLinkUrl = (siteId: string, pageLink: IPageLink) => {
    if (!pageLink) return '';

    let filters: string[] = [];
    if (pageLink.filters && pageLink.filters.length > 0)
        filters.push(`filters=${encodeTableFilters(pageLink.filters)}`)

    if (pageLink.pageMode)
        filters.push(`mode=${pageLink.pageMode}`);

    if (pageLink.systemId)
        filters.push(`id=${pageLink.systemId}`)

    return `/site(${siteId})/page(${pageLink.pageId})?${filters.join('&')}`
}
