import Dropdown from "react-bootstrap/esm/Dropdown"
import DropdownButton from "react-bootstrap/esm/DropdownButton"
import { IControlProps } from "../../../base/models";
import { PageControlDesigner } from "../../page-control";
import { Property } from "../../../base/types";
import { useGetProperties } from "../../../base/hooks";

export const DropdownButtonComponent = ({ control, className, onDesignChange }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const title: string | undefined = properties[Property.Label];

    return (
        <DropdownButton
            title={title}
            bsPrefix={className}>
            {control.controls?.sort((a, b) => a.sequence - b.sequence)?.map((ctrl) =>
                <Dropdown.Item>
                    <PageControlDesigner
                        key={ctrl.controlId}
                        orientation="Auto"
                        onDesignChange={onDesignChange} // TODO:: OnChange is not handled
                        control={ctrl}
                    />
                </Dropdown.Item>)}
        </DropdownButton>)
}