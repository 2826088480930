import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const HTMLViewerComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const htmlContent: string | undefined = properties[Property.HTMLContent];

    if (mode === "Design" && !htmlContent) {
        return (
            <span className={className}>
                <DefaultLabel
                    control={control}
                    propertyId={Property.Text}
                    defaultLabel="HTML Content" />
            </span>)
    }

    return (
        <>
            {(htmlContent) &&
                <div className={className}
                    dangerouslySetInnerHTML={{ __html: htmlContent }} />
            }
        </>)
}

