import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const ActionButtonProperties: IProperty[] = [
    { id: Property.MaterialIcon, name: 'Icon', type: PropertyType.MaterialIcon },
    { id: Property.Label, name: 'Label', type: PropertyType.String },
    { id: Property.PortalAction, name: 'Action', type: PropertyType.Action },
    { id: Property.ExecuteSave, name: 'Save and Execute', type: PropertyType.Boolean },
    { id: Property.AuthExecute, name: 'Auto execute on change data', type: PropertyType.Boolean },
    { id: Property.SystemId, name: 'System Id', type: PropertyType.String },
    { id: Property.PageLink, name: 'Navigate To', type: PropertyType.PageLink },    
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.Disabled, name: 'Disabled', type: PropertyType.Boolean },
    { id: Property.BackgroundStyle, name: 'Style', type: PropertyType.ButtonStyle },
    { id: Property.Size, name: 'Size', type: PropertyType.ControlSize },
  
]
