import { useEffect, useState } from "react";

import { IDataEntity } from "../../base/models";
import { IEntity } from "../../base/types";
import { Option } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from 'react-bootstrap-typeahead';
import clsx from "clsx";
import { useGetEntitiesQuery } from "../../services/data/table";

export const SelectEntity = ({ value, disabled, className, onChange, onBlur }: {
    value?: string,
    className?: string,
    disabled?: boolean,
    onChange: (value?: string) => void,
    onBlur?: () => void
}) => {
    const { data, isError, isLoading, isSuccess } = useGetEntitiesQuery();
    const [entities, setEntities] = useState<IDataEntity[]>([]);

    useEffect(() => {
        if (isSuccess)
            setEntities(data)
    }, [isSuccess, data])

    if (isError) {
        <span className='alert-info' style={{ display: 'block' }}>
            Failed to load entity list
        </span>
    }

    const handleOnChange = (selected: Option[]) => {
        const getSelected = (selected: Option[]) => {
            if (!selected || selected.length === 0) {
                return;
            }

            const selectedItem = selected[0] as IDataEntity
            return selectedItem.entity;
        }

        const newValue = getSelected(selected);
        onChange(newValue)
    }

    const getSelectedItems = (): IDataEntity[] => {
        if (!value) return []

        const selected = entities?.filter(p => p.entity === value || p.id === value)

        if (!selected || selected.length === 0) {
            return [{ id: '', entity: value, displayName: value }]
        }

        return selected
    }

    const MenuItem = (option: Option) => {
        const { entity, displayName }: IEntity = option as any;

        return <>
            <div className='d-flex flex-column'>
                <label className="fw-bold m-0 p-0">{displayName}</label>
                <label className="fs-8 fw-semibold m-0 p-0 text-gray-600">{entity}</label>
            </div>
        </>
    }

    return (
        <>
            <Typeahead
                id="entity"
                filterBy={["id", "displayName", "entity"]}
                labelKey="displayName"
                className={clsx(className, "custom-typeahead typeahead-sm")}
                isLoading={isLoading}
                clearButton={true}
                disabled={disabled}
                renderMenuItemChildren={MenuItem}
                options={entities}
                onChange={handleOnChange}
                onBlur={onBlur}
                selected={getSelectedItems()}
            />
            <label className="fs-9 fw-semibold">{`${value}`}</label>
        </>
    )
}