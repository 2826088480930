/* eslint-disable jsx-a11y/anchor-is-valid */

import * as Yup from 'yup'

import { Link, useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLazyGetUserByTokenQuery, useRegisterMutation } from '../../../framework/services/auth'

import { FieldError } from '../../../framework/design/components/services/components/service-attribute/components/FieldError'
import { PasswordMeterComponent } from '../../../_metronic/assets/ts/components'
import { SelectSite } from '../../../framework/design/widgets'
import clsx from 'clsx'
import { useAuth } from '../core/Auth'
import { useFormik } from 'formik'

const initialValues = {
  portalId: '',
  userName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  changePassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('Last name is required'),
  phoneNumber: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('Phone number is required'),
  portalId: Yup.string()
    .required('Portal Id is required'),
  userName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('User Name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changePassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setUser: setCurrentUser } = useAuth()
  const { portalId: portalIdParam, code: invitationCode, entityId } = useRegistrationParams()
  const [portalId, setPortalId] = useState(portalIdParam);
  const [register] = useRegisterMutation();
  const [getUser] = useLazyGetUserByTokenQuery();

  const formik = useFormik({
    initialValues: { ...initialValues, portalId: portalIdParam || '' },
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      register({
        portalId: values.portalId,
        userName: values.userName,
        password: values.password,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        invitationCode,
        entityId
      }).unwrap()
        .then(async (auth) => {
          saveAuth({ ...auth, authType: "Custom" })
          return getUser(auth.token)
            .unwrap()
            .then((user) => setCurrentUser(user))
        }).catch((err) => {
          saveAuth(undefined)
          console.log(err);
          setStatus(err.data || 'The registration details are not correct, please try again.')
        }).finally(() => {
          setSubmitting(false)
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div className='container d-flex justify-content-center'>
      <div className='mw-450px'>
        <div className='card bg-light border'>
          <div className='card-header'>
            <h1 className='text-dark mt-5'>Create an Account</h1>
            <div className='text-gray-400 fw-semibold fs-7'>
              Already have an account?
              <Link to='/auth/login' className='link-primary fw-bold ms-2'>
                Login
              </Link>
            </div>
          </div>
          <div className='card-body'>
            <form
              className='form fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className=''>

                {formik.status && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}

                <div className='col-12 mb-5'>
                  <label className='form-label fw-bolder text-dark'>Site</label>
                  <SelectSite
                    value={portalId}
                    onChange={(portal) => setPortalId(portal)}
                    disabled={!!portalIdParam}
                    props={formik.getFieldProps('portalId')}
                    className={clsx(
                      { 'is-invalid': formik.touched.portalId && formik.errors.portalId },
                      { 'is-valid': formik.touched.portalId && !formik.errors.portalId, }
                    )}
                  />
                  <FieldError
                    show={formik.touched.portalId && !!formik.errors.portalId}
                    message={formik.errors.portalId} />
                </div>

                <div className='row'>
                  <div className='col-12 mb-5'>
                    <label className='form-label fw-bolder'>User Name</label>
                    <input
                      placeholder='User Name'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('userName')}
                      className={clsx(
                        'form-control',
                        { 'is-invalid': formik.touched.userName && formik.errors.userName },
                        { 'is-valid': formik.touched.userName && !formik.errors.userName }
                      )}
                    />
                    <FieldError
                      show={formik.touched.userName && !!formik.errors.userName}
                      message={formik.errors.userName} />
                  </div>

                  <div className='col-md-6 col-sm-12 mb-5'>
                    <label className='form-label fw-bolder'>Password</label>
                    <div className='position-relative mb-3'>
                      <input
                        type='password'
                        placeholder='Password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control',
                          { 'is-invalid': formik.touched.password && formik.errors.password, },
                          { 'is-valid': formik.touched.password && !formik.errors.password, }
                        )}
                      />
                      <FieldError
                        show={formik.touched.password && !!formik.errors.password}
                        message={formik.errors.password} />
                    </div>
                  </div>

                  <div className='col-md-6 col-sm-12 mb-5'>
                    <label className='form-label fw-bolder'>Confirm Password</label>
                    <input
                      type='password'
                      placeholder='Password confirmation'
                      autoComplete='off'
                      {...formik.getFieldProps('changePassword')}
                      className={clsx(
                        'form-control',
                        { 'is-invalid': formik.touched.changePassword && formik.errors.changePassword, },
                        { 'is-valid': formik.touched.changePassword && !formik.errors.changePassword, }
                      )}
                    />
                    <FieldError
                      show={formik.touched.changePassword && !!formik.errors.changePassword}
                      message={formik.errors.changePassword} />
                  </div>

                  <div className='col-12 text-muted mb-5'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder'>First Name</label>
                    <input
                      placeholder='First Name'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('firstName')}
                      className={clsx(
                        'form-control',
                        { 'is-invalid': formik.touched.firstName && formik.errors.firstName },
                        { 'is-valid': formik.touched.firstName && !formik.errors.firstName }
                      )}
                    />
                    <FieldError
                      show={formik.touched.firstName && !!formik.errors.firstName}
                      message={formik.errors.firstName} />
                  </div>
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder'>Last Name</label>
                    <input
                      placeholder='Last Name'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('lastName')}
                      className={clsx(
                        'form-control',
                        { 'is-invalid': formik.touched.lastName && formik.errors.lastName },
                        { 'is-valid': formik.touched.lastName && !formik.errors.lastName }
                      )}
                    />
                    <FieldError
                      show={formik.touched.lastName && !!formik.errors.lastName}
                      message={formik.errors.lastName} />
                  </div>
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder'>Email</label>
                    <input
                      placeholder='Email'
                      type='email'
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        { 'is-valid': formik.touched.email && !formik.errors.email, }
                      )}
                    />
                    <FieldError
                      show={formik.touched.email && !!formik.errors.email}
                      message={formik.errors.email} />
                  </div>
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder'>Phone Number</label>
                    <input
                      placeholder='Phone Name'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('phoneNumber')}
                      className={clsx(
                        'form-control',
                        { 'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber },
                        { 'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber }
                      )}
                    />
                    <FieldError
                      show={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
                      message={formik.errors.phoneNumber} />
                  </div>
                </div>

                <div className='fv-row mb-10 px-5'>
                  <div className='form-check form-check-custom'>
                    <input
                      className='form-check-input border-primary'
                      type='checkbox'
                      id='kt_login_toc_agree'
                      {...formik.getFieldProps('acceptTerms')}
                    />
                    <label
                      className='form-check-label fw-bold text-gray-700 fs-6'
                      htmlFor='kt_login_toc_agree'
                    >
                      I Agree the{' '}
                      <Link to='/auth/terms' className='ms-1 link-primary'>
                        terms and conditions
                      </Link>
                      .
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.acceptTerms}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='text-center d-flex flex-wrap'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-50 px-5'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <Link to='/auth/login' className='w-50 px-5'>
                    <button
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-lg btn-light-primary w-100'>
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div >
  )
}

const useRegistrationParams = () => {
  const { portalId } = useParams<{ portalId?: string }>();
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const code = query.get("icode") || undefined
  const entityId = query.get("entityId") || undefined

  return { code, portalId, entityId }
}
