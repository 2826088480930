import { AlertMessage, MUIIcon, Spinner } from "../../../widgets";
import { IControlProps, IEntityFilter, IPageLink } from "../../../base/models";
import { PageMode, Property } from "../../../base/types";
import { useEffect, useState } from "react";

import { DropdownButton } from "react-bootstrap"
import { PageWithToolbar } from "../../page";
import clsx from "clsx"
import { useGetPageMetadataQuery } from "../../../services/data";
import { useGetProperties } from "../../../base/hooks";
import { usePageContext } from "../../page/context";

export const DropdownPageComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const icon = properties[Property.MaterialIcon];
    const [pageLink, setPageLink] = useState<IPageLink>();
    const tooltip: string | undefined = properties[Property.Tooltip];

    useEffect(() => {
        if (!properties)
            return;

        const newPageLink: IPageLink = properties[Property.PageLink]
        if (newPageLink) {
            setPageLink(newPageLink)
        }
        // eslint-disable-next-line
    }, [properties[Property.PageLink]])

    return <>
        <DropdownButton
            variant="link"
            size="sm"
            bsPrefix={clsx("btn btn-link btn-sm", className)}
            title={<span title={tooltip}>
                {icon && <MUIIcon iconName={icon} className="me-1" />}
                <span className="me-1">{label}</span>
            </span>}>
            <div className="p-3">
                {pageLink?.pageId && <ListPartPageOuter pageId={pageLink.pageId} filters={pageLink.filters || []} />}
            </div>
        </DropdownButton>
    </>
}

const ListPartPageOuter = ({ pageId, filters }: {
    pageId: string,
    filters: IEntityFilter[]
}) => {
    const { data: page, isLoading, isSuccess, isError, error } = useGetPageMetadataQuery(pageId);
    const { pageView } = usePageContext()

    return <>
        <div className="d-flex justify-content-center">
            <Spinner show={isLoading} className="mb-2" message="Please wait ..." />
        </div>
        <AlertMessage
            show={isError}
            type="Error"
            message={(error as any)?.data?.error?.message || `Failed to load the page id: ${pageId}`} />

        {isSuccess && <PageWithToolbar page={page} pageView={{
            pageMode: pageView?.pageMode || PageMode.View,
            linkFilters: filters
        }} />}
    </>
}