import { RoundedSize } from "../../../../base/styles";
import clsx from "clsx";

export const SelectBorderRadiusSize = ({ title, value, className, onChange }:{
    title: string,
    value?: RoundedSize,
    className?: string,
    onChange: (value?: RoundedSize) => void
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as RoundedSize;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={RoundedSize.Rounded0}>0</option>
                <option value={RoundedSize.Rounded1}>1</option>
                <option value={RoundedSize.Rounded2}>2</option>
                <option value={RoundedSize.Rounded3}>3</option>
                <option value={RoundedSize.Rounded4}>4</option>
                <option value={RoundedSize.Rounded5}>5</option>
            </select>
        </div>
    )
}