export enum ButtonStyle {
    None = "none",
    Primary = "primary",
    Secondary = "secondary",
    Success = "success",
    Danger = "danger",
    Warning = "warning",
    Info = "info",
    Light = "light",
    Dark = "dark",
    Link = "link",
    OutlinePrimary = "outline-primary",
    OutlineSecondary = "outline-secondary",
    OutlineSuccess = "outline-success",
    OutlineDanger = "outline-danger",
    OutlineWarning = "outline-warning",
    OutlineInfo = "outline-info",
    OutlineLight = "outline-light",
    OutlineDark = "outline-dark",
}

