export enum LayoutType {
    Grid = "Grid", // row
    Flex = "Flex"  // d-flex
};

export enum ZIndex {
    n1 = 'index-n1',
    n2 = 'index-n2',
    Z0 = 'index-0',
    Z1 = 'index-1',
    Z2 = 'z-index-2',
    Z3 = 'index-3',
}

export enum ColumnSize {
    Column = '0',      // Auto
    Column1 = '1',    // Single (1)
    Column2 = '2',    // Pair (2)  
    Column3 = '3',    // Quarter (3)
    Column4 = '4',    // Two pairs (4)
    Column5 = '5',    // One less than half (5) 
    Column6 = '6',    // Half (6)
    Column7 = '7',    // One more than half (7)
    Column8 = '8',    // Four pairs (8)
    Column9 = '9',    // Three quarters (9)
    Column10 = '10',  // Five pairs (10)
    Column11 = '11',  // One less than full (11)
    Column12 = '12',  // Full (12)
}

export enum FlexWrap {
    NoWrap = 'nowrap',          // flex-nowrap
    Wrap = 'wrap',               // flex-wrap
    WrapReverse = 'wrap-reverse' // flex-wrap-reverse    
}

export enum FlexHorizontalAlign {
    Start = 'start',      // justify-content-start
    End = 'end',          // justify-content-end
    Center = 'center',    // justify-content-center
    Between = 'between',  // justify-content-between
    Around = 'around',    // justify-content-around
    Evenly = 'evenly',    // justify-content-evenly
}

export enum SelfAlign {
    Start = 'start',        // align-self-start
    End = 'end',            // align-self-end
    Center = 'center',      // align-self-center
    Baseline = 'baseline',  // align-self-baseline
    Stretch = 'stretch'     // align-self-stretch
}

export enum GrowShrink {
    Grow1 = 'grow-1',        // flex-grow-1
    Grow0 = 'grow-0',        // flex-grow-0
    Shrink1 = 'shrink-1',    // flex-shrink-1
    Shrink0 = 'shrink-0',    // flex-shrink-0
}

export enum ColumnOrder {
    Order0 = 'order-0', // order-0
    Order1 = 'order-1', // order-1
    Order2 = 'order-2', // order-2
    Order3 = 'order-3', // order-3
    Order4 = 'order-4', // order-4
    Order5 = 'order-5', // order-5
}

export enum FlexDirection {
    Row = 'row',                      // flex-row
    RowReverse = 'row-reverse',       // flex-row-reverse
    Column = 'column',                // flex-column
    ColumnReverse = 'column-reverse', // flex-column-reverse
}

export enum FlexVerticalAlign {
    Start = 'start',        // align-items-start
    End = 'end',            // align-items-end
    Center = 'center',      // align-items-center
    Baseline = 'baseline',  // align-items-baseline
    Stretch = 'stretch',    // align-items-stretch
}

export enum Display {
    None = "none", // d-none
    Inline = "inline", // d-inline
    InlineBlock = "inline-block", // d-inline-block
    Block = "block", // d-block
    Table = "table", // d-table
    TableCell = "table-cell", // d-table-cell
    TableRow = "table-row", // d-table-row
    Flex = "flex", // d-flex
    InlineFlex = "inline-flex", // d-inline-flex
}

export enum Alignment {
    Baseline = "Baseline",      // align-baseline
    Top = "Top",                // align-top
    Middle = "Middle",          // align-middle
    Bottom = "Bottom",          // align-bottom
    TextBottom = "Text-bottom", // align-text-bottom
    TextTop = "Text-top",       // align-text-top
}

export enum BackgroundColor {
    Primary = "primary",         // bg-primary
    Secondary = "secondary",     // bg-secondary
    Success = "success",         // bg-success
    Danger = "danger",           // bg-danger
    Warning = "warning",         // bg-warning
    Info = "info",               // bg-info
    Light = "light",             // bg-light
    Dark = "dark",               // bg-dark
    Body = "body",               // bg-body
    White = "white",              // bg-white
    Transparent = "transparent", // bg-transparent
    Gray100 = "gray-100",        // bg-gray-100
    Gray200 = "gray-200",        // bg-gray-200
    Gray300 = "gray-300",        // bg-gray-300
    Gray400 = "gray-400",        // bg-gray-400
    Gray500 = "gray-500",        // bg-gray-500
    Gray600 = "gray-600",        // bg-gray-600
    Gray700 = "gray-700",        // bg-gray-700
    Gray800 = "gray-800",        // bg-gray-800
    Gray900 = "gray-900",        // bg-gray-900

    LightPrimary = "light-primary",  // .bg-light-primary
    LightSuccess = "light-success",  // .bg-light-success
    LightInfo = "light-info",        // .bg-light-info
    LightWarning = "light-warning",  // .bg-light-warning
    LightDanger = "light-danger",    // .bg-light-danger
    LightDark = "light-dark",        // .bg-light-dark
}

export enum Opacity {
    Opacity75 = "Opacity-75", // bg-Opacity-75
    Opacity50 = "Opacity-50", // bg-Opacity-50
    Opacity25 = "Opacity-25", // bg-Opacity-25
    Opacity10 = "Opacity-10", // bg-Opacity-10

    Opacity0 = "Opacity-0", // bg-Opacity-10
    Opacity5 = "Opacity-5", // bg-Opacity-10
    Opacity15 = "Opacity-15", // bg-Opacity-10
    Opacity20 = "Opacity-20", // bg-Opacity-10
    Opacity100 = "Opacity-100", // bg-Opacity-10
}

export enum HoverBackgroundColor {
    Primary = "primary",         // bg-primary
    Secondary = "secondary",     // bg-secondary
    Success = "success",         // bg-success
    Danger = "danger",           // bg-danger
    Warning = "warning",         // bg-warning
    Info = "info",               // bg-info
    Light = "light",             // bg-light
    Dark = "dark",               // bg-dark
    White = "white",              // bg-white

    LightPrimary = "light-primary",
    LightSuccess = "light-success",
    LightInfo = "light-info",
    LightWarning = "light-warning",
    LightDanger = "light-danger",
    LightDark = "light-dark",
}

export enum BorderStyle {
    Dashed = "dashed",
    Dotted = "dotted"
}

export enum BorderColor {
    Primary = "primary",      // border-primary
    Secondary = "secondary",  // border-secondary
    Success = "success",      // border-success
    Danger = "sanger",        // border-danger
    Warning = "warning",      // border-warning
    Info = "info",            // border-info
    Light = "light",          // border-light
    Dark = "dark",            // border-dark
    White = "white",          // border-white
    Gray100 = "gray-100",
    Gray200 = "gray-200",
    Gray300 = "gray-300",
    Gray400 = "gray-400",
    Gray500 = "gray-500",
    Gray600 = "gray-600",
    Gray700 = "gray-700",
    Gray800 = "gray-800",
    Gray900 = "gray-900",
}

export enum RoundedBorder {
    Rounded = "rounded",      // rounded
    Top = "rop",              // rounded-top
    End = "end",              // rounded-right
    Bottom = "bottom",        // rounded-bottom
    Start = "start",          // rounded-left
    Circle = "circle",        // rounded-circle
    Pill = "pill",            // rounded-pill
}

export enum YesNoChoice {
    Yes = "Yes",
    No = "No"
}

export enum RoundedSize {
    Rounded0 = "rounded-0", // rounded-0
    Rounded1 = "rounded-1", // rounded-1
    Rounded2 = "rounded-2", // rounded-2
    Rounded3 = "rounded-3", // rounded-3
    Rounded4 = "rounded-4", // rounded-4
    Rounded5 = "rounded-5", // rounded-5
}

export enum BorderSize {
    Border0 = "border-0",  // border-0
    Border1 = "border-1",  // border-1
    Border2 = "border-2",  // border-2
    Border3 = "border-3",  // border-3
    Border4 = "border-4",  // border-4
    Border5 = "border-5",  // border-5
}

export enum SizeUnit {
    Auto = 'auto',
    S25 = '25%',       // 25%
    S50 = '50%',       // 50%
    S75 = '75%',       // 75%
    S100 = '100%',      // 100%

    S1PX = '1px',
    S2PX = '2px',
    S3PX = '3px',
    S4PX = '4px',
    S5PX = '5px',
    S6PX = '6px',
    S7PX = '7px',
    S8PX = '8px',
    S9PX = '9px',
    S10PX = '10px',
    S15PX = '15px',
    S20PX = '20px',
    S25PX = '25px',
    S30PX = '30px',
    S35PX = '35px',
    S40PX = '40px',
    S45PX = '45px',
    S50PX = '50px',
    S55PX = '55px',
    S60PX = '60px',
    S65PX = '65px',
    S70PX = '70px',
    S75PX = '75px',
    S80PX = '80px',
    S85PX = '85px',
    S90PX = '90px',
    S95PX = '95px',
    S100PX = '100px',
    S125PX = '125px',
    S150PX = '150px',
    S175PX = '175px',
    S200PX = '200px',
    S225PX = '225px',
    S250PX = '250px',
    S275PX = '275px',
    S300PX = '300px',
    S325PX = '325px',
    S350PX = '350px',
    S375PX = '375px',
    S400PX = '400px',
    S425PX = '425px',
    S450PX = '450px',
    S475PX = '475px',
    S500PX = '500px',
    S550PX = '550px',
    S600PX = '600px',
    S650PX = '650px',
    S700PX = '700px',
    S750PX = '750px',
    S800PX = '800px',
    S850PX = '850px',
    S900PX = '900px',
    S950PX = '950px',
    S1000PX = '1000px',
}

export enum TextAlignment {
    Start = 'start',   // text-start
    Center = 'center', // text-center
    End = 'end',       // text-end
}

export enum TextWrap {
    Wrap = 'wrap',    // text-wrap
    NoWrap = 'nowrap', // text-nowrap
}

export enum TextTransform {
    Lowercase = 'lowercase',    // text-lowercase
    Uppercase = 'uppercase',   // text-uppercase
    Capitalize = 'capitalize', // text-capitalize
}

export enum FontSize {
    FS1 = 'fs-1', // fs-1
    FS2 = 'fs-2', // fs-2
    FS3 = 'fs-3', // fs-3
    FS4 = 'fs-4', // fs-4
    FS5 = 'fs-5', // fs-5
    FS6 = 'fs-6', // fs-6
    FS7 = 'fs-7', // fs-7
    FS8 = 'fs-8', // fs-8
    FS9 = 'fs-9', // fs-9
    FS10 = 'fs-10', // fs-10
    Base = 'base',
    Fluid = 'fluid',
    FS2x = 'fs-2x',
    FS2qx = 'fs-2qx',
    FS2hx = 'fs-2hx',
    FS2tx = 'fs-2tx',
    FS3x = 'fs-3x',
    FS3qx = 'fs-3qx',
    FS3hx = 'fs-3hx',
    FS3tx = 'fs-3tx',
    FS4x = 'fs-4x',
    FS4qx = 'fs-4qx',
    FS4hx = 'fs-4hx',
    FS4tx = 'fs-4tx',
    FS5x = 'fs-5x',
    FS5qx = 'fs-5qx',
    FS5hx = 'fs-5hx',
    FS5tx = 'fs-5tx',
}

export enum FontWeight {
    Bold = 'bold',         // fw-bold
    Bolder = 'bolder',     // fw-bolder
    Semibold = 'semibold', // fw-semibold
    Normal = 'normal',     // fw-normal
    Light = 'light',       // fw-light
    Lighter = 'lighter',   // fw-lighter
}

export enum FontStyle {
    Italic = 'italic', // fst-italic
    Normal = 'normal', // fst-normal
}

export enum LineHeight {
    Normal = '1',      // lh-1
    Small = 'sm',      // lh-sm
    Base = 'base',     // lh-base
    Large = 'lg',      // lh-lg
    xLarge = 'xl',     // lh-xl
    xxLarge = 'xxl',   // lh-xxl
}

export enum TextDecoration {
    Underline = 'underline', // text-decoration-underline
    LineThrough = 'line-through', // text-decoration-line-through
}

export enum TextHoverColor {
    Primary = "primary",         // bg-primary
    Secondary = "secondary",     // bg-secondary
    Success = "success",         // bg-success
    Danger = "danger",           // bg-danger
    Warning = "warning",         // bg-warning
    Info = "info",               // bg-info
    Light = "light",             // bg-light
    Dark = "dark",               // bg-dark
    White = "white",              // bg-white

    InversePrimary = 'inverse-primary',     // text-hover-inverse-primary
    InverseSecondary = 'inverse-secondary', // text-hover-inverse-secondary
    InverseSuccess = 'inverse-success',     // text-hover-inverse-success
    InverseInfo = 'inverse-info',           // text-hover-inverse-info
    InverseLight = 'inverse-light',         // text-hover-inverse-light
    InverseWarning = 'inverse-warning',     // text-hover-inverse-warning
    InverseDanger = 'inverse-danger',       // text-hover-inverse-danger
    InverseDark = 'inverse-dark',           // text-hover-inverse-dark
    InverseWhite = 'inverse-white',         // text-hover-inverse-white
}

export enum TextColor {
    Primary = 'primary',     // text-primary
    Secondary = 'secondary', // text-secondary
    Success = 'success',     // text-success
    Danger = 'danger',       // text-danger
    Warning = 'warning',     // text-warning
    Info = 'info',           // text-info
    Light = 'light',         // text-light
    Dark = 'dark',           // text-dark
    Body = 'body',           // text-body
    Muted = 'muted',         // text-muted
    White = 'white',         // text-white
    Black50 = 'black-50',    // text-black-50
    White50 = 'white-50',    // text-white-50
    Gray100 = 'gray-100',    // text-gray-100
    Gray200 = 'gray-200',    // text-gray-200
    Gray300 = 'gray-300',    // text-gray-300
    Gray400 = 'gray-400',    // text-gray-400
    Gray500 = 'gray-500',    // text-gray-500
    Gray600 = 'gray-600',    // text-gray-600
    Gray700 = 'gray-700',    // text-gray-700
    Gray800 = 'gray-800',    // text-gray-800
    Gray900 = 'gray-900',    // text-gray-900

    LightPrimary = 'light-primary', // text-light-primary
    LightSuccess = 'light-success', // text-light-success
    LightInfo = 'light-info',       // text-light-info
    LightWarning = 'light-warning', // text-light-warning
    LightDanger = 'light-danger',   // text-light-danger
    LightDark = 'light-dark',       // text-light-dark

    InversePrimary = 'inverse-primary',     // text-inverse-primary
    InverseSecondary = 'inverse-secondary', // text-inverse-secondary
    InverseSuccess = 'inverse-success',     // text-inverse-success
    InverseInfo = 'inverse-info',           // text-inverse-info
    InverseLight = 'inverse-light',         // text-inverse-light
    InverseWarning = 'inverse-warning',     // text-inverse-warning
    InverseDanger = 'inverse-danger',       // text-inverse-danger
    InverseDark = 'inverse-dark',           // text-inverse-dark
    InverseWhite = 'inverse-white',         // text-inverse-white
}

export enum TextOpacity {
    Opacity75 = 'opacity-75', // text-Opacity-75
    Opacity50 = 'opacity-50', // text-Opacity-50
    Opacity25 = 'opacity-25', // text-Opacity-25
    Opacity10 = 'opacity-10', // text-Opacity-10
}

export enum LetterSpacing {
    S1 = 'ls-1',
    S2 = 'ls-2',
    S3 = 'ls-3',
    S4 = 'ls-4',
    S5 = 'ls-5'
}

export enum SpacingUnit {
    Auto = "auto",
    S0 = "0",
    S1 = "1",
    S2 = "2",
    S3 = "3",
    S4 = "4",
    S5 = "5",

    S6 = "6",
    S7 = "7",
    S8 = "8",
    S9 = "9",
    S10 = "10",
    S11 = "11",
    S12 = "12",
    S13 = "13",
    S14 = "14",
    S15 = "15",
    S16 = "16",
    S17 = "17",
    S18 = "18",
    S19 = "19",
    S20 = "20",
}