import { ActionType, IActionProps } from "../models";

import { ActionComponent } from "./ActionComponent";
import { ActionGroupComponent } from "./ActionGroupComponent";

export const PageAction = ({ action, className, children, onDesignChange }: IActionProps) => {
    const props: IActionProps = {
        action,
        className,
        children,
        onDesignChange,
    };

    switch (action.type) {
        case ActionType.Action:
            return <ActionComponent {...props} />
        case ActionType.ActionGroup:
            return <ActionGroupComponent {...props} />
        default:
            return (
                <div className="card">
                    <div className="card-body p-2">
                        {action.type}
                    </div>
                </div>)
    }
} 