import { AuthPage, Logout, useAuth } from '../modules/auth'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { App } from '../App'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { PrivateRoutes } from './PrivateRoutes'
import { SiteSelectionWrapper } from '../pages/SiteSelectionWrapper'

const { PUBLIC_URL } = process.env

export const AppRoutes = () => {
  const { user } = useAuth()

  if (!user) {
    return <UnauthorizedRoutes />
  } else {
    return <AuthorizedRoutes />
  }
}

const AuthorizedRoutes = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='sites' element={<SiteSelectionWrapper />} />
          <Route path='*' element={<PrivateRoutes />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const UnauthorizedRoutes = () => {
  return (<>
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='auth/*' element={<AuthPage />} />
          <Route path='*' element={<AuthContainer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </>)
}

const AuthContainer = () => {
  const location = useLocation();
  const next = `${location.pathname}${location.search}`;
  return <Navigate to={`/auth?next=${encodeURIComponent(next)} `} />
}