import { ApiTags, rootApi } from '../rootApi';

import { ApiResReadMultiple } from '../models';
import { IRole } from './models/Role.model';

const TAG = ApiTags.Role;
const getTags = (entities: IRole[]) => [...entities.map((p) => ({ type: TAG, id: p.roleId })), TAG]

const rolesApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getRoles: builder.query<IRole[], void>({
      query: () => '/portalRoles',
      transformResponse: (res: ApiResReadMultiple<IRole>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getRole: builder.query<IRole, string>({
      query: (id) => `/portalRoles(${id})`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.roleId }, TAG] : [TAG]
    }),

    createRole: builder.mutation<IRole, IRole>({
      query: (role) => ({
        url: '/portalRoles',
        method: 'POST',
        body: role
      }),
      invalidatesTags: (result) =>
        result ? [{ type: TAG, id: result.roleId }, TAG] : [TAG]
    }),

    updateRole: builder.mutation<IRole, Partial<IRole>>({
      query: (role) => ({
        url: `/portalRoles(${role.roleId})`,
        method: 'PATCH',
        body: role
      }),
      invalidatesTags: (result) =>
        result ? [{ type: TAG, id: result.roleId }, TAG] : [TAG]
    }),

    deleteRole: builder.mutation<void, string>({
      query: (roleId: string) => ({
        url: `/portalRoles(${roleId})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = rolesApi;