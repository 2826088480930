import { ButtonStyle, ControlSize, Property } from "../../../base/types";
import { MUIIcon, Spinner } from "../../../widgets";

import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import clsx from "clsx"
import { getButtonClass } from "./helper";
import { useDataContext } from "../../page/context";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";
import { useResetData } from "../../page/hooks";

export const ResetDataComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const icon: string | undefined = properties[Property.MaterialIcon]
    const disabled: boolean | undefined = properties[Property.Disabled]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]

    const { reset } = useResetData()
    const { isLoading } = useDataContext()

    if (mode === "Design") {
        return (
            <div className={clsx(getButtonClass(style, size), className)}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                <DefaultLabel
                    control={control}
                    propertyId={Property.Label}
                    defaultLabel="Reset"
                    className="text-hover-primary" />
                {children}
            </div>)
    }

    return (
        <button
            type="button"
            title={tooltip}
            onClick={reset}
            disabled={isLoading || disabled === true}
            className={clsx(getButtonClass(style, size), className)}>
            <div className="d-flex">
                <div>
                    <Spinner show={isLoading} className="me-1" />
                    {(!isLoading && icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                    <span>{label}</span>
                </div>
                <div className="flex-fill">
                    {children}
                </div>
            </div>
        </button>)
}

