import React from "react"
import clsx from "clsx"

export const BreadCrump = ({ path, setPath, className }: {
    path: string[],
    setPath: (path: string[]) => void,
    className?: string,
}) => {
    return (<nav aria-label="breadcrumb" className={clsx(className)}>
        <ol className="breadcrumb fw-semibold text-gray-600 fs-7">
            {(path.length === 0) ?
                <li className="breadcrumb-item active">
                    <span className="pe-2">Home</span>
                </li> :
                <li className="breadcrumb-item">
                    <button
                        type="button"
                        className="btn btn-link btn-sm text-primary fs-7 p-0 me-2"
                        onClick={() => setPath([])}>
                        Home
                    </button>
                </li>
            }

            {path.map((name, i) => <React.Fragment key={name}>
                {(i + 1 === path.length) ?
                    <li className="breadcrumb-item active" aria-current="page">
                        <span className="pe-2">{name}</span>
                    </li> :
                    <li className="breadcrumb-item">
                        <button
                            type="button"
                            className="btn btn-link btn-sm text-primary fs-7 p-0 me-2"
                            onClick={() => setPath(path.filter((item, j) => j <= i))}>
                            {name}
                        </button>
                    </li>
                }
            </React.Fragment>)}
        </ol>
    </nav>)
}
