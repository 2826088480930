import { useEffect, useState } from "react";

import { PropertyType } from "../../../../../base/types";
import clsx from "clsx";

export const InputValue = ({ value, dataType, className, onChange }: {
    value: any,
    dataType: PropertyType,
    className?: string,
    onChange: (value: any) => void
}) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value)
        // eslint-disable-next-line
    }, [])

    const getInputType = () => {
        switch (dataType) {
            case PropertyType.Number: return "number";
            case PropertyType.Date: return "date";
            case PropertyType.DateTime: return "datetime-local";
            case PropertyType.Time: return "time"
            default: return "text"
        }
    }

    return <input type={getInputType()}
        className={clsx(className, "form-control form-control-sm form-control-xs")}
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={(e) => onChange(e.target.value)}
    />;
}
