import { rootApi } from "../../../../services/rootApi";

export interface IQRScanRequest {
    siteId: string,
    entity: string,
    action: string,
    referenceNo: string,
}

export interface IQRScanResult {
    status: boolean,
    message: string,
}

const qrScannerApi = rootApi.injectEndpoints({
    overrideExisting: false,

    endpoints: builder => ({
        scan: builder.mutation<IQRScanResult, IQRScanRequest>({
            query: (request) => {
                return {
                    url: `/QRScanner`,
                    method: 'POST',
                    body: request
                }
            },
        }),
    })
})

export const {
    useScanMutation,
} = qrScannerApi;