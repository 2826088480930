import { ActionType, IPageAction } from "../../../../design/page-action/models"

import { ActionLink } from "./components/ActionLink"
import { ActionMenuItems } from "./components/ActionMenuItems"
import DropdownButton from "react-bootstrap/esm/DropdownButton"
import { MUIIcon } from "../../../../widgets"

export const ToolbarPageAction = ({ action }: {
    action: IPageAction
}) => {
    if (action.type === ActionType.Action) {
        return <ActionLink action={action} />
    } else if (action.actions) {
        return (
            <DropdownButton
                title={<>
                    {action.icon && <MUIIcon iconName={action.icon} className="me-1" />}
                    <span>{action.name}</span>
                </>}
                bsPrefix="dropdown-item fs-7 fw-semibold text-gray-800 mx-1"
                className="mx-1" size="sm">
                <ActionMenuItems actions={action.actions} />
            </DropdownButton>)
    } else {
        return (
            <button
                type="button"
                className="dropdown-item fs-7 fw-semibold text-gray-800 mx-1">
                {action.icon && <MUIIcon iconName={action.icon} className="me-1" />}
                <span>{action.name}</span>
            </button>)
    }
}

