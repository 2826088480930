export enum FieldFilterType {
    Const = "Const",
    Field = "Field",
    Filter = "Filter",
    Function = "Function",
}

export interface IEntity extends Record<string, any> {
}

export interface IMultipleEntities {
    count: number,
    records: IEntity[],
}

export enum FilterFunction {
    Last7Days = "Last7Days",
    LastMonth = "LastMonth",
    LastWeek = "LastWeek",
    LastYear = "LastYear",
    Next7Days = "Next7Days",
    NextMonth = "NextMonth",
    NextWeek = "NextWeek",
    ThisMonth = "ThisMonth",
    ThisWeek = "ThisWeek",
    ThisYear = "ThisYear",
    Today = "Today",
    Tomorrow = "Tomorrow",
    Yesterday = "Yesterday",
    
    OnOrAfterToday = "OnOrAfterToday",
    OnOrBeforeToday = "OnOrBeforeToday",
    AfterToday = "AfterToday",
    BeforeToday = "BeforeToday",
    
    UserContactId = "UserContactId",
    UserEntityId = "UserEntityId",
    UserName = "UserName",
    UserId = "UserId",
    UserEmail = "UserEmail",
    UserPhone = "UserPhone",    
}