import { IControlProps, ITableLookup } from "../../../base/models";

import { Property } from "../../../base/types";
import { StringBinding } from "../../../base/bindings/string";
import { Typeahead } from "react-bootstrap-typeahead";
import clsx from "clsx";
import { getQueryFields } from "../../helpers";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useEffect } from "react";
import { useFromData } from "../panels/FormComponent";
import { useGetProperties } from "../../../base/hooks";
import { useLazyReadMultipleQuery } from "../../../services/data/tableDataApi";
import { useState } from "react";

export const LookupComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const tableLookup: ITableLookup = properties[Property.TableLookup] || {};
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const inputValue: string | undefined = properties[Property.ControlValue];
    const required: boolean = properties[Property.Required] || false;
    const readonly: boolean = properties[Property.Readonly] || false;
    const disabled: boolean = properties[Property.Disabled] || false;

    const [binding, setBinding] = useState<StringBinding>()
    const [value, setValue] = useState<string>()
    const { form, values, setFieldValue } = useFromData()

    useEffect(() => {
        const binding = getBinding(Property.ControlValue) as StringBinding
        if (!binding)
            return;

        setBinding(binding)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (mode !== "Preview" || inputValue === undefined)
            return;

        setValue(inputValue || '')
        // eslint-disable-next-line
    }, [inputValue])

    useEffect(() => {
        if (mode !== "Design" || !binding)
            return;

        switch (binding.type) {
            case "Expression":
                setValue(`{${binding.value.query}}`)
                break;
            case "Const":
                setValue(`"${binding.value}"`)
                break;
            case "Field":
                setValue(`{${binding.value}}`)
                break;
        }

        // eslint-disable-next-line
    }, [binding])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field")
            return;

        const value = values[binding.value];
        if (value === undefined)
            return;

        setValue(values[binding.value])
        // eslint-disable-next-line
    }, [values])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field" || !value)
            return;

        setFieldValue(binding.value, value)
        // eslint-disable-next-line
    }, [value])

    return (
        <div className={className}>
            {label &&
                <label
                    title={tooltip}
                    htmlFor={control.controlId}
                    className={clsx("form-label text-gray-600 fs-7", { "required": required })}>
                    {label}
                </label>}

            <SelectFieldValue
                id={control.controlId}
                tableLookup={tableLookup}
                value={value}
                readOnly={readonly}
                disabled={disabled || mode === "Design"}
                onChange={(value) => { setValue(value) }}
            />
        </div>
    )
}

const SelectFieldValue = ({ id, tableLookup, value, className, readOnly, disabled, onChange }: {
    tableLookup: ITableLookup,
    id?: string,
    value?: string,
    readOnly: boolean,
    disabled: boolean,
    className?: string,
    onChange: (value?: string) => void,
}) => {
    const { user, siteId: portalId } = useAuth();
    const [readRecords, { data, isLoading }] = useLazyReadMultipleQuery()
    const [fields, setFields] = useState<{ id: string, label: string }[]>([])

    useEffect(() => {
        if (!user || !tableLookup)
            return;

        const { userId } = user;
        if (!userId || !portalId)
            return;

        if (!tableLookup.tableId || !tableLookup.idField || !tableLookup.displayField)
            return;

        var queryFields = getQueryFields([], [], tableLookup.filters || [])
        const fieldList = Array.from(new Set([...queryFields, tableLookup.idField, tableLookup.displayField]))
        const query = {
            userId, portalId,
            tableId: tableLookup.tableId,
            fieldList,
            filters: [{ group: 0, filters: tableLookup.filters || [] }],
        };

        readRecords(query)

        // eslint-disable-next-line
    }, [user, tableLookup])

    useEffect(() => {
        if (!data || !tableLookup.idField || !tableLookup.displayField) return;

        const idField = tableLookup.idField;
        const displayField = tableLookup.displayField;

        setFields(data.records.map((p) => ({ id: p[idField], label: p[displayField] })))

        // eslint-disable-next-line
    }, [data, tableLookup])

    const handleOnChange = (selected: Record<string, any>) => {
        const value = selected[0];

        if (value) {
            const newValue = value["id"];
            if (value !== newValue)
                onChange(newValue);
        } else
            onChange(undefined);
    }

    return (
        <Typeahead
            id={id}
            className={clsx(className, "custom-typeahead typeahead-sm")}
            labelKey={option => `${(option as any).label || ''}`}
            isLoading={isLoading}
            clearButton={true}
            disabled={disabled || readOnly}
            options={fields}
            filterBy={['label']}
            onChange={handleOnChange}
            selected={fields.filter(p => p.id === value) || ''}
        />
    )
}

