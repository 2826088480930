import { LetterSpacing } from "../../../../base/styles";
import clsx from "clsx";

export const SelectLetterSpacing = ({ title, value, className, onChange }:{
    title: string,
    value?: LetterSpacing,
    className?: string,
    onChange: (value?: LetterSpacing) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as LetterSpacing;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (    
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={LetterSpacing.S1}>1</option>
                <option value={LetterSpacing.S2}>2</option>
                <option value={LetterSpacing.S3}>3</option>
                <option value={LetterSpacing.S4}>4</option>
                <option value={LetterSpacing.S5}>5</option>
            </select>
        </div>
    )
}