import { EntityDocumentChecklistItemMetadata, IEntityChecklistItem_BC } from "../../models";
import { FieldFilterType, IMultipleEntities } from "../../../../../base/types";
import { useEffect, useState } from "react";

import { IFilterGroup } from "../../../../../data";
import { useAuth } from "../../../../../../modules/auth";
import { useLazyReadMultipleQuery } from "../../../../../services/data/tableDataApi";

// TODO:: status fields should be optimized
export const useGetEntityDocumentChecklistItems = (entityChecklistId?: string) => {
    const [readRecords, { data: refetchData, isFetching }] = useLazyReadMultipleQuery();
    const { user, siteId: portalId } = useAuth()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [data, setData] = useState<IEntityChecklistItem_BC[]>()
    const { tableId, fields } = EntityDocumentChecklistItemMetadata

    useEffect(() => {
        const items = dataToChecklistItems(refetchData)
        setData(items)
        // eslint-disable-next-line
    }, [refetchData])

    useEffect(() => {
        setIsLoading(isFetching)
        // eslint-disable-next-line
    }, [isFetching])

    const dataToChecklistItems = (data?: IMultipleEntities) => {
        if (!data) return;

        const records = data.records || [];
        const items: IEntityChecklistItem_BC[] = records.map(p => ({
            id: p[fields.id],
            entityDocumentChecklistId: p[fields.entityDocumentChecklistId],
            documentChecklistItemId: p[fields.documentChecklistItemId],
            documentTypeId: p[fields.documentTypeId],
            fileType: p[fields.fileType],
            url: p[fields.url],
            systemId: p[fields.systemId]
        }))

        return items
    }

    const getRecords = (entityChecklistId: string) => {
        if (!user)
            return Promise.reject();

        const { userId } = user;
        if (!portalId)
            return Promise.reject();

        setIsSuccess(false)
        setIsError(false)
        setError(undefined)
        setIsLoading(true)

        const fieldList: string[] = [
            fields.id,
            fields.entityDocumentChecklistId,
            fields.documentChecklistItemId,
            fields.documentTypeId,
            fields.fileType,
            fields.url,
            fields.systemId
        ]
        const filters: IFilterGroup[] = [{
            group: 0,
            filters: [
                { field: fields.entityDocumentChecklistId, type: FieldFilterType.Const, value: entityChecklistId },
            ]
        }]

        return readRecords({ userId, portalId, tableId: tableId, fieldList, recordLimit: 0, filters })
            .unwrap()
            .then(data => {
                const items = dataToChecklistItems(data)
                setIsSuccess(true)
                return Promise.resolve(items);
            })
            .catch(err => {
                setIsError(true)
                setError(err.data?.error?.message || 'Failed to get data')
                return Promise.reject(err)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (!entityChecklistId) return;
        getRecords(entityChecklistId)
        // eslint-disable-next-line
    }, [entityChecklistId])

    return { data, isLoading, isSuccess, isError, error, getRecords }
}
