import { DisplayFormat, Property } from "../../../base/types";

import { IControlProps } from "../../../base/models";
import { formatValue } from "../../helpers";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const RepeaterColumn = ({
    control,
    className,
    children
}: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const value: string | undefined = properties[Property.Value];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const displayFormat: DisplayFormat | undefined = properties[Property.Format];

    if (mode === "Design") {
        return (
            <div style={{ display: "table-cell" }} className={className} title={tooltip}>
                <span>{formatValue(value, displayFormat)}</span>
                {children}
            </div>)
    } else {
        return (
            <td className={className} title={tooltip}>
                <span>{formatValue(value, displayFormat)}</span>
                {children}
            </td>)
    }
}