export interface IDocumentType {
    id: string,
    documentCategoryId: string,
    description: string,
}

export const DocumentTypeMetadata = {
    tableId: "33101674",
    fields: {
        id: "Id",
        categoryId: "Document Category Id",
        description: "Description",
    }
}