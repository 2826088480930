import { IDataAttribute } from '../../base/models';
import { Option } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from 'react-bootstrap-typeahead';
import clsx from "clsx";
import { useCallback } from 'react';

export const SelectAttribute = ({ attributes, value, disabled, isLoading, className, onChange, onBlur }: {
    attributes: IDataAttribute[],
    value?: string,
    isLoading?: boolean,
    className?: string,
    disabled?: boolean,
    onChange: (value?: string) => void,
    onBlur?: () => void
}) => {
    const sortedAttributes = useCallback(() => {
        return attributes.slice().sort((a, b) => a.displayName.localeCompare(b.displayName))
    }, [attributes])

    const handleOnChange = (selected: Record<string, any>) => {
        const value = selected[0];
        if (value)
            onChange(value["attribute"]);
        else
            onChange(undefined);
    }

    const handleMenuItemRender = (option: Option) => {
        const { attribute, displayName }: { attribute: string, displayName: string } = option as any;
        
        return <>
            <div className='d-flex flex-column'>
                <label className="fw-bold m-0 p-0">{displayName}</label>
                <label className="fs-8 fw-semibold m-0 p-0 text-gray-600">{attribute}</label>
            </div>
        </>
    }

    return (
        <div className={clsx(className, "field-group")}>
            <Typeahead
                id="attribute"
                labelKey='displayName'
                className="custom-typeahead typeahead-sm"
                isLoading={isLoading}
                clearButton={true}
                disabled={disabled}
                renderMenuItemChildren={handleMenuItemRender}
                options={sortedAttributes()}
                onChange={handleOnChange}
                onBlur={onBlur}
                selected={attributes?.filter(p => `${p.attribute}` === value) || [{ attribute: value, displayName: value }]}
            />
        </div>
    )
}