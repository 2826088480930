import { DocumentChecklistItemMetadata, IDocumentChecklistItem } from "../models";
import { useEffect, useState } from "react";

import { FieldFilterType } from "../../../../base/types";
import { IFilterGroup } from "../../../../data";
import { useAuth } from "../../../../../modules/auth";
import { useLazyReadMultipleQuery } from "../../../../services/data/tableDataApi";

// TODO:: status fields should be optimized
export const useGetDocumentChecklistItems = (checklistId: string) => {
    const [readRecords] = useLazyReadMultipleQuery();
    const { user, siteId: portalId } = useAuth()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [data, setData] = useState<IDocumentChecklistItem[]>()

    const refetch = () => {
        if (!user || !checklistId)
            return;

        const { userId } = user;
        if (!portalId) return;

        setIsSuccess(false)
        setIsError(false)
        setError(undefined)
        setIsLoading(true)

        const { tableId, fields } = DocumentChecklistItemMetadata
        const fieldList: string[] = [
            fields.id,
            fields.documentChecklistId,
            fields.lineNo,
            fields.documentCategoryId,
            fields.description,
            fields.required
        ]
        const filters: IFilterGroup[] = [{
            group: 0,
            filters: [
                { field: fields.documentChecklistId, type: FieldFilterType.Const, value: checklistId },
            ]
        }]

        readRecords({
            userId, portalId, tableId, fieldList, recordLimit: 0, filters
        })
            .unwrap()
            .then(data => {
                const records = data?.records || [];
                setData(records.map(p => ({
                    id: p[fields.id],
                    documentChecklistId: p[fields.documentChecklistId],
                    lineNo: p[fields.lineNo],
                    documentCategoryId: p[fields.documentCategoryId],
                    description: p[fields.description],
                    required: p[fields.required]
                })));
                setIsSuccess(true)
            })
            .catch(err => {
                setIsError(true)
                setError(err.data?.error?.message || 'Failed to get data')
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        refetch()
        // eslint-disable-next-line
    }, [checklistId])

    return { data, isLoading, isSuccess, isError, error, refetch }
}
