import { DisplayFormat } from "../../base/types";
import clsx from "clsx";

const DisplayFormats: { value: DisplayFormat, name: string }[] = [
    { value: DisplayFormat.Number, name: "Number" },
    { value: DisplayFormat.Money, name: "Money: ####" },
    { value: DisplayFormat.Money2, name: "Money: ####.##" },
    { value: DisplayFormat.Money3, name: "Money: ####.###" },
    { value: DisplayFormat.Money4, name: "Money: ####.####" },
    { value: DisplayFormat.Date_1, name: "Date: MM-DD-YY" },
    { value: DisplayFormat.Date_2, name: "Date: MM-DD-YYYY" },
    { value: DisplayFormat.Date_3, name: "Date: DD-MM-YY" },
    { value: DisplayFormat.Date_4, name: "Date: DD-MM-YYYY" },
    { value: DisplayFormat.Date_5, name: "Date: DD-MMM-YYYY" },
    { value: DisplayFormat.Date_6, name: "Date: DD MMM YYYY" },
    { value: DisplayFormat.Date_7, name: "Date: DD MMMM YYYY" },
    { value: DisplayFormat.Time_1, name: "Time: HH:MM (24 hrs)" },
    { value: DisplayFormat.Time_2, name: "Time: HH:MM:SS (24 hrs)" },
    { value: DisplayFormat.Time_3, name: "Time: HH:MM AM (12hrs)" },
    { value: DisplayFormat.Time_4, name: "Time: HH:MM:SS AM (12hrs)" },
];

export const SelectDisplayFormat = ({ value, className, onChange }: {
    onChange: (value: DisplayFormat) => void,
    value: DisplayFormat,
    className?: string
}) => {
    return <select
        onChange={(e) => onChange(e.target.value as DisplayFormat)}
        value={value}
        className={clsx(className, "form-select form-select-sm form-control-xs fs-8")}>
        <option></option>
        {DisplayFormats.map(option => <option key={option.value} value={option.value}>
            {option.name}
        </option>)}
    </select>;
}
