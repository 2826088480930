import { IPageAction } from "../../../../../design/page-action/models";
import { IPageLink } from "../../../../../base/models";
import { Link } from "react-router-dom";
import { MUIIcon } from "../../../../../widgets";
import { Property } from "../../../../../base/types";
import { getPageLinkUrl } from "../../../../../base/helpers";
import { useAuth } from "../../../../../../modules/auth";
import { useGetProperties } from "../../../../../base/hooks";

export const ActionLink = ({ action }: {
    action: IPageAction
}) => {
    const { siteId } = useAuth()
    const { properties } = useGetProperties({ bindings: action.bindings });
    const pageLink: IPageLink = properties[Property.PageLink] || {};

    if (!siteId || !pageLink.pageId) {
        return <button
            type="button"
            className="dropdown-item fs-7 fw-semibold text-gray-800 mx-1">
            {action.icon && <MUIIcon iconName={action.icon} className="me-1" />}
            {action.name}
        </button>
    }

    const pageUrl = getPageLinkUrl(siteId, pageLink);

    return (
        <Link
            className="dropdown-item fs-7 fw-semibold text-gray-800 mx-1"
            to={pageUrl}>
            {action.icon && <MUIIcon iconName={action.icon} className="me-1" />}
            <span>{action.name}</span>
        </Link>
    )
}
