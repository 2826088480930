import { getDefaultConstPropertyValue, getDefaultExprPropertyValue, getDefaultFieldPropertyValue } from "../../base/helpers";

import { Binding } from "../../base/bindings";
import { IProperty } from "../../base/models";
import { PropertyType } from "../../base/types";

export const SelectBindingType = ({ property, binding, onChange }: {
    property: IProperty,
    binding: Binding
    onChange: (binding: Binding) => void,
}) => {
    const { type: bindingType } = binding;

    if (!isBindingTypesSupported(property.type))
        return <></>

    const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const bindingType = e.target.value;
        switch (bindingType) {
            case "Const":
                onChange(getDefaultConstPropertyValue(property))
                break;
            case "Field":
                onChange(getDefaultFieldPropertyValue(property))
                break;
            case "Expression":
                onChange(getDefaultExprPropertyValue(property))
                break;
        }
    }

    return (
        <>
            <select
                onChange={handleOnChange}
                value={bindingType}
                className="form-select form-select-sm form-control-xs fs-9 py-1 px-2">
                <option value={"Const"}>Const</option>
                <option value={"Field"}>Field</option>
                {isExpressionBindingTypesSupported(property.type) &&
                    <option value={"Expression"}>Expression</option>}
            </select>
        </>)
}

const isBindingTypesSupported = (type: PropertyType) => {
    return !(type === PropertyType.PageLink ||
        type === PropertyType.TableLookup ||
        type === PropertyType.Dropdown ||
        type === PropertyType.Entity ||
        type === PropertyType.Page ||
        type === PropertyType.DisplayFormat ||
        type === PropertyType.AggregateMethod ||
        type === PropertyType.Action ||
        type === PropertyType.ButtonStyle ||
        type === PropertyType.BadgeStyle ||
        type === PropertyType.ControlSize ||
        type === PropertyType.Placement ||
        type === PropertyType.GridOptions ||
        type === PropertyType.Enumeration
    )
}

const isExpressionBindingTypesSupported = (type: PropertyType) => {
    return (type === PropertyType.String ||
        type === PropertyType.Number ||
        type === PropertyType.Integer ||
        type === PropertyType.Boolean)
}