import { FieldFilterType, PropertyType } from "../../base/types";
import { IDataLink, IEntityFilter, IGridOptions, IPageControl, IPageLink, ITableLookup } from "../../base/models";
import { IPage, IPageView } from "../page/models";

import { Binding } from "../../base/bindings";
import { IFilterGroup } from "../../data";
import { IPageAction } from './../page-action/models/page-action';

export const getFilterGroups = ({ page, pageView }: { page: IPage, pageView?: IPageView }) => {
    const filters: IFilterGroup[] = []
    if (page.filters && page.filters.length > 0)
        filters.push({ group: 0, filters: page.filters })

    if (pageView) {
        const { linkFilters, viewFilters } = pageView
        if (linkFilters && linkFilters.length > 0)
            filters.push({ group: 2, filters: linkFilters })
        if (viewFilters && viewFilters.length > 0)
            filters.push({ group: 3, filters: viewFilters })
    }

    return filters;
}

export const getQueryFields = (controls: IPageControl[], actions: IPageAction[], filters: IEntityFilter[]) => {
    const fieldNames: string[] = [];
    getControlFields(fieldNames, controls || [])
    getActionFields(fieldNames, actions || [])
    getEntityFilterFields(fieldNames, filters || [])

    return fieldNames;
}

const getEntityFilterFields = (fieldNames: string[], filters: IEntityFilter[]) => {
    filters.forEach(filter => {
        addField(fieldNames, filter.field);
    })

    return fieldNames;
}

const getControlFields = (fieldNames: string[], controls: IPageControl[]) => {
    controls.forEach(control => {
        getBindingFields(fieldNames, control.bindings || []);

        if (control.controls) {
            const childFields = getControlFields(fieldNames, control.controls);
            childFields.forEach(name => addField(fieldNames, name))
        }
    })

    return fieldNames;
}

const getActionFields = (fieldNames: string[], actions: IPageAction[]) => {
    if (!actions) return fieldNames;
    actions.forEach(action => {
        getBindingFields(fieldNames, action.bindings || []);

        if (action.actions) {
            const childFields = getActionFields(fieldNames, action.actions);
            childFields.forEach(name => addField(fieldNames, name))
        }
    })

    return fieldNames;
}

function getGridOptionFields(fieldNames: string[], options: IGridOptions) {
    options.columns?.forEach(p => {
        if (p.field)
            addField(fieldNames, p.field)
        if (p.urlField)
            addField(fieldNames, p.urlField);
        if (p.pageLink)
            addPageLinkFields(fieldNames, p.pageLink);
        if (p.lookup)
            addTableLookupFields(fieldNames, p.lookup)
    })
}

function getBindingFields(fieldNames: string[], bindings: Binding[]) {
    bindings?.forEach(p => {
        if (!p.value)
            return;

        switch (p.propType) {
            case PropertyType.PageLink:
                addPageLinkFields(fieldNames, p.value);
                break;
            case PropertyType.DataLink:
                addDataLinkFields(fieldNames, p.value);
                break;
            case PropertyType.GridOptions:
                getGridOptionFields(fieldNames, p.value)
                break;
            case PropertyType.TableLookup:
                addTableLookupFields(fieldNames, p.value);
                break;
            default:
                if (p.type === "Field") {
                    addField(fieldNames, p.value);
                } else if (p.type === "Expression") {
                    p.value.parameters.forEach(param => {
                        if (param.type === "Field")
                            addField(fieldNames, param.value);
                    });
                }
                break;
        }
    });
}

const addField = (fieldNames: string[], fieldName: string) => {
    if (fieldName && fieldNames.indexOf(fieldName) === -1) {
        fieldNames.push(fieldName);
    }
}

function addTableLookupFields(fieldNames: string[], tableLookup: ITableLookup) {
    tableLookup.filters?.forEach(filter => {
        if (filter.type === FieldFilterType.Field)
            addField(fieldNames, filter.value);
    });

    if (tableLookup.idField)
        addField(fieldNames, tableLookup.idField)
    if (tableLookup.displayField)
        addField(fieldNames, tableLookup.displayField)
}

function addPageLinkFields(fieldNames: string[], pageLink: IPageLink,) {
    pageLink.filters?.forEach(filter => {
        if (filter.type === FieldFilterType.Field)
            addField(fieldNames, filter.value);
    });

    if (pageLink.systemId)
        addField(fieldNames, pageLink.systemId);
}

function addDataLinkFields(fieldNames: string[], dataLink: IDataLink) {
    dataLink.filters?.forEach(filter => {
        if (filter.type === FieldFilterType.Field)
            addField(fieldNames, filter.value);
    });

    if (dataLink.systemId)
        addField(fieldNames, dataLink.systemId);
}

