const formatDate= (value?: Date | string) => {
    if (!value) return ''

    return new Date(value).toLocaleString(navigator.language, {
        year: "numeric",
        month: "short",
        day: "2-digit"
    });
}

const formatTime12HH_MM = (value?: Date | string) => {
    if (!value) return ''

    return new Date(value).toLocaleString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
    });
}

const formatTime12HH_MM_SS = (value?: Date | string) => {
    if (!value) return ''

    return new Date(value).toLocaleString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
}

const formatTime24HH_MM = (value?: Date | string) => {
    if (!value) return ''

    return new Date(value).toLocaleString(navigator.language, {
        hourCycle: 'h23',
        hour: "2-digit",
        minute: "2-digit",
    });
}

const formatTime24HH_MM_SS = (value?: Date | string) => {
    if (!value) return ''

    return new Date(value).toLocaleString(navigator.language, {
        hourCycle: 'h23',
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
}

const formatDateTime = (value?: Date | string) => {
    if (!value) return ''

    return `${formatDate(value)} ${formatTime12HH_MM(value)}`;
}

export const DateFormat = {
    date: formatDate,
    time12HH_MM: formatTime12HH_MM,
    time12HH_MM_SS: formatTime12HH_MM_SS,
    time24HH_MM: formatTime24HH_MM,
    time24HH_MM_SS: formatTime24HH_MM_SS,
    dateTime: formatDateTime
}