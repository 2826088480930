import { ControlCollection } from "./collections";
import { IControlProps } from "../../base/models";
import { PageControl } from "./PageControl";
import clsx from "clsx";
import { getControlClasses } from "../../base/styles/helpers";

export const ViewPageControl = (props: IControlProps) => {
    const { control, className } = props;

    const styles = getControlClasses(control.styles);

    return <PageControl {...props}
        className={clsx("page-control", styles, className, control.className)} >
        <ControlCollection
            control={control}
            orientation="Auto"
            controls={control.controls || []}
            onDesignChange={() => { }} />
    </PageControl>
}

