import { AlertMessage, Spinner, When } from "../../../../widgets"

import { DocumentTypeSelection } from "./DocumentTypeSelection"
import { IDocumentChecklistItem } from "../models"
import clsx from "clsx"
import { useGetDocumentCategory } from "../hooks"

export const ChecklistItem = ({ checklistItem, className, onUpload }: {
    checklistItem: IDocumentChecklistItem,
    className?: string,
    onUpload?: (documentTypeId: string, file: File) => Promise<void>
}) => {
    const { data, isLoading, isSuccess, isError, error } = useGetDocumentCategory(checklistItem.documentCategoryId)
    
    return (
        <div className={clsx(className)}>
            <AlertMessage show={isError} type="Error" message={error} />
            <Spinner show={isLoading} />
            <When condition={isSuccess}>
                {data && <div className="card card-body">
                    <label className={clsx(
                        "fs-7 fw-bold mb-3 me-3",
                        { "required": checklistItem.required }
                    )}>{checklistItem.description}</label>
                    <div className="d-flex align-items-end">
                        <DocumentTypeSelection
                            className="flex-fill me-2"
                            categoryId={data.id}
                            onUpload={onUpload} />
                    </div>
                </div>}
            </When>
        </div >
    )
}