import { KTSVG, WithChildren } from '../../../helpers'

import { FC } from 'react'
import { Link } from 'react-router-dom'
import { MUIIcon } from '../../../framework/widgets'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { useLocation } from 'react-router'

function checkIsActive(pathname: string, url: string) {
  const current = pathname.split(/[?#]/)[0]
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (url.length > pathname.length) {
    return url.indexOf(pathname) > -1
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string,
  materialIcon?: string,
  hasBullet?: boolean,
  className?: string,
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  materialIcon,
  hasBullet = false,
  className,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config

  return (
    <div className={'menu-item'}>
      <Link className={clsx(className, 'menu-link without-sub', { active: isActive })} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        {(!hasBullet && materialIcon) && <>
          <MUIIcon iconName={materialIcon} className="me-1" />
        </>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export { AsideMenuItem }
