import { AlertMessage, Spinner } from "../../widgets";

import { EditCustomComponent } from "./EditCustomComponent";
import { ICustomComponent } from "./models";
import { useEffect } from "react";
import { useLazyGetComponentMetadataQuery } from "../../services/data/componentsMetadataApi";

export const CustomComponentLoader = ({ componentId }: {
    componentId: string,
    onLoad: (component: ICustomComponent) => void
  }) => {
    const [getComponent, { data: component, isLoading, isError, error }] = useLazyGetComponentMetadataQuery();
  
    useEffect(() => {
      if (!componentId) return;
  
      getComponent(componentId).unwrap()
      // eslint-disable-next-line
    }, [componentId])
  
    return (
      <>
        <div className="d-flex justify-content-center">
          <Spinner show={isLoading} className="mb-2" message="Please wait ..." />
        </div>
        <AlertMessage
          show={isError}
          type="Error"
          message={(error as any)?.data?.error?.message || `Failed to load the component id: ${componentId}`} />
  
        {component && <EditCustomComponent component={component} />}
      </>)
  }
  