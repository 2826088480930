import { SpacingUnit } from "../../../../../base/styles";
import clsx from "clsx";

export const SelectSpacingUnit = ({ title, value, className, onChange }:{
    title: string,
    value?: SpacingUnit,
    className?: string,
    onChange: (value?: SpacingUnit) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as SpacingUnit;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={SpacingUnit.S0}>0</option>
                <option value={SpacingUnit.S1}>1</option>
                <option value={SpacingUnit.S2}>2</option>
                <option value={SpacingUnit.S3}>3</option>
                <option value={SpacingUnit.S4}>4</option>
                <option value={SpacingUnit.S5}>5</option>
                
                <option value={SpacingUnit.S6}>6</option>
                <option value={SpacingUnit.S7}>7</option>
                <option value={SpacingUnit.S8}>8</option>
                <option value={SpacingUnit.S9}>9</option>
                <option value={SpacingUnit.S10}>10</option>
                <option value={SpacingUnit.S11}>11</option>
                <option value={SpacingUnit.S12}>12</option>
                <option value={SpacingUnit.S13}>13</option>
                <option value={SpacingUnit.S14}>14</option>
                <option value={SpacingUnit.S15}>15</option>
                <option value={SpacingUnit.S16}>16</option>
                <option value={SpacingUnit.S17}>17</option>
                <option value={SpacingUnit.S18}>18</option>
                <option value={SpacingUnit.S19}>19</option>
                <option value={SpacingUnit.S20}>20</option>

                <option value={SpacingUnit.Auto}>Auto</option>
            </select>
        </div>
    )
}