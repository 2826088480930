export const Property = {
    HTMLContent: '59da2d50-c2b3-4e46-b4b1-91ec5c9d4604',
    Text: 'fe8e9928-9c61-4f8c-a013-6d97368fc884',
    Badge: '234e19db-b8f6-4e6c-b92b-4a1f3401b789',
    Label: '335e19db-b8f6-4e6c-b92b-4a1f3401b850',
    OrderNo: 'ac777150-399e-40af-96fb-17745a8c4cf6',
    EnrolmentNo: '980935f4-9730-41a0-b027-fbafa3d1637e',
    Currency: '4d70966f-34cb-4ec2-8330-8ab2e0d6a697',
    Amount: '38592f1e-f534-4992-821b-d72dfcf051c1',
    Target: 'd7d11bd8-c35a-4931-8e69-136b422e345e',
    PageId: '7da515ff-f2b0-41b4-8a32-36f755e3570f',
    RecordId: '0becde8d-e076-48be-a914-5d1a17e18034',
    Heading: '0e60d316-affb-4571-8085-c12159805c9d',
    ControlId: 'b39f2755-5f3e-4931-8330-a4b382811597',
    ControlValue: '518db5d6-24d9-48d8-8e4d-7f9730bd8ada',
    Required: '9bfba774-9553-456e-a621-ccb3f6383518',
    Readonly: 'ffcc46fd-6516-4982-a5cd-edf66044acc9',
    Disabled: 'a203e276-ba2c-498d-a088-3eea551b8aa1',
    AltText: 'e5251e57-b548-4c4a-b204-19c8c4dd73ea',
    Url: '6a6e46ce-748a-4f7d-aaab-810931625bec',
    Base64Image: '9e8d27e7-a6a7-4a1c-965c-98d086834f0f',
    VideoId: '2f22445c-7185-4893-95ba-5fc581e9eafe',
    ScreenName: 'd4b8e8a2-503c-45e3-816f-5b605a88c907',
    Height: '5201e7c7-c27b-4f3e-8b72-964ebc827e3d',
    ColorMap: '03bd05d3-9a96-471a-9d03-042762c4c00c',
    MediaId: 'aa7e60d1-31ff-4773-bf9b-5fba2e05d96e',
    Value: '6235cf95-d8fa-4404-bc65-e66e1b98bc40',
    GeoLang: 'e062e058-4a85-4a58-b074-cbb9fefc8105',
    GeoLat: '41e8140d-cf74-49df-9bdc-f5a209041aad',
    PortalAction: 'b798758e-5e17-4c97-9d88-bc0f87294a6b',
    Dropdown: 'cefdf3ca-0ea7-4887-9bbd-b2ceb19a1d13',
    InsertAllowed: 'fc1ccac9-e677-40b6-8561-cf70347bd2a5',
    ModifyAllowed: '3d818cda-ca40-4aed-ae34-8affbd4c809e',
    DeleteAllowed: '18650ad6-cb69-48c7-95f5-0635e23add7b',
    Submit: '0ad5c8cb-fa97-4119-8891-fcc1eb19f656',
    SystemId: '4ec81f2a-4c47-41c2-9258-91b5696a8b80',
    TriggerAction: '754667c8-6632-476d-9dda-9a63123c0014',
    PageLink: 'bf7652d9-8bfc-47f8-9221-5a837d52bdc3',
    DataLink: '2a84d4a6-902f-442d-94dd-77010ba82529',
    TableLookup: '5e1f1c97-ed9d-4f4f-832c-33f9c0b0d1d6',
    Format: '25b8827c-5494-48c9-a5da-6d2f5b678020',
    Title: '5662552c-3b2f-42e5-bf3c-58ab36d4a771',
    Entity: 'b17dd40a-1397-42c6-b6fd-8d5db7791f06',
    EntityId: '31a56655-d7c3-4a2f-af3b-348c74428d4e',
    Size: '65fd7f47-d885-44fe-b85b-fda4a0c48e41',
    Random: 'c93888c9-6273-4518-82f6-11ad72538a6c',
    MaterialIcon: 'b6fdec64-91a0-45cf-b160-5263e3223d2e',
    HeaderClass: 'a9f265c1-d9bc-461e-a11d-934fff7d79ae',
    RelativePath: '7c10439e-a537-48e7-94dc-ff736f10dca3',
    ModelDialog: '169fcd54-c705-4939-bd02-41336d3d8bbc',
    Collapsible: '1667e823-cb08-4376-842b-23f79247c148',
    Visible2: 'e376a6f0-5863-475c-8d5c-d6aa7889614a',
    Hidden: '1b805208-b906-46a4-8495-ec2eef61947d',
    Tooltip: '9332a218-e268-48a8-ba9a-1bad999b7ec9',
    ExecuteSave: '4ad726c5-7bcc-4f51-a0f8-0284d90695b9',
    AuthExecute: 'd3d5a66b-eb12-4c51-bdc9-45841a472d58',
    BackgroundStyle: 'c796db35-1769-46a6-8b78-d9d6744b5dca',
    EnsurePath: '736da651-528b-45a0-957c-50dbccca171d',
    Offcanvas: '865da651-528b-45a0-957c-50dbccca1435',
    RoundedPill: 'dcc7e4d6-54bf-4128-b4f9-31641d922742',
    Placement: '828f5e1d-1073-4777-a9ff-3abea747181a',
    Monochrome: '7012854c-1303-4981-bd37-ad133399f30b',
    Gradient: '5328c95c-0a3c-4461-99b3-33d17f52d78f',
    ShowToolbar: '8f1435f3-690f-4fde-a90f-09b921823e77',
    ShowLabels: '455de399-f0fd-48a0-af8e-7f1ef996fbed',
    LabelsField: '241345c7-0638-4c88-88e7-91eb5917f7cf',
    ValuesField: '8f96b063-5e33-4a03-a0ea-93107421d6a9',
    CategoryField: '1ec35d65-d8d2-4657-a5ca-1900dceaa739',
    AggregateMethod: '7cd0936d-d211-4e5d-b9e1-c7ccd64d307a',
    UnknownLabel: 'e0443653-15bb-4526-ada4-0c23137e220c',
    Donut: '9ea7ede2-f267-422a-9c4f-69465b170fc1',
    SuccessPage: 'f69e43d9-7c6a-48df-91e6-c36e4b220294',
    FailedPage: '102da572-723e-41df-b7c9-cb160701fb4a',
    ChecklistId: '4ce43aa2-85f8-425e-91ed-691474988670',
    GridOptions: 'e974bfe2-3b41-4917-8421-475710bfa2c8',
    Enum: '89cc131f-b015-4380-859e-48aac35b08c3',
    TimeField: '73375f8c-9558-4a71-bdda-7b83acc79624',
    OldestOnTop: '2671a4b5-2df5-427c-b7eb-8a7246ac7f2d',
    Field: '4b425606-04e7-4f85-b360-6eda531d5af6',
    IsNumber: '5141a4ee-e0a5-42b6-8af7-dfdde659ee98',
    AutoSave: '7f3d47ca-31fc-469d-b041-d3cf2a570ac7',
    ServiceId: 'ce223605-cbf7-498e-9215-8c6230a36f74',
    ServiceRequestId: '6d983820-1acf-42d9-abbc-b6ace845e481',
    HideHeader: '9f6637b3-814b-41fd-9cfe-c740ef33d44a',
    ChangeStatus: '63206319-2aea-4138-8cab-fde76ffeefe8',
    EditOnly: '4235c824-a122-401d-8145-96f9d82b5208',
    DocumentsOnly: '848d9d33-3742-4220-b939-7dcff9e59013',
    ProgramType: '15a848ec-193e-43bc-ac93-1f868a9927ff',
    GenderType: '37be7600-2c36-4fc9-939f-bc0016378e42',
    FeedbackForm: 'd5970d88-6698-4707-b645-6b7484fe3c31',
    FeedbackName: '86bae3e8-239d-4904-b586-ecb60529eb30',
}