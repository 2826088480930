import { AlertMessage, ProgressIndicator } from "../../../../widgets"

import { IPage } from "../../models"
import { useDesignerToolbarActions } from "../../hooks"

export const PageEditorToolbar = ({ page }: { page: IPage }) => {
    const { isLoading, isError, error } = useDesignerToolbarActions({ page })

    return <>
        <ProgressIndicator show={isLoading} className="mb-2" message="Please wait ..." />
        <AlertMessage
            show={isError}
            type="Error"
            message={(error as any)?.data?.error?.message || 'Failed to save page changes'} />
    </>
}
