import { AggregateMethodValue, BadgeStyleValue, BooleanValue, ButtonStyleValue, ControlSizeValue, InputValue, MultilineText, UrlText } from "./components";

import { PlacementValue } from "./components/PlacementValue";
import { PropertyType } from "../../../../base/types";

export const PropertyValue = ({ label, dataType, value, className, onChange }: {
    label?: string,
    dataType: PropertyType,
    value: any,
    className?: string,
    onChange: (value: any) => void
}) => {
    switch (dataType) {
        case PropertyType.Number:
        case PropertyType.Date:
        case PropertyType.DateTime:
        case PropertyType.Time:
            return <InputValue
                value={value}
                dataType={dataType}
                className={className}
                onChange={onChange} />
        case PropertyType.Boolean:
            return <BooleanValue
                className={className}
                label={label || ''}
                value={value}
                onChange={onChange} />
        case PropertyType.Url:
            return <UrlText
                className={className}
                value={value}
                onChange={onChange} />
        case PropertyType.String:
            return <MultilineText
                className={className}
                value={value}
                onChange={onChange} />
        case PropertyType.ButtonStyle:
            return <ButtonStyleValue
                className={className}
                label={label || ''}
                value={value}
                onChange={onChange} />
        case PropertyType.BadgeStyle:
            return <BadgeStyleValue
                className={className}
                label={label || ''}
                value={value}
                onChange={onChange} />
        case PropertyType.Placement:
            return <PlacementValue
                className={className}
                label={label || ''}
                value={value}
                onChange={onChange} />
        case PropertyType.ControlSize:
            return <ControlSizeValue
                className={className}
                label={label || ''}
                value={value}
                onChange={onChange} />
        case PropertyType.AggregateMethod:
            return <AggregateMethodValue
                className={className}
                label={label || ''}
                value={value}
                onChange={onChange} />
        default:
            return <div className={className}>
                {dataType}
            </div>
    }
}