import { DesignAction, DesignEvent } from "../../../../base/models";

import { IPage } from "../../models";
import { IPageAction } from "../../../page-action/models";
import { IPageControl } from '../../../../base/models/page-control';

export const reducer = (page: IPage, event: DesignEvent): IPage => {
    switch (event.type) {
        case DesignAction.Update:
            return event.page
        case DesignAction.UpdateControl:
            return updateControl(page, event.control)
        case DesignAction.DeleteControl:
            return deleteControl(page, event.controlId)
        case DesignAction.ShiftControlUp:
            return shiftControlUp(page, event.controlId)
        case DesignAction.ShiftControlDown:
            return shiftControlDown(page, event.controlId)
        case DesignAction.InsertAfterControl:
            return insertAfterControl(page, event.controlId, event.control)
        case DesignAction.InsertFirstControl:
            return insertFirstControl(page, event.controlId, event.control)
        case DesignAction.InsertFirstLayoutControl:
            return insertFirstLayoutControl(page, event.control)
        case DesignAction.InsertLastLayoutControl:
            return insertLastLayoutControl(page, event.control)

        case DesignAction.DropOnFirstControl:
            return dropOnFirstControl(page, event.controlId, event.control)
        case DesignAction.DropAfterControl:
            return dropAfterControl(page, event.controlId, event.control)
        case DesignAction.DropFirstLayoutControl:
            return dropFirstLayoutControl(page, event.control)
        case DesignAction.DropLastLayoutControl:
            return dropLastLayoutControl(page, event.control)

        case DesignAction.DeleteAction:
            return deleteAction(page, event.actionId)
        case DesignAction.ShiftActionUp:
            return shiftActionUp(page, event.actionId)
        case DesignAction.ShiftActionDown:
            return shiftActionDown(page, event.actionId)
        case DesignAction.InsertAfterAction:
            return insertAfterAction(page, event.actionId, event.action)
        case DesignAction.InsertFirstAction:
            return insertFirstAction(page, event.actionId, event.action)
        case DesignAction.InsertFirstLayoutAction:
            return insertFirstLayoutAction(page, event.action)
        case DesignAction.InsertLastLayoutAction:
            return insertLastLayoutAction(page, event.action)
        case DesignAction.UpdateAction:
            return updateAction(page, event.action)

        case DesignAction.DropOnFirstAction:
            return dropOnFirstAction(page, event.actionId, event.action)
        case DesignAction.DropAfterAction:
            return dropAfterAction(page, event.actionId, event.action)
        case DesignAction.DropFirstLayoutAction:
            return dropFirstLayoutAction(page, event.action)
        case DesignAction.DropLastLayoutAction:
            return dropLastLayoutAction(page, event.action)

        default:
            console.log(`DesignAction not implemented`, event)
            return page;
    }
}

interface IControlStatus {
    found: boolean,
    done: boolean,
    move?: boolean,
    control?: IPageControl,
    siblingId?: string
}

const resetSequenceNos = (controls: IPageControl[]): IPageControl[] => {
    return controls.map<IPageControl>((control, index) => ({
        ...control,
        sequence: index
    }))
}

const updateControl = (page: IPage, control: IPageControl): IPage => {
    const { controls } = page;
    if (!controls) {
        console.error(`No controls found in the page.`)
        return page
    }

    const update = (
        controls: IPageControl[],
        ctrl: IPageControl,
        status: IControlStatus
    ): IPageControl[] => {
        if (status.done) return controls;

        const index = controls.findIndex(p => p.controlId === control.controlId)

        if (index !== -1) {
            status.found = true;

            let pre = controls.slice(0, index)
            let post = controls.slice(index + 1)
            status.done = true

            return resetSequenceNos([...pre, ctrl, ...post])
        } else {
            let updatedControls = controls.map(item => {
                const { controls } = item
                if (controls) {
                    const controls2 = update(controls, ctrl, status)
                    return { ...item, controls: controls2 }
                } else {
                    return item
                }
            })

            return updatedControls
        }
    }

    const status = { found: false, done: false }
    const updatedControls = update(controls, control, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Control Id ${control.controlId} not found in the page.`)
        else
            console.error(`Control Id ${control.controlId} found in the page but could not update.`)

        return page
    }

    return { ...page, controls: updatedControls }
}

const deleteControl = (page: IPage, controlId: string): IPage => {
    const { controls } = page
    if (!controls) {
        console.error(`No controls found in the page.`)
        return page
    }

    const deleteFromCollection = (
        controls: IPageControl[],
        controlId: string,
        status: { deleted: boolean }
    ): IPageControl[] => {
        if (status.deleted) return controls;

        const index = controls.findIndex(p => p.controlId === controlId)
        if (index !== -1) {
            status.deleted = true
            return resetSequenceNos(controls.filter(p => p.controlId !== controlId))
        }

        return controls.map<IPageControl>(control => {
            const { controls } = control
            if (controls) {
                return {
                    ...control,
                    controls: deleteFromCollection(controls, controlId, status)
                }
            } else {
                return control
            }
        })
    }

    const status = { deleted: false }
    const updatedControls = deleteFromCollection(controls, controlId, status)

    if (!status.deleted) {
        console.error(`Control Id ${controlId} not found in the page.`)
        return page
    }

    return { ...page, controls: updatedControls }
}

const shiftControlUp = (page: IPage, controlId: string): IPage => {
    const { controls } = page;
    if (!controls) {
        console.error(`No controls found in the page.`)
        return page
    }

    const moveControl = (
        controls: IPageControl[],
        controlId: string,
        status: IControlStatus
    ): IPageControl[] => {
        if (status.done) return controls;

        const index = controls.findIndex(p => p.controlId === controlId)

        if (index !== -1) {
            status.found = true;
            const target = controls[index]

            let pre = controls.slice(0, index)
            const post = controls.slice(index + 1)
            if (pre.length !== 0) {
                const first = pre.slice(0, pre.length - 1)
                const second = pre.slice(pre.length - 1)
                pre = [...first, target, ...second]
                status.done = true
            } else {
                status.control = target
            }

            return resetSequenceNos([...pre, ...post])
        } else {
            let updatedControls = controls.map(item => {
                const { controls } = item
                if (controls) {
                    const controls2 = moveControl(controls, controlId, status)
                    if (status.found && !status.done && !status.move) {
                        status.move = true
                        status.siblingId = item.controlId
                    }

                    return { ...item, controls: controls2 }
                } else {
                    return item
                }
            })

            if (!status.done && status.siblingId && status.control) {
                const index = updatedControls.findIndex(p => p.controlId === status.siblingId)
                if (index === -1) return updatedControls

                let pre = updatedControls.slice(0, index)
                const post = updatedControls.slice(index)

                updatedControls = resetSequenceNos([...pre, status.control, ...post])
                status.done = true
                status.siblingId = undefined
            }

            return updatedControls
        }
    }

    const status = { found: false, done: false }
    const updatedControls = moveControl(controls, controlId, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Control Id ${controlId} not found in the page.`)
        else
            console.error(`Control Id ${controlId} found in the page but could not move.`)

        return page
    }

    return { ...page, controls: updatedControls }
}

const shiftControlDown = (page: IPage, controlId: string): IPage => {
    const { controls } = page;
    if (!controls) {
        console.error(`No controls found in the page.`)
        return page
    }

    const moveControl = (
        controls: IPageControl[],
        controlId: string,
        status: IControlStatus
    ): IPageControl[] => {
        if (status.done) return controls;
        const index = controls.findIndex(p => p.controlId === controlId)

        if (index !== -1) {
            status.found = true;
            const target = controls[index]

            let pre = controls.slice(0, index)
            let post = controls.slice(index + 1)
            if (post.length !== 0) {
                const first = post.slice(0, 1)
                const second = post.slice(1)
                post = [...first, target, ...second]
                status.done = true
            } else {
                status.control = target
            }

            return resetSequenceNos([...pre, ...post])
        } else {
            let updatedControls = controls.map(item => {
                const { controls } = item
                if (controls) {
                    const controls2 = moveControl(controls, controlId, status)
                    if (status.found && !status.done && !status.move) {
                        status.move = true
                        status.siblingId = item.controlId
                    }

                    return { ...item, controls: controls2 }
                } else {
                    return item
                }
            })

            if (!status.done && status.siblingId && status.control) {
                const index = updatedControls.findIndex(p => p.controlId === status.siblingId)
                if (index === -1) return updatedControls

                let pre = updatedControls.slice(0, index + 1)
                const post = updatedControls.slice(index + 1)

                updatedControls = resetSequenceNos([...pre, status.control, ...post])
                status.done = true
                status.siblingId = undefined
            }

            return updatedControls
        }
    }

    const status = { found: false, done: false }
    const updatedControls = moveControl(controls, controlId, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Control Id ${controlId} not found in the page.`)
        else
            console.error(`Control Id ${controlId} found in the page but could not move.`)

        return page
    }

    return { ...page, controls: updatedControls }
}

const insertAfterControl = (page: IPage, controlId: string, control: IPageControl): IPage => {
    const { controls } = page;
    if (!controls) {
        console.error(`No controls found in the page.`)
        return page
    }

    const addControl = (
        controls: IPageControl[],
        controlId: string,
        status: IControlStatus
    ): IPageControl[] => {
        if (status.done) return controls;

        const index = controls.findIndex(p => p.controlId === controlId)

        if (index !== -1) {
            status.found = true;

            let pre = controls.slice(0, index + 1)
            let post = controls.slice(index + 1)
            status.done = true

            return resetSequenceNos([...pre, control, ...post])
        } else {
            let updatedControls = controls.map(item => {
                const { controls } = item
                if (controls) {
                    const controls2 = addControl(controls, controlId, status)
                    return { ...item, controls: controls2 }
                } else {
                    return item
                }
            })

            return updatedControls
        }
    }

    const status = { found: false, done: false }
    const updatedControls = addControl(controls, controlId, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Control Id ${controlId} not found in the page.`)
        else
            console.error(`Control Id ${controlId} found in the page but could not move.`)

        return page
    }

    return { ...page, controls: updatedControls }
}

const insertFirstControl = (page: IPage, controlId: string, control: IPageControl): IPage => {
    const { controls } = page;
    if (!controls) {
        console.error(`No controls found in the page.`)
        return page
    }

    const addFirstChild = (
        controls: IPageControl[],
        controlId: string,
        status: IControlStatus
    ): IPageControl[] => {
        if (status.done) return controls;

        const index = controls.findIndex(p => p.controlId === controlId)

        if (index !== -1) {
            status.found = true;
            let target: IPageControl = controls[index]
            if (target.controls)
                target = { ...target, controls: resetSequenceNos([control, ...target.controls]) }
            else
                target = { ...target, controls: resetSequenceNos([control]) }

            let pre = controls.slice(0, index)
            let post = controls.slice(index + 1)
            status.done = true

            return resetSequenceNos([...pre, target, ...post])
        } else {
            let updatedControls = controls.map(item => {
                const { controls } = item
                if (controls) {
                    const controls2 = addFirstChild(controls, controlId, status)
                    return { ...item, controls: controls2 }
                } else {
                    return item
                }
            })

            return updatedControls
        }
    }

    const status = { found: false, done: false }
    const updatedControls = addFirstChild(controls, controlId, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Control Id ${controlId} not found in the page.`)
        else
            console.error(`Control Id ${controlId} found in the page but could not move.`)

        return page
    }

    return { ...page, controls: updatedControls }
}

const insertFirstLayoutControl = (page: IPage, control: IPageControl): IPage => {
    if (page.controls)
        return { ...page, controls: resetSequenceNos([control, ...page.controls]) }
    else
        return { ...page, controls: resetSequenceNos([control]) }
}

const insertLastLayoutControl = (page: IPage, control: IPageControl): IPage => {
    if (page.controls)
        return { ...page, controls: resetSequenceNos([...page.controls, control]) }
    else
        return { ...page, controls: resetSequenceNos([control]) }
}

const dropOnFirstControl = (page: IPage, controlId: string, control: IPageControl): IPage => {
    let updatedPage = deleteControl(page, control.controlId)
    return insertFirstControl(updatedPage, controlId, control)
}

const dropAfterControl = (page: IPage, controlId: string, control: IPageControl): IPage => {
    const updated = deleteControl(page, control.controlId)
    return insertAfterControl(updated, controlId, control)
}

const dropFirstLayoutControl = (page: IPage, control: IPageControl): IPage => {
    const updated = deleteControl(page, control.controlId)
    return insertFirstLayoutControl(updated, control)
}

const dropLastLayoutControl = (page: IPage, control: IPageControl): IPage => {
    const updated = deleteControl(page, control.controlId)
    return insertLastLayoutControl(updated, control)
}

interface IActionStatus {
    found: boolean,
    done: boolean,
    move?: boolean,
    action?: IPageAction,
    siblingId?: string
}

const resetActionSequenceNos = (actions: IPageAction[]): IPageAction[] => {
    return actions.map<IPageAction>((action, index) => ({
        ...action,
        sequence: index
    }))
}

const updateAction = (page: IPage, action: IPageAction): IPage => {
    const { actions } = page;
    if (!actions) {
        console.error(`No actions found in the page.`)
        return page
    }

    const update = (
        actions: IPageAction[],
        act: IPageAction,
        status: IActionStatus
    ): IPageAction[] => {
        if (status.done) return actions;

        const index = actions.findIndex(p => p.actionId === action.actionId)

        if (index !== -1) {
            status.found = true;

            let pre = actions.slice(0, index)
            let post = actions.slice(index + 1)
            status.done = true

            return resetActionSequenceNos([...pre, act, ...post])
        } else {
            let updatedActions = actions.map(item => {
                const { actions } = item
                if (actions) {
                    const actions2 = update(actions, act, status)
                    return { ...item, actions: actions2 }
                } else {
                    return item
                }
            })

            return updatedActions
        }
    }

    const status = { found: false, done: false }
    const updatedActions = update(actions, action, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Action Id ${action.actionId} not found in the page.`)
        else
            console.error(`Action Id ${action.actionId} found in the page but could not update.`)

        return page
    }

    return { ...page, actions: updatedActions }
}

const deleteAction = (page: IPage, actionId: string): IPage => {
    const { actions } = page
    if (!actions) {
        console.error(`No actions found in the page.`)
        return page
    }

    const deleteFromCollection = (
        actions: IPageAction[],
        actionId: string,
        status: { deleted: boolean }
    ): IPageAction[] => {
        if (status.deleted) return actions;

        const index = actions.findIndex(p => p.actionId === actionId)
        if (index !== -1) {
            status.deleted = true
            return resetActionSequenceNos(actions.filter(p => p.actionId !== actionId))
        }

        return actions.map<IPageAction>(action => {
            const { actions } = action
            if (actions) {
                return {
                    ...action,
                    actions: deleteFromCollection(actions, actionId, status)
                }
            } else {
                return action
            }
        })
    }

    const status = { deleted: false }
    const updatedActions = deleteFromCollection(actions, actionId, status)

    if (!status.deleted) {
        console.error(`Action Id ${actionId} not found in the page.`)
        return page
    }

    return { ...page, actions: updatedActions }
}

const shiftActionUp = (page: IPage, actionId: string): IPage => {
    const { actions } = page;
    if (!actions) {
        console.error(`No actions found in the page.`)
        return page
    }

    const moveAction = (
        actions: IPageAction[],
        actionId: string,
        status: IActionStatus
    ): IPageAction[] => {
        if (status.done) return actions;

        const index = actions.findIndex(p => p.actionId === actionId)

        if (index !== -1) {
            status.found = true;
            const target = actions[index]

            let pre = actions.slice(0, index)
            const post = actions.slice(index + 1)
            if (pre.length !== 0) {
                const first = pre.slice(0, pre.length - 1)
                const second = pre.slice(pre.length - 1)
                pre = [...first, target, ...second]
                status.done = true
            } else {
                status.action = target
            }

            return resetActionSequenceNos([...pre, ...post])
        } else {
            let updatedActions = actions.map(item => {
                const { actions } = item
                if (actions) {
                    const actions2 = moveAction(actions, actionId, status)
                    if (status.found && !status.done && !status.move) {
                        status.move = true
                        status.siblingId = item.actionId
                    }

                    return { ...item, actions: actions2 }
                } else {
                    return item
                }
            })

            if (!status.done && status.siblingId && status.action) {
                const index = updatedActions.findIndex(p => p.actionId === status.siblingId)
                if (index === -1) return updatedActions

                let pre = updatedActions.slice(0, index)
                const post = updatedActions.slice(index)

                updatedActions = resetActionSequenceNos([...pre, status.action, ...post])
                status.done = true
                status.siblingId = undefined
            }

            return updatedActions
        }
    }

    const status = { found: false, done: false }
    const updatedActions = moveAction(actions, actionId, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Action Id ${actionId} not found in the page.`)
        else
            console.error(`Action Id ${actionId} found in the page but could not move.`)

        return page
    }

    return { ...page, actions: updatedActions }
}

const shiftActionDown = (page: IPage, actionId: string): IPage => {
    const { actions } = page;
    if (!actions) {
        console.error(`No actions found in the page.`)
        return page
    }

    const moveAction = (
        actions: IPageAction[],
        actionId: string,
        status: IActionStatus
    ): IPageAction[] => {
        if (status.done) return actions;
        const index = actions.findIndex(p => p.actionId === actionId)

        if (index !== -1) {
            status.found = true;
            const target = actions[index]

            let pre = actions.slice(0, index)
            let post = actions.slice(index + 1)
            if (post.length !== 0) {
                const first = post.slice(0, 1)
                const second = post.slice(1)
                post = [...first, target, ...second]
                status.done = true
            } else {
                status.action = target
            }

            return resetActionSequenceNos([...pre, ...post])
        } else {
            let updatedActions = actions.map(item => {
                const { actions } = item
                if (actions) {
                    const actions2 = moveAction(actions, actionId, status)
                    if (status.found && !status.done && !status.move) {
                        status.move = true
                        status.siblingId = item.actionId
                    }

                    return { ...item, actions: actions2 }
                } else {
                    return item
                }
            })

            if (!status.done && status.siblingId && status.action) {
                const index = updatedActions.findIndex(p => p.actionId === status.siblingId)
                if (index === -1) return updatedActions

                let pre = updatedActions.slice(0, index + 1)
                const post = updatedActions.slice(index + 1)

                updatedActions = resetActionSequenceNos([...pre, status.action, ...post])
                status.done = true
                status.siblingId = undefined
            }

            return updatedActions
        }
    }

    const status = { found: false, done: false }
    const updatedActions = moveAction(actions, actionId, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Action Id ${actionId} not found in the page.`)
        else
            console.error(`Action Id ${actionId} found in the page but could not move.`)

        return page
    }

    return { ...page, actions: updatedActions }
}

const insertAfterAction = (page: IPage, actionId: string, action: IPageAction): IPage => {
    const { actions } = page;
    if (!actions) {
        console.error(`No actions found in the page.`)
        return page
    }

    const addAction = (
        actions: IPageAction[],
        actionId: string,
        status: IActionStatus
    ): IPageAction[] => {
        if (status.done) return actions;

        const index = actions.findIndex(p => p.actionId === actionId)

        if (index !== -1) {
            status.found = true;

            let pre = actions.slice(0, index + 1)
            let post = actions.slice(index + 1)
            status.done = true

            return resetActionSequenceNos([...pre, action, ...post])
        } else {
            let updatedActions = actions.map(item => {
                const { actions } = item
                if (actions) {
                    const actions2 = addAction(actions, actionId, status)
                    return { ...item, actions: actions2 }
                } else {
                    return item
                }
            })

            return updatedActions
        }
    }

    const status = { found: false, done: false }
    const updatedActions = addAction(actions, actionId, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Action Id ${actionId} not found in the page.`)
        else
            console.error(`Action Id ${actionId} found in the page but could not move.`)

        return page
    }

    return { ...page, actions: updatedActions }
}

const insertFirstAction = (page: IPage, actionId: string, action: IPageAction): IPage => {
    const { actions } = page;
    if (!actions) {
        console.error(`No actions found in the page.`)
        return page
    }

    const addFirstChild = (
        actions: IPageAction[],
        actionId: string,
        status: IActionStatus
    ): IPageAction[] => {
        if (status.done) return actions;

        const index = actions.findIndex(p => p.actionId === actionId)

        if (index !== -1) {
            status.found = true;
            let target: IPageAction = actions[index]
            if (target.actions)
                target = { ...target, actions: resetActionSequenceNos([action, ...target.actions]) }
            else
                target = { ...target, actions: resetActionSequenceNos([action]) }

            let pre = actions.slice(0, index)
            let post = actions.slice(index + 1)
            status.done = true

            return resetActionSequenceNos([...pre, target, ...post])
        } else {
            let updatedActions = actions.map(item => {
                const { actions } = item
                if (actions) {
                    const actions2 = addFirstChild(actions, actionId, status)
                    return { ...item, actions: actions2 }
                } else {
                    return item
                }
            })

            return updatedActions
        }
    }

    const status = { found: false, done: false }
    const updatedActions = addFirstChild(actions, actionId, status)
    if (!status.done) {
        if (!status.found)
            console.error(`Action Id ${actionId} not found in the page.`)
        else
            console.error(`Action Id ${actionId} found in the page but could not move.`)

        return page
    }

    return { ...page, actions: updatedActions }
}

const insertFirstLayoutAction = (page: IPage, action: IPageAction): IPage => {
    if (page.actions)
        return { ...page, actions: resetActionSequenceNos([action, ...page.actions]) }
    else
        return { ...page, actions: resetActionSequenceNos([action]) }
}

const insertLastLayoutAction = (page: IPage, action: IPageAction): IPage => {
    if (page.actions)
        return { ...page, actions: resetActionSequenceNos([...page.actions, action]) }
    else
        return { ...page, actions: resetActionSequenceNos([action]) }
}

const dropOnFirstAction = (page: IPage, actionId: string, action: IPageAction): IPage => {
    let updatedPage = deleteAction(page, action.actionId)
    return insertFirstAction(updatedPage, actionId, action)
}

const dropAfterAction = (page: IPage, actionId: string, action: IPageAction): IPage => {
    const updated = deleteAction(page, action.actionId)
    return insertAfterAction(updated, actionId, action)
}

const dropFirstLayoutAction = (page: IPage, action: IPageAction): IPage => {
    const updated = deleteAction(page, action.actionId)
    return insertFirstLayoutAction(updated, action)
}

const dropLastLayoutAction = (page: IPage, action: IPageAction): IPage => {
    const updated = deleteAction(page, action.actionId)
    return insertLastLayoutAction(updated, action)
}
