import { AvatarText } from "./AvatarText";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useGetProperties } from "../../../base/hooks";

export const AvatarTextComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const size: number | undefined = properties[Property.Size];
    const random: boolean | undefined = properties[Property.Random];

    return (
        <AvatarText
            className={className}
            title={tooltip}
            label={label || ''}
            size={size}
            random={random || true} />
    )
}

