import { FieldFilterType, IDataSource } from "../../../../base/types"

import { IEntityFilter } from "../../../../base/models"
import React from "react"
import { SelectEntityAttribute } from "../../SelectEntityAttribute"
import { SelectFilterFunction } from "../../SelectFilterFunction"

const FilterTypes = [
    { id: FieldFilterType.Const, value: "Const" },
    { id: FieldFilterType.Field, value: "Field" },
    { id: FieldFilterType.Filter, value: "Filter" },
    { id: FieldFilterType.Function, value: "Function" },
]

export const TableFilter = ({ entity, dataSource, filter, onUpdate, onDelete }: {
    entity: string,
    dataSource?: IDataSource,
    filter: IEntityFilter,
    onUpdate: (prop: IEntityFilter) => void,
    onDelete: (prop: IEntityFilter) => void,
}) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onUpdate({
            ...filter,
            value: e.target.value
        })
    }

    const handleOnFieldChange = (field?: string) => {
        onUpdate({
            ...filter,
            field: field || ''
        })
    }

    const handleOnFieldValueChange = (field?: string) => {
        onUpdate({
            ...filter,
            value: field || ''
        })
    }

    const handleOnSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onUpdate({
            ...filter,
            type: e.target.value as FieldFilterType
        })
    }

    return (
        <tr>
            <td>
                <SelectEntityAttribute
                    entity={entity}
                    value={filter.field}
                    onChange={handleOnFieldChange} />
            </td>
            <td>
                <select
                    value={filter.type}
                    onChange={handleOnSelectChange}
                    className="form-select form-select-sm">
                    {FilterTypes.map(item => <option key={item.id} value={item.id}>{item.value}</option>)}
                </select>
            </td>
            <td>
                {filter.type === FieldFilterType.Field &&
                    <>{(dataSource && dataSource.entity) &&
                        <SelectEntityAttribute
                            entity={dataSource.entity}
                            value={filter.value}
                            onChange={handleOnFieldValueChange} />}
                    </>
                }
                {filter.type === FieldFilterType.Function &&
                    <>
                        <SelectFilterFunction
                            value={filter.value}
                            onChange={handleOnFieldValueChange} />
                    </>
                }

                {(filter.type === FieldFilterType.Const || filter.type === FieldFilterType.Filter) &&
                    <input type="text"
                        value={filter.value}
                        onChange={handleOnChange}
                        className="form-control form-control-sm" />
                }
            </td>
            <td>
                <button
                    type="button"
                    onClick={() => onDelete(filter)}
                    className="btn btn-sm btn-danger py-2">
                    <i className="las la-trash"></i>
                    Delete
                </button>
            </td>
        </tr>)
}
