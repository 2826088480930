import { AlertMessage } from "../../widgets";
import { SelectAttribute } from "./SelectAttribute";
import { useEffect } from "react";
import { useReadAttributes } from "../../base/hooks";

export const SelectEntityAttribute = ({ entity, value, disabled, className, onChange, onBlur }: {
    entity: string,
    value?: string,
    disabled?: boolean,
    className?: string,
    onChange: (value?: string) => void,
    onBlur?: () => void
}) => {
    const { readAttributes, attributes, isError, isLoading, error } = useReadAttributes();

    useEffect(() => {
        readAttributes(entity)
        // eslint-disable-next-line
    }, [entity])

    if (isError || !attributes) {
        return <AlertMessage type="Error" show={isError} message={error} />
    }

    return (
        <SelectAttribute
            className={className}
            isLoading={isLoading}
            value={value}
            disabled={disabled}
            attributes={attributes}
            onChange={onChange} onBlur={onBlur} />
    )
}
