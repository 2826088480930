import { useCreateServiceRequestMutation, useUpdateServiceRequestMutation } from "../services"

import { IServiceMetadata } from "../services/useGetServiceMetadata"
import { IServiceRequest } from "../models"
import { ServiceReqHelper } from "../helper"
import { ServiceRequestWizard } from "./ServiceRequestWizard"
import { useState } from "react"

export const NewServiceRequest = ({ serviceId, entity, relativePath, hideHeader, metadata, changeStatus, onComplete }: {
    serviceId: string,
    entity?: string,
    relativePath?: string,
    hideHeader?: boolean,
    metadata?: IServiceMetadata,
    changeStatus?: boolean,
    onComplete: () => void,
}) => {
    const [createServiceRequest] = useCreateServiceRequestMutation()
    const [updateServiceRequest] = useUpdateServiceRequestMutation()
    const [serviceRequest, setServiceRequest] = useState<IServiceRequest>()

    const handleOnChange = (request: Partial<IServiceRequest>) => {
        if (!serviceRequest) {
            return createServiceRequest(request)
                .unwrap()
                .then(data => {
                    setServiceRequest(data)
                    return Promise.resolve(data)
                })
                .catch((err) => {
                    return Promise.reject('Failed to update the request details')
                })
        } else {
            return updateServiceRequest(request)
                .unwrap()
                .then(data => {
                    setServiceRequest(data)
                    return Promise.resolve(data)
                })
                .catch(err => Promise.reject('Failed to update service request'))
        }
    }

    return <>
        {(metadata) && <>
            <ServiceRequestWizard
                request={serviceRequest}
                onChange={handleOnChange}
                onComplete={onComplete}
                metadata={metadata}
                entity={entity}
                hideHeader={hideHeader}
                changeStatus={changeStatus}
                relativePath={relativePath}
                initValues={ServiceReqHelper.initValues(metadata)}
                schemas={ServiceReqHelper.schema(metadata)} />
        </>}
    </>
}
