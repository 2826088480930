import { AlertMessage, MUIIcon, ProgressIndicator } from "../../../../widgets"

import { ISPFile } from "../../../../services/data/sharepoint";
import { useDropzone } from 'react-dropzone';
import { useState } from "react";
import { useUploadDocument } from "../../../../services/data/sharepoint";

export const SPUploadDocument = ({ folderRelativeUrl, className, onUpload }: {
    folderRelativeUrl: string,
    className?: string,
    onUpload?: (files: string[]) => void
}) => {
    const { uploadDocument } = useUploadDocument();
    const { getRootProps, getInputProps } = useDropzone({ onDropAccepted });
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    function onDropAccepted(files: File[]) {
        setIsError(false)
        setErrorMessage('')
        setIsLoading(true)

        const promises: Promise<ISPFile>[] = []
        files.forEach(file => {
            var spFilePromise = uploadDocument({ file, folderRelativeUrl })
            promises.push(spFilePromise)
        })

        Promise.all(promises)
            .then(files => {
                if (onUpload) onUpload(files.map(p => p.serverRelativeUrl))
            })
            .catch(err => {
                setIsError(true)
                setErrorMessage((err as any).data?.error?.message || 'Failed to upload file(s)')
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <section>
            <AlertMessage show={isError} type="Error" className="my-1" message={errorMessage} />
            <div {...getRootProps({ className: 'dropzone py-2' })}>
                <input {...getInputProps()} />
                <div className="d-flex flex-column justify-content-center text-gray-600">
                    <div className="mb-1 fs-4">
                        {!isLoading && <MUIIcon iconName="CloudUploadOutlined" />}
                        <ProgressIndicator show={isLoading}
                            className="my-1"
                            messageClass="fs-7"
                            message="Uploading ..." />
                    </div>
                    <p className="m-0">Drag 'n' drop some files here, or click to select files</p>
                </div>
            </div>
        </section>
    )
}
