import { CollapsiblePanel, Panel } from "../../../widgets";

import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { SPListItems } from "./components/SPListItems";
import { useGetProperties } from "../../../base/hooks";

export const SPDocumentsComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });

    const title: string | undefined = properties[Property.Title]
    const headerClass: string | undefined = properties[Property.HeaderClass]
    const relativePath: string | undefined = properties[Property.RelativePath];
    const allowUpload: boolean | undefined = properties[Property.InsertAllowed];
    const allowDelete: boolean | undefined = properties[Property.DeleteAllowed];
    const collapsible: boolean | undefined = properties[Property.Collapsible];
    const createFolder: boolean | undefined = properties[Property.EnsurePath];

    if (collapsible) {
        return (
            <CollapsiblePanel
                eventKey="documents"
                className={className}
                header={title}
                icon="GridViewOutlined"
                headerClass={headerClass}
            >
                {relativePath && <SPListItems
                    relativePath={relativePath}
                    createFolder={createFolder}
                    allowUpload={allowUpload}
                    allowDelete={allowDelete} />}
            </CollapsiblePanel>
        )
    }

    return (
        <Panel
            className={className}
            header={title}
            icon="GridViewOutlined"
            headerClass={headerClass}>
            {relativePath && <SPListItems
                relativePath={relativePath}
                createFolder={createFolder}
                allowUpload={allowUpload}
                allowDelete={allowDelete} />}
        </Panel>)
}