import { ApiTags, rootApi } from "../../rootApi";
import { IPortalAction, IPortalExecuteAction } from "./models";

import { ApiResReadMultiple } from "../../models";

const TAG = ApiTags.PortalAction;
const getTags = (entities: IPortalAction[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const portalActionsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getPortalActions: builder.query<IPortalAction[], void>({
      query: () => '/portalActions',
      transformResponse: (res: ApiResReadMultiple<IPortalAction>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getPortalActionsByTableId: builder.query<IPortalAction[], string>({
      query: (tableId) => `/portalActions/ByTableId/${tableId}`,
      transformResponse: (res: ApiResReadMultiple<IPortalAction>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getPortalAction: builder.query<IPortalAction, string>({
      query: (id) => `/portalActions(${id})`,
      providesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    createPortalAction: builder.mutation<IPortalAction, IPortalAction>({
      query: (entity) => ({
        url: '/portalActions',
        method: 'POST',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updatePortalAction: builder.mutation<IPortalAction, Partial<IPortalAction>>({
      query: (entity) => ({
        url: `/portalActions(${entity.id})`,
        method: 'PATCH',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deletePortalAction: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/portalActions(${id})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    }),

    executePortalAction: builder.mutation<void, IPortalExecuteAction>({
      query: (query) => {
        const request = { query: JSON.stringify(query) };
        return {
          url: `/portalActions/ExecuteAction`,
          method: 'POST',
          body: request
        }
      }
    }),
  })
})

export const {
  useGetPortalActionQuery,
  useGetPortalActionsQuery,
  useGetPortalActionsByTableIdQuery,
  useLazyGetPortalActionsByTableIdQuery,
  useCreatePortalActionMutation,
  useUpdatePortalActionMutation,
  useDeletePortalActionMutation,
  useExecutePortalActionMutation,
} = portalActionsApi;