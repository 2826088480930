import { DisplayFormat, Property } from "../../../base/types";

import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import { formatValue } from "../../helpers";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const NormalTextComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const text: string | undefined = properties[Property.Text];
    const displayFormat: DisplayFormat | undefined = properties[Property.Format];
    const tooltip: string | undefined = properties[Property.Tooltip];

    if (mode === "Design") {
        return (
            <span className={className} title={tooltip}>
                <DefaultLabel
                    control={control}
                    propertyId={Property.Text}
                    defaultLabel="Text" />
            </span>)
    }

    return (
        <span className={className} title={tooltip}>
            {formatValue(text, displayFormat)}
        </span>)
}

