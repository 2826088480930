import { IGridColumn, IGridOptions } from "../../../base/models";
import { useEffect, useState } from "react";

import { CollapsiblePanel } from "../../../widgets";
import { EditGridColumns } from "./EditGridColumns";
import { EditGridOptions } from "./EditGridOptions";
import { IDataSource } from "../../../base/types";
import Modal from "react-bootstrap/esm/Modal";
import clsx from "clsx";

export const GridOptionsValue = ({
  dataSource,
  options,
  className,
  onChange,
}: {
  options: IGridOptions;
  dataSource?: IDataSource;
  className?: string;
  onChange: (options: IGridOptions) => void;
}) => {
  const { columns } = options;
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const hideModal = () => {
    setShowDialog(false);
  };

  return (
    <div className={clsx(className, "card mt-0 bg-transparent mx-0")}>
      <div className="card-body pt-0 px-0">
        <div className="fs-8 mt-3">
          <p className="flex-fill fw-bold text-gray-600 fs-8 mb-1">Columns:</p>
          {options.columns?.map((item, i) => <p key={i} className="m-0">
            <span className="fw-semibold text-primary">{item.header}</span> =
            <span className="fw-semibold text-primary">{`{${item.field}}`}</span>)</p>)}

          <div className="d-flex align-items-end mt-3">
            <button
              type="button"
              className="btn btn-primary btn-sm py-2"
              onClick={() => setShowDialog(true)}
            >
              Edit Options
            </button>
          </div>
        </div>
        {columns && (
          <GridOptionsModel
            hideModal={hideModal}
            show={showDialog}
            options={options}
            dataSource={dataSource}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};

const GridOptionsModel = ({
  options,
  show,
  hideModal,
  onChange,
  dataSource,
}: {
  options: IGridOptions;
  show: boolean;
  hideModal: () => void;
  onChange: (columns: IGridOptions) => void;
  dataSource?: IDataSource;
}) => {
  const [localOptions, setGridOptions] = useState<IGridOptions>(options);

  useEffect(() => {
    setGridOptions(options);
  }, [options]);

  const handleChanges = (options: IGridOptions) => {
    setGridOptions(options);
  }

  const handleColumnChanges = (columns: IGridColumn[]) => {
    setGridOptions({ ...localOptions, columns });
  }

  return (
    <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
      <Modal.Header closeButton className="bg-secondary border-0 py-5">
        <h4 className="my-0">Grid Options</h4>
      </Modal.Header>
      <Modal.Body className="scroll">
        <div className="border rounded bg-light p-5 mb-5">
          <EditGridOptions
            options={localOptions}
            onChange={handleChanges} />
        </div>
        <CollapsiblePanel header="Columns" icon="ViewColumnOutlined" eventKey="columns">
          <EditGridColumns
            columns={localOptions.columns || []}
            dataSource={dataSource}
            onChange={handleColumnChanges}
          />
        </CollapsiblePanel>
      </Modal.Body>
      <Modal.Footer className="border-0 py-5">
        <button
          type="button"
          onClick={() => {
            onChange(localOptions);
            hideModal();
          }}
          className="btn btn-sm btn-primary"
        >
          OK
        </button>
        <button
          type="button"
          onClick={hideModal}
          className="btn btn-sm btn-secondary"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

