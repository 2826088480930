import { GridTheme, IGridOptions } from "../../../base/models"

import clsx from "clsx"

export const EditGridOptions = ({ options, className, onChange }: {
  options: IGridOptions,
  className?: string,
  onChange: (options: IGridOptions) => void,
}) => {
  return <div className={clsx(className)}>
    <div className="row g-2 gy-3">

      <div className="col-lg-2 col-md-4 col-sm-12">
        <label className="form-label fs-7 fw-semibold">Header Height</label>
        <input
          type="number"
          className="form-control form-control-sm"
          onChange={(e) => onChange({ ...options, headerHeight: Number(e.target.value) })}
          value={options.headerHeight || 32}
        />
      </div>

      <div className="col-lg-2 col-md-4 col-sm-12">
        <label className="form-label fs-7 fw-semibold">Row Height</label>
        <input
          type="number"
          className="form-control form-control-sm"
          onChange={(e) => onChange({ ...options, rowHeight: Number(e.target.value) })}
          value={options.rowHeight || 32}
        />
      </div>

      <div className="col-lg-2 col-md-4 col-sm-12">
        <div className="form-check form-switch">
          <label className="form-check-label" htmlFor="pagination">Pagination</label>
          <input className="form-check-input"
            type="checkbox"
            id="pagination"
            name="pagination"
            onChange={(e) => onChange({ ...options, pagination: e.target.checked })}
            checked={options.pagination === true} />
        </div>
      </div>

      <div className="col-lg-2 col-md-4 col-sm-12">
        <label className="form-label fs-7 fw-semibold">Page Size</label>
        <input
          type="number"
          className="form-control form-control-sm"
          onChange={(e) => onChange({ ...options, paginationPageSize: Number(e.target.value) })}
          value={options.paginationPageSize || 10}
        />
      </div>
      
      <div className="col-lg-4 col-md-6 col-sm-12">
        <label className="form-label fs-7 fw-semibold">Theme</label>
        <select
          className="form-select form-select-sm"
          onChange={(e) => onChange({ ...options, theme: e.target.value as GridTheme })}
          value={options.theme || GridTheme.Alpine}
        >
          <option value={GridTheme.Alpine}>Alpine</option>
          <option value={GridTheme.AlpineDark}>Alpine Dark</option>
          <option value={GridTheme.Balham}>Balham</option>
          <option value={GridTheme.BalhamDark}>Balham Dark</option>
          <option value={GridTheme.Material}>Material</option>
        </select>
      </div>
    </div>
  </div>
}