export enum DisplayFormat {
    Normal = "Normal",
    Money = "Money",
    Money2 = "Money(2)",
    Money3 = "Money(3)",
    Money4 = "Money(4)",
    Number = "Number",
    Date_1 = "Date-1",
    Date_2 = "Date-2",
    Date_3 = "Date-3",
    Date_4 = "Date-4",
    Date_5 = "Date-5",
    Date_6 = "Date-6",
    Date_7 = "Date-7",
    Time_1 = "Time-1",
    Time_2 = "Time-2",
    Time_3 = "Time-3",
    Time_4 = "Time-4",
}
