import { Binding } from "../../../base/bindings";
import { ComponentProperty } from "../../control-property";
import { IDataSource } from "../../../base/types";
import { IProperty } from "../../../base/models";
import React from "react";
import { getDefaultConstPropertyValue } from "../../../base/helpers";

export const PropertyBindings = ({ bindings, properties, dataSource, className, onChange }: {
    bindings: Binding[],
    properties: IProperty[],
    dataSource?: IDataSource,
    className?: string,
    onChange: (bindings: Binding[]) => void;
}) => {
    const getBinding = (property: IProperty): Binding => {
        let binding = bindings.find(p => p.propId === property.id);
        if (binding) {
            // To correct, if property type is not matching with binding's property type
            if (property.type && binding.propType === property.type) {
                const newBinding = { ...binding };
                newBinding.propType = property.type;
                return newBinding
            }

            return binding;
        }

        return getDefaultConstPropertyValue(property);
    }

    const handlePropertyChange = (binding: Binding) => {
        const index = bindings.findIndex(p => p.propId === binding.propId);
        if (index === -1) {
            onChange([...bindings, binding])
        } else {
            onChange(bindings.map(p => p.propId === binding.propId ? binding : p));
        }
    }

    return (
        <div className={className}>
            {properties.map(item => <React.Fragment key={item.id}>
                <ComponentProperty
                    onChange={handlePropertyChange}
                    property={item}
                    binding={getBinding(item)}
                    dataSource={dataSource}
                    className="mb-2" />
            </React.Fragment>)}
        </div>
    );
}

