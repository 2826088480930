import * as uuid from "uuid";

import { AlertMessage, Spinner } from "../../widgets";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCreatePageMutation, useDeletePageMutation, useLazyGetPageMetadataQuery, useLazyGetSitePagesQuery, useUpdatePageMetadataMutation } from "../../services/data";

import { IPage } from "../../design/page/models";
import { useAuth } from "../../../modules/auth";
import { useSitePageToolbarActions } from "./hooks";

export const SitePageList = () => {
    const { siteId } = useAuth()
    const navigate = useNavigate();
    useSitePageToolbarActions();
    const [searchText, setSearchText] = useState<string>('');
    const ref = useRef<HTMLInputElement>(null)
    const [getPages, { data, isLoading, isError, error }] = useLazyGetSitePagesQuery();
    const [createPage, { isLoading: inserting }] = useCreatePageMutation();
    const [getPageMetadata] = useLazyGetPageMetadataQuery();
    const [updatePageMetadata, { isLoading: updating }] = useUpdatePageMetadataMutation();
    const [deletePage, { isLoading: deleting }] = useDeletePageMutation();

    useEffect(() => {
        if (siteId)
            getPages(siteId)
        // eslint-disable-next-line
    }, [siteId])

    useEffect(() => {
        ref.current?.focus()
    })

    const handleDeletePage = (page: IPage) => {
        deletePage(page.id).unwrap()
    }

    const handleClonePage = async (page: IPage) => {
        try {
            const pageId = uuid.v1();
            const pageName = `${page.name} - Copy`;

            await createPage({
                ...page,
                id: pageId,
                name: pageName
            }).unwrap();

            const page2 = await getPageMetadata(page.id, true)
                .unwrap();

            await updatePageMetadata({
                ...page2,
                id: pageId,
                name: pageName
            }).unwrap();

            navigate(`/site(${siteId})/design-page(${pageId})`)
        } catch (err) {
            console.error(`Unable to clone the page. <span className='ms-3 fs-8 text-gray-600 font-italic'>${err}<span>`)
        }
    }

    const getFilteredData = useCallback(() => {
        if (!data) return []

        const text = searchText.toLowerCase()

        return data.filter(p => p.name.toLowerCase().indexOf(text) !== -1)
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [data, searchText])

    return (
        <div className="position-relative">
            <div className="d-flex justify-content-center">
                <Spinner
                    show={isLoading || inserting || updating || deleting}
                    className="mb-2" message="Please wait ..." />
            </div>

            <AlertMessage
                show={isError}
                type="Error"
                message={(error as any)?.data?.error?.message || `Failed to load site pages`} />

            {data &&
                <div className="input-group input-group my-7">
                    <input
                        ref={ref}
                        type="search"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="form-control fs-6" />
                    <span className="input-group-text">
                        <i className="las la-search fs-5"></i>
                    </span>
                </div>}

            {getFilteredData().map(page =>
                <div key={page.id} className="card card-body bg-hover-light px-5 py-2 mb-2">
                    <div className="d-flex flex-row align-items-center">
                        <div className="flex-fill me-2">
                            <h6 className="m-0">
                                <Link to={`/site(${siteId})/page(${page.id})`}>{page.name}</Link>
                            </h6>
                            <div className="fs-7 mt-1">
                                <label className="text-gray-700 m-0 me-2 fw-semibold">Type: </label>
                                <span className="fw-bold text-gray-700">{page.type}</span>
                            </div>
                        </div>
                        <div>
                            <Link
                                to={`/site(${siteId})/design-page(${page.id})`}
                                className="btn btn-sm py-2 lh-1 btn-primary me-2">
                                <i className="las la-edit"></i>
                                Design
                            </Link>
                            <button
                                type="button"
                                onClick={() => handleDeletePage(page)}
                                className="btn btn-sm py-2 lh-1 btn-danger me-2">
                                <i className="las la-trash"></i>
                                Delete
                            </button>
                            <button
                                type="button"
                                onClick={() => handleClonePage(page)}
                                className="btn btn-sm py-2 lh-1 btn-secondary">
                                <i className="las la-clone"></i>
                                Clone
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>)
}