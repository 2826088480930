import { IPage } from "../../models";
import { ProgressIndicator } from "../../../../widgets";
import { ViewPageControls } from "./ViewPageControls";
import { useDataContext } from "../../context";

export const CardPage = ({ page, className }: {
    page: IPage,
    className?: string
}) => {
    const { type, isLoading, isSuccess, isError } = useDataContext()

    return (
        <div className={className}>
            {(isSuccess && (type === "Card" || type === "Static")) && <>
                <ViewPageControls controls={page.controls} />
            </>}
            <ProgressIndicator show={isLoading} message="Loading..." />
            {isError && <></>}
        </div>)
}
