import { DisplayFormat } from "../types"
import { IPageLink } from "./page-link"
import { ITableLookup } from "./table-lookup"

export enum GridTheme {
    Alpine = "ag-theme-alpine",  
    AlpineDark = "ag-theme-alpine-dark",
    Balham = "ag-theme-balham",
    BalhamDark = "ag-theme-balham-dark",
    Material = "ag-theme-material"
}

export interface IGridOptions {
    theme?: GridTheme,
    headerHeight?: number,    
    rowHeight?: number,
    pagination?: boolean,
    paginationPageSize?: number,

    columns?: IGridColumn[],
}

export enum GridPageTarget {
    Redirect = "redirect",
    NewTab = "new-tab",
    Dialog = "dialog",
    Offcanvas = "offcanvas",
}

export interface IGridColumn {
    sequence?: number,
    header: string,
    columnType: GridColumnType,
    pageLink?: IPageLink,
    pageTarget?: GridPageTarget,
    lookup?: ITableLookup,
    displayFormat?: DisplayFormat, 

    fieldType: GridFieldType,
    field?: string,
    urlField?: string,

    resizable?: boolean,
    sortable?: boolean,
    filter?: boolean

    cellClass?: string,
    headerClass?: string,
    alignment?: CellTextAlignment,

    width?: number, 
    minWidth?: number,
    maxWidth?: number,
}

export enum CellTextAlignment {
    Default = "",
    Start = "start",
    Center = "center",
    End = "end"
}

export enum GridFieldType {
    Text = "text",
    Boolean = "boolean",
    Date = "date",
    Time = "time", 
    DateTime = "date-time",
    Number = "number",
    Money = "money",
    Lookup = "lookup"
}

export enum GridColumnType {
    Normal = "normal",
    PageLink = "page-link",
    DownloadLink = "download-link"
}