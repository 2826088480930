import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import clsx from "clsx";
import { responsiveTextAlignmentClasses } from "../../../base/styles/helpers";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const RepeaterColumnHeader = ({
    control,
    className,
    children,
}: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const heading: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];

    const textAlign = responsiveTextAlignmentClasses(control.styles?.text?.alignment);

    if (mode === "Design") {
        return (
            <div style={{ display: "table-cell" }} className={clsx(className, textAlign, "fw-bold")} title={tooltip}>
                <span>{heading}</span>
                {children}
            </div>)
    } else {
        return <th className={clsx(className, textAlign, "fw-bold")}><span>{heading}</span></th>
    }
}