import { useEffect, useState } from "react";

import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../../helpers";
import { useGetProperties } from "../../../base/hooks";

export const Base64ImageComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const tooltip: string | undefined = properties[Property.Tooltip];
    const base64Image: string | undefined = properties[Property.Base64Image];
    const altText: string | undefined = properties[Property.AltText];

    const [base64, setBase64] = useState<string>()

    useEffect(() => {
        if (!base64Image) {
            setBase64(undefined)
            return;
        }

        if (base64Image.startsWith("data:"))
            setBase64(base64Image)
        else
            setBase64(`data:image/png;base64, ${base64Image}`)
    }, [base64Image])

    if (!base64)
        return <div className="h-75px d-flex justify-content-center">
            <img alt={altText} className={clsx(className)}
                style={{ objectFit: "cover", objectPosition: "top", height: "100%" }}
                src={toAbsoluteUrl('/media/svg/general/no-image-placeholder.svg')} title={tooltip} />
        </div>
    return (
        <img alt={altText} className={clsx(className, "dskjfk")}
            style={{ objectFit: "cover", objectPosition: "top" }}
            src={base64} title={tooltip} />
    )
}