import { MUIIcon } from "./MUIIcon"
import clsx from "clsx"
import { isObject } from "formik"
import { isString } from "react-bootstrap-typeahead/types/utils"

export const AlertMessage = ({ message, type, show, className, children }: {
    message?: string,
    type?: 'Info' | 'Warning' | 'Error'
    show?: boolean,
    className?: string,
    children?: React.ReactNode
}) => {
    if (show === false) return <></>
    
    if(  isObject(message) && !isString(message)) {
        console.error("Invalid message", message);
    }

    return (
        <div className={clsx(className, "alert fs-7 px-5 py-3", {
            "alert-danger": type === "Error",
            "alert-warning": type === "Warning",
            "alert-info": type === "Info"
        })}>
            <div className="d-flex align-items-center">
                <MUIIcon iconName={clsx({
                    'ErrorOutline': type === 'Error',
                    'WarningAmber': type === 'Warning',
                    'InfoOutlined': (type === 'Info' || !type)
                })} className="me-2 fs-2" />
                <span className="fw-semibold">{message}</span>
                {children}
            </div>
        </div>
    )
}