import { DesignAction, DesignEvent, IPageControl } from "../../base/models";
import { EditDisplay, SelectDisplay } from "./components/display";
import React, { useEffect, useState } from "react";

import Accordion from "react-bootstrap/esm/Accordion";
import { BackgroundStyles } from "./components/background";
import { BorderStyles } from "./components/borders";
import { EditComponentSpacing } from "./components/spacing/EditComponentSpacing";
import { EditControlGeneralProps } from "./components/general/EditControlGeneralProps";
import { EditLayout } from "./components/layout";
import { IControlStyle } from "../../base/styles";
import { SelectAlignment } from "./components/alignment";
import { SizingStyles } from "./components/sizing";
import { TextStyles } from "./components/text/TextStyles";
import { ViewportSizingStyles } from "./components/viewport-sizing";

export const ComponentStyle = ({ control, onDesignChange }: {
    control: IPageControl,
    onDesignChange: (event: DesignEvent) => void;
}) => {
    const [styles, setStyles] = useState<IControlStyle>(control.styles || {});

    useEffect(() => {
        setStyles(control.styles || {})
    }, [control])

    const onStyleChange = (style: IControlStyle) => {
        setStyles(style)
        const updatedControl: IPageControl = { ...control, styles: style }
        onDesignChange({ type: DesignAction.UpdateControl, control: updatedControl })
    }

    const onGenPropsChange = (control: IPageControl) => {
        onDesignChange({ type: DesignAction.UpdateControl, control })
    }

    return (
        <div>
            <Accordion className="accordion-sm" flush>
                <Accordion.Item eventKey="0" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">General</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <EditControlGeneralProps
                            control={control}
                            onChange={onGenPropsChange} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">Layout</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <EditLayout
                            value={styles.layout || {}}
                            onChange={(newValue) => onStyleChange({ ...styles, layout: newValue })} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">Background</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <BackgroundStyles
                            className="mb-3"
                            value={styles.background || {}}
                            onChange={(newValue) => onStyleChange({ ...styles, background: newValue })} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">Borders</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <BorderStyles
                            value={styles.borders || {}}
                            onChange={(newValue) => onStyleChange({ ...styles, borders: newValue })} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">Text</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <TextStyles
                            className="mb-3"
                            value={styles.text || {}}
                            onChange={(newValue) => onStyleChange({ ...styles, text: newValue })} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">Margins</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <EditComponentSpacing
                            className="mb-3"
                            title="Margin"
                            value={styles.spacing?.margin || {}}
                            onChange={(newValue) => onStyleChange({
                                ...styles, spacing: { ...styles.spacing, margin: newValue }
                            })} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">Paddings</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <EditComponentSpacing
                            className="mb-3"
                            title="Padding"
                            value={styles.spacing?.padding || {}}
                            onChange={(newValue) => onStyleChange({
                                ...styles, spacing: { ...styles.spacing, padding: newValue }
                            })} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">Sizing</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <h6 className="fs-7 fs-bold text-gray-700 mb-2">Normal</h6>
                        <SizingStyles
                            className="mb-3"
                            value={styles.sizing || {}}
                            onChange={(newValue) => onStyleChange({ ...styles, sizing: newValue })} />

                        <h6 className="fs-7 fs-bold text-gray-700 mb-2">Viewport</h6>
                        <ViewportSizingStyles
                            className="mb-3"
                            value={styles.viewportSizing || {}}
                            onChange={(newValue) => onStyleChange({ ...styles, viewportSizing: newValue })} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9" className="mb-2">
                    <Accordion.Header>
                        <span className="fs-7">Others</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <SelectAlignment
                            className="mb-3"
                            title="Alignment"
                            value={styles.alignment}
                            onChange={(newValue) => onStyleChange({ ...styles, alignment: newValue })} />
                        <EditDisplay
                            title="Display"
                            className="mb-3"
                            value={styles.display || {}}
                            onChange={(newValue) => onStyleChange({ ...styles, display: newValue })} />
                        <SelectDisplay
                            title="Print"
                            className="mb-3"
                            value={styles.printDisplay}
                            onChange={(newValue) => onStyleChange({ ...styles, printDisplay: newValue })} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}