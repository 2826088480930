import { DefaultLabel } from "../../widgets";
import { Enumeration } from "../../../base/types/enumeration";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const EnumLabelComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const value: string | undefined = properties[Property.Value];
    const enumValue: Enumeration | undefined = properties[Property.Enum];
    const tooltip: string | undefined = properties[Property.Tooltip];

    if (mode === "Design") {
        return (
            <span className={className} title={tooltip}>
                <DefaultLabel
                    control={control}
                    propertyId={Property.Value}
                    defaultLabel="Enum" />
            </span>)
    }

    const getEnumValue = () => {
        if (enumValue === undefined || value === undefined)
            return '';

        if (enumValue.type === "string") {
            const item = enumValue.items.find((p) => p.key === value)
            if (item) return item.value
        } else if (enumValue.type === "number") {
            const item = enumValue.items.find((p) => p.key === Number(value))
            if (item) return item.value
        }

        return value;
    }

    return (
        <label className={className} title={tooltip}>
            <span>{getEnumValue()}</span>
            {children}
        </label>)
}
