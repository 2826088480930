import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { QRCodeCanvas } from "qrcode.react";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const QRCodeComponent = ({ control, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const value: string | undefined = properties[Property.Value];
    const size: number | undefined = properties[Property.Size];

    if (mode === "Design" && !value) {
        return <div>
            <div className="alert alert-info fs-8 mb-0">
                <div className="badge badge-info mb-2">QR Code Component</div>
            </div>
        </div>
    }

    return (
        <QRCodeCanvas className={className} value={value || ""} size={size || 300} />
    )
}