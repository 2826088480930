import { ApiTags, rootApi } from "../rootApi";

import { ApiResponse } from "../models";
import { ICustomComponent } from "../../design/custom-component";

const TAG = ApiTags.ComponentMetadata;
const ComponentTag = ApiTags.Component;

const componentsMetadataApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getComponentMetadata: builder.query<ICustomComponent, string>({
            query: (componentId) => `/ComponentMetadata/components(${componentId})`,
            transformResponse: (res: ApiResponse) => {
                return JSON.parse(res.value) as ICustomComponent;
            },
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.id },
                { type: ComponentTag, id: result.id },
                    TAG] : [TAG]
        }),

        updateComponentMetadata: builder.mutation<ICustomComponent, ICustomComponent>({
            query: (component) => ({
                url: `/ComponentMetadata/components(${component.id})`,
                method: 'PATCH',
                body: { id: component.id, json: JSON.stringify(component) }
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.id },
                { type: ComponentTag, id: result.id },
                    TAG] : [TAG]
        }),
    })
})

export const {
    useGetComponentMetadataQuery,
    useLazyGetComponentMetadataQuery,
    useUpdateComponentMetadataMutation,
} = componentsMetadataApi;