import { AlertMessage, MUIIcon, Spinner } from "../../../../widgets"
import { useDeleteEntityDocumentChecklistItem, useGetDocumentCategory } from "../hooks"

import { DocumentImage } from "../../sharepoint-documents/components/DocumentImage"
import { IEntityChecklistItem_BC } from "../models"
import clsx from "clsx"
import { useDownloadDocument } from "../../../../services/data/sharepoint"
import { useGetDocumentType } from "../hooks/useGetDocumentType"

export const EntityChecklistItem = ({ checklistItem, documentCategoryId, className }: {
    checklistItem: IEntityChecklistItem_BC,
    documentCategoryId: string,
    className?: string,
}) => {
    const { data, isLoading, isError, error } = useGetDocumentType(checklistItem.documentTypeId)
    const {
        data: category,
        isLoading: isCategoryLoading,
        isError: isCategoryError,
        error: categoryError
    } = useGetDocumentCategory(documentCategoryId)
    const {
        deleteRecord,
        isLoading: isDeleting,
        isError: deleteFailed,
        error: deleteError
    } = useDeleteEntityDocumentChecklistItem()
    const { downloadDocument, isLoading: downloading } = useDownloadDocument()

    const onClickDelete = () => {
        deleteRecord(checklistItem.systemId)
    }

    const getFileName = (url: string) => {
        return (url || '').split('/').pop()
    }

    return (
        <div className={clsx(className)}>
            <AlertMessage show={isError} type="Error" message={error} />
            <AlertMessage show={isCategoryError} type="Error" message={categoryError} />
            <Spinner show={isLoading || isCategoryLoading} />
            <AlertMessage show={deleteFailed} type="Error" message={deleteError} />

            {(data && category) && <div className="card card-body px-0">
                <label className={clsx(
                    "fs-7 fw-bold mb-3 me-3",
                )}>{category.description}</label>

                <div className={clsx(className, 'card card-body border p-0')}>
                    <div className={clsx(
                        'd-flex align-items-center',
                        'cursor-pointer')
                    }>
                        <div className='d-flex align-items-center align-self-stretch px-5 bg-light rounded-start'>
                            <span className='symbol symbol-35px'>
                                <DocumentImage fileName={checklistItem.url} />
                            </span>
                        </div>
                        <div className='d-flex flex-column flex-fill px-3 p-2'>
                            <span className='fw-bolder fs-7'>{category.description}</span>
                            <span className='fs-8 text-muted'>
                                {getFileName(checklistItem.url)}
                            </span>
                            <div>
                                <button type="button"
                                    disabled={downloading}
                                    onClick={() => downloadDocument(checklistItem.url)}
                                    className="btn btn-outline text-hover-primary my-1 py-1 p-2 fs-9 fw-semibold">
                                    <MUIIcon className="fs-6 me-1" iconName="CloudDownloadOutlined" />
                                    Download
                                </button>
                            </div>
                        </div>
                        <div className='d-flex align-self-start p-2'>
                            <button
                                disabled={isDeleting}
                                className='btn btn-sm p-0 text-hover-primary'
                                title="Delete the document"
                                onClick={onClickDelete}>
                                <Spinner show={isDeleting} />
                                {(!isDeleting) && <MUIIcon
                                    className="fs-6 fw-bold"
                                    iconName="CloseOutlined" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}