import { ActionType, IActionCollectionProps } from "../models";

import { ActionTypesDialog } from "./ActionTypesDialog";
import { DesignAction } from "../../../base/models";
import { DndItem } from "../../dnd/models";
import { Droppable } from "../../dnd";
import { PageAction } from "./PageAction";
import { PageActionDesigner } from "../PageActionDesigner";
import React from "react";
import { createAction } from "../../helpers";
import { useDesignContext } from "../../../providers";
import { useState } from "react"

export const ActionCollection = ({
    action, actions, onDesignChange
}: IActionCollectionProps) => {
    const { mode } = useDesignContext()

    switch (mode) {
        case "Design":
            return <DesignActionCollection
                action={action}
                actions={actions}
                onDesignChange={onDesignChange} />
        case "Preview":
            return <ViewActionCollection
                action={action}
                actions={actions}
                onDesignChange={() => { }} />
    }
}

const ViewActionCollection = ({ actions }: IActionCollectionProps) => {
    if (!actions) return <></>

    return <>
        {actions.slice()
            .sort((a, b) => a.sequence - b.sequence)
            .map((action) =>
                <React.Fragment key={action.actionId}>
                    <PageAction
                        action={action}
                        onDesignChange={() => { }} />
                </React.Fragment>)}
    </>
}

const DesignActionCollection = ({ actions, className, onDesignChange }: IActionCollectionProps) => {
    const [dialog, showDialog] = useState<boolean>(false);
    const [targetActionId, setTargetActionId] = useState<string>()

    const onSelectAction = (actionType: ActionType) => {
        if (!targetActionId) {
            console.error(`Could't insert the new control, target control is not updated.`)
            return;
        }

        const newAction = createAction(actionType)

        onDesignChange({
            type: DesignAction.InsertAfterAction,
            actionId: targetActionId,
            action: newAction
        });
    }
    
    const onDropItem = (item: DndItem, actionId: string) => {
        if (item.type !== "Action") return;

        onDesignChange({
            type: DesignAction.DropAfterAction,
            actionId: actionId,
            action: item.action
        });
    }

    const onInsertAfter = (actionId: string) => {
        setTargetActionId(actionId)
        showDialog(true);
    }

    return <>
        {actions.slice()
            .sort((a, b) => a.sequence - b.sequence)
            .map((action) =>
                <React.Fragment key={action.actionId}>
                    <PageActionDesigner
                        action={action}
                        className={className}
                        onDesignChange={onDesignChange}>
                        <Droppable layout={"Auto"}
                            title={`Click to insert a new component`}
                            onDropItem={(item) => onDropItem(item, action.actionId)}
                            onClick={(e) => {
                                e.stopPropagation();
                                onInsertAfter(action.actionId)
                            }} />
                    </PageActionDesigner>
                </React.Fragment>)}

        <ActionTypesDialog
            show={dialog}
            onSelect={onSelectAction}
            onHideModal={() => showDialog(false)}
        />
    </>
}