import { Placement } from "../../../../../base/types";

export const PlacementValue = ({ label, value, className, onChange }: {
    label: string,
    value: any
    className?: string,
    onChange: (value?: Placement) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as Placement;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return <select
        onChange={handleOnSelectionChange}
        value={value || ""}
        className="form-select form-select-sm form-control-xs">
        <option value={Placement.Start}>Start</option>
        <option value={Placement.End}>End</option>
        <option value={Placement.Top}>Top</option>
        <option value={Placement.Bottom}>Bottom</option>
    </select>
}
