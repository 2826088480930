import { ApiTags, rootApi } from './../rootApi';

import { ApiResReadMultiple } from '../models';
import { IPageComponent } from '../../base/models';

const TAG = ApiTags.Component;
const getTags = (entities: IPageComponent[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const componentsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getComponents: builder.query<IPageComponent[], void>({
      query: () => '/components',
      transformResponse: (res: ApiResReadMultiple<IPageComponent>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getComponent: builder.query<IPageComponent, string>({
      query: (id) => `/components(${id})`,
      providesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    createComponent: builder.mutation<IPageComponent, IPageComponent>({
      query: (page) => ({
        url: 'components',
        method: 'POST',
        body: page
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updateComponent: builder.mutation<IPageComponent, Partial<IPageComponent>>({
      query: (page) => ({
        url: `components(${page.id})`,
        method: 'PATCH',
        body: page
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deleteComponent: builder.mutation<void, string>({
      query: (pageId: string) => ({
        url: `components(${pageId})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    })
  })
})

export const {
  useGetComponentsQuery,
  useGetComponentQuery,
  useCreateComponentMutation,
  useUpdateComponentMutation,
  useDeleteComponentMutation,
} = componentsApi;