import { ComponentList } from '../framework/pages/components'
import { FC } from 'react'
import { PageTitle } from '../layout/core'

export const ComponentsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Site Components</PageTitle>
      <ComponentList />
    </>)
}
