import * as uuid from "uuid";

import { useEffect, useState } from "react"

import { DropdownOption } from "../../../base/types";
import Modal from "react-bootstrap/esm/Modal"
import React from "react";
import clsx from "clsx";

export const EditDropdownButton = ({ options, className, onChange }: {
    options: DropdownOption[],
    className?: string,
    onChange: (options: DropdownOption[]) => void,
}) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const hideModal = () => {
        setShowDialog(false);
    }

    return <>
        <div className="fs-8 mb-2">
            <div>
                <p className="fw-bold text-gray-600 mb-1">Options:</p>
                {options.map((item, i) => <p key={i} className="fw-semibold m-0">
                    <span className="text-gray-600">{item.value}</span>
                    <span className="mx-2">:</span>
                    <span className="text-primary">{item.label}</span>
                </p>)}
            </div>
            
            <div className="d-flex align-items-center mt-3">
                <button
                    type="button"
                    className={clsx(className, "btn btn-primary btn-sm py-2")}
                    onClick={() => setShowDialog(true)}>
                    Edit Options
                </button>
            </div>
        </div>

        <DropdownOptionsModel
            hideModal={hideModal}
            show={showDialog}
            options={options}
            onChange={onChange}
        />
    </>
}

type DropdownOptionWithId = DropdownOption & { id: string }
const DropdownOptionsModel = ({ options, show, hideModal, onChange }: {
    options: DropdownOption[],
    show: boolean,
    hideModal: () => void,
    onChange: (options: DropdownOption[]) => void
}) => {
    const [localOptions, setLocalOptions] = useState<DropdownOptionWithId[]>([]);

    useEffect(() => {
        const optionsWithId = options.map((option: DropdownOption) => ({ ...option, id: uuid.v1() }))
        setLocalOptions(optionsWithId)
    }, [options, show])

    return <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
        <Modal.Header closeButton className="bg-secondary border-0 py-3">
            <h4 className="my-0">Dropdown Options</h4>
        </Modal.Header>
        <Modal.Body>
            <DropdownOptionsTable
                options={localOptions}
                onChange={setLocalOptions} />
        </Modal.Body>
        <Modal.Footer className="border-0 py-5">
            <button type="button"
                onClick={() => {
                    onChange(localOptions)
                    hideModal()
                }}
                className="btn btn-sm btn-primary">OK</button>
            <button type="button"
                onClick={hideModal}
                className="btn btn-sm btn-secondary">Cancel</button>
        </Modal.Footer>
    </Modal>
}

const DropdownOptionsTable = ({ options, onChange }: {
    options: DropdownOptionWithId[],
    onChange: (options: DropdownOptionWithId[]) => void,
}) => {
    const handleOnAdd = (option: DropdownOptionWithId) => {
        onChange([...options, option]);
    }

    const handleOnUpdate = (option: DropdownOptionWithId) => {
        onChange(options.map(p => p.id === option.id ? option : p))
    }

    const handleOnDelete = (option: DropdownOptionWithId) => {
        onChange(options.filter(p => p.id !== option.id))
    }

    return (<table className="table table-sm table-borderless fs-7">
        <thead className="fw-bold">
            <tr>
                <th scope="col">Value</th>
                <th scope="col">Label</th>
                <th scope="col">&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            {options.map(option => (
                <React.Fragment key={option.id}>
                    <EditDropdownOptionRow
                        option={option}
                        onDelete={handleOnDelete}
                        onUpdate={handleOnUpdate}
                    />
                </React.Fragment>))}

            <NewDropdownOptionRow onAdd={handleOnAdd} />
        </tbody>
    </table>)
}

const EditDropdownOptionRow = ({ option, onUpdate, onDelete }: {
    option: DropdownOptionWithId,
    onUpdate: (prop: DropdownOptionWithId) => void,
    onDelete: (prop: DropdownOptionWithId) => void,
}) => {
    return (
        <tr>
            <td className="py-1">
                <input type="text"
                    value={option.value}
                    onChange={(e) => onUpdate({ ...option, value: e.target.value })}
                    className="form-control form-control-sm" />
            </td>
            <td className="py-1">
                <input type="text"
                    value={option.label}
                    onChange={(e) => onUpdate({ ...option, label: e.target.value })}
                    className="form-control form-control-sm" />
            </td>
            <td className="py-1">
                <button
                    type="button"
                    onClick={() => onDelete(option)}
                    className="btn btn-sm btn-danger py-2">
                    <i className="las la-trash"></i>
                    Delete
                </button>
            </td>
        </tr>)
}

const NewDropdownOptionRow = ({ onAdd }: {
    onAdd: (option: DropdownOptionWithId) => void
}) => {
    const [option, setOption] = useState<DropdownOptionWithId>({ id: uuid.v1(), value: '', label: '' });

    return (
        <tr>
            <td className="py-1">
                <input type="text"
                    value={option.value}
                    onChange={(e) => setOption({ ...option, value: e.target.value })}
                    className="form-control form-control-sm" />
            </td>
            <td className="py-1">
                <input type="text"
                    value={option.label}
                    onChange={(e) => setOption({ ...option, label: e.target.value })}
                    className="form-control form-control-sm" />
            </td>
            <td className="py-1">
                <button
                    type="button"
                    onClick={() => {
                        onAdd(option);
                        setOption({ id: uuid.v1(), value: '', label: '' });
                    }}
                    className="btn btn-sm btn-primary py-2">
                    <i className="las la-plus"></i>
                    Add
                </button>
            </td>
        </tr>)
}