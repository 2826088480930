import { ApiTags, rootApi } from "../rootApi";

import { IEntityRelation } from "./models";

const TAG = ApiTags.EntityRelations;
const getTags = (entities: IEntityRelation[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const entityRelationsApi = rootApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    getEntityRelation: builder.query<IEntityRelation, { siteId: string, relationId: string }>({
      query: ({ siteId, relationId }) => `/${siteId}/PortalEntityRelations/${relationId}`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    readEntityRelations: builder.query<IEntityRelation[], { siteId: string }>({
      query: ({ siteId }) => `/${siteId}/PortalEntityRelations`,
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    createEntityRelation: builder.mutation<IEntityRelation, { siteId: string, entity: Partial<IEntityRelation> }>({
      query: ({ siteId, entity }) => {
        return {
          url: `/${siteId}/PortalEntityRelations`,
          method: 'POST',
          body: entity
        }
      },
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updateEntityRelation: builder.mutation<IEntityRelation, { siteId: string, relationId: string, entity: Partial<IEntityRelation> }>({
      query: ({ siteId, relationId, entity }) => {
        return {
          url: `/${siteId}/PortalEntityRelations/${relationId}`,
          method: 'PATCH',
          body: entity
        }
      },
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deleteEntityRelation: builder.mutation<void, { siteId: string, relationId: string }>({
      query: ({ siteId, relationId }) => {
        return {
          url: `/${siteId}/PortalEntityRelations/${relationId}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, x, args) => result ? [{ type: TAG, id: args.relationId }, TAG] : [TAG]
    })
  })
})

export const {
  useGetEntityRelationQuery,
  useLazyGetEntityRelationQuery,
  useReadEntityRelationsQuery,
  useLazyReadEntityRelationsQuery,
  useCreateEntityRelationMutation,
  useUpdateEntityRelationMutation,
  useDeleteEntityRelationMutation,
} = entityRelationsApi;