import { IActionProps } from "../models"
import { MUIIcon } from "../../../widgets"

export const ActionComponent = ({ action, className }: IActionProps) => {
    return (
        <div className={className}>
            <div className="px-5 py-2">
                {action.icon && <MUIIcon iconName={action.icon} className="me-1" />} {action.name}
            </div>
        </div>)
}

