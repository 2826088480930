import { IDataAttribute } from "../models";
import { useAuth } from "../../../modules/auth";
import { useLazyReadAttributesQuery } from "../../services/data/table";
import { useState } from "react";

export const useReadAttributes = () => {
    type AttributesStatus = {
        attributes?: IDataAttribute[],
        isLoading: boolean,
        isSuccess: boolean,
        isError: boolean,
        error?: string
    }
    const [status, setStatus] = useState<AttributesStatus>({ isLoading: false, isSuccess: false, isError: false })
    const [getAttributes] = useLazyReadAttributesQuery();
    const { siteId } = useAuth()

    const readAttributes = (entity: string) => {
        if (!entity || !siteId) {
            setStatus({ isLoading: true, isSuccess: false, isError: true, error: 'Invalid entity' })
            return Promise.reject('Invalid entity');
        }

        setStatus({ isLoading: true, isSuccess: false, isError: false })
        return getAttributes({ siteId, entity }, true)
            .unwrap()
            .then(attributes => {
                setStatus({ isLoading: false, isSuccess: true, isError: false, attributes })
                return Promise.resolve(attributes)
            })
            .catch(err => {
                const error = (err as any)?.data?.error?.message;
                setStatus({ isLoading: false, isSuccess: true, isError: true, error })
                return Promise.reject(error)
            })

        // eslint-disable-next-line
    }

    return { readAttributes, ...status }
}
