import { AggregateMethod, Property } from "../../../../base/types";
import { getAveragePropertyValue, getMaxPropertyValue, getMinPropertyValue, getSumOfPropertyValue, getUniquePropertyValues } from "./helper";
import { useEffect, useState } from "react";

import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts"
import { IControlProps } from "../../../../base/models";
import _ from "lodash";
import clsx from "clsx";
import { useDataContext } from "../../../page/context";
import { useGetProperties } from "../../../../base/hooks";

interface IChartData {
    category: string,
    label: string,
    value: number
}

export const StackedBarsComponent = ({ control, className, children }: IControlProps) => {
    const { properties, getPropertyValue } = useGetProperties({ bindings: control.bindings })
    const tooltip: string | undefined = properties[Property.Tooltip]
    const height: number | undefined = properties[Property.Height]
    const aggregateMethod: AggregateMethod | undefined = properties[Property.AggregateMethod]

    const labelsField = getPropertyValue<string>(Property.LabelsField)
    const categoryField = getPropertyValue<string>(Property.CategoryField)
    const valuesField = getPropertyValue<string>(Property.ValuesField)
    const { type, data } = useDataContext()

    const [options, setOptions] = useState<ApexOptions>();
    const [series, setSeries] = useState<ApexAxisChartSeries>();
    const colorMapJsonText: string | undefined = getPropertyValue(Property.ColorMap)

    const getColors = (labels: string[], colorMapJsonText?: string) => {
        try {
            if (!colorMapJsonText) return;
            const colorMap = JSON.parse(colorMapJsonText);
            return labels.map(p => (colorMap[p] || null));
        } catch (err) { console.log(err) }
    }

    const getChartData = () => {
        if (type !== "List" || !categoryField || !labelsField || !valuesField) {
            return;
        }

        const rows = data.records.map<IChartData>(row => ({
            category: row[categoryField] as string,
            label: row[labelsField] || 'Others' as string,
            value: Number(row[valuesField] || 0),
        }))

        return rows;
    }

    const aggregateChartData = (rows: IChartData[], aggregateMethod?: string) => {
        const data = []
        const categories = getUniquePropertyValues(rows, "category") as string[];
        const labels = getUniquePropertyValues(rows, "label") as string[];

        for (const category of categories) {
            const categoryData = rows.filter(p => p.category === category);

            for (const label of labels) {
                let value = 0;
                const labelData = categoryData.filter(p => p.label === label);
                switch (aggregateMethod) {
                    case AggregateMethod.Maximum:
                        value = Number(getMaxPropertyValue(labelData, "value") || 0)
                        break;
                    case AggregateMethod.Minimum:
                        value = Number(getMinPropertyValue(labelData, "value") || 0)
                        break;
                    case AggregateMethod.Count:
                        value = labelData.length;
                        break;
                    case AggregateMethod.Average:
                        value = Number(getAveragePropertyValue(labelData, "value") || 0)
                        break;
                    case AggregateMethod.Sum:
                    default:
                        value = Number(getSumOfPropertyValue(labelData, "value") || 0)
                        break;
                }

                data.push({ category, label, value })
            }
        }

        return _.sortBy(data, p => p.label)
    }

    const updateChartData = () => {
        const rows = getChartData();
        if (!rows) return;

        const chartData = aggregateChartData(rows, aggregateMethod);
        const categories = getUniquePropertyValues(chartData, "category") as string[];
        const labels = getUniquePropertyValues(chartData, "label") as string[];
        const colors = getColors(labels, colorMapJsonText)

        const series: ApexAxisChartSeries = [];
        for (const label of labels) {
            const seriesData = chartData.filter(p => p.label === label).map(p => p.value);
            series.push({ name: label, data: seriesData })
        }

        setOptions({
            colors,
            chart: {
                type: 'bar',
                stacked: true,
                stackType: '100%',
                toolbar: { show: false },
            },
            plotOptions: {
                bar: {horizontal: true
                }                
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                offsetX: 20,
            },
            xaxis: {
                type: 'category',
                labels: { show: false },
                categories: categories,
            },
            fill: {
                opacity: 1
            },
        })

        setSeries(series)
    }

    useEffect(() => {
        updateChartData();
        // eslint-disable-next-line
    }, [type, data, properties])

    return (
        <>
            <div title={tooltip} className={clsx(className, "position-relative")} >
                {(options && series) && <>
                    <Chart options={options} series={series} type="bar" width={"100%"} height={height || "250"} />
                </>}
            </div>
        </>)
}