import { HTMLInputTypeAttribute, useEffect } from "react";

import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { StringBinding } from "../../../base/bindings/string";
import clsx from "clsx";
import { useDesignContext } from "../../../providers";
import { useFromData } from "../panels/FormComponent";
import { useGetProperties } from "../../../base/hooks";
import { useState } from "react";

type Props = { type: HTMLInputTypeAttribute }
export const InputComponent = ({ control, type, className }: IControlProps & Props) => {
    const { mode } = useDesignContext()
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const inputValue: string | undefined = properties[Property.ControlValue];
    const required: boolean = properties[Property.Required] || false;
    const readonly: boolean = properties[Property.Readonly] || false;
    const disabled: boolean = properties[Property.Disabled] || false;

    const [binding, setBinding] = useState<StringBinding>()
    const [value, setValue] = useState<any>()
    const { form, values, setFieldValue } = useFromData()

    useEffect(() => {
        const binding = getBinding(Property.ControlValue) as StringBinding
        if (!binding)
            return;

        setBinding(binding)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (mode !== "Preview" || inputValue === undefined)
            return;

        setValue(getTypeValue(inputValue))
        // eslint-disable-next-line
    }, [inputValue])

    useEffect(() => {
        if (mode !== "Design" || !binding)
            return;

        switch (binding.type) {
            case "Expression":
                setValue(`{${binding.value.query}}`)
                break;
            case "Const":
                setValue(`"${binding.value}"`)
                break;
            case "Field":
                setValue(`{${binding.value}}`)
                break;
        }

        // eslint-disable-next-line
    }, [binding])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field" || values[binding.value] === undefined)
            return;

        setValue(getTypeValue(values[binding.value]))
        // eslint-disable-next-line
    }, [values])

    useEffect(() => {
        if (!form || !binding || binding.type !== "Field" || value === undefined)
            return;

        setFieldValue(binding.value, getTypeValue(value))
        // eslint-disable-next-line
    }, [value])

    const getTypeValue = (value?: string | number) => {
        switch (type) {
            case "number":
                return value ? Number(value) : ''
            case "date":
                if (!value) return '';
                return (value as string).length > 10 ?
                    (value as string).slice(0, 10) :
                    value
            default:
                return value || ''
        }
    }

    const onChange = (value: string) => {
        setValue(getTypeValue(value))
    }

    return (
        <div className={className}>
            {label &&
                <label
                    title={tooltip}
                    htmlFor={control.controlId}
                    className={clsx("form-label text-gray-600 fs-7", { "required": required })}>
                    {label}
                </label>}
            <input
                onChange={(e) => { onChange(e.target.value) }}
                id={control.controlId}
                title={tooltip}
                value={value || ''}
                className={"form-control form-control-sm"}
                readOnly={readonly}
                disabled={disabled || binding?.type !== "Field"}
                required={required}
                type={type}
            />
        </div>
    )
}