import * as uuid from "uuid";

import { ActionCallback, useToolbar } from "../../../layout";
import { useEffect, useRef } from "react";

import { Current_Page_Version } from "../../../design/page";
import { PageType } from "../../../design/page/models";
import { useAuth } from "../../../../modules/auth";
import { useCreatePageMutation } from "../../../services/data";
import { useNavigate } from "react-router-dom";

export const useSitePageToolbarActions = () => {
    const [createPage, { isLoading, isError, error }] = useCreatePageMutation();
    const navigate = useNavigate();
    const { siteId: portalId } = useAuth()
    const { setButtons, setOnAction } = useToolbar()

    const onToolbarAction = async (actionId: string) => {
        switch (actionId) {
            case 'add-new-page':
                await createNewPage()
                break;
        }
    }
    const onActionRef = useRef<ActionCallback>(onToolbarAction)

    useEffect(() => {
        onActionRef.current = onToolbarAction
        setOnAction(onActionRef)

        setButtons([{
            id: 'add-new-page',
            label: 'Add New Page',
            className: 'btn btn-primary btn-sm py-2',
            icon: 'AddOutlined',
        }])
        // eslint-disable-next-line
    }, [])

    const createNewPage = () => {
        if (!portalId) {
            return Promise.reject();
        }

        return createPage({
            id: uuid.v1(),
            name: '<< Page Name >>',
            title: '<< Page Title >>',
            portalId,
            type: PageType.Card,
            version: Current_Page_Version,
        }).unwrap()
            .then((page) => {
                navigate(`/site(${portalId})/design-page(${page.id})`)
            })
    }

    return { isLoading, isError, error }
}
