import { ConstPropertyValue, FieldPropertyValue } from "./components";
import { Expression, SelectBindingType } from "../widgets";

import { Binding } from "../../base/bindings";
import { IDataSource } from "../../base/types";
import { IProperty } from "../../base/models";
import clsx from "clsx";

export const ComponentProperty = ({ property, dataSource, binding, className, onChange }: {
    property: IProperty
    binding: Binding,
    dataSource?: IDataSource,
    className?: string,
    onChange: (binding: Binding) => void,
}) => {
    return <div className={clsx(className, "card card-secondary border rounded-2")}>
        <div className="card-header bg-secondary min-h-5px px-5 py-1">
            <div className="d-flex flex-fill align-items-center fs-8">
                <div className="fw-semibold my-1">{property.name}</div>
                <div className="flex-fill"></div>
                <div>
                    <SelectBindingType
                        property={property}
                        binding={binding}
                        onChange={onChange} />
                </div>
            </div>
        </div>
        <div className="card-body px-5 py-2">
            <PropertyBody
                property={property}
                binding={binding}
                dataSource={dataSource}
                onChange={onChange} />
        </div>
    </div>
}

const PropertyBody = ({ property, binding, dataSource, className, onChange }: {
    property: IProperty
    binding: Binding,
    dataSource?: IDataSource,
    className?: string,
    onChange: (binding: Binding) => void,
}) => {
    switch (binding.type) {
        case "Const":
            return <ConstPropertyValue
                binding={binding}
                property={property}
                dataSource={dataSource}
                onChange={onChange} />
        case "Field":
            return <FieldPropertyValue
                binding={binding}
                dataSource={dataSource}
                onChange={onChange} />
        case "Expression":
            return <Expression
                type={property.type}
                expression={binding.value}
                dataSource={dataSource}
                onChange={(value) => {
                    const newBinding = { ...binding }
                    newBinding.value = value
                    onChange(newBinding)
                }} />
        default:
            return <div className={className}>
                Invalid binding type
            </div>
    }
}

