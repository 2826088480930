import { DndItem } from "./models"
import clsx from "clsx"
import { useDndContext } from "./DndContextProvider"
import { useRef } from "react"

export const Draggable = ({ className, item, styles, children, onClick }: {
    item: DndItem,
    className?: string,
    styles?: React.CSSProperties,
    children?: React.ReactNode,
    onClick?: (e: React.MouseEvent) => void
}) => {
    const draggableRef = useRef<HTMLDivElement>(null)
    const { setItem } = useDndContext()

    const onDragStart = (e: React.DragEvent) => {
        e.stopPropagation()
        e.currentTarget.classList.add("pfx-dragging")
        setItem(item)
    }

    const onDragEnd = (e: React.DragEvent) => {
        e.stopPropagation()
        e.currentTarget.classList.remove("pfx-dragging")
        setItem({ type: "Empty" })
    }

    return <div style={styles} className={clsx(className, "pfx-drop-zone")}
        onClick={onClick}
        draggable="true"
        ref={draggableRef}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
    >
        {children}
    </div>
}