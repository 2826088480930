import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import clsx from "clsx";
import { useGetProperties } from "../../../base/hooks";

export const TextAreaComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const value: string | undefined = properties[Property.ControlValue];
    const required: boolean = properties[Property.Required] || false;
    const readonly: boolean = properties[Property.Readonly] || false;
    const disabled: boolean = properties[Property.Disabled] || false;

    return (
        <div className={clsx(className, 'd-flex flex-column')}>
            {label &&
                <label
                    title={tooltip}
                    htmlFor={control.controlId}
                    className={clsx("form-label text-gray-600 fs-7", { "required": required })}>
                    {label}
                </label>}
            <textarea
                className={"form-control form-control-sm flex-fill"}
                readOnly={readonly}
                disabled={disabled}
                id={control.controlId}
                title={tooltip}
                value={value}
            />
        </div>
    )
}