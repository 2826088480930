import { getMediaType } from "../helper"
import { useLazyGetSPFileWithContentQuery } from "../sharePointFilesApi"
import { useState } from "react"

export const useDownloadDocument = () => {
    const [getFileWithContent] = useLazyGetSPFileWithContentQuery()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState()

    const getBase64Url = (relativeUrl: string): Promise<string> => {
        return getFileWithContent(relativeUrl)
            .unwrap()
            .then((data) => {
                const url = `data:${getMediaType(data.name)};base64,${data.content}`;
                return Promise.resolve(url)
            })
            .catch((error) => {
                return Promise.reject(error)
            })
    }

    const downloadDocument = (relativeUrl: string) => {
        setIsLoading(true)
        getFileWithContent(relativeUrl)
            .unwrap()
            .then(async (data) => {
                const url = `data:${getMediaType(data.name)};base64,${data.content}`;

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.name);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
                link?.parentNode?.removeChild(link);
                setIsSuccess(true)
            })
            .catch((error) => {
                setError(error)
                setIsError(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return { downloadDocument, getBase64Url, isLoading, isSuccess, isError, error }
}