import { ZIndex } from "../../../../base/styles";
import clsx from "clsx";

export const SelectZIndex = ({ title, value, className, onChange }: {
    title: string,
    value?: ZIndex,
    className?: string,
    onChange: (value?: ZIndex) => void,
}) => {

    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as ZIndex;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={ZIndex.Z0}>0</option>
                <option value={ZIndex.Z1}>1</option>
                <option value={ZIndex.Z2}>2</option>
                <option value={ZIndex.Z3}>3</option>
                <option value={ZIndex.n1}>N1</option>
                <option value={ZIndex.n2}>N2</option>
            </select>
        </div>
    )
}