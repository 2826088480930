import { AggregateMethod } from "../../../../../base/types";

export const AggregateMethodValue = ({ label, value, className, onChange }: {
    label: string,
    value: AggregateMethod
    className?: string,
    onChange: (value?: AggregateMethod) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as AggregateMethod;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return <select
        onChange={handleOnSelectionChange}
        value={value || ""}
        className="form-select form-select-sm form-control-xs">
        <option value={AggregateMethod.Sum}>Sum</option>
        <option value={AggregateMethod.Count}>Count</option>
        <option value={AggregateMethod.Average}>Average</option>
        <option value={AggregateMethod.Minimum}>Minimum</option>
        <option value={AggregateMethod.Maximum}>Maximum</option>
    </select>
}
