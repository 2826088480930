import { IPage } from "../../design/page/models"
import { IPageAction } from "../../design/page-action/models";
import { IPageControl } from './page-control';

export enum DesignAction {
    Update = "Update",
    DeleteControl = "DeleteControl",
    UpdateControl = "UpdateControl",
    ShiftControlUp = "ShiftControlUp",
    ShiftControlDown = "ShiftControlDown",
    InsertAfterControl = "InsertAfterControl",
    InsertFirstControl = "InsertFirstControl",
    InsertFirstLayoutControl = "InsertFirstLayoutControl",
    InsertLastLayoutControl = "InsertLastLayoutControl",

    DropOnFirstControl = "DropOnFirstControl",
    DropAfterControl = "DropAfterControl",
    DropFirstLayoutControl = "DropFirstLayoutControl",
    DropLastLayoutControl = "DropLastLayoutControl",

    DeleteAction = "DeleteAction",
    UpdateAction = "UpdateAction",
    ShiftActionUp = "ShiftActionUp",
    ShiftActionDown = "ShiftActionDown",
    InsertAfterAction = "InsertAfterAction",
    InsertFirstAction = "InsertFirstAction",
    InsertFirstLayoutAction = "InsertFirstLayoutAction",
    InsertLastLayoutAction = "InsertLastLayoutAction",

    DropOnFirstAction = "DropOnFirstAction",
    DropAfterAction = "DropAfterAction",
    DropFirstLayoutAction = "DropFirstLayoutAction",
    DropLastLayoutAction = "DropLastLayoutAction",
}

interface IDesignEvent {
    type: DesignAction,
}

export interface IUpdateEvent extends IDesignEvent {
    type: DesignAction.Update
    page: IPage
}


export interface IDeleteControlEvent extends IDesignEvent {
    type: DesignAction.DeleteControl
    controlId: string
}

export interface IShiftControlEvent extends IDesignEvent {
    type: DesignAction.ShiftControlUp | DesignAction.ShiftControlDown
    controlId: string
}

export interface IInsertControlEvent extends IDesignEvent {
    type: DesignAction.InsertAfterControl | DesignAction.InsertFirstControl |
    DesignAction.DropOnFirstControl | DesignAction.DropAfterControl,
    controlId: string,
    control: IPageControl
}

export interface IInsertLayoutControl extends IDesignEvent {
    type: DesignAction.InsertFirstLayoutControl | DesignAction.InsertLastLayoutControl |
    DesignAction.DropFirstLayoutControl | DesignAction.DropLastLayoutControl,
    control: IPageControl
}

export interface IUpdateControl extends IDesignEvent {
    type: DesignAction.UpdateControl,
    control: IPageControl
}

export interface IDeleteActionEvent extends IDesignEvent {
    type: DesignAction.DeleteAction
    actionId: string
}

export interface IShiftActionEvent extends IDesignEvent {
    type: DesignAction.ShiftActionUp | DesignAction.ShiftActionDown
    actionId: string
}

export interface IInsertActionEvent extends IDesignEvent {
    type: DesignAction.InsertAfterAction | DesignAction.InsertFirstAction |
    DesignAction.DropOnFirstAction | DesignAction.DropAfterAction,
    actionId: string,
    action: IPageAction
}

export interface IInsertLayoutAction extends IDesignEvent {
    type: DesignAction.InsertFirstLayoutAction | DesignAction.InsertLastLayoutAction |
    DesignAction.DropFirstLayoutAction | DesignAction.DropLastLayoutAction,
    action: IPageAction
}

export interface IUpdateAction extends IDesignEvent {
    type: DesignAction.UpdateAction,
    action: IPageAction
}

type DesignControlEvent = IDeleteControlEvent | IShiftControlEvent |
    IInsertControlEvent | IInsertLayoutControl | IUpdateControl

type DesignActionEvent = IDeleteActionEvent | IShiftActionEvent |
    IInsertActionEvent | IInsertLayoutAction | IUpdateAction

export type DesignEvent = IUpdateEvent | DesignControlEvent | DesignActionEvent

