import "./timeline.scss"

import { DataContextProvider, useDataContext } from "../../page/context";
import { IDataSource, IEntity, Property } from "../../../base/types";
import { useEffect, useState } from "react";

import { DateFormat } from "./helper";
import { IControlProps } from "../../../base/models";
import { ListRows } from "../lists";
import React from "react";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const TimelineComponent = ({ control, children, className }: IControlProps) => {
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const oldestOnTop: boolean | undefined = properties[Property.OldestOnTop];
    const [timeField, setTimeField] = useState<string>()
    const { type, data } = useDataContext()
    const { mode } = useDesignContext()

    useEffect(() => {
        const binding = getBinding(Property.TimeField)
        if (binding?.type === "Field") {
            setTimeField(binding.value)
        }

        // eslint-disable-next-line
    }, [properties])

    if (mode === "Design") {
        const timeField2 = timeField || 'time';
        const designData: IEntity[] = type === "List" && data.count > 0 ?
            [data.records[0]] :
            [{ [timeField2]: new Date() }]

        return <div className={clsx(className)}>
            <Timeline records={designData} timeField={timeField2}>
                {children}
            </Timeline>
        </div>

    }

    return (
        <div className={clsx(className)}>
            {(type === "List" && timeField) && <>
                <Timeline
                    records={data.records}
                    oldestOnTop={oldestOnTop}
                    timeField={timeField}>
                    {children}
                </Timeline>
            </>}
        </div>
    )
}

interface ITimelineData {
    key: string,
    date: string,
    time: string,
    records: IEntity[]
}


const getTimelineData = (timeField: string, records: IEntity[], oldestOnTop: boolean = false) => {
    const sorted = _.orderBy(records, p => moment(p[timeField]))
    const grouped = _.groupBy(sorted, p => p[timeField])
    const items: ITimelineData[] = []

    _.forEach(grouped, (values, key) => {
        const keyText = (key && key !== 'undefined') ? DateFormat.dateTime(key) : '';

        items.push({
            key: keyText,
            date: keyText ? DateFormat.date(key) : '',
            time: keyText ? DateFormat.time12HH_MM(key) : '',
            records: values
        })
    })

    let sortedItems = items.sort((a, b) => moment(a.key).valueOf() - moment(b.key).valueOf())
    sortedItems = oldestOnTop ? sortedItems : sortedItems.reverse();
    let prevDate = '';

    for (let i = 0; i < sortedItems.length; i++) {
        if (prevDate === sortedItems[i].date)
            sortedItems[i].date = '';

        prevDate = sortedItems[i].date;
    }

    return sortedItems;
}

const Timeline = ({ records, timeField, oldestOnTop, className, children }: {
    timeField: string,
    records: IEntity[],
    oldestOnTop?: boolean,
    className?: string,
    children?: React.ReactNode
}) => {
    const [items, setTimelineItems] = useState<ITimelineData[]>([]);
    const { dataSource } = useDataContext()

    useEffect(() => {
        const data = getTimelineData(timeField, records, oldestOnTop)
        setTimelineItems(data)
        // eslint-disable-next-line
    }, [])

    return <>
        <div className={`${className} `}>
            <div className='timeline-label'>
                {items.map((item) => <React.Fragment key={item.key}>
                    <div className='timeline-item'>
                        <div className='timeline-label fw-bold text-gray-800 fs-7'>
                            <div className="text-end pe-3">
                                {(item.date) && <>
                                    <p className="my-0 fw-bolder">{item.date}</p>
                                </>}
                                <p className="my-0">{item.time}</p>
                            </div>
                        </div>
                        <div className='timeline-badge'>
                            <i className='fa fa-genderless text-primary fs-1'></i>
                        </div>
                        <div className='timeline-content ps-3'>
                            <DataContextProvider>
                                <SetDataContext
                                    dataSource={dataSource}
                                    records={item.records} />
                                <ListRows>
                                    {children}
                                </ListRows>
                            </DataContextProvider>
                        </div>
                    </div>
                </React.Fragment>)}
            </div>
        </div>
    </>
}

const SetDataContext = ({ records, dataSource }: { dataSource?: IDataSource, records: IEntity[] }) => {
    const { setData, setDataSource } = useDataContext()

    useEffect(() => {
        if (dataSource)
            setDataSource(dataSource)
        // eslint-disable-next-line
    }, [dataSource])

    useEffect(() => {
        setData({ type: "List", data: { count: records.length, records }, isLoading: false, isSuccess: true, isError: false })
        // eslint-disable-next-line
    }, [records])

    return <></>
}