import { useEffect, useState } from 'react';

import { IRankingQuestion } from '../models/feedback-form.model';
import clsx from 'clsx';

const RankingQuestion = ({ question, value, onChange, onBlur }: {
    question: IRankingQuestion;
    value?: string,
    onChange: (value: string) => void,
    onBlur: () => void
}) => {
    const [items, setItems] = useState(value?.split(",") || question.items);

    useEffect(() => {
        onChange(items.join(","))
        // eslint-disable-next-line
    }, [items])

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
        e.dataTransfer.setData('text/plain', index.toString());
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, newIndex: number) => {
        e.preventDefault();
        const oldIndex = Number(e.dataTransfer.getData('text/plain'));
        const newItems = [...items];
        const [movedItem] = newItems.splice(oldIndex, 1);
        newItems.splice(newIndex, 0, movedItem);
        setItems(newItems);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    return (
        <div className="form-group">
            <label className={clsx("form-label", { required: question.isRequired })}>{question.title}</label>
            <div className="d-flex gap-5">
                {items.map((item, index) => (
                    <div
                        key={index}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDrop={(e) => handleDrop(e, index)}
                        onDragOver={(e) => handleDragOver(e)}
                        draggable
                    >
                        <div className="badge badge-secondary border border-primary px-3" role="alert">
                            <div className='rounded-circle badge badge-primary me-2'>{index + 1}</div>
                            <span>{item}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RankingQuestion;
