import { IPage, IPageView } from "../models";
import { useEffect, useState } from "react";

import { PageMode } from "../../../base/types";
import { decodeTableFilters } from "../../../data/helpers";
import { useGetPageMetadataQuery } from "../../../services/data";
import { useSearchParams } from "react-router-dom";

interface IGetPageResult {
    pageId?: string;
    page?: IPage;
    pageView?: IPageView | undefined;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error?: string;
}

const useGetPageView = () => {
    const [searchParams] = useSearchParams();
    const [pageView, setPageView] = useState<IPageView>()

    useEffect(() => {
        const systemId = searchParams.get("id") || undefined
        const pageMode: PageMode = searchParams.get("mode") as PageMode || PageMode.View
        const filters = decodeTableFilters(searchParams.get("filters"));
        const pageView: IPageView = {
            pageMode: pageMode || PageMode.View,
            systemId,
            viewFilters: filters
        }

        setPageView(pageView)
    }, [searchParams])

    return pageView
}

export const useGetPageFromUrl = (pageId: string) => {
    const { data: page, isLoading, isSuccess, isError } = useGetPageMetadataQuery(pageId);
    const pageView = useGetPageView()
    const [result, setResult] = useState<IGetPageResult>({
        isLoading: false,
        isSuccess: false,
        isError: false
    })

    useEffect(() => {
        if (!isLoading || !isError)
            return;
        setResult({ pageId, pageView, isLoading, isSuccess, isError })
        // eslint-disable-next-line
    }, [isLoading, isError])

    useEffect(() => {
        if (!pageId || !pageView) return;
        setResult({ pageId, pageView, page, isLoading, isSuccess, isError })
        // eslint-disable-next-line
    }, [page, pageView])

    return result
}
