import { ButtonStyle, ControlSize, Property } from "../../../base/types"
import { IControlProps, IPageLink } from "../../../base/models"
import { MUIIcon, Spinner } from "../../../widgets"
import { useEffect, useState } from "react"

import { DefaultLabel } from "../../widgets"
import clsx from "clsx"
import { getButtonClass } from "./helper"
import { getPageLinkUrl } from "../../../base/helpers"
import { useAuth } from "../../../../modules/auth"
import { useDataContext } from "../../page/context"
import { useDesignContext } from "../../../providers"
import { useExecutePortalActionMutation } from "../../../services/data/portal-action"
import { useFromData } from "../panels/FormComponent"
import { useGetProperties } from "../../../base/hooks"
import { useNavigate } from "react-router-dom"

export const ActionButtonComponent = ({ control, className, children }: IControlProps) => {
    const navigate = useNavigate()
    const { mode } = useDesignContext()
    const { user, siteId: portalId } = useAuth();
    const { properties } = useGetProperties({ bindings: control.bindings });
    const [executeAction] = useExecutePortalActionMutation()

    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const functionName: string | undefined = properties[Property.PortalAction]
    const sysId: string | undefined = properties[Property.SystemId]
    const pageLink: IPageLink | undefined = properties[Property.PageLink]
    const executeSave: boolean | undefined = properties[Property.ExecuteSave]
    const icon: string | undefined = properties[Property.MaterialIcon]
    const disabled: boolean | undefined = properties[Property.Disabled]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]
    const autoExecute: boolean | undefined = properties[Property.AuthExecute]

    const { values, isSubmitting, setFormErrors, clearFormErrors, saveFormData } = useFromData()
    const { type, data } = useDataContext()
    const [isExecuting, setExecuting] = useState<boolean>(false)
    const { refetch } = useDataContext()

    const isFormDataChanged = () => {
        if (!data || type !== "Card" || !values || Object.keys(values).length === 0)
            return;

        var fields = Object.keys(values);
        const changedField = fields.find(field => data[field] !== values[field])

        if (changedField)
            return true

        return false;
    }

    useEffect(() => {
        if (autoExecute !== true) return;

        if (isFormDataChanged()) {
            console.log("values", values)
            onClick();
        }
        // eslint-disable-next-line
    }, [values])

    const onClick = () => {
        if (!functionName || !user || !portalId) return;
        const { userId } = user

        clearFormErrors()
        setExecuting(true)
        if (executeSave) {
            saveFormData()
                .then(() => {
                    return executeAction({ userId, portalId, functionName, sysId })
                        .unwrap()
                        .then(() => {
                            refetch()
                            return Promise.resolve()
                        })
                        .catch((error) => {
                            if (error.data?.error?.message)
                                return Promise.reject(error.data.error.message)
                            else
                                return Promise.reject('An error occurred while executing the action.')
                        })
                })
                .then(() => {
                    if (portalId && pageLink)
                        navigate(getPageLinkUrl(portalId, pageLink))
                })
                .catch(error => setFormErrors([error]))
                .finally(() => setExecuting(false))

        } else {
            return executeAction({ userId, portalId, functionName, sysId })
                .unwrap()
                .then(() => {
                    if (portalId && pageLink)
                        navigate(getPageLinkUrl(portalId, pageLink))
                })
                .catch(error => setFormErrors([error.data.error.message || 'Failed to execute the action.']))
                .finally(() => setExecuting(false))
        }
    }

    if (mode === "Design") {
        return (
            <div className={clsx(getButtonClass(style, size), className)}>
                <div className="d-flex">
                    {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                    <DefaultLabel
                        control={control}
                        propertyId={Property.Label}
                        defaultLabel="Action Button"
                        className="text-hover-primary" />
                </div>
                {children}
            </div>)
    }

    return (
        <button
            type="button"
            title={tooltip}
            onClick={onClick}
            disabled={disabled === true || isSubmitting || isExecuting}
            className={clsx(getButtonClass(style, size), className)}>
            <div className="d-flex">
                <div>
                    <Spinner show={isExecuting} className="me-1" />
                    {(!isExecuting && icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                    <span>{label}</span>
                </div>
                <div className="flex-fill">
                    {children}
                </div>
            </div>
        </button>)
}