import { Accept, FileRejection, useDropzone } from "react-dropzone";

import { MUIIcon } from "./MUIIcon";
import { Spinner } from "./Spinner";
import clsx from "clsx";
import { useState } from "react";

export interface IAcceptFiles extends Accept { }

export const UploadDocumentButton = ({ title, subtitle, accept, className, onUpload }: {
    title: string,
    subtitle?: string,
    accept?: IAcceptFiles,
    className?: string,
    onUpload?: (file: File) => Promise<void>
}) => {
    const { getRootProps, getInputProps } = useDropzone({ onFileDialogOpen, onDropAccepted, onDropRejected, multiple: false, accept });
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    function onFileDialogOpen() {
        setError('')
    }

    function onDropRejected(rejections: FileRejection[]) {
        const names = rejections.map(item => item.file.name)
        setError(`"${names.join(", ")}" file is rejected. You should upload only the supported files.`);
    }

    function onDropAccepted(files: File[]) {
        if (onUpload && files.length > 0) {
            setIsUploading(true);
            onUpload(files[0])
                .catch(() => setError('Failed to upload the document.'))
                .finally(() => setIsUploading(false))
        }
    }

    return (
        <div className="d-flex flex-column gap-2">
            <div {...getRootProps({
                className: clsx(
                    'border border-secondary rounded shadow-sm bg-light mb-0 pb-0',
                    className,
                    'cursor-pointer')
            })}>
                <input {...getInputProps()} />
                <div className={clsx('d-flex align-items-center')}>
                    <div className='ps-5'>
                        {isUploading ? <>
                            <Spinner show={isUploading} />
                        </> : <>
                            <span className='symbol symbol-35px'>
                                <MUIIcon iconName="AttachFileOutlined" />
                            </span>
                        </>}
                    </div>

                    <div className='d-flex flex-column flex-fill px-5 py-3'>
                        <span className='fw-bolder fs-7'>{title}</span>
                        <span className='fs-8 text-primary'>
                            {subtitle}
                        </span>
                    </div>
                </div>
            </div>

            {(!!error) &&
                <p className="fs-8 fst-italic text-danger m-0">{error}</p>
            }
        </div>
    )
}