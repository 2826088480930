import { EditColumnOrder } from "./column-order"
import { EditColumnSize } from "./column-size"
import { EditFill } from "./fill"
import { EditFlexDirection } from "./flex-direction"
import { EditGrowShrink } from "./grow-shrink"
import { EditHorizontalAlign } from "./horizontal-align"
import { EditSelfAlign } from "./self-align"
import { EditVerticalAlign } from "./vertical-align"
import { EditWrap } from "./wrap"
import { ILayout } from "../../../../base/styles"
import { SelectLayoutType } from "./SelectLayoutType"
import { SelectZIndex } from "./SelectZIndex"

export const EditLayout = ({ value, className, onChange }:{
    value: ILayout,
    className?: string,
    onChange: (value?: ILayout) => void,
}) => {
    return (
        <div className={className}>
            <SelectLayoutType
                title="Layout Type"
                className="flex-grow-1"
                onChange={(newValue) => { onChange({ ...value, layoutType: newValue }) }}
                value={value.layoutType} />

            <EditColumnSize
                title="Column Size"
                className="mb-3"
                value={value.columnSize || {}}
                onChange={(newValue) => onChange({ ...value, columnSize: newValue })} />

            <EditSelfAlign
                className="mb-3"
                title="Self Align"
                value={value.align || {}}
                onChange={(newValue) => onChange({ ...value, align: newValue })} />

            <EditFill
                className="mb-2"
                title="Fill"
                value={value.fill || {}}
                onChange={(newValue) => onChange({ ...value, fill: newValue })} />

            <EditGrowShrink
                className="mb-3"
                title="Grow / Shrink"
                value={value.growShrink || {}}
                onChange={(newValue) => onChange({ ...value, growShrink: newValue })} />

            <EditColumnOrder
                title="Column Order"
                value={value.order || {}}
                onChange={(newValue) => onChange({ ...value, order: newValue })} />

            <EditFlexDirection
                className="mt-2 mb-2"
                title="Direction"
                value={value.direction || {}}
                onChange={(newValue) => onChange({ ...value, direction: newValue })} />

            <EditHorizontalAlign
                className="mb-2"
                title="Horizontal Align"
                value={value.horizontalAlign || {}}
                onChange={(newValue) => onChange({ ...value, horizontalAlign: newValue })} />

            <EditVerticalAlign
                className="mb-2"
                title="Vertical Align"
                value={value.verticalAlign || {}}
                onChange={(newValue) => onChange({ ...value, verticalAlign: newValue })} />

            <EditWrap
                title="Wrap"
                value={value.wrap || {}}
                onChange={(newValue) => onChange({ ...value, wrap: newValue })} />

            <SelectZIndex
                className="mb-3"
                title="Z Index"
                value={value.zIndex}
                onChange={(newValue) => onChange({ ...value, zIndex: newValue })} />
        </div>)
}