import { EditGridColumn, NewGridColumn } from "./components";
import { GridFieldType, IGridColumn } from "../../../base/models";
import React, { useState } from "react";

import { IDataSource } from "../../../base/types";
import clsx from "clsx";

export const EditGridColumns = ({ columns, dataSource, className, onChange }: {
    dataSource?: IDataSource,
    columns: IGridColumn[],
    className?: string,
    onChange: (columns: IGridColumn[]) => void,
}) => {
    const [editColumn, setEditColumn] = useState<{ column: IGridColumn, index: number }>();

    const handleOnAdd = (column: IGridColumn) => {
        onChange([...columns, column]);
        setEditColumn(undefined)
    }

    const handleOnUpdate = (column: IGridColumn) => {
        onChange(columns.map(p => p.field === column.field ? column : p))
        setEditColumn(undefined)
    }

    return <div className={className}>
        <ul className="list-group fs-7 mb-5">
            {[...columns]
                .sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
                .map((column, index) => <React.Fragment key={index} >
                    <li className={clsx("list-group-item", { "active": editColumn?.index === index })}>
                        <div className="d-flex align-items-center">
                            <div className="w-25px h-25px rounded-circle bg-dark me-2 d-flex align-items-center justify-content-center">
                                <span className="text-inverse-dark fw-semibold fs-9">{column.sequence || 0}</span>
                            </div>
                            <div className="flex-fill">
                                <label className="fw-semibold mb-0">
                                    <span className="me-1">
                                        {column.header || ((column as any).label)}
                                    </span>
                                    <span className="badge badge-success p-1 fs-8">{column.fieldType || GridFieldType.Text}</span>
                                </label>
                            </div>
                            {(editColumn?.index !== index) && <>
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => setEditColumn({ column, index })}
                                        className="btn btn-sm btn-primary py-1 me-2">
                                        Edit
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => onChange(columns.filter((_, i) => i !== index))}
                                        className="btn btn-sm btn-danger py-1 m-0">
                                        Delete
                                    </button>
                                </div>
                            </>}
                        </div>
                    </li>
                </React.Fragment>)}
        </ul>
        
        <div className="border rounded bg-light p-5 px-10 mb-5">
            {(editColumn) ? <>
                <h5>{`Edit Column: '${editColumn.column.header}'`}</h5>
                <EditGridColumn
                    dataSource={dataSource}
                    column={editColumn.column}
                    onCancel={() => setEditColumn(undefined)}
                    onChange={handleOnUpdate}
                />
            </> : <>
                <h5>{`Add new column`}</h5>
                <NewGridColumn dataSource={dataSource} onAdd={handleOnAdd} />
            </>}
        </div>
    </div>
}