import { ISize, IViewPortSize, SizeUnit } from "../models";

import clsx from "clsx";

export const sizingClasses = (size?: ISize) => {
    let classes = "";

    classes = clsx(classes, sizeUnitClass(size?.width, "w"));
    classes = clsx(classes, sizeUnitClass(size?.minWidth, "min-w"));
    classes = clsx(classes, sizeUnitClass(size?.maxWidth, "mw"));
    classes = clsx(classes, sizeUnitClass(size?.height, "h"));
    classes = clsx(classes, sizeUnitClass(size?.maxHeight, "mh"));
    classes = clsx(classes, sizeUnitClass(size?.minHeight, "min-h"));

    return classes;
}

export const viewPortSizingClasses = (size?: IViewPortSize) => {
    let classes = "";

    classes = clsx(classes, sizeUnitClass(size?.width, "vw"));
    classes = clsx(classes, sizeUnitClass(size?.minWidth, "min-vw"));
    classes = clsx(classes, sizeUnitClass(size?.height, "vh"));
    classes = clsx(classes, sizeUnitClass(size?.minHeight, "min-vh"));

    return classes;
}

const sizeUnitClass = (unit?: SizeUnit, prefix?: string) => {
    switch (unit) {
        case SizeUnit.Auto: return `${prefix}-auto`;
        case SizeUnit.S25: return `${prefix}-25`;
        case SizeUnit.S50: return `${prefix}-50`;
        case SizeUnit.S75: return `${prefix}-75`;
        case SizeUnit.S100: return `${prefix}-100`;
        
        case SizeUnit.S1PX: return `${prefix}-1px`;
        case SizeUnit.S2PX: return `${prefix}-2px`;
        case SizeUnit.S3PX: return `${prefix}-3px`;
        case SizeUnit.S4PX: return `${prefix}-4px`;
        case SizeUnit.S5PX: return `${prefix}-5px`;
        case SizeUnit.S6PX: return `${prefix}-6px`;
        case SizeUnit.S7PX: return `${prefix}-7px`;
        case SizeUnit.S8PX: return `${prefix}-8px`;
        case SizeUnit.S9PX: return `${prefix}-9px`;
        case SizeUnit.S10PX: return `${prefix}-10px`;
        case SizeUnit.S15PX: return `${prefix}-15px`;
        case SizeUnit.S20PX: return `${prefix}-20px`;
        case SizeUnit.S25PX: return `${prefix}-25px`;
        case SizeUnit.S30PX: return `${prefix}-30px`;
        case SizeUnit.S35PX: return `${prefix}-35px`;
        case SizeUnit.S40PX: return `${prefix}-40px`;
        case SizeUnit.S45PX: return `${prefix}-45px`;
        case SizeUnit.S50PX: return `${prefix}-50px`;
        case SizeUnit.S55PX: return `${prefix}-55px`;
        case SizeUnit.S60PX: return `${prefix}-60px`;
        case SizeUnit.S65PX: return `${prefix}-65px`;
        case SizeUnit.S70PX: return `${prefix}-70px`;
        case SizeUnit.S75PX: return `${prefix}-75px`;
        case SizeUnit.S80PX: return `${prefix}-80px`;
        case SizeUnit.S85PX: return `${prefix}-85px`;
        case SizeUnit.S90PX: return `${prefix}-90px`;
        case SizeUnit.S95PX: return `${prefix}-95px`;
        case SizeUnit.S100PX: return `${prefix}-100px`;
        case SizeUnit.S125PX: return `${prefix}-125px`;
        case SizeUnit.S150PX: return `${prefix}-150px`;
        case SizeUnit.S175PX: return `${prefix}-175px`;
        case SizeUnit.S200PX: return `${prefix}-200px`;
        case SizeUnit.S225PX: return `${prefix}-225px`;
        case SizeUnit.S250PX: return `${prefix}-250px`;
        case SizeUnit.S275PX: return `${prefix}-275px`;
        case SizeUnit.S300PX: return `${prefix}-300px`;
        case SizeUnit.S325PX: return `${prefix}-325px`;
        case SizeUnit.S350PX: return `${prefix}-350px`;
        case SizeUnit.S375PX: return `${prefix}-375px`;
        case SizeUnit.S400PX: return `${prefix}-400px`;
        case SizeUnit.S425PX: return `${prefix}-425px`;
        case SizeUnit.S450PX: return `${prefix}-450px`;
        case SizeUnit.S475PX: return `${prefix}-475px`;
        case SizeUnit.S500PX: return `${prefix}-500px`;
        case SizeUnit.S550PX: return `${prefix}-550px`;
        case SizeUnit.S600PX: return `${prefix}-600px`;
        case SizeUnit.S650PX: return `${prefix}-650px`;
        case SizeUnit.S700PX: return `${prefix}-700px`;
        case SizeUnit.S750PX: return `${prefix}-750px`;
        case SizeUnit.S800PX: return `${prefix}-800px`;
        case SizeUnit.S850PX: return `${prefix}-850px`;
        case SizeUnit.S900PX: return `${prefix}-900px`;
        case SizeUnit.S950PX: return `${prefix}-950px`;
        case SizeUnit.S1000PX: return `${prefix}-1000px`;
    }
}