import clsx from "clsx"

export const BooleanAttribute = ({
    attributeId,
    label,
    value,
    readOnly,
    labelClass,
    className,
    onChange,
    onBlur,
}: {
    label?: string,
    attributeId: string,
    value?: boolean,
    readOnly?: boolean,
    labelClass?: string,
    className?: string,
    onChange: (value: any) => void,
    onBlur: () => void
}) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.checked;
        newValue === true ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "form-check form-check-sm d-flex align-items-center")}>
            <input
                className="form-check-input form-check-input-sm me-2 min-w-20px"
                type="checkbox"
                disabled={readOnly}
                onChange={handleOnChange}
                onBlur={() => onBlur()}
                checked={value}
                id={attributeId} />

            <label className={clsx(labelClass, "form-check-label form-label m-0")} htmlFor={attributeId}>
                {label}
            </label>
        </div>
    )
}
