import { ButtonStyle, ControlSize, Property } from "../../../base/types";
import { IControlProps, IPageLink } from "../../../base/models";

import { DefaultLabel } from "../../widgets";
import { MUIIcon } from "../../../widgets";
import { PayTmButton } from "./components/PayTmButton";
import clsx from "clsx";
import { getButtonClass } from "../buttons/helper";
import { useAuth } from "../../../../modules/auth";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const PaytmButtonComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const icon: string | undefined = properties[Property.MaterialIcon]
    const disabled: boolean | undefined = properties[Property.Disabled]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]

    const enrolmentNo: string | undefined = properties[Property.EnrolmentNo]
    const orderNo: string | undefined = properties[Property.OrderNo]
    const amount: number | undefined = properties[Property.Amount]
    const currency: string | undefined = properties[Property.Currency]
    const successPage: IPageLink | undefined = properties[Property.SuccessPage]
    const failedPage: IPageLink | undefined = properties[Property.FailedPage]

    const { user } = useAuth()

    if (mode === "Design") {
        return (<div className={clsx(getButtonClass(style, size, "btn-secondary"), className)}>
            {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
            <DefaultLabel
                control={control}
                propertyId={Property.Label}
                defaultLabel="Button"
                className="text-hover-primary" />
            {children}
        </div>)
    }

    if (!user || !amount || !successPage || !failedPage) {
        return <></>
    }

    return (
        <>
            <PayTmButton
                enrolmentNo={enrolmentNo || user.userName}
                orderNo={orderNo}
                amount={amount || 0}
                currency={currency || "INR"}
                firstName={user.firstName || user.userName}
                lastName={user.lastName}
                email={user.email}
                phoneNumber={user.phoneNumber}
                disabled={disabled}
                icon={icon}
                label={label}
                tooltip={tooltip}
                style={style}
                size={size}
                successPage={successPage}
                failedPage={failedPage}
            >
                {children}
            </PayTmButton>
        </>
    )
}

