import { IMsalConfig, IMsalLoginModel } from "../models";

import { IAuthUserModel } from "../../core/AuthModel";
import { rootApi } from "../../../../framework/services/rootApi";

const msalApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getConfig: builder.query<IMsalConfig, void>({
            query: () => "/Authentication/MsalConfig",
        }),

        msalLogin: builder.mutation<IAuthUserModel, IMsalLoginModel>({
            query: (body) => ({
                url: `/Authentication/MsalLogin`,
                method: 'POST',
                body
            })
        }),
    })
})

export const {
    useGetConfigQuery,
    useLazyGetConfigQuery,
    useMsalLoginMutation
} = msalApi;