import { ActionType, IPageAction } from "../models";
import { DesignAction, DesignEvent, IProperty } from "../../../base/models";
import { IDataSource, Property, PropertyType } from "../../../base/types";
import React, { useEffect, useState } from "react";

import { Binding } from "../../../base/bindings";
import { PropertyBindings } from "../../page-control/property-panel/PropertyBindings";
import { SelectMaterialIcon } from "../../widgets";
import { useDesignContext } from "../../../providers";

const properties: IProperty[] = [
    { id: Property.PageLink, name: 'Page Link', type: PropertyType.PageLink },
]

export const ActionProperties = ({ action: originalAction, dataSource, onDesignChange }: {
    action: IPageAction,
    dataSource?: IDataSource,
    onDesignChange: (event: DesignEvent) => void
}) => {
    const { setContext } = useDesignContext()
    const [action, setAction] = useState<IPageAction>(originalAction)

    useEffect(() => {
        onDesignChange({ type: DesignAction.UpdateAction, action })
        // eslint-disable-next-line
    }, [action])

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAction({ ...action, name: e.target.value })
    }

    const onBindingsChange = (bindings: Binding[]) => {
        setAction({ ...action, bindings: bindings })
    }

    const onIconChange = (value?: string) => {
        setAction({ ...action, icon: value })
    }

    return (
        <div className="card h-100 ms-2">
            <div className="card-header min-h-5px border-0 px-5 mb-10">
                <div>
                    <h6 className="mt-5 mb-1">
                        <span>Properties</span>
                        <span className="badge badge-primary fw-semibold ms-3">
                            {action.type}
                        </span>
                    </h6>
                </div>
                <div className="card-toolbar">
                    <button
                        type="button"
                        onClick={() => setContext()}
                        className="btn-close" />
                </div>
            </div>
            <div className="card-body p-5 pt-2 overflow-auto">
                <div className="mb-2">
                    <label className="fs-8 fw-bold text-gray-600">Action Name</label>
                    <input type="text"
                        className="form-control form-control-xs"
                        onChange={onNameChange}
                        value={action.name} />
                </div>
                
                <SelectMaterialIcon
                    title="Icon"
                    className="mb-2"
                    value={action.icon}
                    onChange={onIconChange} />

                <PropertyBindings
                    bindings={action.bindings}
                    properties={action.type === ActionType.Action ? properties : []}
                    dataSource={dataSource}
                    onChange={onBindingsChange} />
            </div>
        </div>
    )
}
