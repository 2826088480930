import { IDocumentCategory } from "./DocumentCategory"
import { IDocumentChecklistItem } from "./DocumentChecklistItem"

export interface IDocumentChecklist {
    id: string,
    description: string,
    checklistItems?: IDocumentChecklistItem[],
    categories?: IDocumentCategory[],
}

export const DocumentChecklistMetadata = {
    tableId: "33101672",
    fields: {
        id: "Id",
        description: "Description"
    }
}
