import { IDataLink, IEntityFilter } from "../../../base/models"

import { BooleanValue } from "../../control-property/components/property-value/components"
import { EditTableFilters } from "../table-filters"
import { IDataSource } from "../../../base/types"
import Modal from "react-bootstrap/esm/Modal"
import { SelectAttribute } from "../SelectAttribute"
import { SelectEntity } from "../SelectEntity"
import clsx from "clsx"
import { useState } from "react"

export const EditDataLink = ({ dataSource, dataLink, className, onChange }: {
    dataLink: IDataLink,
    dataSource?: IDataSource,
    className?: string,
    onChange: (dataLink: IDataLink) => void,
}) => {
    const { entity, dataSet, systemId, filters } = dataLink;
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [entityFilters, setEntityFilters] = useState<IEntityFilter[]>(filters || [])

    const handleOnFiltersChange = (filters: IEntityFilter[]) => {
        setEntityFilters(filters)
        onChange({ ...dataLink, filters });
    }

    const hideModal = () => {
        setShowDialog(false);
    }
    const fields = dataSource ? dataSource.attributes : [];

    return <div className={clsx(className, "card mt-0 bg-transparent mx-0")}>
        <div className="card-body pt-0 px-0">
            <div className="mb-2">
                <label className="form-label fs-8 mb-1 required">Entity</label>
                <SelectEntity
                    value={entity}
                    onChange={(entity) => onChange({ ...dataLink, entity })} />
            </div>
            <div className="mb-2">
                <BooleanValue
                    label="Is Data Set"

                    value={dataSet === true}
                    onChange={(value) => onChange({ ...dataLink, dataSet: value })} />
            </div>
            <div>
                <label className="form-label fs-8 mb-1">System Id</label>
                <SelectAttribute
                    attributes={fields}
                    value={systemId}
                    onChange={(value) => onChange({ ...dataLink, systemId: value })} />
            </div>

            <div className="fs-8 mt-3">
                <p className="flex-fill fw-bold text-gray-600 fs-8 mb-1">Filters:</p>
                {entityFilters.map((item, i) => <p key={i} className="m-0">
                    <span className="fw-semibold text-primary">{item.field}</span> =
                    <span className="fw-bold ms-2">{item.type}</span>
                    (<span className="fw-semibold text-primary">{item.value}</span>)</p>)}

                <div className="d-flex align-items-end mt-3">
                    <button
                        type="button"
                        className="btn btn-primary btn-sm py-2"
                        onClick={() => setShowDialog(true)}>
                        Edit Filters
                    </button>
                </div>
            </div>

            {(entity) && <TableFiltersModel
                hideModal={hideModal}
                show={showDialog}
                filters={entityFilters}
                entity={entity}
                dataSource={dataSource}
                onChange={handleOnFiltersChange}
            />}
        </div>
    </div>
}

const TableFiltersModel = ({ entity, dataSource, filters, show, hideModal, onChange }: {
    entity: string,
    dataSource?: IDataSource,
    filters: IEntityFilter[],
    show: boolean,
    hideModal: () => void,
    onChange: (filters: IEntityFilter[]) => void
}) => {
    const [localFilters, setLocalFilters] = useState<IEntityFilter[]>([...filters]);

    const handleChanges = (filters: IEntityFilter[]) => {
        setLocalFilters(filters)
    }

    if (!entity) return <></>

    return <Modal show={show} onHide={hideModal} dialogClassName="min-w-75 p-20">
        <Modal.Header closeButton className="bg-secondary border-0 py-5">
            <h4 className="my-0">Table Filters</h4>
        </Modal.Header>
        <Modal.Body>
            <EditTableFilters
                filters={localFilters}
                entity={entity}
                dataSource={dataSource}
                onChange={handleChanges} />
        </Modal.Body>
        <Modal.Footer className="border-0 py-5">
            <button type="button"
                onClick={() => {
                    onChange(localFilters)
                    hideModal()
                }}
                className="btn btn-sm btn-primary">OK</button>
            <button type="button"
                onClick={hideModal}
                className="btn btn-sm btn-secondary">Cancel</button>
        </Modal.Footer>
    </Modal>
}
