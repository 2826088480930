import { Checkbox } from "../../Checkbox"
import { IResponsiveTextAlignment } from "../../../../../base/styles"
import { SelectTextAlignment } from "./SelectTextAlignment"
import clsx from "clsx"
import { useState } from "react"

export const EditTextAlignment = ({ value, title, className, onChange }: {
    title: string,
    value: IResponsiveTextAlignment,
    className?: string,
    onChange: (value?: IResponsiveTextAlignment) => void
}) => {
    const [responsive, setResponsive] = useState<boolean>();

    return <div className={clsx(className)}>
        <div className="d-flex align-items-end">
            <SelectTextAlignment
                title={title}
                className="flex-grow-1 pe-2"
                onChange={(newValue) => { onChange({ ...value, allSizes: newValue }) }}
                value={value?.allSizes} />
            <Checkbox
                title="Responsive"
                className="fs-8 fw-semibold"
                value={responsive === true}
                onChange={setResponsive} />
        </div>

        {responsive &&
            <div className="d-flex flex-row flex-wrap mx-5 my-3">
                <SelectTextAlignment
                    title="Small"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, small: newValue }) }}
                    value={value?.small} />
                <SelectTextAlignment
                    title="Medium"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, medium: newValue }) }}
                    value={value?.medium} />
                <SelectTextAlignment
                    title="Large"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, large: newValue }) }}
                    value={value?.large} />
                <SelectTextAlignment
                    title="Ex. Large"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, xLarge: newValue }) }}
                    value={value?.xLarge} />
            </div>
        }
    </div>
}