import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const OffcanvasProperties: IProperty[] = [
    { id: Property.Heading, name: 'Heading', type: PropertyType.String },
    { id: Property.Placement, name: 'Placement', type: PropertyType.Placement },
    { id: Property.Label, name: 'Button Label', type: PropertyType.String },
    { id: Property.MaterialIcon, name: 'Button Icon', type: PropertyType.MaterialIcon },
    { id: Property.BackgroundStyle, name: 'Button Style', type: PropertyType.ButtonStyle },
    { id: Property.Size, name: 'Button Size', type: PropertyType.ControlSize },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.PageLink, name: 'Page Link', type: PropertyType.PageLink },
]