import { EditExpressionDialog, ViewExpression } from "./components";
import { IDataSource, PropertyType } from "../../../base/types";

import { IValueExpression } from "../../../base/models";
import { useState } from "react";

export const Expression = ({ type, expression, dataSource, onChange }: {
    type: PropertyType,
    expression: IValueExpression,
    dataSource?: IDataSource,
    onChange: (expr: IValueExpression) => void
}) => {
    const entity = dataSource ? dataSource.entity : undefined;
    const [showDialog, setShowDialog] = useState<boolean>(false);

    return (
        <div className="">
            <ViewExpression expression={expression} className="mb-3" />
            <div>
                <button
                    type="button"
                    className="btn btn-primary btn-sm py-2 ms-auto"
                    onClick={() => setShowDialog(true)}>
                    Edit Expression
                </button>
            </div>

            <EditExpressionDialog
                entity={entity}
                type={type}
                expression={expression}
                onChange={onChange}
                hideModal={() => setShowDialog(false)}
                show={showDialog}
            />
        </div>)
}
