import { CollapsiblePanel, Panel } from "../../../widgets";

import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const PanelComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const title: string | undefined = properties[Property.Title]
    const headerClass: string | undefined = properties[Property.HeaderClass]
    const collapsible: boolean | undefined = properties[Property.Collapsible]
    const icon: string | undefined = properties[Property.MaterialIcon]
    const hidden: boolean | undefined = properties[Property.Hidden]
    const tooltip: string | undefined = properties[Property.Tooltip];

    if (mode === "Preview" && hidden === true) {
        return <></>
    }

    if (collapsible) {
        return (
            <CollapsiblePanel
                eventKey="chat-component"
                className={className}
                header={title}
                title={tooltip}                
                icon={icon}
                headerClass={headerClass}
            >
                {children}
            </CollapsiblePanel>
        )
    }

    return (
        <Panel
            className={className}
            header={title}
            title={tooltip}
            icon={icon}
            headerClass={headerClass}>
            {children}
        </Panel>)
}