import { FC } from 'react'
import { PageTitle } from '../layout/core'
import { SitesGridView } from '../framework/sites/sites-grid'

export const SiteSelectionWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Sites</PageTitle>
      <SitesGridView />
    </>)
}
