import { LineHeight } from "../../../../base/styles";
import clsx from "clsx";

export const SelectLineHeight = ({ title, value, className, onChange }:{
    title: string,
    value?: LineHeight,
    className?: string,
    onChange: (value?: LineHeight) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as LineHeight;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (    
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={LineHeight.Normal}>Normal</option>
                <option value={LineHeight.Small}>Small</option>
                <option value={LineHeight.Base}>Base</option>
                <option value={LineHeight.Large}>Large</option>
                <option value={LineHeight.xLarge}>Ex. Large</option>
                <option value={LineHeight.xxLarge}>Ex. Large +</option>
            </select>
        </div>
    )
}