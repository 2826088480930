import { SizeUnit } from "../../../../base/styles";
import clsx from "clsx";

export const SelectSizeUnit = ({ title, value, className, onChange }: {
    title: string,
    value?: SizeUnit,
    className?: string,
    onChange: (value?: SizeUnit) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as SizeUnit;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={SizeUnit.Auto}>Auto</option>
                <option value={SizeUnit.S25}>25 %</option>
                <option value={SizeUnit.S50}>50 %</option>
                <option value={SizeUnit.S75}>75 %</option>
                <option value={SizeUnit.S100}>100 %</option>
                <option value={SizeUnit.S1PX}>1px</option>
                <option value={SizeUnit.S2PX}>2px</option>
                <option value={SizeUnit.S3PX}>3px</option>
                <option value={SizeUnit.S4PX}>4px</option>
                <option value={SizeUnit.S5PX}>5px</option>
                <option value={SizeUnit.S6PX}>6px</option>
                <option value={SizeUnit.S7PX}>7px</option>
                <option value={SizeUnit.S8PX}>8px</option>
                <option value={SizeUnit.S9PX}>9px</option>
                <option value={SizeUnit.S10PX}>10px</option>
                <option value={SizeUnit.S15PX}>15px</option>
                <option value={SizeUnit.S20PX}>20px</option>
                <option value={SizeUnit.S25PX}>25px</option>
                <option value={SizeUnit.S30PX}>30px</option>
                <option value={SizeUnit.S35PX}>35px</option>
                <option value={SizeUnit.S40PX}>40px</option>
                <option value={SizeUnit.S45PX}>45px</option>
                <option value={SizeUnit.S50PX}>50px</option>
                <option value={SizeUnit.S55PX}>55px</option>
                <option value={SizeUnit.S60PX}>60px</option>
                <option value={SizeUnit.S65PX}>65px</option>
                <option value={SizeUnit.S70PX}>70px</option>
                <option value={SizeUnit.S75PX}>75px</option>
                <option value={SizeUnit.S80PX}>80px</option>
                <option value={SizeUnit.S85PX}>85px</option>
                <option value={SizeUnit.S90PX}>90px</option>
                <option value={SizeUnit.S95PX}>95px</option>
                <option value={SizeUnit.S100PX}>100px</option>
                <option value={SizeUnit.S125PX}>125px</option>
                <option value={SizeUnit.S150PX}>150px</option>
                <option value={SizeUnit.S175PX}>175px</option>
                <option value={SizeUnit.S200PX}>200px</option>
                <option value={SizeUnit.S225PX}>225px</option>
                <option value={SizeUnit.S250PX}>250px</option>
                <option value={SizeUnit.S275PX}>275px</option>
                <option value={SizeUnit.S300PX}>300px</option>
                <option value={SizeUnit.S325PX}>325px</option>
                <option value={SizeUnit.S350PX}>350px</option>
                <option value={SizeUnit.S375PX}>375px</option>
                <option value={SizeUnit.S400PX}>400px</option>
                <option value={SizeUnit.S425PX}>425px</option>
                <option value={SizeUnit.S450PX}>450px</option>
                <option value={SizeUnit.S475PX}>475px</option>
                <option value={SizeUnit.S500PX}>500px</option>
                <option value={SizeUnit.S550PX}>550px</option>
                <option value={SizeUnit.S600PX}>600px</option>
                <option value={SizeUnit.S650PX}>650px</option>
                <option value={SizeUnit.S700PX}>700px</option>
                <option value={SizeUnit.S750PX}>750px</option>
                <option value={SizeUnit.S800PX}>800px</option>
                <option value={SizeUnit.S850PX}>850px</option>
                <option value={SizeUnit.S900PX}>900px</option>
                <option value={SizeUnit.S950PX}>950px</option>
                <option value={SizeUnit.S1000PX}>1000px</option>
            </select>
        </div>
    )
}