/* eslint-disable jsx-a11y/anchor-is-valid */

import "./styles.scss"

import { Outlet, Route, Routes } from 'react-router-dom'

import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { MsalAuthenticate } from "./msal"
import { Registration } from './components/Registration'
import { ResetPassword } from './components/ResetPassword'
import { useEffect } from 'react'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div className='w-100 h-100 background-image mobile-logo'>
      <Outlet />
    </div>

  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='authenticate' element={<MsalAuthenticate />} />
      <Route path='registration' element={<Registration />} />
      <Route path='registration(:portalId)' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
