import { ApiTags, rootApi } from '../rootApi';

import { ApiResReadMultiple } from '../models';
import { IMember } from './models/Member.model';

const TAG = ApiTags.Member;
const getTags = (entities: IMember[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]

const membersApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getMembers: builder.query<IMember[], void>({
      query: () => '/portalMembers',
      transformResponse: (res: ApiResReadMultiple<IMember>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getMember: builder.query<IMember, string>({
      query: (id) => `/portalMembers(${id})`,
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    getMembersByUserId: builder.query<IMember[], string>({
      query: (userId) => `/portalMembers/User(${userId})`,
      transformResponse: (res: ApiResReadMultiple<IMember>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    getMembersBySiteId: builder.query<IMember[], string>({
      query: (siteId) => `/portalMembers/Site(${siteId})`,
      transformResponse: (res: ApiResReadMultiple<IMember>) => {
        return res.value || [];
      },
      providesTags: (result) => result ? getTags(result) : [TAG]
    }),

    createMember: builder.mutation<IMember, Partial<IMember>>({
      query: (entity) => ({
        url: '/portalMembers',
        method: 'POST',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    updateMember: builder.mutation<IMember, { id: string, entity: Partial<IMember> }>({
      query: ({ id, entity }) => ({
        url: `/portalMembers(${id})`,
        method: 'PATCH',
        body: entity
      }),
      invalidatesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),

    deleteMember: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/portalMembers(${id})`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG]
    }),
  })
})

export const {
  useGetMembersQuery,
  useGetMemberQuery,
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useGetMembersByUserIdQuery,
  useLazyGetMembersByUserIdQuery,
  useGetMembersBySiteIdQuery,
  useLazyGetMembersBySiteIdQuery,
} = membersApi;