import { IPageControl } from "../../../../base/models";
import { ViewPageControl } from "../../../page-control";

export const ViewPageControls = ({ controls }: { controls?: IPageControl[] }) => {
    if (!controls) return <></>

    return <div className="position-relative">
        {controls
            .slice()
            .sort((a, b) => a.sequence - b.sequence)
            .map((ctrl) =>
                <ViewPageControl
                    key={ctrl.controlId}
                    orientation="Auto"
                    onDesignChange={() => { }}
                    control={ctrl}
                />)}
    </div>
}