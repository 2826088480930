import { DataContextProvider, useDataContext } from "../../page/context"
import { IEntity, Property } from "../../../base/types"
import React, { useEffect } from "react"

import { AlertMessage } from "../../../widgets"
import { IControlProps } from "../../../base/models"
import { RepeaterColumnHeader } from "./RepeaterColumnHeader"
import { useDesignContext } from "../../../providers"
import { useGetProperties } from "../../../base/hooks"

export const RepeaterComponent = ({ control, children, onDesignChange, className }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const tooltip: string | undefined = properties[Property.Tooltip];

    if (mode === "Design") {
        return (
            <div style={{ display: 'table' }} className={className} title={tooltip}>
                <div style={{ display: 'table-header-group' }}>
                    <Headers
                        control={control}
                        children={children}
                        orientation="Auto"
                        onDesignChange={onDesignChange} />
                </div>
            </div>
        )
    }


    return (
        <table className={className} title={tooltip}>
            <thead>
                <Headers
                    control={control}
                    orientation="Auto"
                    onDesignChange={onDesignChange} />
            </thead>
            <tbody>
                <RepeaterRows control={control} children={children} orientation="Auto" onDesignChange={onDesignChange} />
            </tbody>
        </table>
    )
}

const RepeaterRows = ({ children }: IControlProps) => {
    const { type, data, isLoading, isSuccess, isError } = useDataContext()

    if (isLoading || isError || !isSuccess)
        return <></>

    if (type !== "List")
        return <AlertMessage message="Not a list" />

    return <>
        {data.records.map((row, index) =>
            <React.Fragment key={index}>
                <DataContextProvider>
                    <RowProducer data={row} />
                    <tr>
                        {!children && <>No children</>}
                        {children}
                    </tr>
                </DataContextProvider>
            </React.Fragment>
        )}
    </>
}

const RowProducer = ({ data }: { data: IEntity }) => {
    const { setData } = useDataContext()

    useEffect(() => {
        setData({ type: "Card", data, isLoading: false, isSuccess: true, isError: false })
        // eslint-disable-next-line
    }, [])

    return <></>
}

const Headers = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()

    if (mode === "Design") {
        return <>
            <div style={{ display: "table-row" }} className={className}>
                <div style={{ display: "table-cell" }} className={className}>
                </div>

                {control.controls?.map((column) => <React.Fragment key={column.controlId}>
                    <RepeaterColumnHeader control={column} orientation="Horizontal" onDesignChange={() => { }} />
                </React.Fragment>)}
            </div>
            <div style={{ display: "table-row" }} className={className}>
                {children}
            </div>
        </>
    }

    return <>
        <tr className={className}>
            {control.controls?.map((column) => <React.Fragment key={column.controlId}>
                <RepeaterColumnHeader control={column} orientation="Horizontal" onDesignChange={() => { }} />
            </React.Fragment>)}
        </tr>
        <tr className={className}>
            {children}
        </tr>
    </>
}
