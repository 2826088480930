import { BackgroundColor } from "../../../../base/styles";
import clsx from "clsx";

export const SelectBackgroundColor = ({ title, value, className, onChange }:{
    title: string,
    value?: BackgroundColor,
    className?: string,
    onChange: (value?: BackgroundColor) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as BackgroundColor;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option className="bg-transparent"></option>
                <option className="bg-primary text-light" value={BackgroundColor.Primary}>Primary</option>
                <option className="bg-secondary text-dark" value={BackgroundColor.Secondary}>Secondary</option>
                <option className="bg-success text-dark" value={BackgroundColor.Success}>Success</option>
                <option className="bg-danger text-light" value={BackgroundColor.Danger}>Danger</option>
                <option className="bg-warning text-dark" value={BackgroundColor.Warning}>Warning</option>
                <option className="bg-info text-light" value={BackgroundColor.Info}>Info</option>
                <option className="bg-light text-dark" value={BackgroundColor.Light}>Light</option>
                <option className="bg-dark text-light"  value={BackgroundColor.Dark}>Dark</option>
                
                <option className="bg-body text-dark" value={BackgroundColor.Body}>Body</option>
                <option className="bg-white text-dark" value={BackgroundColor.White}>White</option>
                <option className="bg-transparent text-dark" value={BackgroundColor.Transparent}>Transparent</option>
                <option className="bg-gray-100 text-dark" value={BackgroundColor.Gray100}>Gray - 100</option>
                <option className="bg-gray-200 text-dark" value={BackgroundColor.Gray200}>Gray - 200</option>
                <option className="bg-gray-300 text-dark" value={BackgroundColor.Gray300}>Gray - 300</option>
                <option className="bg-gray-400 text-dark" value={BackgroundColor.Gray400}>Gray - 400</option>
                <option className="bg-gray-500 text-dark" value={BackgroundColor.Gray500}>Gray - 500</option>
                <option className="bg-gray-600 text-light" value={BackgroundColor.Gray600}>Gray - 600</option>
                <option className="bg-gray-700 text-light" value={BackgroundColor.Gray700}>Gray - 700</option>
                <option className="bg-gray-800 text-light" value={BackgroundColor.Gray800}>Gray - 800</option>
                <option className="bg-gray-900 text-gray-100" value={BackgroundColor.Gray900}>Gray - 900</option>

                <option className="bg-light-primary text-dark" value={BackgroundColor.LightPrimary}>Light Primary</option>
                <option className="bg-light-success text-dark" value={BackgroundColor.LightSuccess}>Light Success</option>
                <option className="bg-light-info text-dark" value={BackgroundColor.LightInfo}>Light Info</option>
                <option className="bg-light-warning text-dark" value={BackgroundColor.LightWarning}>Light Warning</option>
                <option className="bg-light-danger text-dark" value={BackgroundColor.LightDanger}>Light Danger</option>
                <option className="bg-light-dark text-dark" value={BackgroundColor.LightDark}>Light Dark</option>
            </select>
        </div>
    )
}