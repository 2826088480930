import { Binding } from "../../../base/bindings";
import { ComponentList } from "../../page-control/constants";
import { IPage } from "../models";
import { IPageAction } from "../../page-action/models";
import { IPageControl } from "../../../base/models";
import { PropertyType } from "../../../base/types";

const Current_Page_Version = "1.0";
export const upgradePageToV1_0 = (page: IPage) => {
    return {
        ...page,
        version: Current_Page_Version,
        controls: toControls1_0(page.controls || []),
        actions: toActions1_0(page.actions || [])
    }
}

const toActions1_0 = (actions: IPageAction[]) => {
    return actions.map(action => getNewAction1_0(action))
}

const getNewAction1_0 = (action: IPageAction): IPageAction => {
    return {
        ...action,
        bindings: toBindings1_0("Action", action.actionId, action.bindings || [])
    }
}

const toControls1_0 = (controls: IPageControl[]): IPageControl[] => {
    return controls.map(control => getNewControl1_0(control))
}

const toBindings1_0 = (type: "Control" | "Action", componentId: string, bindings: Binding[]) => {
    const newBindings: Binding[] = []

    bindings.forEach(binding => {
        const newBinding = getNewBinding1_0(type, componentId, binding)
        if (newBinding)
            newBindings.push(newBinding)
    })

    return newBindings;
}

const getNewControl1_0 = (control: IPageControl): IPageControl => {
    const getComponentId = () => {
        const compType = ComponentList.find(p => p.id === control.componentId)
        if (compType) return compType.id;

        switch (control.componentId) {
            case "2b9c9700-8311-4127-a6ba-4680bd8e503c": // Paragraph
                return "43e4f2ad-08c5-47ae-aae5-22c096bc2a9b";
            default:
                throw new Error(`Component Type ${control.componentId} not found`);
        }
    }

    const componentId = getComponentId()
    return {
        ...control,
        componentId,
        bindings: toBindings1_0("Control", componentId, control.bindings || []),
        controls: toControls1_0(control.controls || [])
    }
}

const getNewBinding1_0 = (type: string, componentId: string, binding: Binding): Binding | null => {
    const oldBinding: any = binding;

    const getPropId = (propId: string) => {
        switch (propId) {
            case "4d5e75ad-771a-4dba-8dc7-89c503c8d907":
                return "0e60d316-affb-4571-8085-c12159805c9d";
            case "d2c0006f-bc5d-4eff-aac3-0d2c643868d4": // PageLink_Label
            case "a3f5ce29-61f6-4280-b4c7-a76d575473f0": // RepeaterColumn_Heading
            case "5d368de4-0907-45ab-82f0-e6ab88300d87": // LabelText
            case "24d5866f-4105-4eb6-846a-3a172b2ab528": // Label
            case "98accefd-d9de-4648-8246-2c836be2cdfa": // Input Label
                return "335e19db-b8f6-4e6c-b92b-4a1f3401b850"; // Label
            case "67dc8a5a-bb34-416c-b10f-72fa728a1133": // Video_Url
            case "aa6062b5-bfde-4736-aade-0517852c1151": // Hyperlink Url
                return "6a6e46ce-748a-4f7d-aaab-810931625bec";
            case "8317b643-fa01-4358-aef1-966676e869b0": // NormalText_Text
                return "fe8e9928-9c61-4f8c-a013-6d97368fc884"; // Text
            case "d3ffd14f-dd14-4bf4-8945-ac68a067abe4": // PageAction_Link
                return "bf7652d9-8bfc-47f8-9221-5a837d52bdc3";
            default:
                return propId;
        }
    }

    let propId = getPropId(binding.propId);
    switch (propId) {
        case "bf7652d9-8bfc-47f8-9221-5a837d52bdc3": // PageLink
            return {
                propId,
                type: "Const",
                propType: PropertyType.PageLink,
                value: oldBinding.expression
            }
        case "a203e276-ba2c-498d-a088-3eea551b8aa1": // Disabled
        case "ffcc46fd-6516-4982-a5cd-edf66044acc9": // Readonly
        case "9bfba774-9553-456e-a621-ccb3f6383518": // Required
        case "fc1ccac9-e677-40b6-8561-cf70347bd2a5": // InsertAllowed
        case "3d818cda-ca40-4aed-ae34-8affbd4c809e": // ModifyAllowed
        case "0ad5c8cb-fa97-4119-8891-fcc1eb19f656": // SubmitAllowed
        case "c93888c9-6273-4518-82f6-11ad72538a6c": // Random
            return {
                propId,
                type: binding.type,
                propType: PropertyType.Boolean,
                value: oldBinding.expression
            }
        case "335e19db-b8f6-4e6c-b92b-4a1f3401b850": // Label
        case "b798758e-5e17-4c97-9d88-bc0f87294a6b": // PortalAction
        case "b39f2755-5f3e-4931-8330-a4b382811597": // Control ID
        case "518db5d6-24d9-48d8-8e4d-7f9730bd8ada": // Control Value
        case "25b8827c-5494-48c9-a5da-6d2f5b678020": // Format
        case "2f22445c-7185-4893-95ba-5fc581e9eafe": // VideoId                
        case "0e60d316-affb-4571-8085-c12159805c9d": // Heading
        case "6a6e46ce-748a-4f7d-aaab-810931625bec": // Url
        case "e5251e57-b548-4c4a-b204-19c8c4dd73ea": // AltText
        case "fe8e9928-9c61-4f8c-a013-6d97368fc884": // Text
        case "6235cf95-d8fa-4404-bc65-e66e1b98bc40": // Value
        case "4ec81f2a-4c47-41c2-9258-91b5696a8b80": // SystemIdField
        case "754667c8-6632-476d-9dda-9a63123c0014": // Trigger Action
        case "5662552c-3b2f-42e5-bf3c-58ab36d4a771": // Title
        case "31a56655-d7c3-4a2f-af3b-348c74428d4e": // Entity Id
        case "e062e058-4a85-4a58-b074-cbb9fefc8105": // GeoLang
        case "41e8140d-cf74-49df-9bdc-f5a209041aad": // GeoLat
        case "d4b8e8a2-503c-45e3-816f-5b605a88c907": // Screen Name
        case "5201e7c7-c27b-4f3e-8b72-964ebc827e3d": // Twitter_Height
            return {
                propId,
                type: binding.type,
                propType: PropertyType.String,
                value: oldBinding.expression
            }
        case "0becde8d-e076-48be-a914-5d1a17e18034": // PageLink_RecordId
            return {
                propId,
                type: "Const",
                propType: PropertyType.PageLink,
                value: oldBinding.expression
            }
        case "b6fdec64-91a0-45cf-b160-5263e3223d2e": // MaterialIcon
            return {
                propId,
                type: "Const",
                propType: PropertyType.MaterialIcon,
                value: oldBinding.expression
            }
        case "b17dd40a-1397-42c6-b6fd-8d5db7791f06": // Entity
            return {
                propId,
                type: "Const",
                propType: PropertyType.Entity,
                value: oldBinding.expression
            }
        case "aa7e60d1-31ff-4773-bf9b-5fba2e05d96e": // Media Id
            return {
                propId,
                type: "Const",
                propType: PropertyType.Media,
                value: oldBinding.expression
            }
        case "7da515ff-f2b0-41b4-8a32-36f755e3570f": // PageId
            return {
                propId,
                type: "Const",
                propType: PropertyType.Page,
                value: oldBinding.expression
            }

        // Properties removed
        case "b347eb57-5148-4bfb-980a-927164346746": // Submit
        case "4abfb2c0-d399-404f-9fd2-d60a11fa0d59": // Save
        case "5d6501be-bfa2-4674-b9f8-5757ccb311cb":
            return null;
        default:
            throw new Error(`property id ${propId}, binding: ${JSON.stringify(binding)}  not found in ${type} ${componentId}`)
    }
}
