import { NewTableFilter, TableFilter } from "./components";

import { IDataSource } from "../../../base/types";
import { IEntityFilter } from "../../../base/models";

export const EditTableFilters = ({ entity, filters, dataSource, onChange }: {
    entity: string,
    dataSource?: IDataSource,
    filters: IEntityFilter[],
    onChange: (filters: IEntityFilter[]) => void,
}) => {
    const handleOnAdd = (filter: IEntityFilter) => {
        onChange([...filters, filter]);
    }

    const handleOnUpdate = (filter: IEntityFilter) => {
        onChange(filters.map(p => p.field === filter.field ? filter : p))
    }

    const handleOnDelete = (filter: IEntityFilter) => {
        onChange(filters.filter(p => p.field !== filter.field))
    }

    return <>
        <table className="table table-sm table-borderless fs-8">
            <thead>
                <tr className="fs-8 fw-bold text-gray-600">
                    <th scope="col">Field</th>
                    <th scope="col">Type</th>
                    <th scope="col">Filter Value</th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {filters.map(item => (
                    <TableFilter
                        key={item.field}
                        entity={entity}
                        dataSource={dataSource}
                        filter={item}
                        onDelete={handleOnDelete}
                        onUpdate={handleOnUpdate}
                    />))}
                <NewTableFilter entity={entity} dataSource={dataSource} onAdd={handleOnAdd} />
            </tbody>
        </table>
    </>
}