import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useGetProperties } from "../../../base/hooks";

export const VideoComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const url: string | undefined = properties[Property.Url];
    const altText: string | undefined = properties[Property.AltText];
    const tooltip: string | undefined = properties[Property.Tooltip];

    return (
        <video className={className} controls title={tooltip}>
            <source src={url} />
            {altText || 'Your browser does not support the video tag.'}
        </video>
    )
}