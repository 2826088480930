import { Checkbox } from "../Checkbox"
import { EditTextAlignment } from "./text-alignment"
import { ITextStyle } from "../../../../base/styles"
import { SelectFontSize } from "./SelectFontSize"
import { SelectFontStyle } from "./SelectFontStyle"
import { SelectFontWeight } from "./SelectFontWeight"
import { SelectLetterSpacing } from "./SelectLetterSpacing"
import { SelectLineHeight } from "./SelectLineHeight"
import { SelectTextColor } from "./SelectTextColor"
import { SelectTextDecoration } from "./SelectTextDecoration"
import { SelectTextHoverColor } from "./SelectTextHoverColor"
import { SelectTextOpacity } from "./SelectTextOpacity"
import { SelectTextTransform } from "./SelectTextTransform"
import { SelectTextWrap } from "./SelectTextWrap"

export const TextStyles = ({ value, className, onChange }: {
    value: ITextStyle,
    className?: string,
    onChange: (value?: ITextStyle) => void
}) => {
    return (
        <div className={className}>
            <EditTextAlignment
                className="mb-2"
                title="Text Align"
                onChange={(newValue) => { onChange({ ...value, alignment: newValue }) }}
                value={value?.alignment || {}} />
            <div className="d-flex flex-row flex-wrap align-items-center">
                <SelectTextColor
                    title="Color"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, color: newValue }) }}
                    value={value?.color} />
                <SelectFontSize
                    title="Font Size"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, size: newValue }) }}
                    value={value?.size} />
                <SelectFontWeight
                    title="Font Weight"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, weight: newValue }) }}
                    value={value?.weight} />
                <SelectFontStyle
                    title="Font Style"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, style: newValue }) }}
                    value={value?.style} />
                <SelectTextWrap
                    title="Wrap"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, wrap: newValue }) }}
                    value={value?.wrap} />
                <SelectTextTransform
                    title="Transform"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, transform: newValue }) }}
                    value={value?.transform} />
                <SelectLineHeight
                    title="Line Height"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, height: newValue }) }}
                    value={value?.height} />
                <SelectTextDecoration
                    title="Decoration"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, decoration: newValue }) }}
                    value={value?.decoration} />
                <SelectTextOpacity
                    title="Opacity"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, opacity: newValue }) }}
                    value={value?.opacity} />
                <SelectLetterSpacing
                    title="Letter Spacing"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, letterSpacing: newValue }) }}
                    value={value?.letterSpacing} />
                <SelectTextHoverColor
                    title="Hover Color"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, hoverColor: newValue }) }}
                    value={value?.hoverColor} />
            </div>

            <div className="d-flex flex-row flex-wrap align-items-center">
                <Checkbox
                    title="Text Justify"
                    className="w-50 pe-2 mb-2"
                    onChange={(newValue) => { onChange({ ...value, justify: newValue }) }}
                    value={value?.justify === true} />
                <Checkbox
                    title="Font mono"
                    className="w-50 pe-2 mb-2"
                    onChange={(newValue) => { onChange({ ...value, fontMono: newValue }) }}
                    value={value?.fontMono === true} />
                <Checkbox
                    title="Text Break"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, textBreak: newValue }) }}
                    value={value?.textBreak === true} />
                <Checkbox
                    title="Text Reset"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, reset: newValue }) }}
                    value={value?.reset === true} />
            </div>
        </div>)
}