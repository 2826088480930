import { ApiTags, rootApi } from '../../rootApi';

export interface IContact {
    id: string,
    title: string,
}

const TAG = ApiTags.Contact;
const getTags = (entities: IContact[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]
const contactsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getContacts: builder.query<IContact[], void>({
            query: () => `/Contacts`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),
    })
})

export const {
    useGetContactsQuery,
    useLazyGetContactsQuery
} = contactsApi;