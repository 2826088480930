import { BorderColor, BorderSize, BorderStyle, IBorderStyle, RoundedBorder, RoundedSize, YesNoChoice } from "../models";

import clsx from "clsx";

export const borderStyleClasses = (borderStyle?: IBorderStyle) => {
    let classes = "";

    if (borderStyle) {
        classes = clsx(classes, yesNoChoiceClass(borderStyle.border, "border"));
        classes = clsx(classes, yesNoChoiceClass(borderStyle.borderTop, "border-top"));
        classes = clsx(classes, yesNoChoiceClass(borderStyle.borderRight, "border-right"));
        classes = clsx(classes, yesNoChoiceClass(borderStyle.borderBottom, "border-bottom"));
        classes = clsx(classes, yesNoChoiceClass(borderStyle.borderLeft, "border-left"));
        classes = clsx(classes, borderColorClass(borderStyle.color));
        classes = clsx(classes, roundedBorderClass(borderStyle.roundedBorder));
        classes = clsx(classes, roundedSizeClass(borderStyle.roundedSize));
        classes = clsx(classes, borderSizeClass(borderStyle.size));
        classes = clsx(classes, borderStyleClass(borderStyle.style));
    }

    return classes;
}
const borderStyleClass = (style?: BorderStyle) => {
    switch (style) {
        case BorderStyle.Dashed: return 'border-dashed';
        case BorderStyle.Dotted: return 'border-dotted';
    }
}

const borderSizeClass = (width?: BorderSize) => {
    switch (width) {
        case BorderSize.Border0: return 'border-0';
        case BorderSize.Border1: return 'border-1';
        case BorderSize.Border2: return 'border-2';
        case BorderSize.Border3: return 'border-3';
        case BorderSize.Border4: return 'border-4';
        case BorderSize.Border5: return 'border-5';
    }
}

const roundedSizeClass = (size?: RoundedSize) => {
    switch (size) {
        case RoundedSize.Rounded0: return 'rounded-0';
        case RoundedSize.Rounded1: return 'rounded-1';
        case RoundedSize.Rounded2: return 'rounded-2';
        case RoundedSize.Rounded3: return 'rounded-3';
        case RoundedSize.Rounded4: return 'rounded-4';
        case RoundedSize.Rounded5: return 'rounded-5';
    }
}

const yesNoChoiceClass = (choice?: YesNoChoice, prefix?: string) => {
    switch (choice) {
        case YesNoChoice.Yes: return `${prefix}`;
        case YesNoChoice.No: return `${prefix}-0`;
    }
}

const borderColorClass = (color?: BorderColor) => {
    switch (color) {
        case BorderColor.Primary: return 'border-primary';
        case BorderColor.Secondary: return 'border-secondary';
        case BorderColor.Success: return 'border-success';
        case BorderColor.Danger: return 'border-danger';
        case BorderColor.Warning: return 'border-warning';
        case BorderColor.Info: return 'border-info';
        case BorderColor.Light: return 'border-light';
        case BorderColor.Dark: return 'border-dark';
        case BorderColor.White: return 'border-white';

        case BorderColor.Gray100: return 'border-gray-100';
        case BorderColor.Gray200: return 'border-gray-200';
        case BorderColor.Gray300: return 'border-gray-300';
        case BorderColor.Gray400: return 'border-gray-400';
        case BorderColor.Gray500: return 'border-gray-500';
        case BorderColor.Gray600: return 'border-gray-600';
        case BorderColor.Gray700: return 'border-gray-700';
        case BorderColor.Gray800: return 'border-gray-800';
        case BorderColor.Gray900: return 'border-gray-900';
    }
}

const roundedBorderClass = (radius?: RoundedBorder) => {
    switch (radius) {
        case RoundedBorder.Rounded: return 'rounded';
        case RoundedBorder.Top: return 'rounded-top';
        case RoundedBorder.End: return 'rounded-end';
        case RoundedBorder.Bottom: return 'rounded-bottom';
        case RoundedBorder.Start: return 'rounded-start';
        case RoundedBorder.Circle: return 'rounded-circle';
        case RoundedBorder.Pill: return 'rounded-pill';
    }
}