import { EditSpacing } from "./responsive-spacing"
import { ICornerSpacing } from "../../../../base/styles"

export const EditComponentSpacing = ({ title, value, className, onChange }:{
    title: string,
    value: ICornerSpacing,
    className?: string,
    onChange: (value?: ICornerSpacing) => void
}) => {
    return (
        <div className={className}>
            <EditSpacing
                className="mb-2"
                title={`${title}`}
                onChange={(newValue) => { onChange({ ...value, all: newValue }) }}
                value={value?.all || {}} />
            <EditSpacing
                className="mb-2"
                title={`${title} Top`}
                onChange={(newValue) => { onChange({ ...value, top: newValue }) }}
                value={value?.top || {}} />
            <EditSpacing
                className="mb-2"
                title={`${title} Bottom`}
                onChange={(newValue) => { onChange({ ...value, bottom: newValue }) }}
                value={value?.bottom || {}} />
            <EditSpacing
                className="mb-2"
                title={`${title} Left`}
                onChange={(newValue) => { onChange({ ...value, left: newValue }) }}
                value={value?.left || {}} />
            <EditSpacing
                className="mb-2"
                title={`${title} Right`}
                onChange={(newValue) => { onChange({ ...value, right: newValue }) }}
                value={value?.right || {}} />
            <EditSpacing
                className="mb-2"
                title={`${title} Horizontal`}
                onChange={(newValue) => { onChange({ ...value, x: newValue }) }}
                value={value?.x || {}} />
            <EditSpacing
                className="mb-2"
                title={`${title} Vertical`}
                onChange={(newValue) => { onChange({ ...value, y: newValue }) }}
                value={value?.y || {}} />
        </div>)
}