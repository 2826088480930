import { BorderColor } from "../../../../base/styles";
import clsx from "clsx";

export const SelectBorderColor = ({ title, value, className, onChange }:{
    title: string,
    value?: BorderColor,
    className?: string,
    onChange: (value?: BorderColor) => void,
}) => {
    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as BorderColor;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group mb-2")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option className="bg-transparent"></option>
                <option className="bg-primary text-light" value={BorderColor.Primary}>Primary</option>
                <option className="bg-secondary text-dark" value={BorderColor.Secondary}>Secondary</option>
                <option className="bg-success text-dark" value={BorderColor.Success}>Success</option>
                <option className="bg-danger text-light" value={BorderColor.Danger}>Danger</option>
                <option className="bg-warning text-dark" value={BorderColor.Warning}>Warning</option>
                <option className="bg-info text-light" value={BorderColor.Info}>Info</option>
                <option className="bg-light text-dark" value={BorderColor.Light}>Light</option>
                <option className="bg-dark text-light" value={BorderColor.Dark}>Dark</option>
                <option className="bg-white text-dark" value={BorderColor.White}>White</option>

                <option className="bg-gray-100 text-dark" value={BorderColor.Gray100}>Gray - 100</option>
                <option className="bg-gray-200 text-dark" value={BorderColor.Gray200}>Gray - 200</option>
                <option className="bg-gray-300 text-dark" value={BorderColor.Gray300}>Gray - 300</option>
                <option className="bg-gray-400 text-dark" value={BorderColor.Gray400}>Gray - 400</option>
                <option className="bg-gray-500 text-dark" value={BorderColor.Gray500}>Gray - 500</option>
                <option className="bg-gray-600 text-light" value={BorderColor.Gray600}>Gray - 600</option>
                <option className="bg-gray-700 text-light" value={BorderColor.Gray700}>Gray - 700</option>
                <option className="bg-gray-800 text-light" value={BorderColor.Gray800}>Gray - 800</option>
                <option className="bg-gray-900 text-gray-100" value={BorderColor.Gray900}>Gray - 900</option>

            </select>
        </div>
    )
}