import { IValueExpression, QueryParameter } from "../../../../base/models"
import React, { useEffect, useState } from "react"

import { EditQueryParameter } from "./EditQueryParameter"
import { getQueryExprParameters } from "./helper"

export const BooleanExpression = ({ entity, expression, onChange }: {
    entity?: string,
    expression: IValueExpression,
    onChange: (expression: IValueExpression) => void
}) => {
    const [query, setQuery] = useState<string>(expression.query || '')
    const [parameters, setParameters] = useState<QueryParameter[]>(expression.parameters || [])

    useEffect(() => {
        const queryTokens = getQueryExprParameters(query)
        const newQueryTokens = queryTokens.filter(p => !parameters.find(p2 => p2.name === p))
            .map<QueryParameter>(p => ({ name: p, type: "Const", value: "" })) || []
        const validTokens = parameters.filter(p => queryTokens.indexOf(p.name) !== -1)

        setParameters([...validTokens, ...newQueryTokens])

        // eslint-disable-next-line
    }, [query])

    useEffect(() => {
        onChange({ type: "Boolean", query, parameters })

        // eslint-disable-next-line
    }, [query, parameters])

    return <div className="d-flex flex-column fs-7">
        <div className="card mb-3 bg-light">
            <div className="card-body py-2 pb-3 px-5 my-0">
                <div className="flex-fill">
                    <span className="text-dark fw-bold fs-9">Example:</span>
                </div>
                <div className="d-flex flex-row align-items-center fs-9">
                    <div className="me-5">
                        <span className="badge badge-info fs-10 me-2">Query</span>
                        <span className="text-gray-600">{`{age} > 25 && {role} == 'consultant'`}</span>
                    </div>
                    <div className="me-5">
                        <span className="badge badge-info fs-10 me-2">Values</span>
                        <span className="text-gray-600">{`age: 30, role: consultant`}</span>
                    </div>
                    <div className="my-0">
                        <span className="badge badge-success fs-10 me-2">Result</span>
                        <span className="text-gray-700">{`true`}</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="flex-fill">
            <label className="form-label fs-8">
                Query
            </label>
            <textarea
                id="query"
                className={"form-control form-control-sm flex-fill"}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
            />
        </div>

        <div className="table-responsive2 fs-8 mt-5">
            <table className='table align-middle fw-semibold'>
                <thead>
                    <tr>
                        <th className='pe-2 min-w-50px'>Name</th>
                        <th className='px-2 min-w-50px'>Type</th>
                        <th className='ps-2 min-w-150px'>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map(token => <React.Fragment key={token.name}>
                        <EditQueryParameter type={"text"} entity={entity} parameter={token}
                            onChange={(newToken) => {
                                setParameters(parameters.map(p => p.name === newToken.name ? newToken : p))
                            }} />
                    </React.Fragment>)}
                </tbody>
            </table>
        </div>
    </div>
}

