import * as Yup from 'yup'

import { AlertMessage, MUIIcon, Spinner } from '../../../widgets'
import { Formik, FormikHelpers } from 'formik'
import { IEntityRelation, ISite } from "../../../services/auth/models"

import { SelectEntity } from '../../../design/widgets'
import { SelectEntityAttribute } from '../../../design/widgets/SelectEntityAttribute'
import { useCreateEntityRelationMutation } from '../../../services/auth'

const entityRelationSchema = Yup.object().shape({
    entityId: Yup.string()
        .required('Entity is required'),
    fieldId: Yup.string()
        .required('Field is required'),
    mappingFieldId: Yup.string()
        .required('Mapping Field is required')
})

export const CreateEntityRelation = ({ site, className }: {
    site: ISite,
    className?: string
}) => {
    const [createEntityRelation] = useCreateEntityRelationMutation()
    const initialValues: Partial<IEntityRelation> = {
        portalId: site.portalId
    };

    const handleSubmit = (values: Partial<IEntityRelation>, { setStatus, setSubmitting, resetForm }: FormikHelpers<Partial<IEntityRelation>>) => {
        setSubmitting(true)
        console.log(values)
        createEntityRelation({ siteId: site.portalId, entity: values })
            .unwrap()
            .then((entityRelation) => {
                resetForm()
            })
            .catch(err => {
                setStatus(err.data?.error?.message || 'Failed to add entity relation.')
            })
            .finally(() => setSubmitting(false))
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={entityRelationSchema}
            onSubmit={handleSubmit}>
            {({
                errors, touched, status, isSubmitting, isValid, values,
                handleSubmit, setFieldValue, setFieldTouched, resetForm, setStatus }) => (
                <>
                    <tr>
                        <td className='pt-0'>
                            <form className='form' onSubmit={handleSubmit} noValidate id='entity-relation'>
                            </form>
                            <SelectEntity
                                value={values.entityId}
                                onBlur={() => setFieldTouched('entityId', true)}
                                onChange={(entity) => { setFieldValue('entityId', entity) }} />
                            <FieldError show={touched.entityId && !!errors.entityId} message={errors.entityId} />
                        </td>
                        <td className='pt-0'>
                            {values.entityId && <>
                                <SelectEntityAttribute
                                    entity={values.entityId}
                                    value={values.fieldId}
                                    onBlur={() => setFieldTouched('fieldId', true)}
                                    onChange={(value) => { setFieldValue('fieldId', value) }} />
                                <FieldError show={touched.fieldId && !!errors.fieldId} message={errors.fieldId} />
                            </>}
                        </td>
                        <td className='pt-0'>
                            {values.entityId && <>
                                <SelectEntityAttribute
                                    entity={site.portalEntity}
                                    value={values.mappingFieldId}
                                    onBlur={() => setFieldTouched('mappingFieldId', true)}
                                    onChange={(value) => { setFieldValue('mappingFieldId', value) }} />
                                <FieldError show={touched.mappingFieldId && !!errors.mappingFieldId} message={errors.mappingFieldId} />
                            </>
                            }
                        </td>
                        <td className='pt-0'>
                            <div className='d-flex justify-content-center align-items-stretch flex-wrap'>
                                <button
                                    type='submit'
                                    form='entity-relation'
                                    id='submit-settings'
                                    className='btn btn-sm btn-primary fs-8 py-2 px-3 flex-fill'
                                    disabled={isSubmitting || !isValid}
                                >
                                    <span className='indicator-label'>
                                        {!isSubmitting ?
                                            <MUIIcon iconName='AddOutlined' className='fs-5 me-1' />
                                            : <Spinner show={isSubmitting} />}
                                        Add
                                    </span>
                                </button>
                                <button
                                    className='btn btn-sm btn-secondary fs-8 py-2 px-3 ms-2 flex-fill'
                                    type='button'
                                    onClick={() => {
                                        resetForm();
                                        setStatus('');
                                    }}>
                                    <MUIIcon iconName='CancelOutlined' className='fs-5 me-1' />
                                    Cancel
                                </button>
                            </div>
                        </td>
                    </tr>
                    {!!status && <>
                        <tr><td colSpan={4} className='pt-0'>
                            <AlertMessage show={!!status} message={status} type='Error' />
                        </td></tr>
                    </>}
                </>
            )}
        </Formik>
    )
}

const FieldError = ({ show, message }: { show?: boolean, message?: string }) => {
    if (!show) return <></>

    return <div className='fv-plugins-message-container'>
        <div className='fv-help-block fs-8'>
            <span role='alert'>{message}</span>
        </div>
    </div>
}