import { AlertMessage, MUIIcon, Spinner } from "../../../../widgets"

import { IPaymentButtonProps } from "../IPaymentButtonProps"
import clsx from "clsx"
import { getButtonClass } from "../../buttons/helper"
import { getPageLinkUrl } from "../../../../base/helpers"
import { submitForm } from "../../../helpers/submit-form"
import { toAbsoluteUrl } from "../../../../../helpers"
import { useAuth } from "../../../../../modules/auth"
import { useGetRazorPayHashMutation } from "../services"
import { useState } from "react"

export const RazorPayButton = ({
    orderNo, enrolmentNo, entity, entityId,
    currency, amount,
    firstName, email, phoneNumber,
    successPage, failedPage,
    disabled, tooltip, style, size, icon, label,
    className, children
}: IPaymentButtonProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const { siteId, user } = useAuth()
    const [getRazorPayHash] = useGetRazorPayHashMutation()

    const handleOnClick = () => {
        if (!siteId || !user || !amount || !successPage || !failedPage) return;
        setIsSubmitting(true)
        setError('')

        const successPageUrl = getPageLinkUrl(siteId, successPage);
        const failedPageUrl = getPageLinkUrl(siteId, failedPage);

        getRazorPayHash({
            enrolmentNo,
            orderNo,
            entity,
            entityId,
            currency: currency || "INR",
            amount,
            name: firstName || '',
            email: email || '',
            phoneNumber: phoneNumber || '',
            successPageUrl,
            failedPageUrl,
        })
            .unwrap()
            .then(data => {
                submitForm(data.url, data.formData, "post")
            })
            .catch(() => {
                setError('Failed to generate payment request.');
                setIsSubmitting(false)
            })
            .finally()
    }

    return (
        <>
            <button
                disabled={disabled === true || isSubmitting === true || !user || !amount}
                type="button"
                title={tooltip}
                onClick={handleOnClick}
                className={clsx(getButtonClass(style, size, "btn-secondary"), className)}
            >
                <Spinner show={isSubmitting} className="me-1" />
                <img
                    alt='img'
                    className='h-25px'
                    src={toAbsoluteUrl('/media/logos/razorpay.png')}
                />

                {(!isSubmitting && icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                {label && <span>{label}</span>}
                {children}
            </button>
            <AlertMessage type="Error" show={!!error} message={error} />
        </>
    )
}