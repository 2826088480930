import * as Yup from 'yup'

import { AlertMessage, MUIIcon, Spinner } from '../../../widgets'
import { Formik, FormikHelpers } from 'formik'
import { SelectEntityId, SelectPage, SelectUser } from '../../../design/widgets'
import { useDeleteMemberMutation, useUpdateMemberMutation } from '../../../services/auth'

import { IMember } from '../../../services/auth/models/Member.model'
import { ISite } from '../../../services/auth/models'
import clsx from 'clsx'
import { useState } from 'react'

const entitySchema = Yup.object().shape({
    userId: Yup.string()
        .required('User is required'),
    entityId: Yup.string()
        .required('Entity is required'),
    dashboardPageId: Yup.string(),
    blocked: Yup.boolean(),
})

export const UpdateSiteMember = ({ site, member, className }: {
    site: ISite,
    member: IMember,
    className?: string
}) => {
    const [editEntity, setEditEntity] = useState<boolean>(false)
    const [updateSiteMember] = useUpdateMemberMutation()
    const [deleteSiteMember] = useDeleteMemberMutation()

    const handleSubmit = (values: Partial<IMember>, { setStatus, setSubmitting }: FormikHelpers<IMember>) => {
        setSubmitting(true)
        setStatus()
        updateSiteMember({ id: member.id, entity: values })
            .unwrap()
            .then(() => setEditEntity(false))
            .catch(err => {
                setStatus(err.data?.error?.message || 'Failed to update site member.')
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    const handleDelete = ({ setStatus, setSubmitting }: {
        setStatus: (status?: string) => void,
        setSubmitting: (isSubmitting: boolean) => void
    }) => {
        setSubmitting(true)
        setStatus()
        deleteSiteMember(member.id)
            .unwrap()
            .then(() => {
                setEditEntity(false);
            })
            .catch(err => {
                setStatus(err.data?.error?.message || 'Failed to delete entity relation.')
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <>
            <Formik
                initialValues={member}
                validationSchema={entitySchema}
                onSubmit={handleSubmit}>
                {({
                    errors, touched, status, isSubmitting, isValid, values,
                    getFieldProps,
                    handleSubmit, setFieldValue, setFieldTouched, resetForm, setStatus, setSubmitting }) => (
                    <>
                        <tr>
                            <td className='pt-0'>
                                <form onSubmit={handleSubmit} noValidate id={`update-site-member-${member.id}`} />
                                <SelectUser
                                    disabled={!editEntity}
                                    value={values.userId}
                                    onBlur={() => setFieldTouched('userId', true)}
                                    onChange={(userId) => { setFieldValue('userId', userId) }} />
                                <FieldError show={touched.entityId && !!errors.entityId} message={errors.entityId} />
                            </td>
                            <td className='pt-0'>
                                <SelectEntityId
                                    entity={site.portalEntity}
                                    disabled={!editEntity}
                                    value={values.entityId}
                                    onBlur={() => setFieldTouched('entityId', true)}
                                    onChange={(value) => { setFieldValue('entityId', value) }} />
                                <FieldError show={touched.entityId && !!errors.entityId} message={errors.entityId} />
                            </td>
                            <td className='pt-0'>
                                <SelectPage
                                    siteId={site.portalId}
                                    value={values.dashboardPageId}
                                    onBlur={() => setFieldTouched('dashboardPageId', true)}
                                    onChange={(value) => { setFieldValue('dashboardPageId', value) }} />
                                <FieldError show={touched.dashboardPageId && !!errors.dashboardPageId} message={errors.dashboardPageId} />
                            </td>
                            <td className='pt-0'>
                                <div className={clsx(className, "form-check form-check-sm form-check-custom")}>
                                    <input
                                        id={'blocked'}
                                        className="form-check-input"
                                        type="checkbox"
                                        {...getFieldProps('blocked')}
                                    />
                                </div>
                                <FieldError show={touched.blocked && !!errors.blocked} message={errors.blocked} />
                            </td>
                            <td className='pt-0'>
                                <div className='d-flex justify-content-center align-items-stretch flex-wrap'>
                                    {!editEntity &&
                                        <>
                                            <button
                                                className='btn btn-sm btn-primary fs-8 py-2 px-3 flex-fill'
                                                type='button'
                                                onClick={() => setEditEntity(true)}>
                                                <MUIIcon iconName='EditOutlined' className='fs-5 me-1' />
                                                Edit
                                            </button>
                                            <button
                                                className='btn btn-sm btn-danger fs-8 py-2 px-3 ms-2 flex-fill'
                                                disabled={isSubmitting}
                                                type='button'
                                                onClick={() => handleDelete({ setStatus, setSubmitting })}>
                                                {!isSubmitting ?
                                                    <MUIIcon iconName='DeleteOutlined' className='fs-5 me-1' />
                                                    : <Spinner show={isSubmitting} />}
                                                Delete
                                            </button>
                                        </>
                                    }
                                    {editEntity && <>
                                        <button
                                            type='submit'
                                            form={`update-site-member-${member.id}`}
                                            id='submit-settings'
                                            className='btn btn-sm btn-primary fs-8 py-2 px-3 flex-fill'
                                            disabled={isSubmitting || !isValid}
                                        >
                                            <span className='indicator-label'>
                                                {!isSubmitting ?
                                                    <MUIIcon iconName='SaveOutlined' className='fs-5 me-1' />
                                                    : <Spinner show={isSubmitting} />}
                                                Save
                                            </span>
                                        </button>
                                        <button
                                            className='btn btn-sm btn-secondary fs-8 py-2 px-3 ms-2 flex-fill'
                                            type='button'
                                            onClick={() => {
                                                resetForm();
                                                setStatus('');
                                                setEditEntity(false)
                                            }}>
                                            <MUIIcon iconName='CancelOutlined' className='fs-5 me-1' />
                                            Cancel
                                        </button>
                                    </>}
                                </div>
                            </td>
                        </tr>
                        {!!status && <>
                            <tr><td colSpan={4} className='pt-0'>
                                <AlertMessage show={!!status} message={status} type='Error' />
                            </td></tr>
                        </>}
                    </>
                )}
            </Formik>
        </>
    )
}

const FieldError = ({ show, message }: { show?: boolean, message?: string }) => {
    if (!show) return <></>

    return <div className='fv-plugins-message-container'>
        <div className='fv-help-block fs-8'>
            <span role='alert'>{message}</span>
        </div>
    </div>
}