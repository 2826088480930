import { Property, PropertyType } from '../../../../base/types';

import { IProperty } from '../../../../base/models';

export const HyperLinkProperties: IProperty[] = [
    { id: Property.MaterialIcon, name: 'Icon', type: PropertyType.MaterialIcon },
    { id: Property.Label, name: 'Label', type: PropertyType.String },
    { id: Property.Url, name: 'Url', type: PropertyType.String },
    { id: Property.Target, name: 'Target', type: PropertyType.String },
    { id: Property.Tooltip, name: 'Tooltip', type: PropertyType.String },
    { id: Property.BackgroundStyle, name: 'Style', type: PropertyType.ButtonStyle },
    { id: Property.Size, name: 'Size', type: PropertyType.ControlSize },
   
]
