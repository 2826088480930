import { ApiTags, rootApi } from "../../rootApi";

import { IMedia } from "./models"

const TAG = ApiTags.Media;

const mediaApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getMedia: builder.query<IMedia, string>({
      query: (mediaId) => `/TenantMedia(${mediaId})`,
      providesTags: (result) => result ? [{ type: TAG, id: result.id }, TAG] : [TAG]
    }),
  })
})

export const { useLazyGetMediaQuery, useGetMediaQuery } = mediaApi;