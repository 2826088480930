import { DataContextProvider, useDataContext } from "../../page/context";

import { AlertMessage } from "../../../widgets";
import { ControlCollection } from "../../page-control";
import { DesignContextProvider } from "../../../providers";
import { IControlProps } from "../../../base/models";
import { IEntity } from "../../../base/types";
import { useEffect } from "react";
import { useGetComponentMetadataQuery } from "../../../services/data/componentsMetadataApi";
import { useGetProperties } from "../../../base/hooks";

export const CustomComponent = ({ control, orientation: layout, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const { data: component, isLoading, isError } = useGetComponentMetadataQuery(control.componentId);

    if (!control.componentId) {
        return <div className="alert alert-danger">No component to show</div>
    }

    if (isLoading) {
        return <div className="d-flex align-items-center justify-content-center m-10">
            <span className='indicator-progress' style={{ display: 'block' }}>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
        </div>
    }

    if (isError) {
        return <>Error</>
    }

    if (!component) {
        return <AlertMessage
            type="Error"
            message="An error while loading the component metadata, please try after sometime." />
    }

    if (!component?.controls) {
        return <></>
    }

    return (
        <DesignContextProvider>
            <DataContextProvider>
                <DataProducer data={properties} />
                {component.controls &&
                    <ControlCollection
                        onDesignChange={() => { }}
                        control={control}
                        orientation={layout}
                        controls={component.controls}
                        className={className} />}
            </DataContextProvider>
        </DesignContextProvider>
    )
}

const DataProducer = ({ data }: { data: IEntity }) => {
    const { setData } = useDataContext()

    useEffect(() => {
        setData({ type: "Card", data, isLoading: false, isSuccess: true, isError: false })
        // eslint-disable-next-line
    }, [])

    return <></>
}