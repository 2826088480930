import { AttributeDataType, IAttributeMetadata, IAttributeOption, IServiceAttribute, IServiceRequest, IServiceStage } from '../models'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'

import { CollapsiblePanel } from '../../../../widgets'
import { IServiceMetadata } from "../services/useGetServiceMetadata"
import { ReadOnlyAttribute } from './service-attribute/ReadOnlyAttribute'
import { ServiceReqHelper } from '../helper'
import clsx from 'clsx'
import { useState } from 'react'

export const ViewServiceRequest = ({ metadata, values, entity, relativePath, request, hideHeader, documentsOnly }: {
    metadata: IServiceMetadata,
    request: IServiceRequest,
    hideHeader?: boolean,
    documentsOnly: boolean,
    values: any,
    entity?: string,
    relativePath?: string,
}) => {
    const [stages, setStages] = useState<IServiceStage[]>([])

    useEffect(() => {
        const stages = metadata
            .stages
            .sort((a, b) => (a.stageNumber || 0) - (b.stageNumber || 0))

        setStages(stages)

        // eslint-disable-next-line
    }, [])

    const getStages = () => {
        let stages2 = stages;
        if (documentsOnly) {
            let docStages: IServiceStage[] = [];
            for (var i = 0; i < stages.length; i++) {
                const stage = stages[i];
                var items = metadata.serviceAttributes.filter(p => p.required && p.serviceStageId === stage.id && metadata.attributes.find(p2 => p2.id === p.attributeId)?.datatype === AttributeDataType.DocumentChecklist)
                if (items && items.length !== 0) {
                    docStages.push(stage)
                }
            }

            stages2 = docStages;
        }

        return <>
            {stages2.map((stage, index) => <React.Fragment key={stage.id}>
                <CollapsiblePanel
                    eventKey={stage.id}
                    header={stage.name}>
                    <div className={clsx('row g-2')}>
                        {metadata.serviceAttributes
                            .filter(p => p.serviceStageId === stage.id)
                            .sort((a, b) => (a.order || 0) - (b.order || 0))
                            .map(attr => <React.Fragment key={attr.id}>
                                <ServiceAttributeWrapper
                                    metadata={metadata}
                                    attribute={attr}
                                    value={values[attr.attributeId]}
                                    entity={entity}
                                    entityId={request?.id}
                                    relativePath={relativePath}
                                    documentsOnly={documentsOnly}
                                />
                            </React.Fragment>)}
                    </div>
                </CollapsiblePanel>
            </React.Fragment>)}
        </>
    }

    const requestHeader = () => {
        if (hideHeader === true) return <></>

        return <>
            <div className='d-flex mb-5'>
                <h1 className='flex-fill'>{metadata.service.name}</h1>
                <div className='d-flex flex-column fs-8'>
                    <label className='me-3 fw-bold'>Status</label>
                    <span className='badge badge-info px-2 py-1'>{ServiceReqHelper.status(request?.statusCode)}</span>
                </div>
            </div>
        </>
    }


    return (
        <Formik
            initialValues={{}}
            onSubmit={() => { }}>
            {() => (
                <Form>
                    <>{requestHeader()}</>
                    <div>
                        {getStages()}
                    </div>
                </Form>
            )}
        </Formik>
    )
}


const ServiceAttributeWrapper = ({ metadata, attribute, value, entity, entityId, relativePath, documentsOnly }: {
    metadata: IServiceMetadata,
    attribute: IServiceAttribute,
    value?: string,
    entity?: string,
    entityId?: string,
    relativePath: string | undefined,
    documentsOnly: boolean,
}) => {
    const [attributeMetadata, setAttributeMetadata] = useState<IAttributeMetadata>()
    const [options, setOptions] = useState<IAttributeOption[]>([])
    const [checklistId, setChecklistId] = useState<string>()

    useEffect(() => {
        const options = metadata.attributeOptions.filter(p => p.attributeId === attribute.attributeId)
        setOptions(options)

        const attributeMetadata = metadata.attributes.find(p => p.id === attribute.attributeId)
        setAttributeMetadata(attributeMetadata)

        if (attributeMetadata?.datatype === AttributeDataType.DocumentChecklist) {
            setChecklistId(attributeMetadata.documentChecklist)
        }

        // eslint-disable-next-line
    }, [])

    return <>
        <ReadOnlyAttribute
            attribute={attribute}
            value={value}
            entity={entity}
            entityId={entityId}
            checklistId={checklistId}
            relativePath={relativePath}
            options={options}
            documentsOnly={documentsOnly}
            metadata={attributeMetadata} />
    </>
}

