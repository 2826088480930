import { FlexDirection } from "../../../../../base/styles";
import clsx from "clsx";

export const SelectFlexDirection = ({ title, value, className, onChange }:{
    title: string,
    value?: FlexDirection,
    className?: string,
    onChange: (value?: FlexDirection) => void,
}) => {

    const handleOnSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as FlexDirection;
        newValue ? onChange(newValue) : onChange(undefined);
    }

    return (
        <div className={clsx(className, "field-group")}>
            <label className="fs-8 fw-bold text-gray-600">{title}</label>
            <select
                onChange={handleOnSelectionChange}
                value={value || ""}
                className="form-select form-select-sm form-control-xs">
                <option></option>
                <option value={FlexDirection.Row}>Row</option>
                <option value={FlexDirection.RowReverse}>Row Reverse</option>
                <option value={FlexDirection.Column}>Column</option>
                <option value={FlexDirection.ColumnReverse}>Column Reverse</option>
            </select>
        </div>
    )
}