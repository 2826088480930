import { DocumentChecklistMetadata, IDocumentChecklist } from "../models";
import { useEffect, useState } from "react";

import { FieldFilterType } from "../../../../base/types";
import { IFilterGroup } from "../../../../data";
import { useAuth } from "../../../../../modules/auth";
import { useLazyReadMultipleQuery } from "../../../../services/data/tableDataApi";

// TODO:: status fields should be optimized
export const useGetDocumentChecklist = (checklistId: string) => {
    const [getRecords] = useLazyReadMultipleQuery();
    const { user, siteId: portalId } = useAuth()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [data, setData] = useState<IDocumentChecklist>()

    const refetch = () => {
        if (!user || !checklistId)
            return;

        const { userId } = user;
        if (!portalId) return;

        setIsSuccess(false)
        setIsError(false)
        setError(undefined)
        setIsLoading(true)

        const { tableId, fields } = DocumentChecklistMetadata
        const fieldList: string[] = [fields.id, fields.description]
        const filters: IFilterGroup[] = [{
            group: 0,
            filters: [
                { field: fields.id, type: FieldFilterType.Const, value: checklistId }
            ]
        }]

        getRecords({
            userId, portalId, tableId, filters, fieldList, recordLimit: 1
        })
            .unwrap()
            .then(data => {
                const records = data?.records || [];
                if (records.length > 0) {
                    const record = records[0];
                    setData({
                        id: record[fields.id],
                        description: record[fields.description],
                    });
                    setIsSuccess(true)
                } else {
                    setData(undefined)
                    setIsError(true)
                    setError(`Document Checklist Id: ${checklistId} not found.`)
                }
            })
            .catch(err => {
                setIsError(true)
                setError(err.data?.error?.message || 'Failed to get data')
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        refetch()
        // eslint-disable-next-line
    }, [checklistId])

    return { data, isLoading, isSuccess, isError, error, refetch }
}
