import { useDeleteSPFolderMutation } from "../sharePointFilesApi"
import { useState } from "react"

export const useDeleteFolder = () => {
    const [deleteSPFolder, {isLoading, isSuccess, isError}] = useDeleteSPFolderMutation()
    const [error, setError] = useState()

    const deleteFolder = (relativeUrl: string) => {
        deleteSPFolder(relativeUrl)
            .unwrap()
            .catch((error) => {
                setError(error)
            })
    }

    return { deleteFolder, isLoading, isSuccess, isError, error }
}