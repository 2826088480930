import { ButtonStyle, ControlSize, Property } from "../../../base/types";

import { DefaultLabel } from "../../widgets";
import { IControlProps } from "../../../base/models";
import { MUIIcon } from "../../../widgets";
import clsx from "clsx";
import { getButtonClass } from "../buttons/helper";
import { useDesignContext } from "../../../providers";
import { useGetProperties } from "../../../base/hooks";

export const HyperLinkComponent = ({ control, className, children }: IControlProps) => {
    const { mode } = useDesignContext()
    const { properties } = useGetProperties({ bindings: control.bindings });
    const label: string | undefined = properties[Property.Label];
    const tooltip: string | undefined = properties[Property.Tooltip];
    const url: string | undefined = properties[Property.Url];
    const target: string | undefined = properties[Property.Target];
    const icon: string | undefined = properties[Property.MaterialIcon]
    const style: ButtonStyle | undefined = properties[Property.BackgroundStyle]
    const size: ControlSize | undefined = properties[Property.Size]
    
    if (mode === "Design") {
        return (
            <div className={clsx(getButtonClass(style, size, "btn btn-link btn-sm"), className)} title={tooltip}>
                {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
                <DefaultLabel
                    control={control}
                    propertyId={Property.Label}
                    defaultLabel="Hyper Link"
                    className="text-hover-primary bg-secondary" />
                {children}
            </div>)
    }

    return (
        <a className={clsx(getButtonClass(style, size, "btn btn-link btn-sm"), className)} title={tooltip} href={url} target={target}>
            {(icon) && <MUIIcon iconName={icon} className={clsx({ "me-1": !!label })} />}
            <span>{label}</span>
            {children}
        </a >)
}