import * as uuid from "uuid";

import { ActionType, IPageAction } from "../page-action/models";
import { IPageComponent, IPageControl } from "../../base/models";

import { ControlType } from "../../base/types";

export const createControl = (component: IPageComponent): IPageControl => {
    const controlId = uuid.v1()

    return {
        controlId,
        controlType: ControlType.Control,
        controlName: component.name,
        componentId: component.id,
        componentType: component.type,
        componentName: component.name,
        sequence: 0,
        bindings: []
    }
}

export const createAction = (type: ActionType): IPageAction => {
    return {
        actionId: uuid.v1(),
        name: type,
        sequence: 0,
        type: type,
        bindings: []
    }
}

export const readClipboardControlAsync = async () => {
    const text = await navigator.clipboard.readText();
    if (!text.startsWith("::PAGE-CONTROL::")) {
        console.log("Not a valid control to paste");
        return;
    }

    const json = text.substring("::PAGE-CONTROL::".length + 1);
    return JSON.parse(json) as IPageControl;
}

export const readClipboardActionAsync = async () => {
    const text = await navigator.clipboard.readText();
    if (!text.startsWith("::PAGE-ACTION::")) {
        console.log("Not a valid action to paste");
        return;
    }

    const json = text.substring("::PAGE-ACTION::".length + 1);
    return JSON.parse(json) as IPageAction;
}

export const resetControlIds = (control: IPageControl): IPageControl => {
    const resetControlsId = (controls?: IPageControl[]): IPageControl[] | undefined => {
        if (!controls || controls.length === 0) return controls;
        return controls.map<IPageControl>(item => ({
            ...item,
            controlId: uuid.v1(),
            controls: resetControlsId(item.controls)
        }))
    }

    return { ...control, controlId: uuid.v1(), controls: resetControlsId(control.controls) };
}

export const resetActionIds = (action: IPageAction): IPageAction => {
    const resetControlsId = (actions?: IPageAction[]): IPageAction[] | undefined => {
        if (!actions || actions.length === 0) return actions;
        return actions.map<IPageAction>(item => ({
            ...item,
            actionId: uuid.v1(),
            actions: resetControlsId(item.actions)
        }))
    }

    return { ...action, actionId: uuid.v1(), actions: resetControlsId(action.actions) };
}