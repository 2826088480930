import * as Yup from 'yup'

import { AlertMessage, MUIIcon, Spinner } from '../../../../framework/widgets'
import { Formik, FormikHelpers } from 'formik'

import { IUser } from '../../../../framework/services/auth/models'
import { SelectContact } from '../../../../framework/design/widgets'
import clsx from 'clsx'
import { useUpdateUserMutation } from '../../../../framework/services/auth'

const userSchema = Yup.object().shape({
    userName: Yup.string(),
    firstName: Yup.string()
        .required('First name is required'),
    middleName: Yup.string(),
    lastName: Yup.string()
        .required('Last name is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required'),
    email: Yup.string()
        .email()
        .required('Email is required'),
    contactNo: Yup.string()
        .required('Contact is required'),
})

export const EditUser = ({ user, className, onSave, onCancel }: {
    user: IUser,
    className?: string,
    onSave?: () => void,
    onCancel?: () => void
}) => {
    const [updateUser] = useUpdateUserMutation()

    const handleSubmit = (values: Partial<IUser>, { setStatus, setSubmitting }: FormikHelpers<IUser>) => {
        setSubmitting(true)
        setStatus()
        updateUser({ id: user.userId, user: values })
            .unwrap()
            .then(onSave)
            .catch(err => {
                setStatus(err.data?.error?.message || 'Failed to update user info.')
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    const labelClass = "fs-7 fw-semibold";
    const fieldGroupClass = "col-lg-12";

    return (
        <>
            <Formik
                initialValues={user}
                validationSchema={userSchema}
                onSubmit={handleSubmit}>
                {({
                    errors, touched, status, isSubmitting, isValid, values, getFieldProps,
                    handleSubmit, setFieldValue, setFieldTouched, resetForm, setStatus, setSubmitting }) => (
                    <>
                        <div className='row g-2'>
                            <form onSubmit={handleSubmit} noValidate id={`user-${user.userId}`} />
                            <div className={fieldGroupClass}>
                                <label className={clsx(labelClass, "form-label")}>User Name</label>
                                <div className='form-control form-control-sm'>{user.userName}</div>
                            </div>
                            <div className={fieldGroupClass}>
                                <label className={clsx(labelClass, "form-label")}>First Name</label>
                                <input
                                    type="text"
                                    className='form-control form-control-sm'
                                    {...getFieldProps('firstName')} />

                                <FieldError show={touched.firstName && !!errors.firstName} message={errors.firstName} />
                            </div>
                            <div className={fieldGroupClass}>
                                <label className={clsx(labelClass, "form-label")}>Middle Name</label>
                                <input
                                    type="text"
                                    className='form-control form-control-sm'
                                    {...getFieldProps('middleName')} />

                                <FieldError show={touched.middleName && !!errors.middleName} message={errors.middleName} />
                            </div>
                            <div className={fieldGroupClass}>
                                <label className={clsx(labelClass, "form-label")}>Last Name</label>
                                <input
                                    type="text"
                                    className='form-control form-control-sm'
                                    {...getFieldProps('lastName')} />

                                <FieldError show={touched.lastName && !!errors.lastName} message={errors.lastName} />
                            </div>
                            <div className={fieldGroupClass}>
                                <label className={clsx(labelClass, "form-label")}>E-Mail</label>
                                <input
                                    type="email"
                                    className='form-control form-control-sm'
                                    {...getFieldProps('email')} />

                                <FieldError show={touched.email && !!errors.email} message={errors.email} />
                            </div>
                            <div className={fieldGroupClass}>
                                <label className={clsx(labelClass, "form-label")}>Phone Number</label>
                                <input
                                    type="text"
                                    className='form-control form-control-sm'
                                    {...getFieldProps('phoneNumber')} />

                                <FieldError show={touched.phoneNumber && !!errors.phoneNumber} message={errors.phoneNumber} />
                            </div>
                            <div className={fieldGroupClass}>
                                <label className={clsx(labelClass, "form-label")}>Contact</label>
                                <SelectContact
                                    value={values.contactNo}
                                    onBlur={() => setFieldTouched('contactNo', true)}
                                    onChange={(value) => { setFieldValue('contactNo', value) }} />

                                <FieldError show={touched.contactNo && !!errors.contactNo} message={errors.contactNo} />
                            </div>

                            <AlertMessage show={!!status} message={status} type='Error' />

                            <div className='pt-0 my-10'>
                                <div className='d-flex justify-content-center align-items-stretch flex-wrap'>
                                    <button
                                        type='submit'
                                        form={`user-${user.userId}`}
                                        id='submit-settings'
                                        className='btn btn-sm btn-primary fs-8 py-2 px-3 flex-fill'
                                        disabled={isSubmitting || !isValid}
                                    >
                                        <span className='indicator-label'>
                                            {!isSubmitting ?
                                                <MUIIcon iconName='SaveOutlined' className='fs-5 me-1' />
                                                : <Spinner show={isSubmitting} />}
                                            Save
                                        </span>
                                    </button>
                                    <button
                                        className='btn btn-sm btn-secondary fs-8 py-2 px-3 ms-2 flex-fill'
                                        type='button'
                                        onClick={() => {
                                            resetForm();
                                            setStatus('');
                                            onCancel && onCancel()
                                        }}>
                                        <MUIIcon iconName='CancelOutlined' className='fs-5 me-1' />
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

const FieldError = ({ show, message }: { show?: boolean, message?: string }) => {
    if (!show) return <></>

    return <div className='fv-plugins-message-container'>
        <div className='fv-help-block fs-8'>
            <span role='alert'>{message}</span>
        </div>
    </div>
}