import { createContext, useContext, useEffect, useRef, useState } from "react";

import { AuthType } from "../core/AuthModel";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useLazyGetConfigQuery } from "./services/msalApi";

export interface IAuthProvider {
    authTypes: AuthType[]
}

const context = createContext<IAuthProvider>({
    authTypes: [],
})

export const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
    const [getMSALConfig] = useLazyGetConfigQuery()

    const [msalInstance, setMSALInstance] = useState<PublicClientApplication>()
    const didRequest = useRef(false)

    const getConfig = () => {
        getMSALConfig()
            .unwrap()
            .then((config) => {
                const instance = new PublicClientApplication({
                    auth: {
                        clientId: config.clientId,
                        authority: `https://login.microsoftonline.com/${config.tenantId}`,
                        redirectUri: config.redirectUri,
                    },
                    cache: {
                        cacheLocation: "sessionStorage",
                        storeAuthStateInCookie: false,
                    }
                })

                setMSALInstance(instance);
            })
    }

    useEffect(() => {
        if (!didRequest.current) {
            getConfig()
        }

        return () => { didRequest.current = true }
        // eslint-disable-next-line
    }, [didRequest])

    if (!msalInstance) {
        return <context.Provider value={{ authTypes: ["Custom"] }}>
            {children}
        </context.Provider>
    }

    return (
        <context.Provider value={{ authTypes: ["Custom", "MSAL"] }}>
            <MsalProvider instance={msalInstance}>
                {children}
            </MsalProvider>
        </context.Provider>
    )
}

export function useAuthProviders() {
    return useContext(context)
}