import { DataContextWithStatus, IQuery, IQueryMultiple } from "../models";
import { createContext, useContext, useState } from "react"
import { useLazyReadMultipleQuery, useLazyReadQuery } from "../../../services/data/tableDataApi";

import { IDataSource } from "../../../base/types";
import { IReadMultipleOptions } from "../../../services/models";
import { useAuth } from "../../../../modules/auth";

type DataContext = DataContextWithStatus & {
    setData: (data: DataContextWithStatus) => void,
    setDataSource: (dataSource: IDataSource) => void,
    getRecord: (query: IQuery) => void,
    getMultipleRecords: (query: IQueryMultiple) => void,
    getFirstRecord: (query: IQueryMultiple) => void,
    refetch: () => void
}

const context = createContext<DataContext>({
    type: "Static",
    isLoading: false,
    isError: false,
    isSuccess: false,
    setData: () => { },
    setDataSource: () => { },
    getRecord: () => { },
    getMultipleRecords: () => { },
    getFirstRecord: () => { },
    refetch: () => { }
})

export const DataContextProvider = ({ children }: {
    children?: React.ReactNode
}) => {
    const { user, siteId } = useAuth();
    const [readRecords] = useLazyReadMultipleQuery();
    const [readRecord] = useLazyReadQuery();

    const [dataSource, setDataSource] = useState<IDataSource>()
    const [data, setData] = useState<DataContextWithStatus>({
        type: "Static",
        isLoading: false,
        isError: false,
        isSuccess: false,
    });
    const [method, setMethod] = useState<"getRecord" | "getFirstRecord" | "getMultipleRecords">()

    const refetch = () => {
        switch (method) {
            case "getRecord":
                getRecord(data.query as IQuery)
                break;
            case "getFirstRecord":
                getFirstRecord(data.query as IQueryMultiple)
                break;
            case "getMultipleRecords":
                getMultipleRecords(data.query as IQueryMultiple)
                break
        }
    }

    const getRecord = (query: IQuery) => {
        // Site
        if (!siteId) return;

        // User
        if (!user) return;
        const { userId } = user;
        if (!userId) return;

        // Data Source
        if (!dataSource) return;
        const { entity } = dataSource;
        if (!entity) return;

        // Query
        setMethod('getRecord')
        const { id: sysId, fields: fieldList } = query
        setData({ query: query, type: "Static", isLoading: true, isSuccess: false, isError: false })

        readRecord({ userId, portalId: siteId, tableId: entity, sysId, fieldList }, true)
            .unwrap()
            .then(data => {
                setData({ query, type: "Card", data, isLoading: false, isSuccess: true, isError: false })
            })
            .catch(err => {
                const error = err.data?.error?.message || 'Failed to get data'
                setData({ query, type: "Static", isLoading: false, isSuccess: false, isError: true, error })
            })
    }

    const getFirstRecord = (query: IQueryMultiple) => {
        // Site
        if (!siteId) return;

        // User
        if (!user) return;
        const { userId } = user;
        if (!userId) return;

        // Data Source
        if (!dataSource) return;
        const { entity } = dataSource;
        if (!entity) return;

        setMethod('getFirstRecord')
        const { fields: fieldList, filters, orderBy } = query

        const request: IReadMultipleOptions = {
            userId, portalId: siteId, tableId: entity, fieldList, filters,
            pageNo: 0,
            recordLimit: 1,
            orderBy
        }

        setData({ query: query, type: "Static", isLoading: true, isSuccess: false, isError: false })
        readRecords(request, true)
            .unwrap()
            .then(data => {
                const records = data?.records || [];
                const record = Array.isArray(records) ? (records[0] || undefined) : records;
                setData({ query, type: "Card", data: record, isLoading: false, isSuccess: true, isError: false })
            })
            .catch(err => {
                const error = err.data?.error?.message || 'Failed to get data'
                setData({ query, type: "Static", isLoading: false, isSuccess: false, isError: true, error })
            })
    }

    const getMultipleRecords = (query: IQueryMultiple) => {
        // Site
        if (!siteId) return;

        // User
        if (!user) return;
        const { userId } = user;
        if (!userId) return;

        // Data Source
        if (!dataSource) return;
        const { entity } = dataSource;
        if (!entity) return;

        setMethod('getMultipleRecords')
        const { fields: fieldList, filters, pageNo, pageSize, orderBy } = query

        const request: IReadMultipleOptions = {
            userId, portalId: siteId, tableId: entity, fieldList, filters,
            pageNo: pageNo || 0,
            recordLimit: pageSize,
            orderBy
        }

        setData({ query: query, type: "Static", isLoading: true, isSuccess: false, isError: false })
        readRecords(request, true)
            .unwrap()
            .then(data => {
                setData({ query: query, type: "List", data, isLoading: false, isSuccess: true, isError: false })
            })
            .catch(err => {
                const error = (err as any)?.data?.error?.message || `Failed to load page data`
                setData({ query: query, type: "Static", isLoading: false, isSuccess: false, isError: true, error })
            })
    }

    return (
        <context.Provider value={{
            ...data,
            setData,
            dataSource,
            setDataSource,
            getRecord,
            getMultipleRecords,
            getFirstRecord,
            refetch,
        }}>
            {children}
        </context.Provider>
    )
}

export function useDataContext() {
    return useContext(context)
}
