import { MUIIcon } from "../../MUIIcon"
import clsx from "clsx"

export const PanelTitle = ({ header, title, icon, headerClass, className }: {
    header?: string,
    title?: string,
    icon?: string,
    headerClass?: string,
    className?: string,
}) => {
    if (!header) return <></>
    
    return (
        <div className={clsx(className)} title={title}>
            <h6 className={clsx(headerClass, "my-2")}>
                {icon && <MUIIcon iconName={icon} className="me-1" />}
                {header}
            </h6>
        </div>
    )
}

