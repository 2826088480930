import { CardPage, ListPage, StaticPage } from "./components";
import { IPage, IPageView, PageType } from "../models";
import { PageContextProducer, PageContextProvider } from "../context";
import { getFilterGroups, getQueryFields } from "../../helpers";
import { useEffect, useState } from "react";

import { DataContextProducer } from "../context/DataContextProducer";
import { DataContextProvider } from "../context/DataContextProvider";
import { DesignButton } from "./components/DesignButton";
import { IFilterGroup } from "../../../data";
import { PageMode } from "../../../base/types";

interface IDataContextProps {
    fields: string[];
    filters: IFilterGroup[];
    produce: "RecordSet" | "Record";
}

export const PageViewer = ({ page, pageView, children }: {
    page: IPage,
    pageView: IPageView,
    children?: React.ReactNode
}) => {
    const [inputs, setInputs] = useState<IDataContextProps>()

    const getInputs = () => {
        const fields = getQueryFields(page.controls || [], page.actions || [], page.filters || []);
        const filters = getFilterGroups({ page, pageView })
        const produce: "RecordSet" | "Record" = (page.type === PageType.List || page.type === PageType.ListPart) ? "RecordSet" : "Record"
        return { fields, filters, produce }
    }

    useEffect(() => {
        const inputs = getInputs()
        setInputs(inputs)
        // eslint-disable-next-line
    }, [page, pageView])

    const getPage = () => {
        switch (page.type) {
            case PageType.Card:
            case PageType.CardPart:
                return <CardPage page={page} />;
            case PageType.List:
            case PageType.ListPart:
                return <ListPage page={page} />;
            default:
                if (page.sourceObject)
                    return <CardPage page={page} />;
                return <StaticPage page={page} />;
        }
    }

    return (
        <PageContextProvider>
            <PageContextProducer page={page} pageView={pageView} />
            <DataContextProvider>
                {(inputs) && <>
                    <DataContextProducer
                        produce={inputs.produce}
                        newRecord={pageView?.pageMode === PageMode.New}
                        entity={page.sourceObject}
                        systemId={pageView?.systemId}
                        fields={inputs.fields}
                        filters={inputs.filters}
                        orderBy={page.orderBy}
                        pageSize={page.recordLimit} />
                </>}
                {children}
                <div className="position-relative">
                    <DesignButton pageId={page.id} />
                    <div>
                        {getPage()}
                    </div>
                </div>
            </DataContextProvider>
        </PageContextProvider>
    )
}


