import { AggregateMethod, BadgeStyle, ButtonStyle, ControlSize, DisplayFormat, PageMode, Placement, PropertyType } from "../types";

import { Binding } from "../bindings";
import { IProperty } from "../models";

export const getDefaultConstPropertyValue = (property: IProperty): Binding => {
    switch (property.type) {
        case PropertyType.PageLink:
            return {
                propId: property.id,
                propType: PropertyType.PageLink,
                type: "Const",
                value: { pageId: '', pageMode: PageMode.View, filters: [] }
            };
        case PropertyType.GridOptions:
            return {
                propId: property.id,
                propType: PropertyType.GridOptions,
                type: "Const",
                value: { rowHeight: 24, columns: [] }
            };
        case PropertyType.DataLink:
            return {
                propId: property.id,
                propType: PropertyType.DataLink,
                type: "Const",
                value: { entity: '', systemId: '', filters: [] }
            };
        case PropertyType.Dropdown:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: []
            };
        case PropertyType.TableLookup:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: { tableId: '', idField: '', displayField: '', filters: [] }
            };
        case PropertyType.DisplayFormat:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: DisplayFormat.Normal
            };
        case PropertyType.Enumeration:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: {
                    type: "string",
                    items: []
                }
            }
        case PropertyType.String:
        case PropertyType.Entity:
        case PropertyType.MaterialIcon:
        case PropertyType.Action:
        case PropertyType.Page:
        case PropertyType.Media:
        case PropertyType.MediaSet:
        case PropertyType.Url:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: ''
            };
        case PropertyType.Number:
        case PropertyType.Integer:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: 0
            };
        case PropertyType.Date:
        case PropertyType.DateTime:
        case PropertyType.Time:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: ''
            };
        case PropertyType.Boolean:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: false
            };
        case PropertyType.BadgeStyle:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: BadgeStyle.None
            };
        case PropertyType.Placement:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: Placement.Start
            };
        case PropertyType.ButtonStyle:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: ButtonStyle.None
            };
        case PropertyType.ControlSize:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: ControlSize.Default
            };
        case PropertyType.AggregateMethod:
            return {
                propId: property.id,
                propType: property.type,
                type: "Const",
                value: AggregateMethod.Sum
            };
        default:
            throw new Error(`Property type ${property.type} not implemented.`);
    }
}

export const getDefaultFieldPropertyValue = (property: IProperty): Binding => {
    switch (property.type) {
        case PropertyType.String:
        case PropertyType.Number:
        case PropertyType.Integer:
        case PropertyType.Boolean:
        case PropertyType.Date:
        case PropertyType.DateTime:
        case PropertyType.Time:
        case PropertyType.Media:
        case PropertyType.MediaSet:
            return {
                propId: property.id,
                propType: property.type,
                type: "Field",
                value: ''
            };
        default:
            throw new Error(`Property type ${property.type} not implemented.`);
    }
}

export const getDefaultExprPropertyValue = (property: IProperty): Binding => {
    switch (property.type) {
        case PropertyType.String:
            return {
                propId: property.id,
                propType: property.type,
                type: "Expression",
                value: { type: "String", query: '', parameters: [] }
            };
        case PropertyType.Number:
        case PropertyType.Integer:
            return {
                propId: property.id,
                propType: property.type,
                type: "Expression",
                value: { type: "Number", query: '', parameters: [] }
            };
        case PropertyType.Boolean:
            return {
                propId: property.id,
                propType: property.type,
                type: "Expression",
                value: { type: "Boolean", query: '', parameters: [] }
            };
        default:
            throw new Error(`Property type ${property.type} not implemented.`);
    }
}
