import { AsideDefault, Content, Footer, HeaderWrapper, ScrollTop } from './components'

import { MenuComponent } from '../_metronic/assets/ts/components'
import { NotificationsProvider } from '../framework/layout/push-notifications/NotificationsProvider'
import { Outlet } from 'react-router-dom'
import { PageDataProvider } from './core'
import { ThemeModeProvider, } from './partials'
import { ToasterProvider } from '../framework/layout/toast'
import { Toolbar } from './components/header/Toolbar'
import { ToolbarProvider } from '../framework/layout/toolbar'
import { useEffect } from 'react'

const MasterLayout = () => {
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  return (
    <ToasterProvider>
      <NotificationsProvider>
        <PageDataProvider>
          <ThemeModeProvider>
            <ToolbarProvider>
              <div className='page d-flex flex-row flex-column-fluid'>
                <AsideDefault />
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                  <HeaderWrapper />
                  <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                    <Toolbar />
                    <div className='post d-flex flex-column-fluid position-relative' id='kt_post'>
                      <Content>
                        <Outlet />
                      </Content>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
            </ToolbarProvider>
            {/* end:: Modals */}
            <ScrollTop />
          </ThemeModeProvider>
        </PageDataProvider>
      </NotificationsProvider>
    </ToasterProvider>
  )
}

export { MasterLayout }
