import { AsideMenuItem } from "../../../layout/components/aside/AsideMenuItem";
import { AsideMenuItemWithSub } from "../../../layout/components/aside/AsideMenuItemWithSub";
import { IPageAction } from "../../design/page-action/models";
import { IPageLink } from "../../base/models";
import { Property } from "../../base/types";
import React from "react";
import { getPageLinkUrl } from "../../base/helpers";
import { useAuth } from "../../../modules/auth";
import { useGetProperties } from "../../base/hooks";
import { useProfilePage } from "../../providers";

export const AsideMenuActions = () => {
    const { page, isLoading } = useProfilePage();

    if (isLoading) {
        return (
            <div className="d-flex text-light align-items-center justify-content-center m-10">
                <span className='indicator-progress' style={{ display: 'block' }}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            </div>
        )
    }

    return <>
        {page && page.actions?.map(action => <React.Fragment key={action.name}>
            <PageMenuAction
                className={'fs-7'}
                action={action} />
        </React.Fragment>)}
    </>
}

const PageMenuAction = ({ action, className, hasBullet = false }: {
    action: IPageAction,
    className?: string,
    hasBullet?: boolean
}) => {
    const { siteId } = useAuth()
    const { properties } = useGetProperties({ bindings: action.bindings });
    const pageLink: IPageLink | undefined = properties[Property.PageLink];

    if (!siteId || !pageLink) return <></>
    const pageUrl = getPageLinkUrl(siteId, pageLink);

    switch (action.type) {
        case "Action Group":
            return (
                <AsideMenuItemWithSub
                    to=''
                    className={className}
                    title={action.name}
                    fontIcon='bi-chat-left'
                    materialIcon={action.icon}>
                    {action?.actions && action?.actions.map(action => <React.Fragment key={action.name}>
                        <PageMenuAction
                            hasBullet={true}
                            className={'fs-8 py-1'}
                            action={action} />
                    </React.Fragment>)}
                </AsideMenuItemWithSub>
            )
        default:
            return (
                <AsideMenuItem
                    hasBullet={hasBullet}
                    className={className}
                    to={pageUrl}
                    materialIcon={action.icon}
                    title={action.name}
                    fontIcon='bi-app-indicator'
                />)
    }
}
