import { rootApi } from "../../../../services/rootApi";

export interface IOtpRequest {
    identifier: string
}

export interface IOtpVerify {
    identifier: string,
    otp: string,
}

export interface IOtpStatus {
    verified: boolean
}

const otpApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        sendEmailOTP: builder.mutation<void, IOtpRequest>({
            query: (request) => ({
                url: "/OTP/email",
                method: "POST",
                body: request
            })
        }),

        sendMobileOTP: builder.mutation<void, IOtpRequest>({
            query: (request) => ({
                url: "/OTP/mobile",
                method: "POST",
                body: request
            })
        }),

        verifyOTP: builder.mutation<IOtpStatus, IOtpVerify>({
            query: (request) => ({
                url: `/OTP/verify`,
                method: "POST",
                body: request
            })
        }),
    })
})

export const {
    useSendEmailOTPMutation,
    useSendMobileOTPMutation,
    useVerifyOTPMutation,
} = otpApi;