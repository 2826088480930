import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { InteractionStatus } from "@azure/msal-browser";
import { LoginRequest } from "./authConfig";
import { useAuth } from "../core/Auth";
import { useState } from "react";

export const useMsalSignOut = () => {
    const isAuthenticated = useIsAuthenticated()
    const { instance, accounts, inProgress } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);
    const { logout } = useAuth();

    const signOut = () => {
        if (!isAuthenticated || !accounts || accounts.length === 0) {
            logout()
            return Promise.resolve();
        }

        const account = accounts[0];
        setLoading(true)
        return instance.logout({ ...LoginRequest, account })
            .then(() => {
                logout()
            })
            .finally(() => setLoading(false))
    }

    return { signOut, loading: (loading || inProgress !== InteractionStatus.None) }
} 