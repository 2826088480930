import { AttributeDataType, IAttributeMetadata, IAttributeOption, IServiceAttribute } from "../../models"
import { BooleanAttribute, DateAttribute, LookupAttribute, MultilineTextAttribute, NumberAttribute, OptionSetAttribute, TextAttribute } from "./components"

import { DateTimeAttribute } from "./components/DateTimeAttribute"
import { DocumentChecklistAttribute } from "./DocumentChecklistAttribute"
import { FieldError } from "./components/FieldError"
import { MoneyAttribute } from "./components/MoneyAttribute"
import { OtpVerifiedEMail } from "../../../otp/OtpVerifiedEMail"
import { OtpVerifiedMobile } from "../../../otp/OtpVerifiedMobile"
import { ReadOnlyAttribute } from "./ReadOnlyAttribute"
import clsx from "clsx"

export const ServiceAttribute = ({
    metadata,
    attribute,
    entity,
    entityId,
    checklistId,
    relativePath,
    className,
    value,
    readOnly,
    error,
    touched,
    options,
    onChange,
    onBlur,
}: {
    metadata?: IAttributeMetadata,
    attribute: IServiceAttribute,
    checklistId?: string,
    entity?: string,
    entityId?: string,
    relativePath?: string
    className?: string,
    value?: string,
    readOnly?: boolean,
    touched: boolean,
    error?: string,
    options?: IAttributeOption[],
    onChange: (value: any) => void,
    onBlur: () => void
}) => {
    if (!metadata) return <></>

    const labelClass = clsx("form-label fw-semibold fs-7 mb-1", { required: attribute.required })
    const inputClass = ""

    const getAttributeControl = () => {
        switch (metadata.datatype) {
            case AttributeDataType.Text:
                return <TextAttribute
                    value={value}
                    readOnly={readOnly}
                    className={inputClass}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={attribute.required} />
            case AttributeDataType.MultilineText:
                return <MultilineTextAttribute
                    value={value}
                    readOnly={readOnly}
                    className={inputClass}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={attribute.required} />
            case AttributeDataType.EMail:
                if (attribute.isVerificationRequired) {
                    return <OtpVerifiedEMail
                        fieldId={attribute.attributeId}
                        verifiedFieldId={`${attribute.attributeId}_verified`}
                    />
                } else {
                    return <TextAttribute
                        value={value}
                        readOnly={readOnly}
                        className={inputClass}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={attribute.required} />
                }
            case AttributeDataType.MobileNo:
                if (attribute.isVerificationRequired) {
                    return <OtpVerifiedMobile
                        fieldId={attribute.attributeId}
                        verifiedFieldId={`${attribute.attributeId}_verified`}
                    />
                } else {
                    return <TextAttribute
                        value={value}
                        readOnly={readOnly}
                        className={inputClass}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={attribute.required} />
                }
            case AttributeDataType.Date:
                return <DateAttribute
                    value={value}
                    readOnly={readOnly}
                    className={inputClass}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={attribute.required} />
            case AttributeDataType.DateTime:
                return <DateTimeAttribute
                    value={value}
                    readOnly={readOnly}
                    className={inputClass}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={attribute.required} />
            case AttributeDataType.Number:
                return <NumberAttribute
                    value={value}
                    readOnly={readOnly}
                    className={inputClass}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={attribute.required} />
            case AttributeDataType.Money:
                return <MoneyAttribute
                    attributeId={metadata.id}
                    value={value}
                    readOnly={readOnly}
                    className={inputClass}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={attribute.required} />
            case AttributeDataType.Boolean:
                return <BooleanAttribute
                    label={metadata.label}
                    attributeId={metadata.id}
                    value={Boolean(value || false)}
                    readOnly={readOnly}
                    className={inputClass}
                    labelClass={labelClass}
                    onChange={onChange}
                    onBlur={onBlur} />
            case AttributeDataType.Lookup:
                return <LookupAttribute
                    attributeId={metadata.id}
                    value={value}
                    readOnly={readOnly}
                    className={inputClass}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={attribute.required} />
            case AttributeDataType.OptionSet:
                return <OptionSetAttribute
                    value={value}
                    readOnly={readOnly}
                    className={inputClass}
                    options={options}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={attribute.required} />
            case AttributeDataType.DocumentChecklist:
                return <DocumentChecklistAttribute
                    checklistId={checklistId}
                    attributeId={metadata.id}
                    entity={entity}
                    entityId={entityId}
                    relativePath={relativePath}
                    readOnly={readOnly}
                    className={inputClass} />
            case AttributeDataType.RichText:
                return <>
                    {(metadata.richText) && <>
                        <div className="my-2">
                            <div dangerouslySetInnerHTML={{ __html: metadata.richText || '' }} />
                        </div>
                    </>}
                </>
            default:
                return <span>DataType: {metadata.datatype} not implemented</span>
        }
    }

    const getAttributeLabel = () => {
        switch (metadata.datatype) {
            case AttributeDataType.Boolean:
            case AttributeDataType.DocumentChecklist:
            case AttributeDataType.RichText:
                return <></>
            default:
                return <label className={labelClass}>
                    {metadata.label}
                </label>
        }
    }

    const fullWidth = metadata.datatype ===
        AttributeDataType.DocumentChecklist ||
        metadata.datatype === AttributeDataType.Money ||
        metadata.datatype === AttributeDataType.RichText ||
        metadata.datatype === AttributeDataType.MultilineText ||
        ((metadata.datatype === AttributeDataType.EMail || metadata.datatype === AttributeDataType.MobileNo)
            && attribute.isVerificationRequired === true);

    const groupClass = clsx(
        { "col-lg-6 col-md-6 col-sm-12": !fullWidth },
        { "col-lg-12": fullWidth })

    if (readOnly) {
        return <ReadOnlyAttribute
            metadata={metadata}
            attribute={attribute}
            entity={entity}
            entityId={entityId}
            checklistId={checklistId}
            relativePath={relativePath}
            className={className}
            documentsOnly={false}
            value={value}
            options={options} />
    }

    return <div className={clsx(className, groupClass)}>
        {getAttributeLabel()}
        {getAttributeControl()}
        <FieldError show={touched && !!error} message={error} />
    </div>
}