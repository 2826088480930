import { AlertMessage, Spinner } from "../../../../widgets"

import { ChecklistItems } from "./ChecklistItems"
import { IEntityDocumentChecklist } from "../models"
import clsx from "clsx"
import { useGetDocumentChecklist } from "../hooks"

export const EntityDocumentChecklist = ({ entityDocumentChecklist, relativePath, className }: {
    entityDocumentChecklist: IEntityDocumentChecklist,
    relativePath: string,
    className?: string,
}) => {
    const { data, isLoading, isError, error } = useGetDocumentChecklist(entityDocumentChecklist.documentChecklistId)

    return (
        <div className={clsx(className)}>
            <AlertMessage show={isError} type="Error" message={error} />
            {data && <>
                <h6>{data.description} <Spinner show={isLoading} /></h6>
                <ChecklistItems
                    entityDocumentChecklist={entityDocumentChecklist}
                    relativePath={relativePath} />
            </>}
        </div>
    )
}