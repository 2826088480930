import { BackgroundColor, HoverBackgroundColor, IBackground, Opacity } from "../models";

import clsx from "clsx";

export const backgroundClasses = (background?: IBackground) => {
    let classes = "";

    if (background) {
        classes = clsx(classes, backgroundColorClass(background.color));
        classes = clsx(classes, hoverBGColorClass(background.hoverColor));
        classes = clsx(classes, gradientClass(background.gradient));
        classes = clsx(classes, opacityClass(background.opacity));
        classes = clsx(classes, hoverOpacityClass(background.hoverOpacity));
    }

    return classes;
}

const hoverOpacityClass = (opacity?: Opacity) => {
    switch (opacity) {
        case Opacity.Opacity100: return 'bg-hover-opacity-100';
        case Opacity.Opacity75: return 'bg-hover-opacity-75';
        case Opacity.Opacity50: return 'bg-hover-opacity-50';
        case Opacity.Opacity25: return 'bg-hover-opacity-25';
        case Opacity.Opacity20: return 'bg-hover-opacity-20';
        case Opacity.Opacity15: return 'bg-hover-opacity-15';
        case Opacity.Opacity10: return 'bg-hover-opacity-10';
        case Opacity.Opacity5: return 'bg-hover-opacity-5';
        case Opacity.Opacity0: return 'bg-hover-opacity-0';
    }
}

const opacityClass = (opacity?: Opacity) => {
    switch (opacity) {
        case Opacity.Opacity100: return 'bg-opacity-100';
        case Opacity.Opacity75: return 'bg-opacity-75';
        case Opacity.Opacity50: return 'bg-opacity-50';
        case Opacity.Opacity25: return 'bg-opacity-25';
        case Opacity.Opacity20: return 'bg-opacity-20';
        case Opacity.Opacity15: return 'bg-opacity-15';
        case Opacity.Opacity10: return 'bg-opacity-10';
        case Opacity.Opacity5: return 'bg-opacity-5';
        case Opacity.Opacity0: return 'bg-opacity-0';
    }
}

const gradientClass = (gradient?: boolean) => {
    return gradient === true ? 'bg-gradient' : '';
}

const backgroundColorClass = (color?: BackgroundColor, prefix?: string) => {
    switch (color) {
        case BackgroundColor.Primary: return 'bg-primary';
        case BackgroundColor.Secondary: return 'bg-secondary';
        case BackgroundColor.Success: return 'bg-success';
        case BackgroundColor.Danger: return 'bg-danger';
        case BackgroundColor.Warning: return 'bg-warning';
        case BackgroundColor.Info: return 'bg-info';
        case BackgroundColor.Light: return 'bg-light';
        case BackgroundColor.Dark: return 'bg-dark';
        case BackgroundColor.Body: return 'bg-body';
        case BackgroundColor.White: return 'bg-white';
        case BackgroundColor.Transparent: return 'bg-transparent';
        case BackgroundColor.Gray100: return 'bg-gray-100';
        case BackgroundColor.Gray200: return 'bg-gray-200';
        case BackgroundColor.Gray300: return 'bg-gray-300';
        case BackgroundColor.Gray400: return 'bg-gray-400';
        case BackgroundColor.Gray500: return 'bg-gray-500';
        case BackgroundColor.Gray600: return 'bg-gray-600';
        case BackgroundColor.Gray700: return 'bg-gray-700';
        case BackgroundColor.Gray800: return 'bg-gray-800';
        case BackgroundColor.Gray900: return 'bg-gray-900';

        case BackgroundColor.LightPrimary: return 'bg-light-primary';
        case BackgroundColor.LightSuccess: return 'bg-light-success';
        case BackgroundColor.LightInfo: return 'bg-light-info';
        case BackgroundColor.LightWarning: return 'bg-light-warning';
        case BackgroundColor.LightDanger: return 'bg-light-danger';
        case BackgroundColor.LightDark: return 'bg-light-dark';
    }
}

const hoverBGColorClass = (color?: HoverBackgroundColor) => {
    switch (color) {
        case HoverBackgroundColor.Primary: return 'bg-hover-primary';
        case HoverBackgroundColor.Secondary: return 'bg-hover-secondary';
        case HoverBackgroundColor.Success: return 'bg-hover-success';
        case HoverBackgroundColor.Danger: return 'bg-hover-danger';
        case HoverBackgroundColor.Warning: return 'bg-hover-warning';
        case HoverBackgroundColor.Info: return 'bg-hover-info';
        case HoverBackgroundColor.Light: return 'bg-hover-light';
        case HoverBackgroundColor.Dark: return 'bg-hover-dark';
        case HoverBackgroundColor.White: return 'bg-hover-white';

        case HoverBackgroundColor.LightPrimary: return 'bg-hover-light-primary';
        case HoverBackgroundColor.LightSuccess: return 'bg-hover-light-success';
        case HoverBackgroundColor.LightInfo: return 'bg-hover-light-info';
        case HoverBackgroundColor.LightWarning: return 'bg-hover-light-warning';
        case HoverBackgroundColor.LightDanger: return 'bg-hover-light-danger';
        case HoverBackgroundColor.LightDark: return 'bg-hover-light-dark';
    }
}
