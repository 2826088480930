import { ApiTags, rootApi } from '../../rootApi';

export interface IEntityLookupData {
    id: string,
    title: string,
}

const TAG = ApiTags.LookupData;
const getTags = (entities: IEntityLookupData[]) => [...entities.map((p) => ({ type: TAG, id: p.id })), TAG]
const entityLookupDataApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getLookupData: builder.query<IEntityLookupData[], string>({
            query: (entityId) => `/EntityLookupData/${entityId}`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),
    })
})

export const {
    useGetLookupDataQuery,
    useLazyGetLookupDataQuery
} = entityLookupDataApi;