import { AlertMessage, IAcceptFiles, MUIIcon, ProgressIndicator, Spinner, UploadDocumentButton } from "../../../widgets"
import { FileType, IDocumentCategory, IDocumentChecklistItem, IEntityChecklistItem } from "./models"
import React, { useCallback } from "react"
import { useCreateEntityChecklistItemMutation, useDeleteEntityChecklistItemMutation, useLazyGetEntityChecklistItemsQuery, useUpdateEntityChecklistItemMutation } from "./services/entityChecklistItemsApi"

import { DocumentImage } from "../sharepoint-documents/components/DocumentImage"
import { IEntity } from "../../../base/types"
import clsx from "clsx"
import { useEffect } from "react"
import { useFormikContext } from "formik"
import { useGetDocumentChecklistQuery } from "./services/documentChecklistApi"
import { useDeleteSPFileMutation, useUploadDocument } from "../../../services/data/sharepoint"

export const DocumentChecklist = ({ entity, entityId, checklistId, relativePath, readonly, fieldId, className }: {
    fieldId?: string,
    entity?: string,
    entityId?: string,
    checklistId: string,
    relativePath?: string,
    readonly?: boolean,
    className?: string,
}) => {
    const { data: checklistMetadata, isLoading, isError } = useGetDocumentChecklistQuery(checklistId)
    const [getEntityChecklistItems, {
        data: entityChecklistItems,
        isLoading: isEntityLoading,
        isError: isEntityError
    }] = useLazyGetEntityChecklistItemsQuery();
    const { uploadDocument } = useUploadDocument()
    const [createRecord] = useCreateEntityChecklistItemMutation()
    const { errors, setFieldValue } = useFormikContext<IEntity>()

    useEffect(() => {
        if (!fieldId) return;

        const fieldError = isChecklistValid()
        if (fieldError) {
            setFieldValue(fieldId, false)
        } else {
            setFieldValue(fieldId, true)
        }
        // eslint-disable-next-line
    }, [checklistMetadata, entityChecklistItems])

    useEffect(() => {
        if (!entity || !entityId)
            return;

        getEntityChecklistItems({ entity, entityId });
        // eslint-disable-next-line
    }, [entity, entityId])

    const isChecklistValid = useCallback(() => {
        if (!checklistMetadata || !entityChecklistItems)
            return;

        var requiredDocs = checklistMetadata.checklistItems?.filter(p => p.required === true)
        if (!requiredDocs || requiredDocs.length === 0)
            return;

        const errors: string[] = []
        for (let i = 0; i < requiredDocs.length; i++) {
            const requiredDoc = requiredDocs[i]

            const checklistItem = entityChecklistItems
                .find(
                    p => p.documentCategoryId === requiredDoc.documentCategoryId &&
                        p.documentUrl && p.documentUrl.length > 0)

            if (!checklistItem) {
                errors.push(`"${requiredDoc.description}"`)
            }
        }

        if (errors.length > 0)
            return "You need to upload all the required documents.";

        // eslint-disable-next-line
    }, [checklistMetadata, entityChecklistItems])

    const getDocumentCategory = (categoryId: string) => {
        return checklistMetadata?.categories?.find(p => p.id === categoryId)!
    }

    const getEntityChecklistItem = (categoryId: string) => {
        return entityChecklistItems?.find(p => p.documentCategoryId === categoryId)!
    }

    const handleUpload = (category: IDocumentCategory, file: File, documentTypeId: string): Promise<void> => {
        if (!relativePath)
            return Promise.reject('Cant upload this document.');

        return uploadDocument({ file, folderRelativeUrl: relativePath })
            .then((document) => {
                return createRecord({
                    documentCategoryId: category.id,
                    documentChecklistId: checklistId,
                    entityType: entity,
                    entityId,
                    documentUrl: document.serverAbsoluteUrl || document.serverRelativeUrl,
                    description: category.description,
                })
                    .then(() => Promise.resolve())
                    .catch(() => Promise.reject('Cant upload this document.'))
            })
            .catch(error => {
                console.error(error)
                return Promise.reject(error)
            })
    }

    return <div className={clsx(className)}>
        <ProgressIndicator show={isLoading || isEntityLoading} message="Loading the checklist documents ..." />
        <AlertMessage show={isError || isEntityError} message={'Could not load the document checklist'} />

        {(checklistMetadata?.description) && <>
            <h4 className={clsx("fs-7 my-3")}>
                <MUIIcon iconName="FolderOutlined" className="me-2" />
                {checklistMetadata?.description}
            </h4>
        </>}

        {checklistMetadata?.checklistItems?.map(checklistItem => <React.Fragment key={checklistItem.id}>
            <DocumentChecklistItem
                className="mb-2"
                readonly={readonly}
                checklistItem={checklistItem}
                relativePath={relativePath}
                entity={entity}
                entityId={entityId}
                category={getDocumentCategory(checklistItem.documentCategoryId)}
                entityChecklistItem={getEntityChecklistItem(checklistItem.documentCategoryId)}
                onUpload={handleUpload}
            />
        </React.Fragment>)}

        {(fieldId && errors[fieldId]) && <>
            <AlertMessage
                show={true}
                className="fs-8 mt-5"
                type="Error" message={errors[fieldId] as string} />
        </>}
    </div>
}

const DocumentChecklistItem = ({ checklistItem, entityChecklistItem, category, entity, entityId, relativePath, readonly, className, onUpload }: {
    checklistItem: IDocumentChecklistItem,
    entityChecklistItem?: IEntityChecklistItem,
    category: IDocumentCategory,
    relativePath?: string,
    entity?: string,
    entityId?: string,
    readonly?: boolean,
    className?: string,
    onUpload?: (category: IDocumentCategory, file: File, documentTypeId: string) => Promise<void>
}) => {
    return <div className={clsx(className)}>
        {(entityChecklistItem) ? <>
            <label className={clsx("form-label fs-7", { "required": checklistItem.required === true })}>
                {category.description}
            </label>
            {(entityChecklistItem.rejected) && <>
                <div>
                    <span className="fw-semibold ms-1 badge badge-danger">Rejected</span>
                    <label className={clsx("form-label fs-8 ms-2")}>
                        <span className="fw-semibold me-1 text-muted">Reason: </span>
                        <span className="text-danger fst-italic">{entityChecklistItem.rejectedReason}</span>
                    </label>
                </div>
            </>}

            <EntityChecklistItem
                readonly={readonly && !entityChecklistItem.rejected}
                category={category}
                entity={entity}
                entityId={entityId}
                relativePath={relativePath}
                checklistItem={checklistItem}
                entityChecklistItem={entityChecklistItem} />
        </> : <>
            {(!readonly || checklistItem.required) && <>
                <label className={clsx("form-label fs-7", { "required": checklistItem.required === true })}>
                    {category.description}
                </label>
                <DocumentTypes category={category} onUpload={onUpload} />
            </>}
        </>}
    </div>
}

const DocumentTypes = ({ category, className, onUpload }: {
    category: IDocumentCategory,
    className?: string,
    onUpload?: (category: IDocumentCategory, file: File, documentTypeId: string) => Promise<void>
}) => {
    const handleUpload = (file: File, documentTypeId: string): Promise<void> => {
        if (onUpload)
            return onUpload(category, file, documentTypeId)

        return Promise.reject();
    }

    const getAcceptFiles = () => {
        if (!category.allowedFileTypes || category.allowedFileTypes.length === 0)
            return;

        const accept: IAcceptFiles = {};
        category.allowedFileTypes.forEach(fileType => {
            switch (fileType.fileType) {
                case FileType.PDF:
                    accept['application/pdf'] = ['.pdf'];
                    break;
                case FileType.JPEG:
                case FileType.JPG:
                    accept['image/jpeg'] = ['.jpeg', 'jpg'];
                    break;
                case FileType.PNG:
                    accept['image/png'] = ['.png'];
                    break;
                case FileType.BMP:
                    accept['image/bmp'] = ['.bmp'];
                    break;
                case FileType.WORD:
                    accept['application/msword'] = ['.doc'];
                    accept['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = ['.docx'];
                    break;
            }
        })

        return accept;
    }

    return (
        <div className={clsx(className)}>
            {category.documentTypes && <>
                {category.documentTypes.length > 1 && <div className="text-muted fs-8 fst-italic mb-1">
                    <span>Click any one of the below documents.</span>
                </div>}
                <div className="d-flex flex-row flex-wrap" style={{ gap: "1rem" }}>
                    {category.documentTypes.map(item => <React.Fragment key={item.id}>
                        <UploadDocumentButton
                            title={item.description}
                            accept={getAcceptFiles()}
                            subtitle={`Click here to upload ${item.description}`}
                            className="bg-hover-secondary text-hover-primary min-w-300px"
                            onUpload={(file) => handleUpload(file, item.id)} />
                    </React.Fragment>)}
                </div>
            </>}
        </div>
    )
}

const EntityChecklistItem = ({ entityChecklistItem, checklistItem, category, relativePath, entity, entityId, readonly, className }: {
    category: IDocumentCategory,
    entityChecklistItem: IEntityChecklistItem,
    checklistItem: IDocumentChecklistItem,
    entity?: string,
    entityId?: string,
    readonly?: boolean,
    relativePath?: string,
    className?: string,
}) => {
    const [deleteRecord, { isLoading }] = useDeleteEntityChecklistItemMutation();
    const [updateRecord, { isLoading: isUpdating }] = useUpdateEntityChecklistItemMutation()
    const [deleteSPFile, { isLoading: isDeleting }] = useDeleteSPFileMutation()
    const { uploadDocument } = useUploadDocument()

    const handleUpload = (category: IDocumentCategory, file: File, documentTypeId: string): Promise<void> => {
        if (!relativePath)
            return Promise.reject('Cant upload this document.');

        return uploadDocument({ file, folderRelativeUrl: relativePath })
            .then((document) => {
                let reason = entityChecklistItem.rejectedReason;
                if (entityChecklistItem.rejected) reason = 'Resubmitted';

                return updateRecord({
                    id: entityChecklistItem.id,
                    entity: {
                        ...entityChecklistItem,
                        entityType: entity,
                        entityId,
                        documentCategoryId: category.id,
                        description: category.description,
                        rejectedReason: reason,
                        documentUrl: document.serverAbsoluteUrl || document.serverRelativeUrl,
                    }
                })
                    .then(() => Promise.resolve())
                    .catch(() => Promise.reject('Cant upload this document.'))
            })
            .catch(error => {
                console.error(error)
                return Promise.reject(error)
            })
    }

    const onClickDelete = () => {
        deleteSPFile(entityChecklistItem.documentUrl)
            .unwrap()
            .finally(() => {
                if (entityChecklistItem.rejected) {
                    updateRecord({
                        id: entityChecklistItem.id,
                        entity: {
                            ...entityChecklistItem,
                            entityType: entity,
                            entityId,
                            documentCategoryId: category.id,
                            description: category.description,
                            documentUrl: ''
                        }
                    })
                        .unwrap();
                } else {
                    deleteRecord(entityChecklistItem.id)
                        .unwrap();
                }
            })
    }

    const getFileName = (url: string) => {
        return (url || '').split('/').pop()
    }

    const fileUrl = (documentUrl: string) => {
        documentUrl = documentUrl.toLowerCase()
        if (documentUrl.startsWith("https://")) {
            const url = new URL(documentUrl)
            documentUrl = `${url.pathname}${url.search}`;
        }

        return `/api/documents/document?url=${encodeURIComponent(documentUrl)}`
    }

    if (!entityChecklistItem.documentUrl) {
        return <>
            <DocumentTypes category={category} onUpload={handleUpload} />
        </>
    }

    return (
        <>
            <div className={clsx(className,
                'border border-secondary rounded bg-body')}>
                <div className={clsx(
                    'd-flex align-items-center')
                }>
                    <div className='d-flex align-items-center align-self-stretch px-5 bg-light rounded-start'>
                        <span className='symbol symbol-35px'>
                            <DocumentImage fileName={entityChecklistItem.documentUrl} />
                        </span>
                    </div>
                    <div className='d-flex flex-fill flex-row flex-wrap px-5 p-3'>
                        <div>
                            <h6 className='fw-bold text-gray-800 fs-7 mb-2'>{category.description}</h6>
                            <div className="d-flex flex-fill flex-row align-items-center" style={{ gap: '1rem' }}>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={fileUrl(entityChecklistItem.documentUrl)}
                                    className="btn btn-link text-hover-info text-primary p-0 fs-8 fw-bold">
                                    <MUIIcon className="fs-6 me-1" iconName="CloudDownloadOutlined" />
                                    {`${getFileName(entityChecklistItem.documentUrl)}`}
                                </a>

                                {(!readonly || entityChecklistItem.rejected === true) && <>
                                    <button
                                        disabled={isLoading || isUpdating || isDeleting}
                                        className='btn btn-link text-danger text-hover-primary p-0 fs-8 fw-bold'
                                        title="Delete the document"
                                        onClick={onClickDelete}>
                                        <Spinner show={isLoading || isUpdating || isDeleting} />
                                        {(!isLoading) && <MUIIcon
                                            className="fs-6 me-1"
                                            iconName="DeleteOutlineOutlined" />}
                                        Delete
                                    </button>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}