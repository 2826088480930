export interface IService {
    id: string,
    code: string,
    name: string,
    enabled: boolean,
    startDate?: string | Date,
    endDate?: string | Date,
    parent: string,
    section: string,
    programType?: any,
    genderType?: any,
    paidService?: boolean,
    feeComponentId?: string,
    amount?: number,
    children?: IService[],
}

export interface IServiceStage {
    id: string,
    name: string,
    description: string,
    serviceId: string,
    stageNumber: number,
}

export interface IServiceAttribute {
    id: string,
    name: string
    serviceId: string,
    required: boolean,
    serviceStageId: string,
    order: number,
    attributeId: string,
    isVerificationRequired: boolean,
}

export interface IAttributeOption {
    id: string,
    name: string
    value: string,
    attributeId: string,
}

export interface IAttributeMetadata {
    id: string,
    name: string,
    datatype: AttributeDataType,
    label: string,
    entity: string,
    filters: string,
    documentChecklist: string,
    options?: IAttributeOption[]
    richText?: string,
    regExpression?: string,
}

export interface IEntityLookupData {
    id: string,
    name: string,
}

export enum AttributeDataType {
    Text = "365970000",
    Number = "365970001",
    Boolean = "365970002",
    Date = "365970003",
    DateTime = "365970012",
    OptionSet = "365970004",
    Money = "365970005",
    Lookup = "365970006",
    DocumentChecklist = "365970007",
    RichText = "365970008",
    MultilineText = "365970009",
    EMail = "365970010",
    MobileNo = "365970011"
}

export interface IServiceRequest {
    id: string,
    requestNumber?: string,
    name: string,
    serviceId: string,
    requestorId?: string,
    stageId: string,
    approvalStatus: number,
    attributes?: IServiceRequestAttribute[],
    statusCode?: ServiceRequestStatus,
    feeComponentId?: string,
    paymentStage?: ServiceRequestPaymentStage
    userId?: string
}

export enum ServiceRequestPaymentStage {
    Completed = 365970000,
    Pending = 365970001,
    Failed = 365970002,
    OnGoing = 365970003 
}

export enum ServiceRequestStatus {
    InProgress = 1,
    Draft = 365970000,
    Submitted = 365970001,
    Approved = 365970002,
    OnHold = 365970005,
    Rejected = 365970006,
    Cancelled = 365970004,
}

export interface IServiceRequestAttribute {
    id?: string,
    name?: string,
    attributeValue?: string,
    serviceAttributeId?: string,
    serviceRequestId?: string,
}