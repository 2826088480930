import { EntityDocumentChecklistItemMetadata, IEntityChecklistItem_BC } from '../../models';

import { IFieldValue } from '../../../../../services/models';
import { useAuth } from "../../../../../../modules/auth";
import { useCreateMutation } from "../../../../../services/data/tableDataApi";
import { useState } from "react";

// TODO: status fields should be optimized
export const useCreateEntityDocumentChecklistItem = () => {
    const [insertRecord] = useCreateMutation();
    const { user, siteId: portalId } = useAuth()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [data, setData] = useState<IEntityChecklistItem_BC>()

    const createRecord = (checklistItem: Partial<IEntityChecklistItem_BC>): Promise<IEntityChecklistItem_BC> => {
        if (!user)
            return Promise.reject('Invalid user');

        const { userId } = user;
        if (!portalId)
            return Promise.reject('Invalid portal');;

        setIsSuccess(false)
        setIsError(false)
        setError(undefined)
        setIsLoading(true)

        const { tableId, fields } = EntityDocumentChecklistItemMetadata
        const record: IFieldValue[] = []
        if (checklistItem.entityDocumentChecklistId)
            record.push({
                field: fields.entityDocumentChecklistId,
                value: checklistItem.entityDocumentChecklistId,
                validate: true
            })

        if (checklistItem.documentChecklistItemId)
            record.push({
                field: fields.documentChecklistItemId,
                value: checklistItem.documentChecklistItemId,
                validate: true
            })
        if (checklistItem.documentTypeId)
            record.push({
                field: fields.documentTypeId,
                value: checklistItem.documentTypeId,
                validate: true
            })

        if (checklistItem.url)
            record.push({
                field: fields.url,
                value: checklistItem.url,
                validate: true
            })

        return insertRecord({
            portalId, userId, tableId, triggers: true,
            filters: [], data: record,
        }).unwrap()
            .then((p) => {
                const record: IEntityChecklistItem_BC = {
                    id: p[fields.id],
                    entityDocumentChecklistId: p[fields.entityDocumentChecklistId],
                    documentChecklistItemId: p[fields.documentChecklistItemId],
                    documentTypeId: p[fields.documentTypeId],
                    fileType: p[fields.fileType],
                    url: p[fields.url],
                    systemId: p[fields.systemId]
                }
                setData(record)
                setIsSuccess(true)
                return Promise.resolve(record)
            })
            .catch(err => {
                setIsError(true)
                setError(err.data?.error?.message || 'Failed to insert data')
                return Promise.reject(err)
            })
            .finally(() => setIsLoading(false))
    }

    return { data, isLoading, isSuccess, isError, error, createRecord }
}
