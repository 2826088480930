import { ApiResponse, ICreateOptions, IDeleteOptions, IReadMultipleOptions, IReadOptions, IUpdateOptions } from "../models";
import { DataTableTags, rootApi } from "../rootApi";
import { IEntity, IMultipleEntities } from "../../base/types";

const getDataTableTag = (cacheKey: string) => {
  const existingItem = DataTableTags.find(p => p.linkedWith === cacheKey)
  if (existingItem) return existingItem.tag;

  const newItem = DataTableTags.find(p => !p.linkedWith)
  if (newItem) return newItem.tag;

  throw new Error("Tag not found")
}

const tableDataApi = rootApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    read: builder.query<IEntity, IReadOptions>({
      query: (options) => ({
        url: `/${options.portalId}/TableData/Read(${options.tableId})`,
        headers: { 'info-query': JSON.stringify(options) }
      }),
      transformResponse: (res: ApiResponse) => {
        return JSON.parse(res.value) as IEntity;
      },
      providesTags: (result, err, arg) => {
        if (err) return [];

        const cacheKey = `${arg.tableId}-${JSON.stringify(arg.sysId)}` as const
        const type = getDataTableTag(cacheKey)
        return [type]
      }
    }),

    readMultiple: builder.query<IMultipleEntities, IReadMultipleOptions>({
      query: (request) => {
        return {
          url: `/${request.portalId}/TableData/ReadMultiple(${request.tableId})`,
          headers: { 'info-query': JSON.stringify(request) }
        }
      },
      transformResponse: (res: ApiResponse) => {
        return JSON.parse(res.value) as IMultipleEntities;
      },
      providesTags: (result, err, arg) => {
        if (err) return [];

        const cacheKey = `${arg.tableId}-${JSON.stringify(arg)}` as const
        const type = getDataTableTag(cacheKey)
        return [type]
      }
    }),

    create: builder.mutation<IEntity, ICreateOptions>({
      query: (request) => {
        return {
          url: `/${request.portalId}/TableData/Create(${request.tableId})`,
          method: 'POST',
          body: request
        }
      },
      transformResponse: (res: ApiResponse) => {
        return JSON.parse(res.value) as IEntity;
      },

      invalidatesTags: (result, err, arg) => {
        if (err) return [];

        const type = `${arg.tableId}` as const
        return [{ type }, type]
      }
    }),

    update: builder.mutation<IEntity, IUpdateOptions>({
      query: (request) => {
        return {
          url: `/${request.portalId}/TableData/Update(${request.tableId})`,
          method: 'PATCH',
          body: request
        }
      },
      transformResponse: (res: ApiResponse) => {
        return JSON.parse(res.value) as IEntity;
      },
      invalidatesTags: (result, err, arg) => {
        if (err) return [];

        const type = `${arg.tableId}` as const
        const id = `${arg.sysId}`
        return [{ type, id }, type]
      }
    }),

    delete: builder.mutation<void, IDeleteOptions>({
      query: (request) => {
        return {
          url: `/${request.portalId}/TableData/Delete(${request.tableId})`,
          method: 'DELETE',
          headers: { 'info-query': JSON.stringify(request) }
        }
      },
      invalidatesTags: (result, err, arg) => {
        if (err) return [];

        const type = `${arg.tableId}` as const
        return [{ type }, type]
      }
    })
  })
})

export const {
  useReadQuery,
  useLazyReadQuery,
  useReadMultipleQuery,
  useLazyReadMultipleQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
} = tableDataApi;