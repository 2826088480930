import { AvatarText } from "../avatar/AvatarText";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useEffect } from "react";
import { useGetProperties } from "../../../base/hooks";
import { useLazyGetMediaQuery } from "../../../services/data/media";

export const MediaComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const mediaId: string | undefined = properties[Property.MediaId];
    const altText: string | undefined = properties[Property.AltText];
    const tooltip: string | undefined = properties[Property.Tooltip];

    if (!mediaId || mediaId === '00000000-0000-0000-0000-000000000000') {
        return <AvatarText className={className} label="" random={true} title={tooltip} />
    }

    return (
        <MediaImage mediaId={mediaId} altText={altText} className={className} title={tooltip} />
    )
}

export const MediaImage = ({ mediaId, altText, title, className }: {
    mediaId: string,
    altText?: string,
    title?: string,
    className?: string,
}) => {
    const [getMedia, { data: media, isSuccess }] = useLazyGetMediaQuery();

    useEffect(() => {
        if (!mediaId || mediaId === '00000000-0000-0000-0000-000000000000') return
        getMedia(mediaId)

        // eslint-disable-next-line
    }, [mediaId])

    return <>
        {!isSuccess && <div className={className}>{altText}</div>}
        {isSuccess && media && <img alt={altText}
            className={className}
            title={title}
            src={`data:${media?.mimeType};base64,${media?.base64}`} />}
    </>

}