import { IActionProps } from "../models";
import { MUIIcon } from "../../../widgets";

export const ActionGroupComponent = ({ action, className, children }: IActionProps) => {
    return (
        <div className={className}>
            {action.icon && <MUIIcon iconName={action.icon} className="me-1" />}
            <span>{action.name}</span>
            {children}
        </div>)
}