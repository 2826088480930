import { ActionMenuItem } from './ActionMenuItem'
import { IToolbarAction } from '../../models'
import React from 'react'

export const ActionMenu = ({ actions, className, onAction }: {
    actions: IToolbarAction[],
    className?: string,
    onAction: (id: string) => Promise<void>
}) => {

    return <>
        {actions.map((action, i) => <React.Fragment key={i}>
            <ActionMenuItem className={className} action={action} onAction={onAction} />
        </React.Fragment>)}
    </>
}

