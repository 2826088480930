import { DataContextWithStatus, IDataContext, IPage, IPageView, PageType } from "../models";
import { useEffect, useState } from "react";
import { useLazyReadMultipleQuery, useLazyReadQuery } from "../../../services/data/tableDataApi";

import { IEntity } from "../../../base/types";
import { useAuth } from "../../../../modules/auth";
import { useDataContext } from "../context/DataContextProvider";

export const useGetDesignData = (page: IPage, sysId?: string) => {
    const [readRecord] = useLazyReadQuery();
    const [readRecords] = useLazyReadMultipleQuery();
    const [tableId] = useState<string | undefined>(page.sourceObject)
    const { user, siteId } = useAuth();
    const [result, setResult] = useState<DataContextWithStatus>({
        type: "Static",
        isLoading: false,
        isSuccess: false,
        isError: false
    })

    const getDefaultResult = () => {
        let pageData: IDataContext = { type: "Static" };

        if (page.type === PageType.List || page.type === PageType.ListPart) {
            pageData = { type: "List", data: { count: 0, records: [] } };
            setResult({ type: "List", data: { count: 0, records: [] }, isLoading: false, isSuccess: true, isError: false });
        } else {
            pageData = { type: "Card", data: {} };
            setResult({ type: "Card", data: {}, isLoading: false, isSuccess: true, isError: false });
        }

        return Promise.resolve(pageData);
    }

    const getRecordAsync = ({ userId, portalId, sysId }: {
        userId: string, portalId: string, sysId: string
    }): Promise<IEntity | IEntity[]> => {
        if (!tableId) {
            return getDefaultResult()
        }

        setResult({ type: "Static", isLoading: true, isSuccess: false, isError: false })
        return readRecord({ userId, portalId, tableId: tableId, sysId, fieldList: [] })
            .unwrap()
            .then((record) => {
                let pageData: IDataContext = { type: "Static" };
                if (page.type === PageType.List || page.type === PageType.ListPart) {
                    pageData = { type: "List", data: { count: 1, records: [record] } }
                    setResult({ type: "List", data: { count: 1, records: [record] }, isLoading: false, isSuccess: true, isError: false })
                } else {
                    pageData = { type: "Card", data: record }
                    setResult({ type: "Card", data: record, isLoading: false, isSuccess: true, isError: false })
                }

                return Promise.resolve(pageData)
            })
            .catch(err => {
                const error = err.data?.error?.message || 'Failed to get data'
                setResult({ type: "Static", isLoading: false, isSuccess: false, isError: true, error })
                return Promise.reject(error)
            })
    }

    const getMultipleRecordsAsync = ({ userId, portalId }: {
        userId: string, portalId: string
    }): Promise<IEntity | IEntity[]> => {
        if (!tableId) {
            return getDefaultResult();
        }

        const recordLimit = page.type === PageType.List || page.type === PageType.ListPart ? 5 : 1;
        const pageNo = 0;
        const filters = []
        
        if (page.filters && page.filters.length > 0)
            filters.push({ group: 0, filters: page.filters })

        setResult({ type: "Static", isLoading: true, isSuccess: false, isError: false })
        return readRecords({ userId, portalId, tableId: tableId, recordLimit, pageNo, filters, fieldList: [] })
            .unwrap()
            .then((data) => {
                let pageData: IDataContext = { type: "Static" };
                if (page.type === PageType.List || page.type === PageType.ListPart) {
                    pageData = { type: "List", data }
                    setResult({ type: "List", data, isLoading: false, isSuccess: true, isError: false })
                } else {
                    const record = data.records.length > 0 ? data.records[0] : {}
                    pageData = { type: "Card", data: record }
                    setResult({ type: "Card", data: record, isLoading: false, isSuccess: true, isError: false })
                }

                return Promise.resolve(pageData)
            })
            .catch(err => {
                const error = err.data?.error?.message || 'Failed to get data'
                setResult({ type: "Static", isLoading: false, isSuccess: false, isError: true, error })
                return Promise.reject(error)
            })
    }

    const refetch = () => {
        if (!user || !siteId)
            return Promise.reject(`Invalid user`)

        const { userId } = user;

        switch (page.type) {
            case PageType.Root:
            case PageType.Card:
            case PageType.CardPart:
                if (sysId)
                    return getRecordAsync({ userId, portalId: siteId, sysId });
                else
                    return getMultipleRecordsAsync({ userId, portalId: siteId })
            case PageType.List:
            case PageType.ListPart:
                return getMultipleRecordsAsync({ userId, portalId: siteId })
            default:
                return Promise.reject(`Invalid page type: ${page.type}`)
        }
    }

    useEffect(() => {
        refetch()
        // eslint-disable-next-line
    }, [user])


    return result
}

export const useUpdateDesignDataContext = ({ page, pageView }: {
    page: IPage,
    pageView?: IPageView,
}) => {
    const { type, data, isLoading, isSuccess, isError, error } = useGetDesignData(page, pageView?.systemId)
    const [isUpdated, setIsUpdated] = useState<boolean>(false)
    const { setData } = useDataContext();

    useEffect(() => {
        if (isLoading || isError) {
            setData({ type: "Static", isLoading, isSuccess, isError, error });
        } else if (isSuccess && data) {
            switch (type) {
                case "List":
                    setData({ type, data, isSuccess: true, isLoading: false, isError: false });
                    break;
                case "Card":
                    setData({ type, data, isSuccess: true, isLoading: false, isError: false });
                    break;
            }
        }

        setIsUpdated(true)

        // eslint-disable-next-line
    }, [isLoading, isSuccess, isError])

    return isUpdated;
}
