import { ISite } from '../../services/auth/models'
import { Link } from 'react-router-dom'
import { SiteGeneralSettings } from './SiteGeneralSettings'
import clsx from 'clsx'

export const SiteSettings = ({ site, className }: {
    site: ISite,
    className?: string
}) => {
    return (
        <div className={clsx(className, 'card')}>
            <div className='card-header min-h-5px border-0'>
                <h4 className='my-3'>Site Settings</h4>
                <div className='card-toolbar d-flex flex-wrap flex-row gap-2'>
                    <Link
                        to={`/site(${site.portalId})/filters`}
                        className='btn btn-secondary btn-sm py-1'>Security Filters</Link>
                    <Link
                        to={`/site(${site.portalId})/users`}
                        className='btn btn-secondary btn-sm py-1'>Site Users</Link>
                </div>
            </div>
            <div className='card-body'>
                <SiteGeneralSettings site={site} />
            </div>
        </div>
    )
}

