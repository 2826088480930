import { useCreateMessageMutation, useGetMessagesByIdQuery } from "../../../services/data";

import { Chat } from "./Chat";
import { IChatMessage } from "../../../data";
import { IControlProps } from "../../../base/models";
import { Property } from "../../../base/types";
import { useEffect } from "react"
import { useGetProperties } from "../../../base/hooks";

export const ChatComponent = ({ control, className }: IControlProps) => {
    const { properties } = useGetProperties({ bindings: control.bindings });
    const entityId: string | undefined = properties[Property.EntityId];
    const entity: string | undefined = properties[Property.Entity]
    const title: string | undefined = properties[Property.Title]
    const headerClass: string | undefined = properties[Property.HeaderClass]
    const collapsible: boolean | undefined = properties[Property.Collapsible]

    return (
        <div className={className}>
            {entity && entityId ?
                <ChatWrapper
                    title={title}
                    icon="ChatOutlined"
                    headerClass={headerClass}
                    collapsible={collapsible}
                    entity={`${entity}`}
                    entityId={entityId} /> :
                <Chat
                    title={title}
                    icon="ChatOutlined"
                    headerClass={headerClass}
                    collapsible={collapsible}
                    messages={[]}
                    onSendMessage={() => { }} />
            }
        </div>)
}

const ChatWrapper = ({ entity, entityId, title, icon, headerClass, collapsible }: {
    title?: string,
    icon?: string,
    entity: string,
    entityId: string,
    headerClass?: string,
    collapsible?: boolean
}) => {
    const { data, isSuccess, refetch } = useGetMessagesByIdQuery({ entity, entityId });
    const [sendMessage] = useCreateMessageMutation();

    useEffect(() => {
        setInterval(() => {
            refetch();
        }, 1000 * 60)

        // eslint-disable-next-line
    }, [isSuccess])

    const handleSendMessage = (message: Partial<IChatMessage>) => {
        sendMessage({
            ...message,
            entity,
            entityId,
            userId: 'portal-user',
            userName: 'Portal User',
            dateTime: new Date()
        })
            .unwrap()
            .then(message => console.log("message", message))
            .catch(err => console.log("err", err));
    }

    return <Chat
        title={title}
        icon={icon}
        headerClass={headerClass}
        collapsible={collapsible}
        messages={data || []}
        onSendMessage={handleSendMessage} />
}