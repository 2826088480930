import { DesignAction, ILayoutCollectionProps, IPageComponent } from "../../../base/models";
import { createControl, readClipboardControlAsync, resetControlIds } from "../../helpers";

import { ComponentsDialog } from "../components";
import { DndItem } from "../../dnd/models";
import { Droppable } from "../../dnd";
import { PageControlDesigner } from "../PageControlDesigner";
import React from "react";
import { useState } from "react"
import { useToaster } from "../../../layout/toast";

export const LayoutControlCollection = ({ controls, onDesignChange }: ILayoutCollectionProps) => {
    const { showToaster } = useToaster()
    const [dialog, showDialog] = useState<boolean>(false);
    const [insertAt, setInsertAt] = useState<"First" | "Last">("First")

    const onSelectComponent = (component: IPageComponent) => {
        const newControl = createControl(component)
        switch (insertAt) {
            case "First":
                onDesignChange({
                    type: DesignAction.InsertFirstLayoutControl,
                    control: newControl
                });
                break;
            case "Last":
                onDesignChange({
                    type: DesignAction.InsertLastLayoutControl,
                    control: newControl
                });
                break;
        }
    }

    const onDropItem = (item: DndItem, insertAt: "First" | "Last") => {
        if (item.type !== "Control") return;
        switch (insertAt) {
            case "First":
                onDesignChange({
                    type: DesignAction.DropFirstLayoutControl,
                    control: item.control
                });
                break;
            case "Last":
                onDesignChange({
                    type: DesignAction.DropLastLayoutControl,
                    control: item.control
                });
                break;
        }
    }

    const pasteControlAsync = async () => {
        let controlToPaste = await readClipboardControlAsync()
        if (!controlToPaste) {
            showToaster("Not a valid page control")
            return
        }

        onDesignChange({
            type: DesignAction.InsertFirstLayoutControl,
            control: resetControlIds(controlToPaste)
        })
    }

    return (
        <div title={`Page Layout`} className={"pfx-control"}>
            {(controls && controls.length > 0) && <>
                <Droppable layout={"Auto"}
                    title={`Click to insert a new component`}
                    onDropItem={(item) => onDropItem(item, "First")}
                    onClick={(e) => {
                        e.stopPropagation();
                        setInsertAt("First")
                        showDialog(true)
                    }} />

                {controls.slice()
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((ctrl) =>
                        <React.Fragment key={ctrl.controlId}>
                            <PageControlDesigner
                                control={ctrl}
                                orientation="Auto"
                                onDesignChange={onDesignChange} />
                        </React.Fragment>)}

                <Droppable layout={"Auto"}
                    title={`Click to insert a new component`}
                    onDropItem={(item) => onDropItem(item, "Last")}
                    onClick={(e) => {
                        e.stopPropagation();
                        setInsertAt("Last")
                        showDialog(true)
                    }} />
            </>}

            {(!controls || controls.length === 0) && <>
                <div className="d-flex align-items-center justify-content-center p-3">
                    <button type="button" title="Add a new control" className="btn btn-secondary btn-sm px-5 py-2 me-3"
                        onClick={(e) => {
                            e.stopPropagation()
                            setInsertAt("First")
                            showDialog(true)
                        }}>
                        <i className="las la-plus fs-3"></i> ADD A NEW CONTROL
                    </button>
                    <button type="button" title="Paste" className="btn btn-secondary btn-sm px-5 py-2"
                        onClick={(e) => {
                            e.stopPropagation()
                            pasteControlAsync()
                        }}>
                        <i className="las la-clipboard  fs-3"></i> PASTE CONTROLS
                    </button>
                </div>
            </>}

            <ComponentsDialog
                show={dialog}
                onSelect={onSelectComponent}
                onHideModal={() => showDialog(false)}
            />
        </div>)
}