import { ReactNode, createContext, useContext, useLayoutEffect, useState } from "react"

import { IToolbarAction } from "./models";
import { useLocation } from "react-router-dom";

export type ActionCallback = (actionId: string) => Promise<void>;

interface IToolbar {
    buttons?: IToolbarAction[],
    setButtons: (buttons?: IToolbarAction[]) => void,

    onAction?: React.MutableRefObject<ActionCallback>,
    setOnAction: (callback?: React.MutableRefObject<ActionCallback>) => void,
}

const context = createContext<IToolbar>({
    setButtons: () => { },
    setOnAction: () => { }
})

export const ToolbarProvider = ({ children }: { children?: ReactNode }) => {
    const [buttons, setButtons] = useState<IToolbarAction[]>();
    const [onAction, setOnAction] = useState<React.MutableRefObject<ActionCallback>>()

    const { pathname } = useLocation()

    useLayoutEffect(() => {
        setButtons(undefined)
        setOnAction(undefined)
    }, [pathname])

    return (
        <context.Provider value={{
            buttons,
            setButtons,
            onAction,
            setOnAction,
        }}>
            {children}
        </context.Provider>
    )
}

export function useToolbar() {
    return useContext(context)
}
