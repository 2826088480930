import { ActionLink } from "./components/ActionLink"
import { ActionMenu } from "./components/ActionMenu"
import DropdownButton from "react-bootstrap/esm/DropdownButton"
import { IToolbarAction } from "../models"
import { MUIIcon } from "../../../widgets"
import clsx from "clsx"

export const ToolbarAction = ({ action, className, onAction }: {
    action: IToolbarAction,
    className?: string,
    onAction: (id: string) => Promise<void>
}) => {
    const { label, icon } = action

    if (!action.children || action.children.length === 0) {
        return <ActionLink action={action} onAction={onAction} className={className} />
    } else {
        return (
            <DropdownButton
                title={<>
                    {(icon) && <MUIIcon iconName={icon} className="fs-5 me-1" />}
                    {label}
                </>}
                bsPrefix={"dropdown-item fs-7 fw-semibold"}
                className={clsx(action.className, className)} size="sm">
                <ActionMenu actions={action.children} onAction={onAction} />
            </DropdownButton>)
    }
}

