import React, { createContext, useContext, useState } from "react"

import { IDataSource } from "../base/types"
import { IPageAction } from "../design/page-action/models"
import { IPageControl } from "../base/models"

type ViewMode = "Preview" | "Design"

export interface IControlContext {
    type: "Control"
    control: IPageControl,
    dataSource?: IDataSource,
}

export interface IActionContext {
    type: "Action"
    action: IPageAction,
    dataSource?: IDataSource,
}

interface IDesignContextModel {
    mode: ViewMode,
    setMode: (mode: ViewMode) => void,
    context?: IControlContext | IActionContext,
    setContext: (context?: IControlContext | IActionContext) => void,
}

export const DesignContext = createContext<IDesignContextModel>({
    mode: "Preview",
    setMode: () => { },
    setContext: () => { },
})

export function useDesignContext() {
    return useContext(DesignContext)
}

export const DesignContextProvider = ({ children }: {
    children?: React.ReactNode
}) => {
    const [mode, setMode] = useState<ViewMode>("Preview");
    const [context, setContext] = useState<IControlContext | IActionContext>()

    return (
        <DesignContext.Provider value={{
            mode,
            setMode,
            context, setContext,
        }}>
            {children}
        </DesignContext.Provider>
    )
}