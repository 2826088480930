import { AlertMessage, MUIIcon, Spinner } from "../../../../widgets"

import { IPaymentButtonProps } from "../IPaymentButtonProps"
import clsx from "clsx"
import { getButtonClass } from "../../buttons/helper"
import { getPageLinkUrl } from "../../../../base/helpers"
import { invokePaymentPage } from "../paytm-helper"
import { toAbsoluteUrl } from "../../../../../helpers"
import { useAuth } from "../../../../../modules/auth"
import { useGetPaytmTokenMutation } from "../services"
import { useState } from "react"

export const PayTmButton = ({
    entity, entityId,
    enrolmentNo,
    orderNo, amount,
    successPage, failedPage,
    disabled, icon, label, tooltip, style, size, className,
    children
}: IPaymentButtonProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [getPaytmToken] = useGetPaytmTokenMutation()
    const { siteId } = useAuth()

    const handleOnClick = () => {
        if (!siteId || !orderNo || !amount || !successPage || !failedPage)
            return;

        setError('')

        const successPageUrl = getPageLinkUrl(siteId, successPage);
        const failedPageUrl = getPageLinkUrl(siteId, failedPage);

        setIsSubmitting(true)
        getPaytmToken({
            amount,
            orderNo,
            entity,
            entityId,
            currency: "INR",
            enrolmentNo,
            successPageUrl,
            failedPageUrl
        })
            .unwrap()
            .then(result => {
                return invokePaymentPage({
                    scriptInfo: { host: result.host, mid: result.mid },
                    data: {
                        amount: String(amount),
                        orderId: result.transactionId,
                        token: result.token,
                        tokenType: "TXN_TOKEN"
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    return (
        <>
            <button
                disabled={disabled === true || isSubmitting === true || !orderNo || !amount}
                type="button"
                title={tooltip}
                onClick={handleOnClick}
                className={clsx(className, getButtonClass(style, size, "btn-secondary"))}
            >
                <Spinner show={isSubmitting} className="me-1" />
                <img
                    alt='img'
                    className='h-25px'
                    src={toAbsoluteUrl('/media/logos/paytm.png')}
                />
                {label && <label>{label}</label>}

                {(!isSubmitting && icon) && <MUIIcon iconName={icon} />}
                {children}
            </button>
            <AlertMessage type="Error" show={!!error} message={error} />
        </>
    )

} 