import { IViewPortSize } from "../../../../base/styles"
import { SelectSizeUnit } from "./SelectSizeUnit"
import clsx from "clsx"

export const ViewportSizingStyles = ({ value, className, onChange }:{
    value: IViewPortSize,
    className?: string,
    onChange: (value?: IViewPortSize) => void
}) => {
    return (
        <div className={clsx(className)}>
            <div className="d-flex flex-row flex-wrap align-items-center">
                <SelectSizeUnit
                    title="Width"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, width: newValue }) }}
                    value={value?.width} />
                <SelectSizeUnit
                    title="Min. Width"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, minWidth: newValue }) }}
                    value={value?.minWidth} />
                <SelectSizeUnit
                    title="Height"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, height: newValue }) }}
                    value={value?.height} />
                <SelectSizeUnit
                    title="Min. Height"
                    className="w-50 pe-2"
                    onChange={(newValue) => { onChange({ ...value, minHeight: newValue }) }}
                    value={value?.minHeight} />
            </div>
        </div>)
}