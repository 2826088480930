import { IPageControl } from "../../base/models"
import clsx from "clsx";

export const DefaultLabel = ({ control, propertyId, defaultLabel, className }: {
    propertyId: string,
    defaultLabel: string,
    control: IPageControl,
    className?: string,
}) => {
    const binding = control.bindings?.find(p => p.propId === propertyId)

    const getLabel = () => {
        if (!binding?.value)
            return defaultLabel;

        switch (binding.type) {
            case "Const": return `"${binding.value}"`
            case "Field": return `{ ${binding.value} }`
            case "Expression": return `{ "${binding.value.query}" }`
        }
    }

    return <span className={clsx(className, "fw-bold fst-italic text-nowrap")}>{getLabel()}</span>
}
