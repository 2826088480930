import { useEffect, useState } from "react"

import { ISite } from "../../../services/auth/models"
import { Link } from "react-router-dom"
import { MUIIcon } from "../../../widgets"
import clsx from "clsx"
import { useAuth } from "../../../../modules/auth"

export const SiteCard = ({ site, onClick, className }: {
    site: ISite,
    onClick?: (site: ISite) => void
    className?: string
}) => {
    const { user } = useAuth()
    const [allowDesign, setAllowDesign] = useState<boolean>(false)

    useEffect(() => {
        if (!user) return
        const allow = user.roles.indexOf('SUPER') !== -1
        setAllowDesign(allow)
    }, [user])

    return (
        <div className={clsx(className, "card")}>
            <div className="card-header border-0 pt-5 min-h-5px">
                <h3 className="m-0 p-0 flex-fill text-center">{site.title}</h3>
            </div>
            <div className="card-body">
                <div className="text-center">
                    <MUIIcon iconName="PagesOutlined" className="fs-4x text-primary" />
                </div>
                <div className="text-truncate py-2">
                    <span className="text-gray-700 fw-semibold fs-7">{site.description}</span>
                </div>
            </div>
            <div className="card-footer bg-dark py-2">
                <div className="d-flex align-items-center justify-content-center">
                    <button type="button"
                        onClick={() => onClick && onClick(site)}
                        className="btn btn-link btn-sm text-primary fw-bold py-2">
                        <MUIIcon iconName="LaunchOutlined" className="text-primary me-1" />
                        Select
                    </button>
                    {(allowDesign) && <>
                        <Link to={`/site(${site.portalId})/settings`}
                            className="btn btn-link btn-sm text-primary fw-bold py-2 ms-5">
                            <MUIIcon iconName="SettingsOutlined" className="text-primary me-1" />
                            Settings
                        </Link>
                    </>}
                </div>
            </div>
        </div>
    )
}
