import { DataContextProvider, useDataContext } from "../../page/context";
import { IControlProps, IDataLink } from "../../../base/models";
import { useEffect, useState } from "react";

import { AlertMessage } from "../../../widgets";
import { DataContextProducer } from "../../page/context/DataContextProducer";
import { IFilterGroup } from "../../../data";
import { Property } from "../../../base/types";
import { getQueryFields } from "../../helpers";
import { useGetProperties } from "../../../base/hooks";

export const DataLinkComponent = ({ control, children, className }: IControlProps) => {
    const { properties, getBinding } = useGetProperties({ bindings: control.bindings });
    const dataLink: IDataLink | undefined = properties[Property.DataLink]
    const produce = dataLink?.dataSet === true ? "RecordSet" : "Record"
    const filters: IFilterGroup[] = [{ group: 0, filters: dataLink?.filters || [] }]
    const fields = getQueryFields(control.controls || [], [], []);
    const [dataLinkConfig, setDataLinkConfig] = useState<IDataLink>()

    useEffect(() => {
        var dataLinkValue = getBinding(Property.DataLink)?.value as IDataLink
        setDataLinkConfig(dataLinkValue)
        // eslint-disable-next-line
    }, [])

    if (!dataLink || !dataLinkConfig?.entity || (produce === "Record" && !dataLinkConfig?.systemId)) {
        return <div className={className}>
            <DataContextProvider>
                {children}
            </DataContextProvider>
        </div>
    }

    return (<div className={className}>
        <DataContextProvider>
            <DataContextProducer
                produce={produce}
                entity={dataLink.entity}
                systemId={dataLink.systemId}
                fields={fields}
                filters={filters} />
            {dataLink.entity && <>
                <DataLinkStatus />
                {children}
            </>}
        </DataContextProvider>
    </div>)
}

const DataLinkStatus = () => {
    const { isError, error } = useDataContext()

    return <>
        <AlertMessage type="Error" show={isError} message={error} />
    </>
}